import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgEr = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7148)">
            <Path
                d="M233.739 233.739C233.739 233.739 75.1305 437.056 74.9805 437.019C121.306 483.346 185.307 512 256 512C397.384 512 512 397.384 512 256L233.739 233.739Z"
                fill="#338AF3"
            />
            <Path
                d="M233.739 256C233.739 256 75.1305 74.945 74.9805 74.981C121.306 28.654 185.307 0 256 0C397.384 0 512 114.616 512 256H233.739Z"
                fill="#6DA544"
            />
            <Path
                d="M74.9795 74.98C-24.9945 174.954 -24.9945 337.045 74.9795 437.02C74.9725 437.061 512 256 512 256L74.9795 74.98Z"
                fill="#D80027"
            />
            <Path
                d="M133.565 150.261C84.4665 150.261 44.5225 190.206 44.5225 239.304V272.695C44.5225 321.794 84.4675 361.738 133.565 361.738C182.663 361.738 222.608 321.793 222.608 272.695V239.304C222.609 190.206 182.664 150.261 133.565 150.261ZM189.217 272.696C189.217 297.568 172.815 318.677 150.26 325.786V289.392L173.884 265.768L150.273 242.157L150.26 233.754V222.61H116.87V256L93.2625 279.607L116.87 303.214V325.787C94.3155 318.678 77.9135 297.569 77.9135 272.697V239.306C77.9135 208.619 102.879 183.654 133.565 183.654C164.251 183.654 189.217 208.62 189.217 239.306V272.696Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7148">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgEr
