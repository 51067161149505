import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgSa = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7275)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#6DA544"
            />
            <Path
                d="M144.696 306.087C144.696 324.528 159.646 339.478 178.087 339.478H278.261C278.261 354.846 290.719 367.304 306.087 367.304H339.478C354.846 367.304 367.304 354.846 367.304 339.478V306.087H144.696Z"
                fill="#F0F0F0"
            />
            <Path
                d="M370.087 144.696V222.609C370.087 234.884 360.101 244.87 347.826 244.87V278.261C378.513 278.261 403.478 253.295 403.478 222.609V144.696H370.087Z"
                fill="#F0F0F0"
            />
            <Path
                d="M130.783 222.609C130.783 234.884 120.797 244.87 108.522 244.87V278.261C139.209 278.261 164.174 253.295 164.174 222.609V144.696H130.783V222.609Z"
                fill="#F0F0F0"
            />
            <Path
                d="M320 144.696H353.391V222.609H320V144.696Z"
                fill="#F0F0F0"
            />
            <Path
                d="M269.913 189.217C269.913 192.286 267.416 194.782 264.348 194.782C261.28 194.782 258.783 192.285 258.783 189.217V144.695H225.392V189.217C225.392 192.286 222.895 194.782 219.827 194.782C216.759 194.782 214.262 192.285 214.262 189.217V144.695H180.87V189.217C180.87 210.698 198.346 228.174 219.827 228.174C228.1 228.174 235.772 225.574 242.088 221.158C248.403 225.573 256.076 228.174 264.349 228.174C266.015 228.174 267.653 228.057 269.264 227.852C266.898 237.601 258.118 244.869 247.653 244.869V278.26C278.34 278.26 303.305 253.294 303.305 222.608V189.217V144.695H269.914V189.217H269.913Z"
                fill="#F0F0F0"
            />
            <Path
                d="M180.87 244.87H230.957V278.261H180.87V244.87Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7275">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgSa
