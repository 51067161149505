import {
    nullable,
    object,
    oneOf,
    Result,
    shape,
    string,
} from '@zeal/toolkit/Result'
import * as Web3 from '@zeal/toolkit/Web3'

import { Account } from '@zeal/domains/Account'

export const parse = (value: unknown): Result<unknown, Account> =>
    object(value).andThen((acc) =>
        shape({
            label: string(acc.label),
            address: Web3.address.parse(acc.address),
            avatarSrc: oneOf(acc.avatarSrc, [
                string(acc.avatarSrc),
                nullable(acc.avatarSrc),
            ]),
        })
    )
