import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgIl = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7186)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M352.393 200.348H288.13L256 144.696L223.871 200.348H159.607L191.741 256L159.607 311.652H223.871L256 367.304L288.13 311.652H352.393L320.259 256L352.393 200.348ZM295.475 256L275.739 290.188H236.264L216.525 256L236.263 221.812H275.738L295.475 256ZM256 187.623L263.346 200.347H248.656L256 187.623ZM196.786 221.812H211.478L204.132 234.536L196.786 221.812ZM196.786 290.188L204.133 277.464L211.479 290.188H196.786ZM256 324.376L248.655 311.652H263.346L256 324.376ZM315.214 290.188H300.522L307.869 277.464L315.214 290.188ZM300.522 221.812H315.214L307.868 234.536L300.522 221.812Z"
                fill="#0052B4"
            />
            <Path
                d="M415.357 55.6521H96.6432C73.2802 74.2601 53.2442 96.8621 37.5742 122.435H474.426C458.755 96.8631 438.719 74.2601 415.357 55.6521Z"
                fill="#0052B4"
            />
            <Path
                d="M96.6432 456.348H415.356C438.719 437.74 458.755 415.138 474.425 389.565H37.5742C53.2452 415.137 73.2812 437.74 96.6432 456.348Z"
                fill="#0052B4"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7186">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgIl
