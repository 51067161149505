import { Address } from '@zeal/domains/Address'
import { CryptoCurrency } from '@zeal/domains/Currency'
import { OPTIMISM_ETH } from '@zeal/domains/Currency/constants'
import { NetworkHexId } from '@zeal/domains/Network'
import { parseNetworkHexId } from '@zeal/domains/Network/helpers/parse'

export const POLYGON_POL: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Polygon|0x0000000000000000000000000000000000001010',
    symbol: 'POL',
    code: 'POL',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Polygon|0x0000000000000000000000000000000000001010',
    name: 'POL',
    address: '0x0000000000000000000000000000000000001010',
    networkHexChainId: parseNetworkHexId('0x89').getSuccessResultOrThrow(
        'failed to parse static network hex id'
    ),
    marketCapRank: null,
}

export const FALLBACK_TOP_UP_CURRENCIES: CryptoCurrency[] = [
    POLYGON_POL,
    OPTIMISM_ETH,
]

export const BICONOMY_SUPPORTED_GAS_ABSTRACTION_CURRENCIES: Record<
    NetworkHexId,
    Address[]
> = {
    // Polygon
    ['0x89' as NetworkHexId]: [
        '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
        '0xd6df932a45c0f255f85145f286ea0b292b21c90b',
        '0x9c9e5fd8bbc25984b178fdce6117defa39d2db39',
        '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
        '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
        '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
        '0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4',
        '0xb5c064f955d8e7f38fe0460c556a72987494ee17',
        '0xbbba073c31bf03b8acf7c28ef0738decf3695683',
        '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a',
        '0x2e1ad108ff1d8c782fcbbb89aad783ac49586756',
        '0xb33eaad8d922b1083446dc23f610c2567fb5180f',
        '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
        '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
        '0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3',
        '0x229b1b6c23ff8953d663c4cbb519717e323a0a84',
        '0x172370d5cd63279efa6d502dab29171933a610af',
        '0xe7804d91dfcde7f776c90043e03eaa6df87e6395',
        '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
        '0xef938b6da8576a896f6e0321ef80996f4890f9c4',
        '0xdab625853c2b35d0a9c6bd8e5a097a664ef4ccfb',
        '0xd99bafe5031cc8b345cb2e8c80135991f12d7130',
        '0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7',
        '0xe06bd4f5aac8d0aa337d13ec88db6defc6eaeefe',
        '0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c',
        '0x4e78011ce80ee02d2c3e649fb657e45898257815',
        '0xc3c7d422809852031b44ab29eec9f1eff2a58756',
        '0xee9a352f6aac4af1a5b9f467f6a93e0ffbe9dd35',
        '0xfa68fb4628dff1028cfec22b4162fccd0d45efb6',
        '0xee7666aacaefaa6efeef62ea40176d3eb21953b9',
        '0xa3fa99a148fa48d14ed51d610c367c61876997f1',
        '0x614389eaae0a6821dc49062d56bda3d9d45fa2ff',
        '0x42d61d766b85431666b39b89c43011f24451bff6',
        '0x431cd3c9ac9fc73644bf68bf5691f4b83f9e104f',
        '0xc17c30e98541188614df99239cabd40280810ca3',
        '0x1d734a02ef1e1f5886e66b0673b71af5b53ffa94',
        '0x62f594339830b90ae4c084ae7d223ffafd9658a7',
        '0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4',
        '0xa1428174f516f527fafdd146b883bb4428682737',
        '0xdf7837de1f2fa4631d716cf2502f8b230f1dcc32',
        '0x255707b70bf90aa112006e1b07b9aea6de021424',
        '0xd0258a3fd00f38aa8090dfee343f10a9d4d30d3f',
        '0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689',
        '0xdc3326e71d45186f113a2f448984ca0e8d201995',
    ],
    // Gnosis
    ['0x64' as NetworkHexId]: [
        '0xddafbb505ad214d7b80b1f830fccc89b60fb7a83',
        '0x4ecaba5870353805a9f068101a40e0f32ed605c6',
        '0x9C58BAcC331c9aa871AFD802DB6379a98e80CEdb',
    ],
    // BSC
    ['0x38' as NetworkHexId]: [
        '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        '0x55d398326f99059ff775485246999027b3197955',
        '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
        '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
        '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        '0x4b0f1812e5df2a09796481ff14017e6005508003',
        '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
        '0x14016e85a25aeb13065688cafb43044c2ef86784',
        '0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
        '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
        '0xf7de7e8a6bd59ed41a4b5fe50278b3b7f31384df',
        '0x7ddc52c4de30e94be3a6a0a2b259b2850f421989',
        '0x715d400f88c167884bbcc41c5fea407ed4d2f8a0',
        '0xd17479997f34dd9156deef8f95a52d81d265be9c',
        '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
        '0xfe56d5892bdffc7bf58f2e84be1b2c32d21c308b',
        '0x4691937a7508860f876c9c0a2a617e7d9e945d4b',
        '0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377',
        '0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
        '0xca3f508b8e4dd382ee878a314789373d80a5190a',
        '0x762539b45a1dcce3d36d080f74d1aed37844b878',
        '0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2',
        '0xfd7b3a77848f1c2d67e05e54d78d174a0c850335',
        '0x1fa4a73a3f0133f0025378af00236f3abdee5d63',
        '0x250632378e573c6be1ac2f97fcdf00515d0aa91b',
        '0x0eb3a705fc54725037cc9e008bdede697f62f335',
        '0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153',
        '0x8ff795a6f4d97e7887c79bea79aba5cc76444adf',
        '0xb59490ab09a0f526cc7305822ac65f2ab12f9723',
        '0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
        '0xa2b726b1145a4773f68593cf171187d8ebe4d495',
        '0xa1faa113cbe53436df28ff0aee54275c13b40975',
        '0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e',
        '0x52ce071bd9b1c4b00a0b92d298c512478cad67e8',
        '0x88f1a5ae2a3bf98aeaf342d26b30a79438c9142e',
        '0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18',
        '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
        '0xa184088a740c695e156f91f5cc086a06bb78b827',
        '0xfe19f0b51438fd612f6fd59c1dbb3ea319f433ba',
        '0xba2ae424d960c26247dd6c32edc70b295c744c43',
        '0x2ed9a5c8c13b93955103b9a7c167b67ef4d568a3',
        '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
        '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
        '0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb',
        '0x8888888888f004100c0353d657be6300587a6ccd',
        '0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51',
        '0x111111111117dc0aa78b770fa6a738034120c302',
        '0xe02df9e3e622debdd69fb838bb799e3f168902c5',
        '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
        '0x101d82428437127bf1608f699cd651e6abf9766e',
        '0xf307910a4c7bbc79691fd374889b36d8531b08e3',
    ],
    // Optimism
    ['0xa' as NetworkHexId]: [
        '0x4200000000000000000000000000000000000006',
        '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
        '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58',
        '0x68f180fcce6836688e9084f035309e29bf0a2095',
        '0x0b2c639c533813f4aa9d7837caf62653d097ff85',
        '0x8700daec35af8ff88c16bdf0418774cb3d7599b4',
        '0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6',
        '0x8c6f28f2f1a3c87f0f938b96d27520d9751ec8d9',
        '0x9e1028f5f1d5ede59748ffcee5532509976840e0',
        '0x4200000000000000000000000000000000000042',
        '0x1f32b1c2345538c0c6f582fcb022739c4a194ebb',
        '0x217d47011b23bb961eb6d93ca9945b7501a5bb11',
        '0x50c5725949a6f0c72e6c4a641f24049a917db0cb',
        '0x3c8b650257cfb5f272f799f5e2b4e65093a11a05',
        '0x8ae125e8653821e851f12a49f7765db9a9ce7384',
        '0x920cf626a271321c151d027030d5d08af699456b',
        '0x9bcef72be871e61ed4fbbc7630889bee758eb81d',
        '0xdfa46478f9e5ea86d57387849598dbfb2e964b02',
        '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    ],
    // Avalanche
    ['0xa86a' as NetworkHexId]: [
        '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
        '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
        '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
        '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
        '0x50b7545627a5162f82a992c33b87adc75187b218',
        '0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
        '0x19860ccb0a68fd4213ab9d8266f7bbf05a8dde98',
        '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
        '0x1c20e891bab6b1727d14da358fae2984ed9b59eb',
        '0x63a72806098bd3d9520cc43356dd78afe5d386d9',
        '0xd1c3f94de7e5b45fa4edbba472491a9f4b166fc4',
        '0x8729438eb15e2c8b576fcc6aecda6a148776c0f5',
        '0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd',
        '0xce1bffbd5374dac86a2893119683f4911a2f7814',
        '0x214db107654ff987ad859f34125307783fc8e387',
        '0x2147efff675e4a4ee1c2f918d181cdbd7a8e208f',
        '0x5947bb275c521040051d82396192181b413227a3',
        '0x37b608519f91f70f2eeb0e5ed9af4061722e4f76',
        '0x130966628846bfd36ff31a822705796e8cb8c18d',
        '0xd24c2ad096400b6fbcd2ad8b24e7acbc21a1da64',
        '0x62edc0692bd897d2295872a9ffcac5425011c661',
        '0x60781c2586d68229fde47564546784ab3faca982',
        '0x027dbca046ca156de9622cd1e2d907d375e53aa7',
        '0x6d923f688c7ff287dc3a5943caeefc994f97b290',
        '0x8f47416cae600bccf9530e9f3aeaa06bdd1caa79',
        '0x7761e2338b35bceb6bda6ce477ef012bde7ae611',
        '0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590',
        '0xf14f4ce569cb3679e99d5059909e23b07bd2f387',
        '0x2b2c81e08f1af8835a78bb2a90ae924ace0ea4be',
        '0xc891eb4cbdeff6e073e859e987815ed1505c2acd',
        '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    ],

    // Arbitrum
    ['0xa4b1' as NetworkHexId]: [
        '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
        '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
        '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
        '0x5979d7b546e38e414f7e9822514be443a4800529',
        '0x912ce59144191c1204e64559fe8253a0e49e6548',
        '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
        '0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a',
        '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0',
        '0x6c2c06790b3e3e3c38e12ee22f8183b37a13ee55',
        '0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07',
        '0x040d1edc9569d4bab2d15287dc5a4f10f56a56b8',
        '0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
        '0x4e352cf164e64adcbad318c3a1e222e9eba4ce42',
        '0x51fc0f6660482ea73330e414efd7808811a57fa2',
        '0xd4d42f0b6def4ce0383636770ef773390d85c61a',
        '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
        '0x3e6648c5a70a150a88bce65f4ad4d506fe15d2af',
        '0x17fc002b466eec40dae837fc4be5c67993ddbd6f',
        '0x6694340fc020c5e6b96567843da2df01b2ce1eb6',
        '0x93c15cd7de26f07265f0272e0b831c5d7fab174f',
        '0xd74f5255d557944cf7dd0e45ff521520002d5748',
        '0xfa5ed56a203466cbbc2430a43c66b9d8723528e7',
        '0x13ad51ed4f1b7e9dc168d8a00cb3f4ddd85efa60',
        '0x088cd8f5ef3652623c22d48b1605dcfe860cd704',
        '0x9ed7e4b1bff939ad473da5e7a218c771d1569456',
        '0xec70dcb4a1efa46b8f2d97c310c9c4790ba5ffa8',
        '0x3082cc23568ea640225c2467653db90e9250aaa0',
        '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    ],
    // Base
    ['0x2105' as NetworkHexId]: [
        '0x50c5725949a6f0c72e6c4a641f24049a917db0cb',
        '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
        '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
        '0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22',
        '0x9eaf8c1e34f05a589eda6bafdf391cf6ad3cb239',
        '0xfA980cEd6895AC314E7dE34Ef1bFAE90a5AdD21b',
        '0x28fe69ff6864c1c218878bdca01482d36b9d57b1',
        '0xfd4330b0312fdeec6d4225075b82e00493ff2e3f',
        '0xb6fe221fe9eef5aba221c348ba20a1bf5e73624c',
    ],
    // Ethereum Mainnet
    ['0x1' as NetworkHexId]: [
        '0xdac17f958d2ee523a2206206994597c13d831ec7',
        '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        '0x4fabb145d64652a948d72533023f6e7a623c7c53',
        '0x514910771af9ca656af840dff83e8264ecf986ca',
        '0xB8c77482e45F1F44dE1745F52C74426C631bDD52',
        '0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1',
        '0xf57e7e7c23978c3caec3c3548e3d615c346e79ff',
        '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
        '0x50327c6c5a14dcade707abad2e27eb517df87ab5',
        '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
        '0x0000000000085d4780B73119b644AE5ecd22b376',
        '0x6982508145454ce325ddbe47a25d4ec3d2311933',
        '0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC',
        '0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32',
        '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        '0xb131f4A55907B10d1F0A50d8ab8FA09EC342cd74',
        '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
        '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
        '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
        '0x92D6C1e31e14520e676a687F0a93788B716BEff5',
        '0x152649eA73beAb28c5b49B26eb48f7EAD6d4c898',
        '0xe28b3B32B6c345A34Ff64674606124Dd5Aceca30',
        '0x3c3a81e81dc49A522A592e7622A7E711c06bf354',
        '0x5283D291DBCF85356A21bA090E6db59121208b44',
        '0x0F5D2fB29fb7d3CFeE444a200298f468908cC942',
        '0x4E15361FD6b4BB609Fa63C81A2be19d873717870',
        '0xc944E90C64B2c07662A292be6244BDf05Cda44a7',
        '0x3845badAde8e6dFF049820680d1F14bD3903a5d0',
        '0x4d224452801ACEd8B2F0aebE155379bb5D594381',
        '0xc00e94Cb662C3520282E6f5717214004A7f26888',
        '0x69af81e73A73B40adF4f3d4223Cd9b1ECE623074',
        '0xaea46A60368A7bD060eec7DF8CBa43b7EF41Ad85',
        '0xe3c408BD53c31C085a1746AF401A4042954ff740',
        '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
        '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
        '0x3506424F91fD33084466F402d5D97f05F8e3b4AF',
        '0x6De037ef9aD2725EB40118Bb1702EBb27e4Aeb24',
        '0x9D65fF81a3c488d585bBfb0Bfe3c7707c7917f54',
        '0x6B3595068778DD592e39A122f4f5a5cF09C90fE2',
        '0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0',
        '0x582d872A1B094FC48F5DE31D3B73F2D9bE47def1',
        '0xAf5191B0De278C7286d6C7CC6ab6BB8A73bA2Cd6',
        '0xd0dA9cBeA9C3852C5d63A95F9ABCC4f6eA0F9032',
        '0x5B7533812759B45C2B44C19e320ba2cD2681b542',
        '0x163f8C2467924be0ae7B5347228CABF260318753',
        '0x6DEA81C8171D0bA574754EF6F8b412F2Ed88c54D',
        '0x767FE9EDC9E0dF98E07454847909b5E959D7ca0E',
        '0xde4EE8057785A7e8e800Db58F9784845A5C2Cbd6',
        '0xC18360217D8F7Ab5e7c516566761Ea12Ce7F9D72',
        '0x111111111117dC0aa78b770fA6A738034120C302',
        '0x967da4048cD07aB37855c090aAF366e4ce1b9F48',
        '0x137dDB47Ee24EaA998a535Ab00378d6BFa84F893',
        '0xf939E0A03FB07F59A73314E73794Be0E57ac1b4E',
        '0xcf0C122c6b73ff809C693DB761e7BaeBe62b6a2E',
        '0x0391D2021f89DC339F60Fff84546EA23E337750f',
        '0xF411903cbC70a74d22900a5DE66A2dda66507255',
        '0x64Bc2cA1Be492bE7185FAA2c8835d9b824c8a194',
        '0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
        '0xdeFA4e8a7bcBA345F687a2f1456F5Edd9CE97202',
        '0x430EF9263E76DAE63c84292C3409D61c598E9682',
        '0x320623b8E4fF03373931769A31Fc52A4E78B5d70',
        '0xBA11D00c5f74255f56a5E366F4F77f5A186d7f55',
        '0xae78736Cd615f374D3085123A210448E74Fc6393',
        '0x8207c1FfC5B6804F6024322CcF34F29c3541Ae26',
        '0x0D8775F648430679A709E98d2b0Cb6250d2887EF',
        '0xD33526068D116cE69F19A9ee46F0bd304F21A51f',
        '0x2dfF88A56767223A5529eA5960Da7A3F5f766406',
        '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
        '0xB62E45c3Df611dcE236A6Ddc7A493d79F9DFadEf',
        '0x595832F8FC6BF59c85C527fEC3740A1b7a361269',
        '0x4a220E6096B25EADb88358cb44068A3248254675',
        '0xA9B1Eb5908CfC3cdf91F9B8B3a74108598009096',
        '0xba100000625a3754423978a60c9317c58a424e3D',
        '0x9AAb071B4129B083B01cB5A0Cb513Ce7ecA26fa5',
        '0x853d955aCEf822Db058eb8505911ED77F175b99e',
        '0xA0b73E1Ff0B80914AB6fe0444E65848C4C34450b',
        '0xC669928185DbCE49d2230CC9B0979BE6DC797957',
        '0x5732046A883704404F284Ce41FfADd5b007FD668',
        '0x0C10bF8FcB7Bf5412187A595ab97a3609160b5c6',
        '0x7A58c0Be72BE218B41C608b7Fe7C5bB630736C71',
        '0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3',
        '0x12BB890508c125661E03b09EC06E404bc9289040',
        '0x7DD9c5Cba05E151C895FDe1CF355C9A1D5DA6429',
        '0xf819d9Cb1c2A819Fd991781A822dE3ca8607c3C9',
        '0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671',
        '0x5E8422345238F34275888049021821E8E08CAa1f',
        '0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
        '0x046EeE2cc3188071C02BfC1745A6b17c656e3f3d',
        '0x090185f2135308BaD17527004364eBcC2D37e5F6',
        '0xBe9895146f7AF43049ca1c1AE358B0541Ea49704',
        '0x9E32b13ce7f2E80A01932B42553652E053D6ed8e',
        '0x1cEB5cB57C4D4E2b2433641b95Dd330A33185A44',
        '0x8E6cd950Ad6ba651F6DD608Dc70e5886B1AA6B24',
        '0x4C19596f5aAfF459fA38B0f7eD92F11AE6543784',
        '0x8Fc8f8269ebca376D046Ce292dC7eaC40c8D358A',
        '0x8E870D67F660D95d5be530380D0eC0bd388289E1',
        '0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828',
        '0xd084B83C305daFD76AE3E1b4E1F1fe2eCcCb3988',
        '0x0f2D719407FdBeFF09D87557AbB7232601FD9F29',
        '0x45804880De22913dAFE09f4980848ECE6EcbAf78',
        '0xB62132e35a6c13ee1EE0f84dC5d40bad8d815206',
        '0x411099C0b413f4fedDb10Edf6a8be63BD321311C',
        '0x6810e776880C02933D47DB1b9fc05908e5386b96',
        '0x0FD10b9899882a6f2fcb5c371E17e70FdEe00C38',
        '0xf4d2888d29D722226FafA5d9B24F9164c092421E',
        '0xCdCFc0f66c522Fd086A1b725ea3c0Eeb9F9e8814',
        '0x0d438F3b5175Bebc262bF23753C1E53d03432bDE',
        '0x115eC79F1de567eC68B7AE7eDA501b406626478e',
        '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
        '0x06450dEe7FD2Fb8E39061434BAbCFC05599a6Fb8',
        '0xa1faa113cbE53436Df28FF0aEe54275c13B40975',
    ],
    // Blast
    ['0x13e31' as NetworkHexId]: ['0x4300000000000000000000000000000000000003'],
    // Linea
    ['0xe708' as NetworkHexId]: [
        '0xA219439258ca9da29E9Cc4cE5596924745e12B93',
        '0x176211869ca2b568f2a7d4ee941e073a821ee1ff',
    ],
    // Ethereum Sepolia
    ['0xaa36a7' as NetworkHexId]: [
        '0x77ab54631bfbae40383c62044dc30b229c7df9f5',
    ],
}
