import {
    failure,
    object,
    Result,
    shape,
    string,
    success,
} from '@zeal/toolkit/Result'

import { PasskeyAndroidUnknownError } from '@zeal/domains/Error/domains/Passkey'

export const parsePasskeyAndroidUnknownError = (
    input: unknown
): Result<unknown, PasskeyAndroidUnknownError> =>
    object(input)
        .andThen((obj) =>
            shape({
                message: string(obj.message).andThen((msg) =>
                    msg.match('CreateCredentialUnknownException') ||
                    msg.match('GetCredentialUnknownException')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                ),
            })
        )
        .map(() => ({
            type: 'passkey_android_unknown_error',
            originalError: input,
        }))
