import {
    failure,
    match,
    object,
    Result,
    shape,
    string,
    success,
} from '@zeal/toolkit/Result'

import { parse as parseAccount } from '@zeal/domains/Account/helpers/parse'
import { KeyStore } from '@zeal/domains/KeyStore'
import {
    ExtensionToTopUpDapp,
    TopUpDappToExtension,
    ZealDAppEntryPoint,
} from '@zeal/domains/Main'

const keyStoreTypeMap: Record<KeyStore['type'], true> = {
    private_key_store: true,
    ledger: true,
    secret_phrase_key: true,
    trezor: true,
    track_only: true,
    safe_4337: true,
}

const _: Record<ZealDAppEntryPoint['type'], true> = {
    // Remember to add msg parser for any new dApp entry points
    account_top_up: true,
}

export const parseKeyStoreType = (
    input: unknown
): Result<unknown, KeyStore['type']> =>
    string(input).andThen((str) =>
        keyStoreTypeMap[str as KeyStore['type']]
            ? success(str as KeyStore['type'])
            : failure({ type: 'invalid_key_store_type' })
    )

export const parseExtensionToTopUpDapp = (
    input: unknown
): Result<unknown, ExtensionToTopUpDapp> =>
    object(input).andThen((obj) =>
        shape({
            type: match(obj.type, 'init_zeal_dapp_entrypoint' as const),
            zealDAppEntryPoint: object(obj.zealDAppEntryPoint).andThen(
                (entryPointObj) =>
                    shape({
                        type: match(
                            entryPointObj.type,
                            'account_top_up' as const
                        ),
                        account: parseAccount(entryPointObj.account),
                        keyStoreType: parseKeyStoreType(
                            entryPointObj.keyStoreType
                        ),
                        installationId: string(entryPointObj.installationId),
                    })
            ),
        })
    )

export const parseTopUpDappToExtension = (
    input: unknown
): Result<unknown, TopUpDappToExtension> =>
    object(input).andThen((obj) =>
        shape({
            type: match(obj.type, 'ready' as const),
        })
    )
