import { useEffect, useState } from 'react'

import { useSwitchChain } from 'wagmi'

import { notReachable } from '@zeal/toolkit'
import { ImperativeError } from '@zeal/toolkit/Error'
import { useLazyLoadableData } from '@zeal/toolkit/LoadableData/LazyLoadableData'
import { MsgOf } from '@zeal/toolkit/MsgOf'

import { captureError } from '@zeal/domains/Error/helpers/captureError'
import { parseAppError } from '@zeal/domains/Error/parsers/parseAppError'
import { ZealDAppEntryPoint } from '@zeal/domains/Main'
import {
    NetworkMap,
    NetworkRPCMap,
    PredefinedNetwork,
} from '@zeal/domains/Network'
import { DefaultCurrencyConfig } from '@zeal/domains/Storage'

import { Modal, State as ModalState } from './Modal'

import { Connected, SendTransactionToExternalWallet } from '../../../types'
import { PortfolioLoader } from '../PortfolioLoader'

type Props = {
    connectionState: Connected
    entryPoint: ZealDAppEntryPoint
    supportedNetworks: PredefinedNetwork[]
    networkRPCMap: NetworkRPCMap
    defaultCurrencyConfig: DefaultCurrencyConfig
    networkMap: NetworkMap
    sendTransaction: SendTransactionToExternalWallet
    onMsg: (msg: Msg) => void
}

type Msg = Extract<
    MsgOf<typeof PortfolioLoader>,
    {
        type:
            | 'on_disconnect_clicked'
            | 'close'
            | 'on_top_up_transaction_complete_close'
            | 'on_external_earn_deposit_completed_close_click'
    }
>

export const NetworkSwitcher = ({
    supportedNetworks,
    onMsg,
    entryPoint,
    connectionState,
    networkRPCMap,
    defaultCurrencyConfig,
    networkMap,
    sendTransaction,
}: Props) => {
    const [modal, setModal] = useState<ModalState>({ type: 'closed' })
    const { switchChainAsync } = useSwitchChain()
    const switchConnectedNetwork = async ({
        network,
    }: {
        network: PredefinedNetwork
    }) => switchChainAsync({ chainId: Number(network.hexChainId) })

    const [loadable, setLoadable] = useLazyLoadableData(switchConnectedNetwork)

    useEffect(() => {
        switch (loadable.type) {
            case 'not_asked':
            case 'loading':
            case 'loaded':
                break
            case 'error':
                const parsed = parseAppError(loadable.error)

                switch (parsed.type) {
                    case 'wallet_connect_add_ethereum_chain_missing_or_invalid':
                    case 'wagmi_switch_chain_chain_id_not_supported':
                    case 'wagmi_add_ethereum_chain_not_supported':
                        setModal({
                            type: 'wallet_does_not_support_network',
                            network: loadable.params.network,
                        })
                        break
                    /* istanbul ignore next */
                    default:
                        captureError(loadable.error)
                }
                break
            /* istanbul ignore next */
            default:
                return notReachable(loadable)
        }
    }, [loadable])

    return (
        <>
            <PortfolioLoader
                networkMap={networkMap}
                defaultCurrencyConfig={defaultCurrencyConfig}
                networkRPCMap={networkRPCMap}
                entryPoint={entryPoint}
                sendTransaction={sendTransaction}
                connectionState={connectionState}
                supportedNetworks={supportedNetworks}
                onMsg={(msg) => {
                    switch (msg.type) {
                        case 'on_connect_to_correct_network_clicked':
                            setLoadable({
                                type: 'loading',
                                params: { network: msg.network },
                            })
                            break
                        case 'on_crypto_currency_selected':
                            const network = supportedNetworks.find(
                                (network) =>
                                    network.hexChainId ===
                                    msg.currency.networkHexChainId
                            )
                            if (!network) {
                                throw new ImperativeError(
                                    'Selected top up currency from unsupported network',
                                    {
                                        selectedCurrency: msg.currency,
                                        supportedNetworks,
                                    }
                                )
                            }
                            setLoadable({
                                type: 'loading',
                                params: { network },
                            })
                            break
                        case 'on_disconnect_clicked':
                        case 'close':
                        case 'on_top_up_transaction_complete_close':
                            onMsg(msg)
                            break
                        /* istanbul ignore next */
                        default:
                            return notReachable(msg)
                    }
                }}
            />
            <Modal
                state={modal}
                connectedAccount={connectionState.account}
                onMsg={(msg) => {
                    switch (msg.type) {
                        case 'close':
                            setModal({ type: 'closed' })
                            break
                        /* istanbul ignore next */
                        default:
                            return notReachable(msg.type)
                    }
                }}
            />
        </>
    )
}
