import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgGy = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7173)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 0C210.739 0 168.231 11.762 131.339 32.372V479.628C168.232 500.238 210.739 512 256 512C397.384 512 512 397.384 512 256C512 114.616 397.384 0 256 0Z"
                fill="#6DA544"
            />
            <Path
                d="M132.092 31.9509C115.987 40.8759 100.949 51.4839 87.21 63.5439C88.599 62.3259 90.002 61.1219 91.416 59.9339L287.481 256L91.415 452.066C90 450.878 88.598 449.674 87.209 448.456C100.948 460.516 115.987 471.123 132.091 480.049L512 256L132.092 31.9509Z"
                fill="#F0F0F0"
            />
            <Path
                d="M110.47 45.3821C102.663 50.7881 95.1646 56.6051 88.0196 62.8221L256 256L88.0186 449.178C95.1636 455.395 102.662 461.213 110.469 466.618L468.178 256L110.47 45.3821Z"
                fill="#FFDA44"
            />
            <Path
                d="M91.4155 59.9341C85.7245 64.7161 80.2325 69.7291 74.9805 74.9801L233.739 256L74.9805 437.02C80.2325 442.272 85.7235 447.286 91.4155 452.066L287.481 256L91.4155 59.9341Z"
                fill="black"
            />
            <Path
                d="M74.9795 74.98C-24.9945 174.954 -24.9945 337.045 74.9795 437.02C116.293 395.707 156.026 355.974 256 256L74.9795 74.98Z"
                fill="#D80027"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7173">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgGy
