import React from 'react'
import { ImageSourcePropType, StyleProp, View, ViewStyle } from 'react-native'

import { ImageBackground as ExpoImageBackground } from 'expo-image'

import { notReachable } from '@zeal/toolkit'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

type Props = {
    style?: StyleProp<ViewStyle>
    position: 'center' | 'right'
    source: ImageSourcePropType
    children?: React.ReactNode
}

export const ImageBackground = ({
    children,
    source,
    position,
    style,
}: Props) => {
    switch (ZealPlatform.OS) {
        case 'ios':
        case 'android':
            return (
                <ExpoImageBackground
                    contentFit="cover"
                    contentPosition={position}
                    source={source}
                    style={style}
                >
                    {children}
                </ExpoImageBackground>
            )
        case 'web':
            return (
                <View style={style}>
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            backgroundSize: 'cover',
                            backgroundImage: `url(${source})`,
                            backgroundPosition: position,
                        }}
                    >
                        {children}
                    </div>
                </View>
            )

        default:
            return notReachable(ZealPlatform.OS)
    }
}
