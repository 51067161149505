import { notReachable } from '@zeal/toolkit'
import {
    failure,
    match,
    matchRegExp,
    object,
    oneOf,
    Result,
    shape,
    string,
    success,
} from '@zeal/toolkit/Result'

import {
    BundlerResponseError,
    PimlicoBundlerResponseError,
} from '@zeal/domains/Error/domains/BundlerError'
import { parseHttpError } from '@zeal/domains/Error/parsers/parseHttpError'
import { parse as parseNetworkHexId } from '@zeal/domains/Network/helpers/parse'

const _ = (error: BundlerResponseError) => {
    switch (error.type) {
        case 'bundler_error_aa10_sender_already_constructed':
        case 'bundler_error_aa13_init_code_failed_or_out_of_gas':
        case 'bundler_error_aa21_didnt_pay_prefund':
        case 'bundler_error_aa22_expired_or_not_due':
        case 'bundler_error_aa23_reverted_or_oog':
        case 'bundler_error_aa24_signature_error':
        case 'bundler_error_aa25_invalid_account_nonce':
        case 'bundler_error_aa31_paymaster_deposit_too_low':
        case 'bundler_error_aa33_reverted_or_out_of_gas':
        case 'bundler_error_aa34_signature_error':
        case 'bundler_error_aa40_over_verification_gas_limit':
        case 'bundler_error_aa41_too_little_verification_gas':
        case 'bundler_error_aa51_prefund_below_gas_cost':
        case 'bundler_error_aa93_invalid_paymaster_and_data':
        case 'bundler_error_aa95_out_of_gas':
        case 'bundler_error_cannot_execute_request':
        case 'bundler_error_unknown':
        case 'bundler_error_user_operation_reverted_during_execution_phase':
            // Remember to add parser to parseBundlerResponseError
            return error
        /* istanbul ignore next */
        default:
            return notReachable(error.type)
    }
}

const BUNDLER_URL_REGEX = /\/proxy\/bundler\/(0x.+)\//i

export const parseBundlerResponseError = (
    input: unknown
): Result<unknown, BundlerResponseError> =>
    parseHttpError(input).andThen((httpError) =>
        shape({
            url: oneOf(httpError.url, [
                match(httpError.url, '/wallet/rpc/bundler/'),
                matchRegExp(httpError.url, BUNDLER_URL_REGEX),
            ]),
            networkHexId: oneOf(httpError.queryParams, [
                object(httpError.queryParams).andThen((params) =>
                    string(params.network).andThen(parseNetworkHexId)
                ),
                string(httpError.url.match(BUNDLER_URL_REGEX)?.[1]).andThen(
                    parseNetworkHexId
                ),
            ]),
            error: oneOf(httpError.data, [
                object(httpError.data)
                    .andThen((errObj) => object(errObj.error))
                    .andThen((error) => string(error.message))
                    .andThen((msg) =>
                        oneOf(msg, [
                            oneOf(msg, [
                                matchRegExp(msg, /AA21/i).map(
                                    () =>
                                        'bundler_error_aa21_didnt_pay_prefund' as const
                                ),
                                matchRegExp(msg, /AA33/i).map(
                                    () =>
                                        'bundler_error_aa33_reverted_or_out_of_gas' as const
                                ),
                                matchRegExp(msg, /AA24/i).map(
                                    () =>
                                        'bundler_error_aa24_signature_error' as const
                                ),
                                matchRegExp(msg, /AA10/i).map(
                                    () =>
                                        'bundler_error_aa10_sender_already_constructed' as const
                                ),
                                matchRegExp(msg, /AA13/i).map(
                                    () =>
                                        'bundler_error_aa13_init_code_failed_or_out_of_gas' as const
                                ),
                                matchRegExp(msg, /AA93/i).map(
                                    () =>
                                        'bundler_error_aa93_invalid_paymaster_and_data' as const
                                ),
                                matchRegExp(msg, /AA95/i).map(
                                    () =>
                                        'bundler_error_aa95_out_of_gas' as const
                                ),
                                matchRegExp(msg, /AA31/i).map(
                                    () =>
                                        'bundler_error_aa31_paymaster_deposit_too_low' as const
                                ),
                                matchRegExp(msg, /AA41/i).map(
                                    () =>
                                        'bundler_error_aa41_too_little_verification_gas' as const
                                ),
                                matchRegExp(msg, /AA40/i).map(
                                    () =>
                                        'bundler_error_aa40_over_verification_gas_limit' as const
                                ),
                            ]),
                            oneOf(msg, [
                                matchRegExp(msg, /AA22/i).map(
                                    () =>
                                        'bundler_error_aa22_expired_or_not_due' as const
                                ),
                                matchRegExp(msg, /AA51/i).map(
                                    () =>
                                        'bundler_error_aa51_prefund_below_gas_cost' as const
                                ),
                                matchRegExp(msg, /AA34/i).map(
                                    () =>
                                        'bundler_error_aa34_signature_error' as const
                                ),
                                matchRegExp(msg, /AA25/i).map(
                                    () =>
                                        'bundler_error_aa25_invalid_account_nonce' as const
                                ),
                                matchRegExp(msg, /AA23/i).map(
                                    () =>
                                        'bundler_error_aa23_reverted_or_oog' as const
                                ),
                                matchRegExp(
                                    msg,
                                    /we can't execute this request/i
                                ).map(
                                    () =>
                                        'bundler_error_cannot_execute_request' as const
                                ),
                                matchRegExp(
                                    msg,
                                    /UserOperation reverted during execution phase/i
                                ).map(
                                    () =>
                                        'bundler_error_user_operation_reverted_during_execution_phase' as const
                                ),
                            ]),
                        ])
                    ),
                success('bundler_error_unknown' as const),
            ]),
        }).map(
            ({ error, networkHexId }) =>
                new BundlerResponseError({
                    payload: httpError.data,
                    request: httpError.requestBody,
                    networkHexId,
                    type: error,
                })
        )
    )

export const parsePimlicoBundlerResponseError = (
    input: unknown
): Result<unknown, PimlicoBundlerResponseError> =>
    input instanceof PimlicoBundlerResponseError &&
    input.type === 'pimlico_bundler_response_error'
        ? success(input)
        : failure('not_correct_instance')
