import Big from 'big.js'

/**
 * @deprecated use Hexadecimal.fromBigInt instead
 */
export const bigIntToHex = (value: bigint): string => `0x${value.toString(16)}`

export const div = (value: bigint, divisor: bigint): number =>
    Big(value.toString(10))
        .div(Big(divisor.toString(10)))
        .toNumber()

export const mulByNumber = (value: bigint, factor: number): bigint =>
    BigInt(Big(value.toString()).mul(factor).toFixed(0))

export const fromFixedWithFraction = (
    amount: string | null,
    fraction: number
): bigint => {
    const value = amount?.replace(/[^\d.]/gim, '') || '0' // We remove everything which is not digit or dot so Big can parse it
    return BigInt(Big(value).mul(Big(10).pow(fraction)).toFixed(0))
}

export const toFixedWithFraction = (value: bigint, fraction: number): string =>
    Big(value.toString(10))
        .div(Big(10).pow(fraction))
        .toFixed(fraction)
        .replace(/(\.0+|0+)$/, '')

export const toNumberWithFraction = (value: bigint, fraction: number): number =>
    Big(value.toString(10)).div(Big(10).pow(fraction)).toNumber()

export const toBigIntWithFraction = (value: number, fraction: number): bigint =>
    BigInt(Big(value).mul(Big(10).pow(fraction)).toFixed(0))

export const abs = (value: bigint): bigint =>
    BigInt(Big(value.toString()).abs().toFixed(0))
