import { ImperativeError } from '@zeal/toolkit/Error'

import {
    CryptoCurrency,
    CurrencyHiddenMap,
    CurrencyId,
    CurrencyPinMap,
} from '@zeal/domains/Currency'
import { NetworkMap } from '@zeal/domains/Network'
import { findNetworkByHexChainId } from '@zeal/domains/Network/constants'
import { ServerPortfolio } from '@zeal/domains/Portfolio'
import { Token } from '@zeal/domains/Token'
import { filterByHideMap } from '@zeal/domains/Token/helpers/filterByHideMap'

export type CryptoCurrenciesSearchResult =
    | {
          type: 'grouped_results'
          portfolioCurrencies: CryptoCurrency[]
          nonPortfolioCurrencies: CryptoCurrency[]
      }
    | { type: 'no_currencies_found' }

const MINIMUM_SEARCH_TERM_TO_SEARCH_BY_ADDRESS = 3

export const searchCryptoCurrencies = ({
    currencies,
    searchTerm,
    currencyPinMap,
    currencyHiddenMap,
    portfolio,
    networkMap,
}: {
    searchTerm: string
    currencies: CryptoCurrency[]
    portfolio: ServerPortfolio | null
    currencyPinMap: CurrencyPinMap
    currencyHiddenMap: CurrencyHiddenMap
    networkMap: NetworkMap
}): CryptoCurrenciesSearchResult => {
    const searchToCheck = searchTerm.toLocaleLowerCase().trim()

    const portfolioTokens = portfolio?.tokens ?? []

    const tokenMap = new Map<string, Token>(
        portfolioTokens.map((token) => [token.balance.currencyId, token])
    )

    const currencyIds = new Set<CurrencyId>(
        currencies.map((currency) => currency.id)
    )

    const portfolioCurrencies = portfolioTokens
        .filter((token) => currencyIds.has(token.balance.currencyId))
        .filter(filterByHideMap(currencyHiddenMap))
        .map((token) => {
            const currency = currencies.find(
                (c) => c.id === token.balance.currencyId
            )

            if (!currency) {
                throw new ImperativeError(
                    'Crypto Currency not found in dictionary'
                )
            }
            return currency
        })
        .filter((currency) => filterMatch(currency, searchToCheck, networkMap))
        .toSorted(
            compositeSort(searchToCheck, currencyPinMap, tokenMap, networkMap)
        )

    const portfolioCurrencyIds = new Set<CurrencyId>(
        portfolioTokens.map((token) => token.balance.currencyId)
    )

    const nonPortfolioCurrencies = currencies
        .filter((currency) => !portfolioCurrencyIds.has(currency.id))
        .filter((currency) => filterMatch(currency, searchToCheck, networkMap))
        .toSorted(
            compositeSort(searchToCheck, currencyPinMap, tokenMap, networkMap)
        )

    if (!portfolioCurrencies.length && !nonPortfolioCurrencies.length) {
        return { type: 'no_currencies_found' }
    }

    return {
        type: 'grouped_results',
        portfolioCurrencies,
        nonPortfolioCurrencies,
    }
}

const compositeSort =
    (
        searchToCheck: string,
        currencyPinMap: CurrencyPinMap,
        tokenMap: Map<string, Token>,
        networkMap: NetworkMap
    ) =>
    (a: CryptoCurrency, b: CryptoCurrency) => {
        if (!a.name && b.name) return 1 // FIXME @negrienko we not have possibility to have optional value
        if (a.name && !b.name) return -1 // FIXME @negrienko we not have possibility to have optional value
        if (!a.name && !b.name) return 0 // FIXME @negrienko we not have possibility to have optional value

        const pinnedA = currencyPinMap[a.id] || false
        const pinnedB = currencyPinMap[b.id] || false
        if (pinnedA && !pinnedB) return -1
        if (!pinnedA && pinnedB) return 1

        const startsWithMatchA = startsWithMatch(a, searchToCheck)
        const startsWithMatchB = startsWithMatch(b, searchToCheck)
        if (startsWithMatchA && !startsWithMatchB) return -1
        if (!startsWithMatchA && startsWithMatchB) return 1

        const exactMatchA = isExactMatch(a, searchToCheck)
        const exactMatchB = isExactMatch(b, searchToCheck)
        if (exactMatchA && !exactMatchB) return -1
        if (!exactMatchA && exactMatchB) return 1

        const balanceA =
            tokenMap.get(a.id)?.priceInDefaultCurrency?.amount ?? 0n
        const balanceB =
            tokenMap.get(b.id)?.priceInDefaultCurrency?.amount ?? 0n

        if (balanceA !== balanceB) {
            return balanceA > balanceB ? -1 : 1
        }

        const rankA = a.marketCapRank ?? Number.MAX_SAFE_INTEGER
        const rankB = b.marketCapRank ?? Number.MAX_SAFE_INTEGER
        if (rankA !== rankB) {
            return rankA - rankB
        }

        if (a.name.toLowerCase() !== b.name.toLowerCase()) {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        }

        if (a.symbol.toLowerCase() !== b.symbol.toLowerCase()) {
            return a.symbol.toLowerCase().localeCompare(b.symbol.toLowerCase())
        }

        if (a.code.toLowerCase() !== b.code.toLowerCase()) {
            return a.code.toLowerCase().localeCompare(b.code.toLowerCase())
        }

        const networkAName = findNetworkByHexChainId(
            a.networkHexChainId,
            networkMap
        ).name.toLowerCase()

        const networkBName = findNetworkByHexChainId(
            b.networkHexChainId,
            networkMap
        ).name.toLowerCase()

        return networkAName.localeCompare(networkBName)
    }

const filterMatch = (
    currency: CryptoCurrency,
    searchTerm: string,
    networkMap: NetworkMap
): boolean => {
    const networkName = findNetworkByHexChainId(
        currency.networkHexChainId,
        networkMap
    ).name.toLowerCase()

    const sanitisedSearch = sanitiseString(searchTerm)

    const thingsToSearchIn =
        sanitisedSearch.length >= MINIMUM_SEARCH_TERM_TO_SEARCH_BY_ADDRESS
            ? [
                  currency.address,
                  currency.symbol,
                  currency.code,
                  currency.name,
                  networkName,
              ]
            : [currency.symbol, currency.code, currency.name, networkName]

    return thingsToSearchIn.reduce(
        (result, item) =>
            result || sanitiseString(item).includes(sanitisedSearch),
        false
    )
}

const isExactMatch = (currency: CryptoCurrency, searchToCheck: string) => {
    const lowerName = currency.name.toLocaleLowerCase()
    const lowerSymbol = currency.symbol.toLocaleLowerCase()
    const lowerCode = currency.code.toLocaleLowerCase()
    return (
        lowerSymbol === searchToCheck ||
        lowerName === searchToCheck ||
        lowerCode === searchToCheck
    )
}

const startsWithMatch = (currency: CryptoCurrency, searchToCheck: string) => {
    const lowerName = currency.name.toLocaleLowerCase()
    const lowerSymbol = currency.symbol.toLocaleLowerCase()
    const lowerCode = currency.code.toLocaleLowerCase()
    return (
        lowerName.startsWith(searchToCheck) ||
        lowerSymbol.startsWith(searchToCheck) ||
        lowerCode.startsWith(searchToCheck)
    )
}

const sanitiseString = (str: string) =>
    str.toLocaleLowerCase().replace(/\./g, '').replace(/\s/g, '')
