import {
    array,
    combine,
    match,
    nullableOf,
    number,
    object,
    oneOf,
    Result,
    shape,
    string,
    success,
} from '@zeal/toolkit/Result'
import * as Web3 from '@zeal/toolkit/Web3'

import {
    App,
    AppNft,
    AppProtocol,
    AppToken,
    CommonProtocol,
    Lending,
    LockedToken,
    UnknownProtocol,
    Vesting,
} from '@zeal/domains/App'
import { FIAT_CURRENCIES } from '@zeal/domains/Currency/constants'
import { FiatMoney } from '@zeal/domains/Money'
import {
    parseCryptoMoney,
    parseCryptoMoneyFromStorage,
    parseFiatMoney,
    parseFiatMoneyFromStorage,
} from '@zeal/domains/Money/helpers/parse'
import { parse as parseNetworkHexId } from '@zeal/domains/Network/helpers/parse'

export const parse = (
    input: unknown,
    knownCurrencies: unknown
): Result<unknown, App> => {
    return object(input).andThen((obj) =>
        shape({
            name: string(obj.name),
            icon: string(obj.icon),
            networkHexId: oneOf(obj, [
                parseNetworkHexId(obj.network),
                parseNetworkHexId(obj.networkHexId),
            ]),
            priceInDefaultCurrency: oneOf(obj, [
                nullableOf(
                    obj.priceInDefaultCurrency,
                    parseFiatMoneyFromStorage
                ),
                success(null),
            ]),
            priceInUsd: oneOf(obj.priceInDefaultCurrency, [
                parseFiatMoney(obj.priceInDefaultCurrency, {
                    USD: FIAT_CURRENCIES.USD,
                }),
                parseFiatMoneyFromStorage(obj.priceInDefaultCurrency),
            ]),
            url: nullableOf(obj.url, string),
            protocols: array(obj.protocols).andThen((arr) =>
                combine(
                    arr.map((item) => parseAppProtocol(item, knownCurrencies))
                )
            ),
        })
    )
}

const parseAppProtocol = (
    input: unknown,
    knownCurrencies: unknown
): Result<unknown, AppProtocol> =>
    oneOf(input, [
        parseCommonProtocol(input, knownCurrencies),
        parseLockedToken(input, knownCurrencies),
        parseLending(input, knownCurrencies),
        parseVesting(input, knownCurrencies),
        parseUnknownProtocol(input, knownCurrencies),
    ])

const parseCommonProtocol = (
    input: unknown,
    knownCurrencies: unknown
): Result<unknown, CommonProtocol> =>
    object(input).andThen((obj) =>
        shape({
            type: match(obj.type, 'CommonAppProtocol' as const),
            priceInDefaultCurrency: parsePriceInDefaultCurrency(
                obj.priceInDefaultCurrency
            ),
            suppliedTokens: array(obj.suppliedTokens).andThen((arr) =>
                combine(arr.map((item) => parseAppToken(item, knownCurrencies)))
            ),
            borrowedTokens: array(obj.borrowedTokens).andThen((arr) =>
                combine(arr.map((item) => parseAppToken(item, knownCurrencies)))
            ),
            rewardTokens: array(obj.rewardTokens).andThen((arr) =>
                combine(arr.map((item) => parseAppToken(item, knownCurrencies)))
            ),
            category: string(obj.category),
            description: nullableOf(obj.description, string),
        })
    )

const parseLockedToken = (
    input: unknown,
    knownCurrencies: unknown
): Result<unknown, LockedToken> =>
    object(input).andThen((obj) =>
        shape({
            type: match(obj.type, 'LockedTokenAppProtocol' as const),
            priceInDefaultCurrency: parsePriceInDefaultCurrency(
                obj.priceInDefaultCurrency
            ),
            lockedTokens: array(obj.lockedTokens).andThen((arr) =>
                combine(arr.map((item) => parseAppToken(item, knownCurrencies)))
            ),
            rewardTokens: array(obj.rewardTokens).andThen((arr) =>
                combine(arr.map((item) => parseAppToken(item, knownCurrencies)))
            ),
            unlockAt: number(obj.unlockAt),
            category: string(obj.category),
            description: nullableOf(obj.description, string),
        })
    )

const parseLending = (
    input: unknown,
    knownCurrencies: unknown
): Result<unknown, Lending> =>
    object(input).andThen((obj) =>
        shape({
            type: match(obj.type, 'LendingAppProtocol' as const),
            priceInDefaultCurrency: parsePriceInDefaultCurrency(
                obj.priceInDefaultCurrency
            ),
            suppliedTokens: array(obj.suppliedTokens).andThen((arr) =>
                combine(arr.map((item) => parseAppToken(item, knownCurrencies)))
            ),
            borrowedTokens: array(obj.borrowedTokens).andThen((arr) =>
                combine(arr.map((item) => parseAppToken(item, knownCurrencies)))
            ),
            rewardTokens: array(obj.rewardTokens).andThen((arr) =>
                combine(arr.map((item) => parseAppToken(item, knownCurrencies)))
            ),
            category: string(obj.category),
            description: nullableOf(obj.description, string),
            healthFactor: number(obj.healthFactor),
        })
    )

const parseVesting = (
    input: unknown,
    knownCurrencies: unknown
): Result<unknown, Vesting> =>
    object(input).andThen((obj) =>
        shape({
            type: match(obj.type, 'VestingAppProtocol' as const),
            priceInDefaultCurrency: parsePriceInDefaultCurrency(
                obj.priceInDefaultCurrency
            ),
            vestedToken: parseAppToken(obj.vestedToken, knownCurrencies),
            claimableToken: parseAppToken(obj.claimableToken, knownCurrencies),
            category: string(obj.category),
        })
    )

const parseUnknownProtocol = (
    input: unknown,
    knownCurrencies: unknown
): Result<unknown, UnknownProtocol> =>
    object(input).andThen((obj) =>
        shape({
            type: match(obj.type, 'UnknownAppProtocol' as const),
            priceInDefaultCurrency: parsePriceInDefaultCurrency(
                obj.priceInDefaultCurrency
            ),
            tokens: array(obj.tokens).andThen((arr) =>
                combine(arr.map((item) => parseAppToken(item, knownCurrencies)))
            ),
            nfts: array(obj.nfts).andThen((arr) =>
                combine(arr.map(parseAppNft))
            ),
            category: string(obj.category),
        })
    )

const parseAppToken = (
    input: unknown,
    knownCurrencies: unknown
): Result<unknown, AppToken> =>
    object(input).andThen((obj) =>
        shape({
            networkHexId: oneOf(obj, [
                parseNetworkHexId(obj.network),
                parseNetworkHexId(obj.networkHexId),
            ]),
            name: string(obj.name),
            address: Web3.address.parse(obj.address),
            balance: oneOf(obj.balance, [
                parseCryptoMoney(obj.balance, knownCurrencies),
                parseCryptoMoneyFromStorage(obj.balance),
            ]),
            priceInDefaultCurrency: parsePriceInDefaultCurrency(
                obj.priceInDefaultCurrency
            ),
        })
    )

const parseAppNft = (input: unknown): Result<unknown, AppNft> =>
    object(input).andThen((obj) =>
        shape({
            tokenId: string(obj.tokenId),
            name: nullableOf(obj.name, string),
            amount: string(obj.amount),
            decimals: number(obj.decimals),
            priceInDefaultCurrency: parsePriceInDefaultCurrency(
                obj.priceInDefaultCurrency
            ),
            uri: nullableOf(obj.uri, string),
        })
    )

const parsePriceInDefaultCurrency = (
    obj: unknown
): Result<unknown, FiatMoney | null> => {
    const knownCurrencies = {
        USD: FIAT_CURRENCIES.USD,
    }
    return oneOf(obj, [
        nullableOf(obj, () => parseFiatMoney(obj, knownCurrencies)),
        nullableOf(obj, () => parseFiatMoneyFromStorage(obj)),
    ])
}
