import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgTv = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7317)">
            <Path
                d="M512 256C512 397.384 397.384 512 256.001 512C114.616 512 0 397.384 0 256C0 256.061 256.001 0.028 256.001 0C397.384 0 512 114.616 512 256Z"
                fill="#338AF3"
            />
            <Path
                d="M122.435 256H208.777L133.565 166.957L122.435 200.348V256Z"
                fill="#0052B4"
            />
            <Path
                d="M256.001 208.778L166.957 122.435H256L256.001 208.778Z"
                fill="#0052B4"
            />
            <Path
                d="M255.315 256H256C256 255.77 256 255.546 256 255.315C255.772 255.544 255.544 255.772 255.315 256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256.001 133.565C256.001 88.52 256.001 59.004 256.001 0H255.958C114.592 0.024 0 114.629 0 256H133.566V180.789L208.777 256H255.317C255.545 255.772 255.773 255.544 256.002 255.315C256.002 238.068 256.002 222.679 256.002 208.778L180.788 133.565H256.001Z"
                fill="#F0F0F0"
            />
            <Path
                d="M129.515 33.3911C89.4761 56.1901 56.1901 89.4761 33.3911 129.515V256H100.174V100.176V100.174H256C256 79.1111 256 59.0451 256 33.3911H129.515Z"
                fill="#D80027"
            />
            <Path
                d="M256.001 224.519L165.046 133.566H133.566V133.568L255.999 256H256.001C256.001 256 256.001 234.293 256.001 224.519Z"
                fill="#D80027"
            />
            <Path
                d="M279.406 422.957L284.932 439.963H302.813L288.348 450.473L293.874 467.478L279.406 456.969L264.941 467.478L270.467 450.473L256 439.963H273.88L279.406 422.957Z"
                fill="#FFDA44"
            />
            <Path
                d="M421.811 311.652L427.336 328.658H445.217L430.752 339.169L436.279 356.174L421.811 345.665L407.346 356.174L412.872 339.169L398.404 328.658H416.285L421.811 311.652Z"
                fill="#FFDA44"
            />
            <Path
                d="M457.493 144.696L463.019 161.702H480.9L466.435 172.212L471.96 189.217L457.493 178.708L443.028 189.217L448.554 172.212L434.087 161.702H451.968L457.493 144.696Z"
                fill="#FFDA44"
            />
            <Path
                d="M466.333 267.13L471.858 284.137H489.739L475.274 294.647L480.8 311.652L466.333 301.143L451.868 311.652L457.394 294.647L442.926 284.137H460.807L466.333 267.13Z"
                fill="#FFDA44"
            />
            <Path
                d="M305.046 382.959L320.981 391.078L333.624 378.435L330.828 396.094L346.759 404.212L329.097 407.01L326.3 424.671L318.184 408.738L300.522 411.537L313.165 398.894L305.046 382.959Z"
                fill="#FFDA44"
            />
            <Path
                d="M305.046 316.177L320.981 324.295L333.624 311.652L330.828 329.312L346.759 337.429L329.097 340.227L326.3 357.888L318.184 341.956L300.522 344.754L313.165 332.111L305.046 316.177Z"
                fill="#FFDA44"
            />
            <Path
                d="M360.698 358.984L376.633 367.103L389.276 354.46L386.48 372.119L402.411 380.237L384.749 383.035L381.952 400.696L373.836 384.762L356.174 387.562L368.817 374.919L360.698 358.984Z"
                fill="#FFDA44"
            />
            <Path
                d="M360.698 216.003L376.633 224.121L389.276 211.478L386.48 229.138L402.411 237.255L384.749 240.053L381.952 257.714L373.836 241.782L356.174 244.58L368.817 231.937L360.698 216.003Z"
                fill="#FFDA44"
            />
            <Path
                d="M414.635 214.289L430.57 222.407L443.213 209.764L440.416 227.424L456.348 235.541L438.686 238.339L435.889 256L427.773 240.068L410.111 242.866L422.754 230.223L414.635 214.289Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7317">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgTv
