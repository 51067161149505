import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgZm = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7333)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#496E2D"
            />
            <Path
                d="M356.174 256H256V512C285.924 512 314.641 506.849 341.333 497.414L356.174 256Z"
                fill="#D80027"
            />
            <Path
                d="M426.666 446.793C479.034 399.919 512 331.813 512 256H411.826L426.666 446.793Z"
                fill="#FF9811"
            />
            <Path
                d="M341.333 256V497.414C373.13 486.173 402.044 468.834 426.667 446.794V256H341.333Z"
                fill="black"
            />
            <Path
                d="M473.043 166.957H406.26C406.26 154.663 396.293 144.696 383.999 144.696C371.705 144.696 361.738 154.663 361.738 166.957H294.955C294.955 179.252 305.664 189.218 317.957 189.218H317.216C317.216 201.513 327.181 211.479 339.477 211.479C339.477 223.774 349.442 233.74 361.738 233.74H406.26C418.554 233.74 428.521 223.774 428.521 211.479C440.815 211.479 450.782 201.513 450.782 189.218H450.041C462.335 189.217 473.043 179.251 473.043 166.957Z"
                fill="#FF9811"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7333">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgZm
