import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgZa = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7289)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M74.98 437.02C-24.994 337.045 -24.994 174.955 74.98 74.98C74.973 74.991 222.609 256 222.609 256L74.98 437.02Z"
                fill="black"
            />
            <Path
                d="M222.609 256L33.953 128.513C28.104 138.68 22.946 149.292 18.514 160.276L114.023 256.001L18.517 351.731C22.945 362.707 28.098 373.312 33.943 383.472L222.609 256Z"
                fill="#FFDA44"
            />
            <Path
                d="M509.833 222.609H222.602L74.978 74.984C59.07 90.892 45.249 108.883 33.953 128.514L161.192 256L33.943 383.47C45.239 403.106 59.066 421.103 74.978 437.015L222.602 289.391H509.833C511.256 278.461 512 267.317 512 256C512 244.683 511.256 233.539 509.833 222.609Z"
                fill="#6DA544"
            />
            <Path
                d="M100.138 459.077C143.307 492.259 197.344 512 256 512C374.279 512 473.805 431.779 503.181 322.783H236.433L100.138 459.077Z"
                fill="#0052B4"
            />
            <Path
                d="M503.181 189.217C473.805 80.221 374.279 0 256 0C197.344 0 143.307 19.741 100.138 52.923L236.432 189.217H503.181Z"
                fill="#D80027"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7289">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgZa
