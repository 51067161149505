import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgTk = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7309)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#FFDA44"
            />
            <Path
                d="M411.826 122.435C409.53 133.47 408.487 145.223 408.487 156.939C408.487 224.473 448.746 282.924 506.441 309.237C510.074 292.061 512 274.256 512 256C512 114.616 397.384 0 256 0C114.616 0 0 114.616 0 256C0 397.384 114.616 512 256 512C343.682 512 421.058 467.908 467.196 400.696H144.696V367.305H486.598C490.097 360.069 493.258 352.64 496.078 345.044H144.696L411.826 122.435Z"
                fill="#0052B4"
            />
            <Path
                d="M188.072 133.565L193.598 150.571H211.478L197.013 161.082L202.539 178.087L188.072 167.578L173.607 178.087L179.132 161.082L164.665 150.571H182.546L188.072 133.565Z"
                fill="#F0F0F0"
            />
            <Path
                d="M115.438 256L122.346 277.259H144.696L126.613 290.394L133.521 311.652L115.438 298.514L97.356 311.652L104.263 290.394L86.181 277.259H108.531L115.438 256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M115.438 77.9131L122.346 99.1721H144.696L126.613 112.307L133.521 133.565L115.438 120.427L97.356 133.565L104.263 112.307L86.181 99.1721H108.531L115.438 77.9131Z"
                fill="#F0F0F0"
            />
            <Path
                d="M59.7861 178.087L66.6941 199.346H89.0431L70.9611 212.481L77.8691 233.739L59.7861 220.601L41.7041 233.739L48.6111 212.481L30.5291 199.346H52.8781L59.7861 178.087Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7309">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgTk
