import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { SectionListData } from 'react-native'

import { Column } from '@zeal/uikit/Column'
import { EmptyStateWidget } from '@zeal/uikit/EmptyStateWidget'
import { Tokens } from '@zeal/uikit/Icon/Empty'
import { OutlineSearch } from '@zeal/uikit/Icon/OutlineSearch'
import { Input } from '@zeal/uikit/Input'
import { SectionList } from '@zeal/uikit/SectionList'

import { noop, notReachable } from '@zeal/toolkit'
import { ImperativeError } from '@zeal/toolkit/Error'

import {
    CryptoCurrency,
    CurrencyHiddenMap,
    CurrencyPinMap,
} from '@zeal/domains/Currency'
import { searchCryptoCurrencies } from '@zeal/domains/Currency/helpers/searchCryptoCurrencies'
import { captureError } from '@zeal/domains/Error/helpers/captureError'
import { NetworkMap } from '@zeal/domains/Network'
import { ServerPortfolio } from '@zeal/domains/Portfolio'
import { getTokenByCryptoCurrency2 } from '@zeal/domains/Portfolio/helpers/getTokenByCryptoCurrency'

import { Token2 } from '../..'
import { ListItem2 } from '../ListItem2'

type Props = {
    cryptoCurrencies: CryptoCurrency[]
    portfolio: ServerPortfolio
    selectedCurrency: CryptoCurrency | null

    networkMap: NetworkMap
    currencyPinMap: CurrencyPinMap
    currencyHiddenMap: CurrencyHiddenMap

    onCryptoCurrencySelected: (currency: CryptoCurrency) => void
}

export const SelectToken = ({
    cryptoCurrencies,
    currencyPinMap,
    currencyHiddenMap,
    networkMap,
    selectedCurrency,
    portfolio,
    onCryptoCurrencySelected,
}: Props) => {
    const { formatMessage } = useIntl()
    const [search, setSearch] = useState<string>('')

    const searchResult = searchCryptoCurrencies({
        currencies: cryptoCurrencies,
        searchTerm: search,
        portfolio,
        currencyPinMap: currencyPinMap,
        currencyHiddenMap: currencyHiddenMap,
        networkMap: networkMap,
    })

    return (
        <Column fill shrink spacing={8}>
            <Input
                keyboardType="default"
                placeholder={formatMessage({
                    id: 'earn.deposit.select-currency.searchPlaceholder',
                    defaultMessage: 'Search',
                })}
                leftIcon={<OutlineSearch size={24} color="iconDefault" />}
                state="normal"
                variant="regular"
                onChange={(e) => setSearch(e.nativeEvent.text)}
                value={search}
                onSubmitEditing={noop}
            />

            {(() => {
                switch (searchResult.type) {
                    case 'no_currencies_found':
                        return (
                            <EmptyStateWidget
                                icon={({ size }) => (
                                    <Tokens
                                        size={size}
                                        color="backgroundLight"
                                    />
                                )}
                                size="regular"
                                title={
                                    <FormattedMessage
                                        id="SelectCurrency.tokens.emptyState"
                                        defaultMessage="We found no tokens"
                                    />
                                }
                            />
                        )

                    case 'grouped_results': {
                        const sections: SectionListData<Token2>[] = [
                            {
                                data: searchResult.portfolioCurrencies.map(
                                    (currency) =>
                                        getTokenByCryptoCurrency2({
                                            currency,
                                            portfolio,
                                        })
                                ),
                            },
                            {
                                data: searchResult.nonPortfolioCurrencies.map(
                                    (currency) =>
                                        getTokenByCryptoCurrency2({
                                            currency,
                                            portfolio,
                                        })
                                ),
                            },
                        ]

                        if (
                            sections.every(
                                (section) => section.data.length === 0
                            )
                        ) {
                            return (
                                <EmptyStateWidget
                                    icon={({ size }) => (
                                        <Tokens
                                            size={size}
                                            color="backgroundLight"
                                        />
                                    )}
                                    size="regular"
                                    title={
                                        <FormattedMessage
                                            id="ERC20.tokens.emptyState"
                                            defaultMessage="We found no tokens"
                                        />
                                    }
                                />
                            )
                        }

                        return (
                            <SectionList
                                variant="grouped"
                                keyboardShouldPersistTaps="handled"
                                itemSpacing={8}
                                sectionSpacing={8}
                                sections={sections}
                                renderItem={({ item: token }) => (
                                    <ListItem2
                                        variant="search"
                                        currencyHiddenMap={currencyHiddenMap}
                                        currencyPinMap={currencyPinMap}
                                        key={token.balance.currency.id}
                                        networkMap={networkMap}
                                        aria-current={
                                            selectedCurrency?.id ===
                                            token.balance.currency.id
                                        }
                                        token={token}
                                        onClick={() => {
                                            const currency =
                                                cryptoCurrencies.find(
                                                    (item) =>
                                                        item.id ===
                                                        token.balance.currency
                                                            .id
                                                ) || null

                                            if (!currency) {
                                                captureError(
                                                    new ImperativeError(
                                                        'Currency not found'
                                                    )
                                                )
                                            } else {
                                                onCryptoCurrencySelected(
                                                    currency
                                                )
                                            }
                                        }}
                                    />
                                )}
                            />
                        )
                    }

                    /* istanbul ignore next */
                    default:
                        return notReachable(searchResult)
                }
            })()}
        </Column>
    )
}
