import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgEt = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 513" fill="none">
        <G clipPath="url(#clip0_4_7150)">
            <Path
                d="M0 256.989C0 288.305 5.633 318.301 15.924 346.035L256 368.293L496.076 346.034C506.368 318.301 512 288.304 512 256.989C512 226.104 506.529 196.497 496.506 169.084L256 145.685L15.494 169.085C5.471 196.497 0 226.104 0 256.989H0Z"
                fill="#FFDA44"
            />
            <Path
                d="M256 512.989C366.071 512.989 459.906 443.517 496.077 346.032H15.9229C52.0939 443.517 145.929 512.989 256 512.989Z"
                fill="#D80027"
            />
            <Path
                d="M256 0.989258C145.929 0.989258 52.0939 70.4613 15.9229 167.946H496.078C459.906 70.4613 366.071 0.989258 256 0.989258Z"
                fill="#6DA544"
            />
            <Path
                d="M256 379.424C323.619 379.424 378.435 324.608 378.435 256.989C378.435 189.37 323.619 134.554 256 134.554C188.381 134.554 133.565 189.37 133.565 256.989C133.565 324.608 188.381 379.424 256 379.424Z"
                fill="#0052B4"
            />
            <Path
                d="M255.974 162.18L278.076 230.202H349.713L291.735 272.243L314.011 340.467L255.974 298.226L198.026 340.389L220.212 272.243L162.287 230.202H233.872L255.974 162.18Z"
                fill="#FFDA44"
            />
            <Path
                d="M344.124 273.919L274.008 251.135L317.341 191.495L299.333 178.409L255.998 238.053L212.667 178.41L194.657 191.494L237.989 251.139L167.876 273.918L174.754 295.091L244.87 272.31V346.032H267.13V272.309L337.243 295.091L344.124 273.919Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7150">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0 0.989258)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgEt
