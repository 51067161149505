import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgNi = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 513 512" fill="none">
        <G clipPath="url(#clip0_4_7237)">
            <Path
                d="M256.988 512C398.373 512 512.988 397.385 512.988 256C512.988 114.615 398.373 0 256.988 0C115.603 0 0.988281 114.615 0.988281 256C0.988281 397.385 115.603 512 256.988 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256.988 0C155.494 0 67.7984 59.065 26.3904 144.696H487.585C446.178 59.065 358.481 0 256.988 0Z"
                fill="#338AF3"
            />
            <Path
                d="M256.988 512C358.481 512 446.178 452.935 487.586 367.304H26.3904C67.7984 452.935 155.494 512 256.988 512Z"
                fill="#338AF3"
            />
            <Path
                d="M256.988 178.087C213.958 178.087 179.075 212.97 179.075 256C179.075 299.03 213.958 333.913 256.988 333.913C300.018 333.913 334.901 299.03 334.901 256C334.901 212.97 300.018 178.087 256.988 178.087ZM256.988 300.522C232.4 300.522 212.466 280.59 212.466 256C212.466 231.41 232.399 211.478 256.988 211.478C281.577 211.478 301.51 231.41 301.51 256C301.51 280.59 281.576 300.522 256.988 300.522Z"
                fill="#FFDA44"
            />
            <Path
                d="M295.546 267.13L256.988 256L218.43 267.13L205.578 289.391H308.397L295.546 267.13Z"
                fill="#0052B4"
            />
            <Path
                d="M256.988 200.348L231.283 244.87L256.988 256L282.693 244.87L256.988 200.348Z"
                fill="#338AF3"
            />
            <Path
                d="M218.43 267.13H295.546L282.693 244.87H231.283L218.43 267.13Z"
                fill="#6DA544"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7237">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0.988281)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgNi
