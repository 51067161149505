import {
    failure,
    match,
    nullableOf,
    number,
    object,
    oneOf,
    recordStrict,
    Result,
    shape,
    string,
    success,
} from '@zeal/toolkit/Result'

import { parse as parseAddress } from '@zeal/domains/Address/helpers/parse'
import {
    CryptoCurrency,
    Currency,
    FiatCurrency,
    FiatCurrencyCode,
    KnownCurrencies,
    ShortCryptoCurrency,
    ShortKnownCryptoCurrencies,
} from '@zeal/domains/Currency'
import { parse as parseNetworkHexId } from '@zeal/domains/Network/helpers/parse'

export const parse = (input: unknown): Result<unknown, Currency> =>
    object(input).andThen((obj) =>
        oneOf(obj, [parseFiatCurrency(obj), parseCryptoCurrency(obj)])
    )

export const parseKnownCurrencies = (
    input: unknown
): Result<unknown, KnownCurrencies> =>
    object(input).andThen((curriencies) =>
        recordStrict(curriencies, {
            keyParser: string,
            valueParser: parse,
        })
    )

export const parseFiatCurrencyCode = (
    code: string
): Result<unknown, FiatCurrencyCode> => {
    const map: Record<FiatCurrencyCode, true> = {
        AED: true,
        AFN: true,
        ALL: true,
        AMD: true,
        ARS: true,
        AUD: true,
        AZN: true,
        BAM: true,
        BDT: true,
        BGN: true,
        BHD: true,
        BIF: true,
        BMD: true,
        BND: true,
        BOB: true,
        BRL: true,
        BWP: true,
        BYN: true,
        BZD: true,
        CAD: true,
        CDF: true,
        CHF: true,
        CLP: true,
        CNY: true,
        COP: true,
        CRC: true,
        CVE: true,
        CZK: true,
        DJF: true,
        DKK: true,
        DOP: true,
        DZD: true,
        EEK: true,
        EGP: true,
        ERN: true,
        ETB: true,
        EUR: true,
        GBP: true,
        GEL: true,
        GHS: true,
        GNF: true,
        GTQ: true,
        HKD: true,
        HNL: true,
        HRK: true,
        HUF: true,
        IDR: true,
        ILS: true,
        INR: true,
        IQD: true,
        IRR: true,
        ISK: true,
        JMD: true,
        JOD: true,
        JPY: true,
        KES: true,
        KHR: true,
        KMF: true,
        KRW: true,
        KWD: true,
        KZT: true,
        LBP: true,
        LKR: true,
        LTL: true,
        LVL: true,
        LYD: true,
        MAD: true,
        MDL: true,
        MGA: true,
        MKD: true,
        MMK: true,
        MOP: true,
        MUR: true,
        MXN: true,
        MYR: true,
        MZN: true,
        NAD: true,
        NGN: true,
        NIO: true,
        NOK: true,
        NPR: true,
        NZD: true,
        OMR: true,
        PAB: true,
        PEN: true,
        PHP: true,
        PKR: true,
        PLN: true,
        PYG: true,
        QAR: true,
        RON: true,
        RSD: true,
        RUB: true,
        RWF: true,
        SAR: true,
        SDG: true,
        SEK: true,
        SGD: true,
        SOS: true,
        SYP: true,
        THB: true,
        TND: true,
        TOP: true,
        TRY: true,
        TTD: true,
        TWD: true,
        TZS: true,
        UAH: true,
        UGX: true,
        USD: true,
        UYU: true,
        UZS: true,
        VEF: true,
        VND: true,
        XAF: true,
        XOF: true,
        YER: true,
        ZAR: true,
        ZMK: true,
        ZWL: true,
    }

    return map[code as FiatCurrencyCode]
        ? success(code as FiatCurrencyCode)
        : failure({ type: 'unknown_fiat_currency_code', code })
}

export const parseFiatCurrency = (
    obj: Record<string, unknown>
): Result<unknown, FiatCurrency> =>
    shape({
        type: match(obj.type, 'FiatCurrency' as const),
        id: string(obj.id),
        symbol: string(obj.symbol),
        code: string(obj.code).andThen(parseFiatCurrencyCode),
        fraction: number(obj.fraction),
        rateFraction: number(obj.rateFraction),
        icon: string(obj.icon),
        name: string(obj.name),
    })

export const parseCryptoCurrency = (
    obj: Record<string, unknown>
): Result<unknown, CryptoCurrency> =>
    shape({
        type: match(obj.type, 'CryptoCurrency' as const),
        id: string(obj.id),
        networkHexChainId: oneOf(obj, [
            parseNetworkHexId(obj.network),
            parseNetworkHexId(obj.networkHexChainId),
        ]),
        address: parseAddress(obj.address),
        code: string(obj.code),
        rateFraction: number(obj.rateFraction),
        icon: string(obj.icon),
        shortCurrency: parseShortCryptoCurrency(obj),
    }).map(
        ({
            rateFraction,
            type,
            id,
            networkHexChainId,
            address,
            code,
            icon,
            shortCurrency,
        }) => {
            return {
                ...shortCurrency,
                type,
                rateFraction,
                id,
                networkHexChainId,
                address,
                code,
                icon,
            }
        }
    )

const parseShortCryptoCurrency = (
    obj: Record<string, unknown>
): Result<unknown, ShortCryptoCurrency> =>
    shape({
        symbol: string(obj.symbol),
        fraction: number(obj.fraction),
        name: string(obj.name),
        marketCapRank: nullableOf(obj.marketCapRank, number),
    })

export const parseShortKnownCryptoCurrencies = (
    input: unknown
): Result<unknown, ShortKnownCryptoCurrencies> =>
    object(input).andThen((curriencies) =>
        recordStrict(curriencies, {
            keyParser: string,
            valueParser: (input) =>
                object(input).andThen((obj) => parseShortCryptoCurrency(obj)),
        })
    )
