import { defaultWagmiConfig } from '@web3modal/wagmi-react-native'
import { Config, createConfig, http } from 'wagmi' // FIXME :: @Nicvaniek mock createConfig in wallet/webpackConfig
import * as wagmiChains from 'wagmi/chains'
import { coinbaseWallet, injected, walletConnect } from 'wagmi/connectors'

import { notReachable } from '@zeal/toolkit'
import { ValueOf, values } from '@zeal/toolkit/Object'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

import {
    ZEAL_DAPP_WC_METADATA,
    ZEAL_DAPP_WC_PROJECT_ID,
} from '@zeal/domains/DApp/domains/WalletConnect/constants'
import { NetworkHexId, PredefinedNetwork } from '@zeal/domains/Network'
import { PREDEFINED_NETWORKS } from '@zeal/domains/Network/constants'
import { parseNetworkHexId } from '@zeal/domains/Network/helpers/parse'

type Chain = ValueOf<typeof wagmiChains>

export const createWagmiConfig = (): {
    config: Config
    supportedNetworks: PredefinedNetwork[]
} => {
    const numericChainIds = new Set(
        PREDEFINED_NETWORKS.map((network) => Number(network.hexChainId))
    )

    const wagmiFilteredChains: Chain[] = values(wagmiChains).filter(
        (chain: Chain) => numericChainIds.has(chain.id)
    )

    const [firstChain, ...otherChains]: Chain[] = wagmiFilteredChains

    const wagmiChainHexIds: Set<NetworkHexId> = new Set<NetworkHexId>(
        wagmiFilteredChains
            .map((chain) => chain.id.toString(10))
            .map((id) => {
                const result = parseNetworkHexId(id)
                switch (result.type) {
                    case 'Failure':
                        return null
                    case 'Success':
                        return result.data

                    default:
                        return notReachable(result)
                }
            })
            .filter((result): result is NetworkHexId => result !== null)
    )

    const config = (() => {
        switch (ZealPlatform.OS) {
            case 'ios':
            case 'android':
                return defaultWagmiConfig({
                    projectId: ZEAL_DAPP_WC_PROJECT_ID,
                    chains: [firstChain, ...otherChains],
                    metadata: ZEAL_DAPP_WC_METADATA,
                })

            case 'web':
                return createConfig({
                    chains: [firstChain, ...otherChains],
                    multiInjectedProviderDiscovery: true,
                    connectors: [
                        injected(),
                        coinbaseWallet({ appName: 'Zeal' }),
                        walletConnect({
                            projectId: ZEAL_DAPP_WC_PROJECT_ID,
                        }),
                    ],
                    transports: wagmiFilteredChains.reduce(
                        (hash, chain) => {
                            if (chain && chain.id) {
                                hash[chain.id] = http()
                            }

                            return hash
                        },
                        {} as Record<number, ReturnType<typeof http>>
                    ),
                })
            /* istanbul ignore next */
            default:
                return notReachable(ZealPlatform.OS)
        }
    })()

    return {
        config,
        supportedNetworks: PREDEFINED_NETWORKS.filter((network) =>
            wagmiChainHexIds.has(network.hexChainId)
        ).filter((network) => {
            switch (network.name) {
                case 'Ethereum':
                    return false // we don't want users to deposit funds on ETH due to expensive fees
                case 'Arbitrum':
                case 'zkSync':
                case 'BSC':
                case 'Polygon':
                case 'PolygonZkevm':
                case 'Linea':
                case 'Fantom':
                case 'Optimism':
                case 'Base':
                case 'Blast':
                case 'OPBNB':
                case 'Gnosis':
                case 'Celo':
                case 'Avalanche':
                case 'Cronos':
                case 'Mantle':
                case 'Manta':
                case 'Aurora':
                    return true
                /* istanbul ignore next */
                default:
                    return notReachable(network.name)
            }
        }),
    }
}
