import { FormattedMessage } from 'react-intl'

import { Column } from '@zeal/uikit/Column'
import { ListItem } from '@zeal/uikit/ListItem'
import { Text } from '@zeal/uikit/Text'

import { AvatarWithoutBadge } from '@zeal/domains/Account/components/Avatar'
import { format } from '@zeal/domains/Address/helpers/format'
import { CryptoMoneyListItem } from '@zeal/domains/Money/components/CryptoMoneyListItem'

import { TopUpRequest } from '../../TopUpRequest'

type Props = {
    topUpRequest: TopUpRequest
}

export const TransactionContent = ({ topUpRequest }: Props) => {
    return (
        <Column spacing={20}>
            <CryptoMoneyListItem
                size="large"
                sign="-"
                balance={topUpRequest.amount}
                priceInDefaultCurrency={topUpRequest.amountInDefaultCurrency}
            />
            <Column spacing={4}>
                <Text
                    variant="paragraph"
                    color="textSecondary"
                    weight="regular"
                >
                    <FormattedMessage id="topup.to" defaultMessage="To" />
                </Text>

                <ListItem
                    aria-current={false}
                    size="large"
                    primaryText={topUpRequest.account.label}
                    shortText={format(topUpRequest.account.address)}
                    avatar={({ size }) => (
                        <AvatarWithoutBadge
                            account={topUpRequest.account}
                            size={size}
                        />
                    )}
                />
            </Column>
        </Column>
    )
}
