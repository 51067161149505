import { Result, string } from '@zeal/toolkit/Result'

import { Address } from '@zeal/domains/Address'
import { fromString } from '@zeal/domains/Address/helpers/fromString'

/**
 * @deprecated please use parser from toolkit
 * TODO @maxtern replace with toolkit
 */
export const parse = (input: unknown): Result<unknown, Address> =>
    string(input).andThen(fromString)
