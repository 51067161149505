import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgCc = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7126)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#6DA544"
            />
            <Path
                d="M392.984 367.304L400.009 381.993L415.873 378.327L408.769 392.978L421.526 403.094L405.642 406.674L405.687 422.957L392.984 412.77L380.282 422.957L380.326 406.674L364.443 403.094L377.2 392.978L370.096 378.327L385.959 381.993L392.984 367.304Z"
                fill="#FFDA44"
            />
            <Path
                d="M327.632 211.478L334.658 226.167L350.522 222.5L343.417 237.152L356.174 247.268L340.291 250.848L340.335 267.13L327.632 256.944L314.93 267.13L314.973 250.848L299.091 247.268L311.848 237.152L304.743 222.5L320.608 226.167L327.632 211.478Z"
                fill="#FFDA44"
            />
            <Path
                d="M392.984 122.435L400.009 137.124L415.873 133.456L408.77 148.108L421.526 158.225L405.642 161.804L405.685 178.087L392.984 167.9L380.282 178.087L380.326 161.804L364.444 158.225L377.2 148.108L370.096 133.456L385.959 137.124L392.984 122.435Z"
                fill="#FFDA44"
            />
            <Path
                d="M450.067 189.217L457.092 203.906L472.957 200.239L465.852 214.891L478.609 225.007L462.726 228.587L462.77 244.87L450.067 234.683L437.365 244.87L437.408 228.587L421.526 225.007L434.283 214.891L427.178 200.239L443.042 203.906L450.067 189.217Z"
                fill="#FFDA44"
            />
            <Path
                d="M409.249 267.13L414.776 284.137H432.657L418.19 294.647L423.717 311.652L409.249 301.143L394.784 311.652L400.309 294.647L385.843 284.137H403.724L409.249 267.13Z"
                fill="#FFDA44"
            />
            <Path
                d="M283.826 328.348C243.869 328.348 211.477 295.956 211.477 256C211.477 216.044 243.868 183.652 283.826 183.652C296.284 183.652 308.007 186.803 318.241 192.348C302.186 176.646 280.231 166.956 255.998 166.956C206.822 166.956 166.956 206.822 166.956 255.999C166.956 305.176 206.822 345.042 255.998 345.042C280.23 345.042 302.186 335.352 318.24 319.65C308.007 325.197 296.284 328.348 283.826 328.348Z"
                fill="#FFDA44"
            />
            <Path
                d="M160.986 100.174C153.286 86.87 138.911 77.913 122.435 77.913C105.959 77.913 91.5838 86.87 83.8838 100.174H160.986Z"
                fill="#FFDA44"
            />
            <Path
                d="M161.134 100.431L122.435 139.13L83.7361 100.431C80.0371 106.922 77.9141 114.429 77.9141 122.435C77.9141 147.024 97.8471 166.957 122.436 166.957C147.025 166.957 166.958 147.025 166.958 122.435C166.957 114.429 164.833 106.922 161.134 100.431Z"
                fill="#FFDA44"
            />
            <Path
                d="M111.304 128V165.554C114.861 166.469 118.591 166.956 122.434 166.956C126.277 166.956 130.007 166.468 133.564 165.554V128H111.304Z"
                fill="#A2001D"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7126">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgCc
