import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgNr = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 513 512" fill="none">
        <G clipPath="url(#clip0_4_7233)">
            <Path
                d="M256.988 512C398.373 512 512.988 397.385 512.988 256C512.988 114.615 398.373 0 256.988 0C115.603 0 0.988281 114.615 0.988281 256C0.988281 397.385 115.603 512 256.988 512Z"
                fill="#0052B4"
            />
            <Path
                d="M256.988 512C398.373 512 512.988 397.385 512.988 256C512.988 114.615 398.373 0 256.988 0C115.603 0 0.988281 114.615 0.988281 256C0.988281 397.385 115.603 512 256.988 512Z"
                fill="#FFDA44"
            />
            <Path
                d="M256.988 0C123.104 0 13.2464 102.781 1.96338 233.739H512.014C500.73 102.781 390.872 0 256.988 0Z"
                fill="#0052B4"
            />
            <Path
                d="M256.988 512C390.872 512 500.73 409.219 512.014 278.261H1.96338C13.2464 409.219 123.104 512 256.988 512Z"
                fill="#0052B4"
            />
            <Path
                d="M212.467 345.043L183.781 358.537L199.056 386.318L167.907 380.359L163.96 411.826L142.263 388.683L120.567 411.826L116.62 380.359L85.4701 386.317L100.746 358.536L72.0601 345.043L100.747 331.55L85.4701 303.77L116.619 309.728L120.568 278.261L142.263 301.404L163.961 278.261L167.907 309.728L199.057 303.77L183.782 331.551L212.467 345.043Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7233">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0.988281)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgNr
