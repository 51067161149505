import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgTj = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 513" fill="none">
        <G clipPath="url(#clip0_4_7304)">
            <Path
                d="M25.402 145.277C9.128 178.933 0 216.691 0 256.581C0 296.471 9.128 334.229 25.402 367.885L256 390.146L486.598 367.885C502.872 334.229 512 296.471 512 256.581C512 216.691 502.872 178.933 486.598 145.277L256 123.016L25.402 145.277Z"
                fill="#F0F0F0"
            />
            <Path
                d="M25.4111 367.885C66.8181 453.515 154.507 512.581 256 512.581C357.493 512.581 445.183 453.515 486.589 367.885H25.4111Z"
                fill="#6DA544"
            />
            <Path
                d="M25.4111 145.277H486.589C445.183 59.6471 357.493 0.581055 256 0.581055C154.507 0.581055 66.8181 59.6471 25.4111 145.277Z"
                fill="#D80027"
            />
            <Path
                d="M211.478 323.364H300.522V285.52L282.713 294.424L256 267.711L229.287 294.424L211.478 285.52V323.364Z"
                fill="#FFDA44"
            />
            <Path
                d="M160.532 278.842L164.677 291.596H178.087L167.237 299.479L171.382 312.233L160.532 304.351L149.682 312.233L153.827 299.479L142.977 291.596H156.387L160.532 278.842Z"
                fill="#FFDA44"
            />
            <Path
                d="M173.381 234.32L177.526 247.074H190.936L180.086 254.957L184.23 267.711L173.381 259.829L162.531 267.711L166.676 254.957L155.826 247.074H169.236L173.381 234.32Z"
                fill="#FFDA44"
            />
            <Path
                d="M210.113 200.929L214.258 213.683H227.667L216.819 221.566L220.962 234.32L210.113 226.438L199.264 234.32L203.408 221.566L192.558 213.683H205.969L210.113 200.929Z"
                fill="#FFDA44"
            />
            <Path
                d="M351.468 278.842L347.323 291.596H333.913L344.763 299.479L340.618 312.233L351.468 304.351L362.317 312.233L358.173 299.479L369.023 291.596H355.613L351.468 278.842Z"
                fill="#FFDA44"
            />
            <Path
                d="M338.619 234.32L334.474 247.074H321.064L331.914 254.957L327.769 267.711L338.619 259.829L349.469 267.711L345.324 254.957L356.174 247.074H342.764L338.619 234.32Z"
                fill="#FFDA44"
            />
            <Path
                d="M301.887 200.929L297.742 213.683H284.333L295.181 221.566L291.037 234.32L301.887 226.438L312.736 234.32L308.592 221.566L319.441 213.683H306.031L301.887 200.929Z"
                fill="#FFDA44"
            />
            <Path
                d="M256 184.232L260.145 196.986H273.555L262.705 204.869L266.85 217.623L256 209.741L245.15 217.623L249.295 204.869L238.445 196.986H251.855L256 184.232Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7304">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0 0.581055)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgTj
