import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgDz = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7077)">
            <Path
                d="M256 0C397.384 0 512 114.616 512 256C512 397.384 397.384 512 256 512C256 500.87 222.609 256 222.609 256L256 0Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 512C114.616 512 0 397.384 0 256C0 114.616 114.616 0 256 0"
                fill="#496E2D"
            />
            <Path
                d="M311.003 206.896L289.999 235.841L255.982 224.809L277.02 253.729L256.018 282.674L290.022 271.603L311.06 300.522L311.038 264.761L345.043 253.689L311.027 242.659L311.003 206.896Z"
                fill="#D80027"
            />
            <Path
                d="M277.237 328.348C237.281 328.348 204.889 295.956 204.889 256C204.889 216.044 237.281 183.652 277.237 183.652C289.695 183.652 301.418 186.802 311.652 192.348C295.596 176.647 273.64 166.956 249.411 166.956C200.233 166.956 160.368 206.822 160.368 255.999C160.368 305.176 200.234 345.042 249.411 345.042C273.641 345.042 295.597 335.351 311.652 319.65C301.418 325.198 289.695 328.348 277.237 328.348Z"
                fill="#D80027"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7077">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgDz
