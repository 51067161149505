import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgSm = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7272)">
            <Path
                d="M512 256C512 397.384 397.384 512 256 512C114.616 512 0 397.384 0 256C11.13 256 256 222.609 256 222.609L512 256Z"
                fill="#338AF3"
            />
            <Path
                d="M0 256C0 114.616 114.616 0 256 0C397.384 0 512 114.616 512 256"
                fill="#F0F0F0"
            />
            <Path
                d="M357.616 176.644L256 278.261L154.384 176.644C141.243 196.151 133.566 219.63 133.566 244.869V278.26C133.566 331.876 168.211 377.549 216.288 394.08C209.871 406.656 210.953 422.351 220.356 434.086C232.844 424.077 245.773 413.716 256.741 404.928C267.709 413.717 280.639 424.078 293.126 434.086C302.639 422.214 303.643 406.284 296.973 393.634C344.393 376.744 378.434 331.406 378.434 278.26V244.87C378.435 219.63 370.757 196.152 357.616 176.644Z"
                fill="#6DA544"
            />
            <Path
                d="M256 367.304C206.901 367.304 166.957 327.359 166.957 278.261V244.87C166.957 195.771 206.902 155.827 256 155.827C305.098 155.827 345.043 195.772 345.043 244.87V278.261C345.043 327.359 305.099 367.304 256 367.304Z"
                fill="#FFDA44"
            />
            <Path
                d="M311.652 278.261V244.87C311.652 214.183 286.686 189.218 256 189.218C225.314 189.218 200.348 214.184 200.348 244.87V278.261L256 289.391L311.652 278.261Z"
                fill="#338AF3"
            />
            <Path
                d="M200.348 278.261C200.348 308.948 225.314 333.913 256 333.913C286.686 333.913 311.652 308.947 311.652 278.261H200.348Z"
                fill="#6DA544"
            />
            <Path
                d="M322.783 155.826C322.783 137.385 307.833 122.435 289.392 122.435C280.837 122.435 273.04 125.655 267.131 130.944V111.304H278.261V89.043H267.131V77.913H244.87V89.043H233.74V111.304H244.87V130.944C238.961 125.655 231.163 122.435 222.609 122.435C204.168 122.435 189.218 137.385 189.218 155.826C189.218 165.713 193.519 174.593 200.348 180.708V200.348H311.652V180.708C318.482 174.593 322.783 165.713 322.783 155.826Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7272">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgSm
