import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgUz = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7325)">
            <Path
                d="M12.088 178.088C4.246 202.656 -0.000999823 228.829 1.76555e-07 255.998C-0.000999823 283.168 4.247 309.343 12.089 333.911L256 345.043L499.911 333.913C507.752 309.344 512.001 283.168 512.001 256C512 228.83 507.753 202.656 499.911 178.087L256 166.957L12.088 178.088Z"
                fill="#F0F0F0"
            />
            <Path
                d="M499.91 178.088L255.999 166.958L12.087 178.088C9.76296 185.372 7.76196 192.799 6.08496 200.349H505.909C504.234 192.798 502.234 185.37 499.91 178.088Z"
                fill="#D80027"
            />
            <Path
                d="M6.08789 311.651C7.76289 319.201 9.76389 326.627 12.0879 333.911L12.1119 333.912L255.998 345.042L499.909 333.912C502.233 326.628 504.234 319.201 505.91 311.651H6.08789Z"
                fill="#D80027"
            />
            <Path
                d="M255.999 511.999C370.215 511.999 466.945 437.196 499.91 333.912H12.0889C45.0539 437.195 141.786 511.999 255.999 511.999Z"
                fill="#6DA544"
            />
            <Path
                d="M255.999 0.000976573C141.785 0.00197657 45.0549 74.805 12.0879 178.088L499.909 178.089C466.946 74.804 370.215 -2.34275e-05 255.999 0.000976573Z"
                fill="#338AF3"
            />
            <Path
                d="M116.986 105.74C116.986 81.7628 133.837 61.7328 156.34 56.8188C152.882 56.0628 149.293 55.6538 145.607 55.6538C117.945 55.6538 95.52 78.0778 95.52 105.741C95.52 133.404 117.943 155.828 145.607 155.828C149.293 155.828 152.881 155.418 156.34 154.663C133.838 149.747 116.986 129.717 116.986 105.74Z"
                fill="#F0F0F0"
            />
            <Path
                d="M185.944 128.507L189.334 138.942H200.306L191.43 145.392L194.82 155.827L185.944 149.378L177.066 155.827L180.457 145.392L171.58 138.942H182.552L185.944 128.507Z"
                fill="#F0F0F0"
            />
            <Path
                d="M220.964 128.507L224.355 138.942H235.327L226.45 145.392L229.841 155.827L220.964 149.378L212.087 155.827L215.478 145.392L206.602 138.942H217.573L220.964 128.507Z"
                fill="#F0F0F0"
            />
            <Path
                d="M255.985 128.507L259.375 138.942H270.348L261.471 145.392L264.861 155.827L255.985 149.378L247.107 155.827L250.499 145.392L241.621 138.942H252.594L255.985 128.507Z"
                fill="#F0F0F0"
            />
            <Path
                d="M291.006 128.507L294.397 138.942H305.368L296.492 145.392L299.883 155.827L291.006 149.378L282.128 155.827L285.52 145.392L276.642 138.942H287.614L291.006 128.507Z"
                fill="#F0F0F0"
            />
            <Path
                d="M326.026 128.507L329.417 138.942H340.389L331.513 145.392L334.904 155.827L326.026 149.378L317.149 155.827L320.54 145.392L311.664 138.942H322.635L326.026 128.507Z"
                fill="#F0F0F0"
            />
            <Path
                d="M220.964 92.0801L224.355 102.514H235.327L226.45 108.965L229.841 119.399L220.964 112.95L212.087 119.399L215.478 108.965L206.602 102.514H217.573L220.964 92.0801Z"
                fill="#F0F0F0"
            />
            <Path
                d="M255.985 92.0801L259.375 102.514H270.348L261.471 108.965L264.861 119.399L255.985 112.95L247.107 119.399L250.499 108.965L241.621 102.514H252.594L255.985 92.0801Z"
                fill="#F0F0F0"
            />
            <Path
                d="M291.006 92.0801L294.397 102.514H305.368L296.492 108.965L299.883 119.399L291.006 112.95L282.128 119.399L285.52 108.965L276.642 102.514H287.614L291.006 92.0801Z"
                fill="#F0F0F0"
            />
            <Path
                d="M326.026 92.0801L329.417 102.514H340.389L331.513 108.965L334.904 119.399L326.026 112.95L317.149 119.399L320.54 108.965L311.664 102.514H322.635L326.026 92.0801Z"
                fill="#F0F0F0"
            />
            <Path
                d="M255.985 55.6519L259.375 66.0879H270.348L261.471 72.5369L264.861 82.9729L255.985 76.5239L247.107 82.9729L250.499 72.5369L241.621 66.0879H252.594L255.985 55.6519Z"
                fill="#F0F0F0"
            />
            <Path
                d="M291.006 55.6519L294.397 66.0879H305.368L296.492 72.5369L299.883 82.9729L291.006 76.5239L282.128 82.9729L285.52 72.5369L276.642 66.0879H287.614L291.006 55.6519Z"
                fill="#F0F0F0"
            />
            <Path
                d="M326.026 55.6519L329.417 66.0879H340.389L331.513 72.5369L334.904 82.9729L326.026 76.5239L317.149 82.9729L320.54 72.5369L311.664 66.0879H322.635L326.026 55.6519Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7325">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgUz
