import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgBa = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7104)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 0C165.23 0 85.4999 47.243 40.0439 118.479L387.203 475.849C461.946 431.147 512 349.423 512 256C512 114.616 397.384 0 256 0Z"
                fill="#FFDA44"
            />
            <Path
                d="M512 256C512 189.578 486.702 129.07 445.217 83.572V428.429C486.702 382.93 512 322.422 512 256Z"
                fill="#0052B4"
            />
            <Path
                d="M319.789 481.873L346.604 481.856L354.876 456.347L363.178 481.846L376.628 481.838C384.875 477.423 392.852 472.571 400.528 467.309L44.689 111.471C38.825 120.027 33.477 128.961 28.668 138.221L31.83 147.933L58.645 147.916L36.961 163.691L45.263 189.19L23.559 173.442L10.614 182.858C3.713 206.034 0 230.583 0 256C0 397.384 114.616 512 256 512C285.892 512 314.583 506.868 341.25 497.448L319.789 481.873ZM57.099 244.87L65.369 219.362L43.667 203.612L70.482 203.595L78.753 178.086L87.055 203.585L113.87 203.568L92.185 219.343L100.487 244.842L78.783 229.094L57.099 244.87ZM112.324 300.522L120.594 275.014L98.892 259.264L125.707 259.247L133.978 233.738L142.28 259.237L169.095 259.22L147.411 274.995L155.713 300.494L134.009 284.746L112.324 300.522ZM167.548 356.174L175.818 330.666L154.116 314.916L180.931 314.899L189.202 289.39L197.504 314.889L224.319 314.872L202.635 330.647L210.937 356.146L189.233 340.396L167.548 356.174ZM244.457 396.051L222.772 411.826L231.042 386.318L209.34 370.568L236.155 370.551L244.427 345.042L252.729 370.541L279.544 370.524L257.86 386.299L266.162 411.798L244.457 396.051ZM277.997 467.478L286.267 441.97L264.565 426.22L291.38 426.203L299.651 400.694L307.953 426.193L334.768 426.176L313.084 441.951L321.386 467.45L299.682 451.7L277.997 467.478Z"
                fill="#0052B4"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7104">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgBa
