import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgIn = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 513" fill="none">
        <G clipPath="url(#clip0_4_7180)">
            <Path
                d="M256 512.989C397.385 512.989 512 398.374 512 256.989C512 115.604 397.385 0.989258 256 0.989258C114.615 0.989258 0 115.604 0 256.989C0 398.374 114.615 512.989 256 512.989Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 0.989258C154.506 0.989258 66.8103 60.0543 25.4023 145.685H486.597C445.19 60.0543 357.493 0.989258 256 0.989258Z"
                fill="#FF9811"
            />
            <Path
                d="M256 512.989C357.493 512.989 445.19 453.924 486.598 368.293H25.4023C66.8103 453.924 154.506 512.989 256 512.989Z"
                fill="#6DA544"
            />
            <Path
                d="M256 346.032C305.177 346.032 345.043 306.166 345.043 256.989C345.043 207.812 305.177 167.946 256 167.946C206.823 167.946 166.957 207.812 166.957 256.989C166.957 306.166 206.823 346.032 256 346.032Z"
                fill="#0052B4"
            />
            <Path
                d="M256 312.641C286.735 312.641 311.652 287.725 311.652 256.989C311.652 226.253 286.735 201.337 256 201.337C225.264 201.337 200.348 226.253 200.348 256.989C200.348 287.725 225.264 312.641 256 312.641Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 188.315L273.169 227.253L315.473 222.652L290.337 256.989L315.473 291.326L273.169 286.725L256 325.663L238.831 286.725L196.527 291.325L221.663 256.989L196.527 222.652L238.831 227.253L256 188.315Z"
                fill="#0052B4"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7180">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0 0.989258)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgIn
