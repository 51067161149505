import {
    Address as ToolKitAddress,
    format as toolkitFormat,
} from '@zeal/toolkit/Web3/address'

import { Address } from '@zeal/domains/Address'

/* @deprecated  use toolkit*/
export const format = (address: Address): string => {
    return toolkitFormat(address as ToolKitAddress)
}
