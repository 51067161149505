import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgNu = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 513 512" fill="none">
        <G clipPath="url(#clip0_4_7240)">
            <Path
                d="M512.988 256C512.988 397.384 398.372 512 256.988 512C115.604 512 0.988281 397.384 0.988281 256C0.988281 256.061 256.988 0.028 256.988 0C398.372 0 512.988 114.616 512.988 256Z"
                fill="#FFDA44"
            />
            <Path
                d="M256.988 122.435V208.778L167.945 122.435H256.988Z"
                fill="#0052B4"
            />
            <Path
                d="M134.553 256H209.764L134.553 155.826L123.423 233.739L134.553 256Z"
                fill="#0052B4"
            />
            <Path
                d="M256.303 256H256.988C256.988 255.77 256.988 255.546 256.988 255.315C256.76 255.544 256.532 255.772 256.303 256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256.988 133.565C256.988 88.52 256.988 59.005 256.988 0H256.945C115.579 0.024 0.988281 114.629 0.988281 256H134.553V180.789L209.764 256H256.304C256.532 255.772 256.76 255.544 256.989 255.315C256.989 238.068 256.989 222.679 256.989 208.778L181.776 133.565H256.988Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256.988 224.519L166.033 133.566H134.553V133.568L256.986 256H256.988C256.988 256 256.988 234.293 256.988 224.519Z"
                fill="#D80027"
            />
            <Path
                d="M130.503 33.3911C120.634 39.0111 111.179 45.2741 102.187 52.1111H112.691L84.9293 72.2851L95.5313 104.922L67.7693 84.7521L64.8153 86.8991C53.2883 99.9891 43.0683 114.257 34.3793 129.515V256H101.162V100.176V100.174H256.988C256.988 79.1111 256.988 59.0451 256.988 33.3911H130.503Z"
                fill="#D80027"
            />
            <Path
                d="M110.348 46.1521C107.587 48.0851 104.865 50.0721 102.184 52.1111L84.9293 72.2851L67.7693 84.7511L64.7903 86.9151C58.5043 94.0551 52.6093 101.547 47.1393 109.36C53.3743 112.386 60.3733 114.087 67.7693 114.087C93.8953 114.087 115.073 92.9091 115.073 66.7831C115.075 59.3851 113.375 52.3881 110.348 46.1521Z"
                fill="#0052B4"
            />
            <Path
                d="M64.7913 86.9151L67.7703 84.7511L95.5323 104.922L84.9293 72.2851L112.689 52.1111H102.183C88.5893 62.4501 76.0623 74.1141 64.7913 86.9151Z"
                fill="#FFDA44"
            />
            <Path
                d="M177.93 44.522L183.456 61.527H201.336L186.871 72.038L192.394 89.043L177.93 78.533L163.465 89.043L168.987 72.038L154.522 61.527H172.403L177.93 44.522Z"
                fill="#FFDA44"
            />
            <Path
                d="M67.7713 155.826L73.2973 172.831H91.1773L76.7133 183.343L82.2363 200.348L67.7713 189.837L53.3053 200.348L58.8283 183.343L44.3643 172.831H62.2443L67.7713 155.826Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7240">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0.988281)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgNu
