import { notReachable } from '@zeal/toolkit'
import { ImperativeError } from '@zeal/toolkit/Error'

type NativePlatform = { OS: 'ios' | 'android' | 'web' | 'windows' | 'macos' }

export type ZealPlatform = { OS: 'ios' | 'android' | 'web' }

export const ZealPlatform: ZealPlatform = ((): ZealPlatform => {
    const platform: NativePlatform = process?.versions?.node // TODO @resetko-zeal should we have a helper for this?
        ? { OS: 'web' }
        : require('react-native').Platform // eslint-disable-line @typescript-eslint/no-var-requires

    switch (platform.OS) {
        case 'android':
        case 'ios':
        case 'web':
            return platform as ZealPlatform
        case 'windows':
        case 'macos':
            throw new ImperativeError('Platform not supported', {
                platformOS: platform.OS,
            })
        /* istanbul ignore next */
        default:
            return notReachable(platform.OS)
    }
})()
