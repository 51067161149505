import {
    failure,
    match,
    object,
    oneOf,
    Result,
    shape,
    string,
    success,
    ValidObject,
} from '@zeal/toolkit/Result'

import { HttpError } from '@zeal/domains/Error'
import { parseHttpError } from '@zeal/domains/Error/parsers/parseHttpError'

import {
    UnblockAccountNumberAndSortCodeMismatch,
    UnblockError,
    UnblockHardKycFailure,
    UnblockInvalidFasterPaymentConfiguration,
    UnblockInvalidIBAN,
    UnblockInvalidOtpError,
    UnblockLoginUserDidNotExists,
    UnblockMaximumOTPSubmitAttemptsError,
    UnblockNonceAlreadyInUse,
    UnblockOtpExpiredError,
    UnblockSessionExpired,
    UnblockUnsupportedCountry,
    UnblockUserAssociatedWithOtherMerchant,
    UnblockUserWithAddressAlreadyExists,
    UnblockUserWithSuchEmailAlreadyExists,
    UnknownUnblockError,
} from '..'

// TODO @resetko-zeal use this parser to parse unblock errors
const parseUnblockErrorObject = (
    input: unknown
): Result<unknown, { errorBody: ValidObject; httpError: HttpError }> =>
    parseHttpError(input).andThen((httpError) =>
        shape({
            url: match(httpError.url, '/wallet/unblock/'),
            errorBody: object(httpError.data),
            httpError: success(httpError),
        })
    )

const parseUnknownUnblockError = (
    input: unknown
): Result<unknown, UnknownUnblockError> =>
    parseUnblockErrorObject(input).andThen(({ errorBody, httpError }) =>
        shape({
            message: string(errorBody.message),
            path: object(httpError.queryParams).andThen((query) =>
                string(query.path)
            ),
            errorId: string(errorBody.error_id),
        }).map(({ message, errorId, path }) => {
            const error = new UnknownUnblockError(
                path,
                httpError.method,
                httpError.status,
                httpError.trace,
                errorId,
                httpError.data,
                message
            )
            error.stack = httpError.stack
            return error
        })
    )

const parseUnblockSessionExpired = (
    input: unknown
): Result<unknown, UnblockSessionExpired> =>
    parseHttpError(input)
        .andThen((err) =>
            object(err.data).andThen((obj) =>
                shape({
                    statusCode: match(err.status, 401),
                    message: string(obj.message).andThen((msg) =>
                        msg.match('Unauthorized')
                            ? success(msg)
                            : failure({
                                  type: 'unauthorized_message_does_not_match_regexp',
                                  msg,
                              })
                    ),
                })
            )
        )
        .map(() => ({ type: 'unblock_session_expired' }) as const)

const parseUnblockUserAlreadyExists = (
    input: unknown
): Result<unknown, UnblockUserWithAddressAlreadyExists> =>
    parseHttpError(input)
        .map((err) => err.data)
        .andThen((data) => object(data))
        .andThen((obj) =>
            shape({
                level: match(obj.level, 'error'),
                name: match(obj.name, 'UserAlreadyExistsError'),
                message: string(obj.message).andThen((msg) =>
                    msg.match(/^user with address/i)
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                ),
            })
        )
        .map(
            () =>
                ({ type: 'unblock_user_with_address_already_exists' }) as const
        )

const parseUnblockUserAssociatedWithOtherMerchant = (
    input: unknown
): Result<unknown, UnblockUserAssociatedWithOtherMerchant> =>
    parseHttpError(input)
        .map((err) => err.data)
        .andThen((data) => object(data))
        .andThen((obj) =>
            shape({
                level: match(obj.level, 'error'),
                name: match(obj.name, 'UserNotAssociatedWithMerchant'),
                message: string(obj.message).andThen((msg) =>
                    msg.match(
                        /^user with uuid.*not associated with the given merchant/i
                    )
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                ),
            })
        )
        .map(
            () =>
                ({
                    type: 'unblock_user_associated_with_other_merchant',
                }) as const
        )

const parseUnblockOtpExpiredError = (
    input: unknown
): Result<unknown, UnblockOtpExpiredError> =>
    parseHttpError(input)
        .map((err) => err.data)
        .andThen((data) => object(data))
        .andThen((obj) =>
            oneOf(obj, [
                shape({
                    error: match(obj.error, 'TOKEN_EXPIRED'),
                    error_id: string(obj.error_id),
                }),
                shape({
                    error: match(obj.error, 'TOKEN_NOT_FOUND'),
                    error_id: string(obj.error_id),
                }),
            ])
        )
        .map(() => ({ type: 'unblock_otp_expired' }) as const)

const parseUnblockInvalidOtpError = (
    input: unknown
): Result<unknown, UnblockInvalidOtpError> =>
    parseHttpError(input)
        .map((err) => err.data)
        .andThen((data) => object(data))
        .andThen((obj) =>
            shape({
                error: match(obj.error, 'CODE_MISMATCH'),
                error_id: string(obj.error_id),
            })
        )
        .map(() => ({ type: 'unblock_invalid_otp' }) as const)

const parseUnblockMaximumOTPSubmitAttemptsError = (
    input: unknown
): Result<unknown, UnblockMaximumOTPSubmitAttemptsError> =>
    parseHttpError(input)
        .map((err) => err.data)
        .andThen((data) => object(data))
        .andThen((obj) =>
            shape({
                name: match(obj.name, 'ATTEMPTS_EXCEEDED'),
                error_id: string(obj.error_id),
            })
        )
        .map(() => ({ type: 'unblock_maximum_otp_attempts_exceeded' }) as const)

const parseUnblockLoginError = (
    input: unknown
): Result<unknown, UnblockLoginUserDidNotExists> =>
    parseHttpError(input)
        .map((err) => err.data)
        .andThen((data) => object(data))
        .andThen((obj) =>
            shape({
                level: match(obj.level, 'error'),
                message: string(obj.message).andThen((msg) =>
                    msg.match(/^user not found/i)
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                ),
                name: match(obj.name, 'AuthBadRequestError'),
            })
        )
        .map(() => ({ type: 'unblock_login_user_did_not_exists' }) as const)

const parseUnblockHardKycFailure = (
    input: unknown
): Result<unknown, UnblockHardKycFailure> =>
    parseHttpError(input)
        .map((err) => err.data)
        .andThen((data) => object(data))
        .andThen((obj) =>
            shape({
                error: string(obj.error).andThen((msg) =>
                    msg.match('KYC status for user is HARD_KYC_FAILED')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                ),
            })
        )
        .map(
            () =>
                ({
                    type: 'unblock_hard_kyc_failure',
                }) as const
        )

const parseUnblockFasterPaymentsIsInvalid = (
    input: unknown
): Result<unknown, UnblockInvalidFasterPaymentConfiguration> => {
    return parseHttpError(input)
        .andThen((err) => object(err.data))
        .andThen((data) => object(data.error))
        .andThen((obj) =>
            shape({
                message: string(obj.error).andThen((msg) =>
                    msg.match('BENEFICIARY_DETAILS_INVALID') &&
                    msg.match('FASTER_PAYMENTS')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                ),
            })
        )
        .map(() => ({
            type: 'unblock_invalid_faster_payment_configuration' as const,
        }))
}

const parseUnblockAccountNumberAndSortCodeMismatch = (
    input: unknown
): Result<unknown, UnblockAccountNumberAndSortCodeMismatch> =>
    parseHttpError(input)
        .andThen((err) => object(err.data))
        .andThen((data) => object(data.error))
        .andThen((obj) =>
            shape({
                statusCode: match(obj.statusCode, 400),
                message: string(obj.message).andThen((msg) =>
                    msg.match('BENEFICIARY_DETAILS_INVALID') &&
                    msg.match('accountNumber_sortCode_mismatch')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                ),
            })
        )
        .map(
            () =>
                ({
                    type: 'unblock_account_number_and_sort_code_mismatch',
                }) as const
        )

const parseUnblockNonceAlreadyInUse = (
    input: unknown
): Result<unknown, UnblockNonceAlreadyInUse> =>
    parseHttpError(input)
        .map((err) => err.data)
        .andThen((data) => object(data))
        .andThen((obj) =>
            shape({
                level: match(obj.level, 'error'),
                name: match(obj.name, 'AuthBadRequestError'),
                message: string(obj.message).andThen((msg) =>
                    msg.match(/^Given nonce is already in use/i)
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                ),
            })
        )
        .map(
            () =>
                ({
                    type: 'unblock_nonce_already_in_use',
                }) as const
        )

const parseUnblockUnsupportedCountry = (
    input: unknown
): Result<unknown, UnblockUnsupportedCountry> =>
    parseUnblockErrorObject(input)
        .andThen(({ errorBody }) =>
            match(errorBody.name, 'CountryNotSupportedError')
        )
        .map(() => ({ type: 'unblock_unsupported_country' }) as const)

const parseUnblockUserWithSuchEmailAlreadyExists = (
    input: unknown
): Result<unknown, UnblockUserWithSuchEmailAlreadyExists> =>
    parseHttpError(input)
        .andThen((error) =>
            shape({
                data: object(error.data).andThen((data) =>
                    oneOf(data, [
                        match(data.name, 'EmailAlreadyExistsError'),
                        string(data.error).andThen((msg) =>
                            match(
                                msg,
                                'EMAIL_ALREADY_USED_FOR_CURRENT_PARENT_CLIENT'
                            )
                        ),
                        match(
                            data.error,
                            'This user email already exists for this merchant'
                        ),
                    ])
                ),
            })
        )
        .map(
            () =>
                ({
                    type: 'unblock_user_with_such_email_already_exists',
                }) as const
        )

const parseUnblockInvalidIBAN = (
    input: unknown
): Result<unknown, UnblockInvalidIBAN> =>
    parseHttpError(input)
        .andThen((err) => object(err.data))
        .andThen((data) => object(data.error))
        .andThen((obj) =>
            shape({
                message: string(obj.error).andThen((msg) =>
                    msg.match('BENEFICIARY_DETAILS_INVALID') &&
                    msg.match('iban_invalid')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                ),
            })
        )
        .map(
            () =>
                ({
                    type: 'unblock_invalid_iban',
                }) as const
        )

const _dontForgetToAddParser: Record<UnblockError['type'], true> = {
    unblock_account_number_and_sort_code_mismatch: true,
    unblock_hard_kyc_failure: true,
    unblock_invalid_faster_payment_configuration: true,
    unblock_invalid_iban: true,
    unblock_invalid_otp: true,
    unblock_login_user_did_not_exists: true,
    unblock_maximum_otp_attempts_exceeded: true,
    unblock_nonce_already_in_use: true,
    unblock_otp_expired: true,
    unblock_session_expired: true,
    unblock_unsupported_country: true,
    unblock_user_associated_with_other_merchant: true,
    unblock_user_with_address_already_exists: true,
    unblock_user_with_such_email_already_exists: true,
    unknown_unblock_error: true,
}

export const parseUnblockError = (
    error: unknown
): Result<unknown, UnblockError> =>
    oneOf(error, [
        oneOf(error, [
            parseUnblockAccountNumberAndSortCodeMismatch(error),
            parseUnblockFasterPaymentsIsInvalid(error),
            parseUnblockHardKycFailure(error),
            parseUnblockInvalidIBAN(error),
            parseUnblockInvalidOtpError(error),
            parseUnblockLoginError(error),
            parseUnblockMaximumOTPSubmitAttemptsError(error),
            parseUnblockNonceAlreadyInUse(error),
            parseUnblockOtpExpiredError(error),
        ]),
        oneOf(error, [
            parseUnblockSessionExpired(error),
            parseUnblockUnsupportedCountry(error),
            parseUnblockUserAlreadyExists(error),
            parseUnblockUserAssociatedWithOtherMerchant(error),
            parseUnblockUserWithSuchEmailAlreadyExists(error),
        ]),
        parseUnknownUnblockError(error), // make sure all known unblock error is above this
    ])
