import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgSz = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 513 512" fill="none">
        <G clipPath="url(#clip0_4_7299)">
            <Path
                d="M487.176 367.304C503.45 333.649 512.578 295.89 512.578 256C512.578 216.11 503.45 178.351 487.176 144.696L256.578 133.565L25.9801 144.695C9.70613 178.351 0.578125 216.11 0.578125 256C0.578125 295.89 9.70613 333.649 25.9801 367.304L256.578 378.435L487.176 367.304Z"
                fill="#FFDA44"
            />
            <Path
                d="M256.578 0C155.084 0 67.3881 59.065 25.9801 144.696H487.175C445.768 59.065 358.071 0 256.578 0Z"
                fill="black"
            />
            <Path
                d="M256.578 512C155.084 512 67.3881 452.935 25.9801 367.304H487.175C445.768 452.935 358.071 512 256.578 512Z"
                fill="#0052B4"
            />
            <Path
                d="M500.489 178.087H12.6671C4.82512 202.656 0.578125 228.831 0.578125 256C0.578125 283.169 4.82512 309.344 12.6671 333.913H500.488C508.331 309.344 512.578 283.169 512.578 256C512.578 228.831 508.331 202.656 500.489 178.087Z"
                fill="#A2001D"
            />
            <Path
                d="M89.6211 244.87H423.534V267.131H89.6211V244.87Z"
                fill="#FFDA44"
            />
            <Path
                d="M134.143 200.348H379.013V222.609H134.143V200.348Z"
                fill="#FFDA44"
            />
            <Path
                d="M380.603 256C380.603 256 323.36 322.783 256.578 322.783C189.796 322.783 189.795 256 189.795 256C189.795 256 189.795 189.217 256.578 189.217C323.361 189.217 380.603 256 380.603 256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256.578 322.783C189.795 322.783 132.553 256 132.553 256C132.553 256 189.795 189.217 256.578 189.217"
                fill="black"
            />
            <Path
                d="M212.056 233.739H234.317V278.261H212.056V233.739Z"
                fill="#F0F0F0"
            />
            <Path
                d="M278.839 233.739H301.1V278.261H278.839V233.739Z"
                fill="black"
            />
            <Path
                d="M89.6211 278.261C101.916 278.261 111.882 268.294 111.882 256C111.882 243.706 101.916 233.739 89.6211 233.739C77.3267 233.739 67.3601 243.706 67.3601 256C67.3601 268.294 77.3267 278.261 89.6211 278.261Z"
                fill="#0052B4"
            />
            <Path
                d="M423.535 278.261C435.83 278.261 445.796 268.294 445.796 256C445.796 243.706 435.83 233.739 423.535 233.739C411.241 233.739 401.274 243.706 401.274 256C401.274 268.294 411.241 278.261 423.535 278.261Z"
                fill="#0052B4"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7299">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0.578125)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgSz
