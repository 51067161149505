import { useIntl } from 'react-intl'

import { notReachable } from '@zeal/toolkit'

import { KnownCurrencies } from '@zeal/domains/Currency'
import { useCurrencyById } from '@zeal/domains/Currency/hooks/useCurrencyById'

import { Money, Money2, MoneyByCurrency } from '..'
import {
    formattedCryptoMoneyPrecise,
    formattedFiatMoneyPrecise,
} from '../helpers/formattedMoneyPrecise'

type Props = {
    money: Money2
    withSymbol: boolean
    sign: '+' | '-' | null
}

/**
 * @deprecated Use FormattedMoneyPrecise instead
 */
export const FormattedMoneyPrecise_deprecated = ({
    money,
    withSymbol,
    sign,
    knownCurrencies,
}: {
    money: Money
    withSymbol: boolean
    sign: '+' | '-' | null
    knownCurrencies: KnownCurrencies
}) => {
    const currency = useCurrencyById(money.currencyId, knownCurrencies)

    if (!currency) {
        return null
    }

    return (
        <FormattedMoneyPrecise
            money={
                {
                    amount: money.amount,
                    currency,
                } as MoneyByCurrency<typeof currency>
            }
            withSymbol={withSymbol}
            sign={sign}
        />
    )
}

export const FormattedMoneyPrecise = ({ money, withSymbol, sign }: Props) => {
    const { formatNumber } = useIntl()

    switch (money.currency.type) {
        case 'FiatCurrency':
            return (
                <>
                    {formattedFiatMoneyPrecise({
                        money: money as MoneyByCurrency<typeof money.currency>,
                        withSymbol,
                        sign,
                        formatNumber,
                    })}
                </>
            )
        case 'CryptoCurrency':
            return (
                <>
                    {formattedCryptoMoneyPrecise({
                        money: money as MoneyByCurrency<typeof money.currency>,
                        withSymbol,
                        sign,
                        formatNumber,
                    })}
                </>
            )
        /* istanbul ignore next */
        default:
            return notReachable(money.currency)
    }
}
