import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgGu = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7168)">
            <Path
                d="M111.713 467.478C152.797 495.563 202.477 512 256 512C309.523 512 359.202 495.563 400.287 467.478L256 445.217L111.713 467.478Z"
                fill="#D80027"
            />
            <Path
                d="M400.287 44.522C359.202 16.437 309.523 0 256 0C202.477 0 152.797 16.437 111.713 44.522L256 66.783L400.287 44.522Z"
                fill="#D80027"
            />
            <Path
                d="M512 256C512 171.29 461.284 86.22 400.287 44.522H111.713C44.27 90.626 0 168.14 0 256C0 343.863 44.27 421.374 111.713 467.478H400.287C467.731 421.374 512 343.863 512 256Z"
                fill="#0052B4"
            />
            <Path
                d="M241.513 417.597C238.015 414.597 155.826 343.149 155.826 256C155.826 168.851 238.014 97.402 241.513 94.403L256 81.985L270.487 94.402C273.985 97.402 356.174 168.85 356.174 256C356.174 343.15 273.986 414.599 270.487 417.597L256 430.015L241.513 417.597Z"
                fill="#D80027"
            />
            <Path
                d="M333.913 256C333.913 178.087 256 111.304 256 111.304C256 111.304 178.087 178.089 178.087 256C178.087 279.835 185.384 302.624 195.506 322.783H316.494C326.616 302.624 333.913 279.835 333.913 256Z"
                fill="#338AF3"
            />
            <Path
                d="M333.913 256C333.913 279.835 326.616 302.624 316.494 322.783L256 333.913L195.506 322.783C185.384 302.625 178.087 279.835 178.087 256H333.913Z"
                fill="#0052B4"
            />
            <Path
                d="M256 400.696C256 400.696 293.528 368.519 316.494 322.783H195.506C218.472 368.519 256 400.696 256 400.696Z"
                fill="#FFDA44"
            />
            <Path
                d="M205.913 211.478H306.087L256 261.565L205.913 211.478Z"
                fill="#6DA544"
            />
            <Path
                d="M239.304 244.87H272.695V345.044H239.304V244.87Z"
                fill="#A2001D"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7168">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgGu
