import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgSg = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7281)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M0 256.001C0 114.616 114.616 0 256 0C397.384 0 512 114.616 512 256.001"
                fill="#D80027"
            />
            <Path
                d="M155.826 133.564C155.826 96.266 182.039 65.108 217.043 57.463C211.663 56.289 206.082 55.652 200.347 55.652C157.316 55.652 122.434 90.534 122.434 133.565C122.434 176.596 157.316 211.478 200.347 211.478C206.08 211.478 211.662 210.841 217.043 209.666C182.039 202.021 155.826 170.863 155.826 133.564Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 61.217L261.526 78.222H279.407L264.94 88.733L270.466 105.738L256 95.229L241.534 105.738L247.06 88.733L232.594 78.222H250.474L256 61.217Z"
                fill="#F0F0F0"
            />
            <Path
                d="M212.625 94.608L218.15 111.614H236.031L221.565 122.124L227.091 139.129L212.625 128.62L198.157 139.129L203.684 122.124L189.217 111.614H207.098L212.625 94.608Z"
                fill="#F0F0F0"
            />
            <Path
                d="M299.376 94.608L304.903 111.614H322.783L308.316 122.124L313.843 139.129L299.376 128.62L284.91 139.129L290.436 122.124L275.97 111.614H293.85L299.376 94.608Z"
                fill="#F0F0F0"
            />
            <Path
                d="M282.681 144.695L288.207 161.701H306.087L291.621 172.211L297.147 189.216L282.681 178.707L268.215 189.216L273.741 172.211L259.275 161.701H277.154L282.681 144.695Z"
                fill="#F0F0F0"
            />
            <Path
                d="M229.32 144.695L234.845 161.701H252.727L238.26 172.211L243.787 189.216L229.32 178.707L214.853 189.216L220.379 172.211L205.913 161.701H223.794L229.32 144.695Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7281">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgSg
