import type { FormatNumberOptions } from 'react-intl'

import { notReachable } from '@zeal/toolkit'
import { abs, toNumberWithFraction } from '@zeal/toolkit/BigInt'
import { truncateInsignificantDecimals } from '@zeal/toolkit/Number'
import { staticFromString } from '@zeal/toolkit/Web3/address'

import { currencyId } from '@zeal/domains/Currency'
import {
    CryptoMoney,
    FiatMoney,
    Money2,
    MoneyByCurrency,
} from '@zeal/domains/Money'
import {
    ARBITRUM,
    AVALANCHE,
    BASE,
    BLAST,
    BSC,
    ETHEREUM,
    GNOSIS,
    LINEA,
    OPTIMISM,
    POLYGON,
} from '@zeal/domains/Network/constants'

export type NumberFormatFunction = (
    value: Parameters<Intl.NumberFormat['format']>[0],
    options?: FormatNumberOptions
) => string

const MAX_SIGNIFICANT_DECIMALS = 2
const MAX_SIGNIFICANT_DESIMALS_LARGE_CURRENCY = 4

export const formattedFiatMoneyPrecise = ({
    money,
    withSymbol,
    sign,
    formatNumber,
}: {
    money: FiatMoney
    withSymbol: boolean
    sign: '+' | '-' | null
    formatNumber: NumberFormatFunction
}): string => {
    const currency = money.currency

    const amount = toNumberWithFraction(abs(money.amount), currency.fraction)

    const decimals = amount % 1

    if (amount !== 0 && amount < 0.01) {
        return `${sign ? sign : ''}<${withSymbol ? currency.symbol : ''}0.01`
    }
    const formattedAmount = (() => {
        switch (true) {
            case amount === 0:
            case amount >= 1 && (decimals === 0 || decimals < 0.01):
                return formatNumber(Math.trunc(amount), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    roundingMode: 'trunc',
                })
            default: {
                return formatNumber(
                    truncateInsignificantDecimals(
                        amount,
                        MAX_SIGNIFICANT_DECIMALS
                    ),
                    {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        roundingMode: 'trunc',
                    }
                )
            }
        }
    })()

    return `${sign ? sign : ''}${withSymbol ? currency.symbol : ''}${formattedAmount}`
}

const LargeTokenLookup: Record<string, true> = {
    // BTC
    [currencyId({
        network: GNOSIS.hexChainId,
        address: staticFromString('0x8e5bBbb09Ed1ebdE8674Cda39A0c169401db4252'),
    })]: true,
    [currencyId({
        network: POLYGON.hexChainId,
        address: staticFromString('0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6'),
    })]: true,
    [currencyId({
        network: OPTIMISM.hexChainId,
        address: staticFromString('0x68f180fcCe6836688e9084f035309E29Bf0A2095'),
    })]: true,
    [currencyId({
        network: ETHEREUM.hexChainId,
        address: staticFromString('0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599'),
    })]: true,
    [currencyId({
        network: LINEA.hexChainId,
        address: staticFromString('0x3aAB2285ddcDdaD8edf438C1bAB47e1a9D05a9b4'),
    })]: true,
    [currencyId({
        network: ARBITRUM.hexChainId,
        address: staticFromString('0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f'),
    })]: true,
    [currencyId({
        network: AVALANCHE.hexChainId,
        address: staticFromString('0x50b7545627a5162f82a992c33b87adc75187b218'),
    })]: true,
    [currencyId({
        network: BSC.hexChainId,
        address: staticFromString('0x0555E30da8f98308EdB960aa94C0Db47230d2B9c'),
    })]: true,
    [currencyId({
        network: BASE.hexChainId,
        address: staticFromString('0xcbB7C0000aB88B473b1f5aFd9ef808440eed33Bf'),
    })]: true,
    [currencyId({
        network: BLAST.hexChainId,
        address: staticFromString('0xF7bc58b8D8f97ADC129cfC4c9f45Ce3C0E1D2692'),
    })]: true,
    // WETH
    [currencyId({
        network: GNOSIS.hexChainId,
        address: staticFromString('0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1'),
    })]: true,
    [currencyId({
        network: POLYGON.hexChainId,
        address: staticFromString('0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619'),
    })]: true,
    [currencyId({
        network: OPTIMISM.hexChainId,
        address: staticFromString('0x4200000000000000000000000000000000000006'),
    })]: true,
    [currencyId({
        network: LINEA.hexChainId,
        address: staticFromString('0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f'),
    })]: true,
    [currencyId({
        network: ARBITRUM.hexChainId,
        address: staticFromString('0x82af49447d8a07e3bd95bd0d56f35241523fbab1'),
    })]: true,
    [currencyId({
        network: AVALANCHE.hexChainId,
        address: staticFromString('0x8b82A291F83ca07Af22120ABa21632088fC92931'),
    })]: true,
    [currencyId({
        network: BSC.hexChainId,
        address: staticFromString('0x4DB5a66E937A9F4473fA95b1cAF1d1E1D62E29EA'),
    })]: true,
    [currencyId({
        network: BASE.hexChainId,
        address: staticFromString('0x4200000000000000000000000000000000000006'),
    })]: true,
    [currencyId({
        network: BLAST.hexChainId,
        address: staticFromString('0x4300000000000000000000000000000000000004'),
    })]: true,
    // Native ETH token
    [currencyId({
        network: ETHEREUM.hexChainId,
        address: ETHEREUM.gasTokenAddress,
    })]: true,
    [currencyId({
        network: BASE.hexChainId,
        address: BASE.gasTokenAddress,
    })]: true,
    [currencyId({
        network: ARBITRUM.hexChainId,
        address: ARBITRUM.gasTokenAddress,
    })]: true,
    [currencyId({
        network: OPTIMISM.hexChainId,
        address: OPTIMISM.gasTokenAddress,
    })]: true,
    [currencyId({
        network: BLAST.hexChainId,
        address: BLAST.gasTokenAddress,
    })]: true,
    [currencyId({
        network: LINEA.hexChainId,
        address: LINEA.gasTokenAddress,
    })]: true,
}

export const formattedCryptoMoneyPrecise = ({
    money,
    withSymbol,
    sign,
    formatNumber,
}: {
    money: CryptoMoney
    withSymbol: boolean
    sign: '+' | '-' | null
    formatNumber: NumberFormatFunction
}): string => {
    const currency = money.currency

    const amount = toNumberWithFraction(abs(money.amount), currency.fraction)

    const formattedAmount = LargeTokenLookup[currency.id]
        ? formatLargeCryptoCurrencyAmount({ amount, formatNumber })
        : formatRegularCryptoCurrencyAmount({ amount, formatNumber })

    return `${sign ? sign : ''}${formattedAmount}${withSymbol ? ` ${currency.symbol}` : ''}`
}

const formatLargeCryptoCurrencyAmount = ({
    amount,
    formatNumber,
}: {
    amount: number
    formatNumber: NumberFormatFunction
}) => {
    switch (true) {
        case amount < 0.000001: {
            return formatNumber(
                truncateInsignificantDecimals(amount, MAX_SIGNIFICANT_DECIMALS),
                {
                    maximumSignificantDigits: MAX_SIGNIFICANT_DECIMALS,
                    roundingMode: 'trunc',
                }
            )
        }
        case amount < 1: {
            return formatNumber(
                truncateInsignificantDecimals(
                    amount,
                    MAX_SIGNIFICANT_DESIMALS_LARGE_CURRENCY
                ),
                {
                    maximumSignificantDigits:
                        MAX_SIGNIFICANT_DESIMALS_LARGE_CURRENCY,
                    roundingMode: 'trunc',
                }
            )
        }
        default:
            return formatNumber(
                truncateInsignificantDecimals(
                    amount,
                    MAX_SIGNIFICANT_DESIMALS_LARGE_CURRENCY
                ),
                {
                    maximumFractionDigits: 4,
                    roundingMode: 'trunc',
                }
            )
    }
}

const formatRegularCryptoCurrencyAmount = ({
    amount,
    formatNumber,
}: {
    amount: number
    formatNumber: NumberFormatFunction
}) => {
    const decimals = amount % 1

    switch (true) {
        case amount === 0:
        case amount >= 1 && (decimals === 0 || decimals < 0.01):
            return formatNumber(Math.trunc(amount), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                roundingMode: 'trunc',
            })
        case amount < 0.01:
            return formatNumber(
                truncateInsignificantDecimals(amount, MAX_SIGNIFICANT_DECIMALS),
                {
                    maximumSignificantDigits: MAX_SIGNIFICANT_DECIMALS,
                    roundingMode: 'trunc',
                }
            )
        default:
            return formatNumber(
                truncateInsignificantDecimals(amount, MAX_SIGNIFICANT_DECIMALS),
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    roundingMode: 'trunc',
                }
            )
    }
}

export const formattedMoneyPrecise = ({
    money,
    withSymbol,
    sign,
    formatNumber,
}: {
    money: Money2
    withSymbol: boolean
    sign: '+' | '-' | null
    formatNumber: NumberFormatFunction
}) => {
    switch (money.currency.type) {
        case 'FiatCurrency':
            return formattedFiatMoneyPrecise({
                money: money as MoneyByCurrency<typeof money.currency>,
                withSymbol,
                sign,
                formatNumber,
            })
        case 'CryptoCurrency':
            return formattedCryptoMoneyPrecise({
                money: money as MoneyByCurrency<typeof money.currency>,
                withSymbol,
                sign,
                formatNumber,
            })
        /* istanbul ignore next */
        default:
            return notReachable(money.currency)
    }
}
