import { CryptoCurrency } from '@zeal/domains/Currency'
import { Token2 } from '@zeal/domains/Token'
import { tokenToToken2 } from '@zeal/domains/Token/helpers/tokenToToken2'

import { ServerPortfolio } from '../index'

type Params = {
    currency: CryptoCurrency
    portfolio: ServerPortfolio
}

export const getTokenByCryptoCurrency2 = ({
    currency,
    portfolio,
}: Params): Token2 => {
    const portfolioToken =
        portfolio.tokens.find(
            (token) => token.balance.currencyId === currency.id
        ) || null

    if (portfolioToken) {
        return tokenToToken2({
            token: portfolioToken,
            knownCurrencies: portfolio.currencies,
        })
    }

    return {
        balance: {
            amount: 0n,
            currency,
        },
        priceInDefaultCurrency: null,
        rate: null,
        marketData: null,
        scam: false,
    }
}
