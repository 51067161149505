import {
    failure,
    object,
    Result,
    shape,
    string,
    success,
} from '@zeal/toolkit/Result'

import { PasskeyAndroidFidoApiNotSupported } from '@zeal/domains/Error/domains/Passkey'

export const parsePasskeyAndroidFidoApiNotSupported = (
    input: unknown
): Result<unknown, PasskeyAndroidFidoApiNotSupported> =>
    object(input)
        .andThen((obj) =>
            shape({
                message: string(obj.message).andThen((msg) =>
                    msg.match('FIDO2 API is not supported on devices below')
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                ),
            })
        )
        .map(() => ({ type: 'passkey_android_fido_api_not_supported' }))
