import { staticFromString } from '@zeal/toolkit/Web3/address'

import {
    CryptoCurrency,
    currencyId,
    FiatCurrency,
    FiatCurrencyCode,
} from '@zeal/domains/Currency'
import {
    ARBITRUM,
    BSC,
    ETHEREUM,
    GNOSIS,
    OPTIMISM,
    POLYGON,
} from '@zeal/domains/Network/constants'

export const SOCKET_NATIVE_TOKEN_ADDRESS = staticFromString(
    '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
)

export const FIAT_DUST: bigint = 10000000000000000n // fromFixedWithFraction('0.01', FIAT_CURRENCIES.USD.fraction) We use a fraction = 18

export const FIAT_CURRENCIES: Record<FiatCurrencyCode, FiatCurrency> = {
    GBP: {
        type: 'FiatCurrency',
        id: 'GBP',
        symbol: '£',
        code: 'GBP',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'British Pound',
    },
    EUR: {
        type: 'FiatCurrency',
        id: 'EUR',
        symbol: '€',
        code: 'EUR',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Euro',
    },
    NGN: {
        type: 'FiatCurrency',
        id: 'NGN',
        symbol: '₦',
        code: 'NGN',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Nigerian Naira',
    },
    PLN: {
        type: 'FiatCurrency',
        id: 'PLN',
        symbol: 'zł',
        code: 'PLN',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Polish Zloty',
    },
    USD: {
        type: 'FiatCurrency',
        id: 'USD',
        symbol: '$',
        code: 'USD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'USD',
    },
    ZWL: {
        type: 'FiatCurrency',
        id: 'ZWL',
        symbol: 'ZWL$',
        code: 'ZWL',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Zimbabwean Dollar',
    },
    BMD: {
        type: 'FiatCurrency',
        id: 'ZWL',
        symbol: '$',
        code: 'BMD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Bermudian Dollar',
    },
    ZMK: {
        type: 'FiatCurrency',
        id: 'ZMK',
        symbol: 'ZK',
        code: 'ZMK',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Zambian Kwacha',
    },
    ZAR: {
        type: 'FiatCurrency',
        id: 'ZAR',
        symbol: 'R',
        code: 'ZAR',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'South African Rand',
    },
    YER: {
        type: 'FiatCurrency',
        id: 'YER',
        symbol: 'YR',
        code: 'YER',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Yemeni Rial',
    },
    XOF: {
        type: 'FiatCurrency',
        id: 'XOF',
        symbol: 'CFA',
        code: 'XOF',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'CFA Franc BCEAO',
    },
    XAF: {
        type: 'FiatCurrency',
        id: 'XAF',
        symbol: 'FCFA',
        code: 'XAF',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'CFA Franc BEAC',
    },
    VND: {
        type: 'FiatCurrency',
        id: 'VND',
        symbol: '₫',
        code: 'VND',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Vietnamese Dong',
    },
    VEF: {
        type: 'FiatCurrency',
        id: 'VEF',
        symbol: 'Bs.F',
        code: 'VEF',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Venezuelan Bolívar',
    },
    UZS: {
        type: 'FiatCurrency',
        id: 'UZS',
        symbol: 'UZS',
        code: 'UZS',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Uzbekistan Som',
    },
    UYU: {
        type: 'FiatCurrency',
        id: 'UYU',
        symbol: '$U',
        code: 'UYU',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Uruguayan Peso',
    },
    UGX: {
        type: 'FiatCurrency',
        id: 'UGX',
        symbol: 'USh',
        code: 'UGX',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Ugandan Shilling',
    },
    UAH: {
        type: 'FiatCurrency',
        id: 'UAH',
        symbol: '₴',
        code: 'UAH',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Ukrainian Hryvnia',
    },
    TZS: {
        type: 'FiatCurrency',
        id: 'TZS',
        symbol: 'TSh',
        code: 'TZS',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Tanzanian Shilling',
    },
    TWD: {
        type: 'FiatCurrency',
        id: 'TWD',
        symbol: 'NT$',
        code: 'TWD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'New Taiwan Dollar',
    },
    TTD: {
        type: 'FiatCurrency',
        id: 'TTD',
        symbol: 'TT$',
        code: 'TTD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Trinidad and Tobago Dollar',
    },
    TRY: {
        type: 'FiatCurrency',
        id: 'TRY',
        symbol: '₺',
        code: 'TRY',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Turkish Lira',
    },
    TOP: {
        type: 'FiatCurrency',
        id: 'TOP',
        symbol: 'T$',
        code: 'TOP',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Tongan Paʻanga',
    },
    TND: {
        type: 'FiatCurrency',
        id: 'TND',
        symbol: 'DT',
        code: 'TND',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Tunisian Dinar',
    },
    THB: {
        type: 'FiatCurrency',
        id: 'THB',
        symbol: '฿',
        code: 'THB',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Thai Baht',
    },
    SYP: {
        type: 'FiatCurrency',
        id: 'SYP',
        symbol: 'SY£',
        code: 'SYP',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Syrian Pound',
    },
    SOS: {
        type: 'FiatCurrency',
        id: 'SOS',
        symbol: 'Ssh',
        code: 'SOS',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Somali Shilling',
    },
    SGD: {
        type: 'FiatCurrency',
        id: 'SGD',
        symbol: 'S$',
        code: 'SGD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Singapore Dollar',
    },
    SEK: {
        type: 'FiatCurrency',
        id: 'SEK',
        symbol: 'kr',
        code: 'SEK',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Swedish Krona',
    },
    SDG: {
        type: 'FiatCurrency',
        id: 'SDG',
        symbol: 'SDG',
        code: 'SDG',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Sudanese Pound',
    },
    SAR: {
        type: 'FiatCurrency',
        id: 'SAR',
        symbol: 'SR',
        code: 'SAR',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Saudi Riyal',
    },
    RWF: {
        type: 'FiatCurrency',
        id: 'RWF',
        symbol: 'RWF',
        code: 'RWF',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Rwandan Franc',
    },
    RUB: {
        type: 'FiatCurrency',
        id: 'RUB',
        symbol: '₽',
        code: 'RUB',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Russian Ruble',
    },
    RSD: {
        type: 'FiatCurrency',
        id: 'RSD',
        symbol: 'din.',
        code: 'RSD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Serbian Dinar',
    },
    RON: {
        type: 'FiatCurrency',
        id: 'RON',
        symbol: 'RON',
        code: 'RON',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Romanian Leu',
    },
    QAR: {
        type: 'FiatCurrency',
        id: 'QAR',
        symbol: 'QR',
        code: 'QAR',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Qatari Rial',
    },
    PYG: {
        type: 'FiatCurrency',
        id: 'PYG',
        symbol: '₲',
        code: 'PYG',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Paraguayan Guarani',
    },
    PKR: {
        type: 'FiatCurrency',
        id: 'PKR',
        symbol: '₨',
        code: 'PKR',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Pakistani Rupee',
    },
    PHP: {
        type: 'FiatCurrency',
        id: 'PHP',
        symbol: '₱',
        code: 'PHP',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Philippine Peso',
    },
    PEN: {
        type: 'FiatCurrency',
        id: 'PEN',
        symbol: 'S/.',
        code: 'PEN',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Peruvian Nuevo Sol',
    },
    PAB: {
        type: 'FiatCurrency',
        id: 'PAB',
        symbol: 'B/.',
        code: 'PAB',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Panamanian Balboa',
    },
    OMR: {
        type: 'FiatCurrency',
        id: 'OMR',
        symbol: 'OMR',
        code: 'OMR',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Omani Rial',
    },
    NZD: {
        type: 'FiatCurrency',
        id: 'NZD',
        symbol: 'NZ$',
        code: 'NZD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'New Zealand Dollar',
    },
    NPR: {
        type: 'FiatCurrency',
        id: 'NPR',
        symbol: 'NPRs',
        code: 'NPR',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Nepalese Rupee',
    },
    NOK: {
        type: 'FiatCurrency',
        id: 'NOK',
        symbol: 'kr',
        code: 'NOK',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Norwegian Krone',
    },
    NIO: {
        type: 'FiatCurrency',
        id: 'NIO',
        symbol: 'C$',
        code: 'NIO',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Nicaraguan Córdoba',
    },
    NAD: {
        type: 'FiatCurrency',
        id: 'NAD',
        symbol: 'N$',
        code: 'NAD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Namibian Dollar',
    },
    MZN: {
        type: 'FiatCurrency',
        id: 'MZN',
        symbol: 'MTn',
        code: 'MZN',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Mozambican Metical',
    },
    MYR: {
        type: 'FiatCurrency',
        id: 'MYR',
        symbol: 'RM',
        code: 'MYR',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Malaysian Ringgit',
    },
    MXN: {
        type: 'FiatCurrency',
        id: 'MXN',
        symbol: 'MX$',
        code: 'MXN',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Mexican Peso',
    },
    MUR: {
        type: 'FiatCurrency',
        id: 'MUR',
        symbol: 'MURs',
        code: 'MUR',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Mauritian Rupee',
    },
    MOP: {
        type: 'FiatCurrency',
        id: 'MOP',
        symbol: 'MOP$',
        code: 'MOP',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Macanese Pataca',
    },
    MMK: {
        type: 'FiatCurrency',
        id: 'MMK',
        symbol: 'K',
        code: 'MMK',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Myanma Kyat',
    },
    MKD: {
        type: 'FiatCurrency',
        id: 'MKD',
        symbol: 'MKD',
        code: 'MKD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Macedonian Denar',
    },
    MGA: {
        type: 'FiatCurrency',
        id: 'MGA',
        symbol: 'MGA',
        code: 'MGA',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Malagasy Ariary',
    },
    MDL: {
        type: 'FiatCurrency',
        id: 'MDL',
        symbol: 'MDL',
        code: 'MDL',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Moldovan Leu',
    },
    MAD: {
        type: 'FiatCurrency',
        id: 'MAD',
        symbol: 'MAD',
        code: 'MAD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Moroccan Dirham',
    },
    LYD: {
        type: 'FiatCurrency',
        id: 'LYD',
        symbol: 'LD',
        code: 'LYD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Libyan Dinar',
    },
    LVL: {
        type: 'FiatCurrency',
        id: 'LVL',
        symbol: 'Ls',
        code: 'LVL',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Latvian Lats',
    },
    LTL: {
        type: 'FiatCurrency',
        id: 'LTL',
        symbol: 'Lt',
        code: 'LTL',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Lithuanian Litas',
    },
    LKR: {
        type: 'FiatCurrency',
        id: 'LKR',
        symbol: 'Rs',
        code: 'LKR',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Sri Lankan Rupee',
    },
    LBP: {
        type: 'FiatCurrency',
        id: 'LBP',
        symbol: 'L.L.',
        code: 'LBP',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Lebanese Pound',
    },
    KZT: {
        type: 'FiatCurrency',
        id: 'KZT',
        symbol: 'KZT',
        code: 'KZT',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Kazakhstani Tenge',
    },
    KWD: {
        type: 'FiatCurrency',
        id: 'KWD',
        symbol: 'KD',
        code: 'KWD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Kuwaiti Dinar',
    },
    KRW: {
        type: 'FiatCurrency',
        id: 'KRW',
        symbol: '₩',
        code: 'KRW',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'South Korean Won',
    },
    KMF: {
        type: 'FiatCurrency',
        id: 'KMF',
        symbol: 'CF',
        code: 'KMF',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Comorian Franc',
    },
    KHR: {
        type: 'FiatCurrency',
        id: 'KHR',
        symbol: 'KHR',
        code: 'KHR',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Cambodian Riel',
    },
    KES: {
        type: 'FiatCurrency',
        id: 'KES',
        symbol: 'Ksh',
        code: 'KES',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Kenyan Shilling',
    },
    JPY: {
        type: 'FiatCurrency',
        id: 'JPY',
        symbol: '¥',
        code: 'JPY',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Japanese Yen',
    },
    JOD: {
        type: 'FiatCurrency',
        id: 'JOD',
        symbol: 'JD',
        code: 'JOD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Jordanian Dinar',
    },
    JMD: {
        type: 'FiatCurrency',
        id: 'JMD',
        symbol: 'J$',
        code: 'JMD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Jamaican Dollar',
    },
    ISK: {
        type: 'FiatCurrency',
        id: 'ISK',
        symbol: 'Ikr',
        code: 'ISK',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Icelandic Króna',
    },
    IRR: {
        type: 'FiatCurrency',
        id: 'IRR',
        symbol: 'IRR',
        code: 'IRR',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Iranian Rial',
    },
    IQD: {
        type: 'FiatCurrency',
        id: 'IQD',
        symbol: 'IQD',
        code: 'IQD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Iraqi Dinar',
    },
    INR: {
        type: 'FiatCurrency',
        id: 'INR',
        symbol: '₹',
        code: 'INR',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Indian Rupee',
    },
    ILS: {
        type: 'FiatCurrency',
        id: 'ILS',
        symbol: '₪',
        code: 'ILS',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Israeli New Sheqel',
    },
    IDR: {
        type: 'FiatCurrency',
        id: 'IDR',
        symbol: 'Rp',
        code: 'IDR',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Indonesian Rupiah',
    },
    HUF: {
        type: 'FiatCurrency',
        id: 'HUF',
        symbol: 'Ft',
        code: 'HUF',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Hungarian Forint',
    },
    HRK: {
        type: 'FiatCurrency',
        id: 'HRK',
        symbol: 'kn',
        code: 'HRK',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Croatian Kuna',
    },
    HNL: {
        type: 'FiatCurrency',
        id: 'HNL',
        symbol: 'HNL',
        code: 'HNL',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Honduran Lempira',
    },
    HKD: {
        type: 'FiatCurrency',
        id: 'HKD',
        symbol: 'HK$',
        code: 'HKD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Hong Kong Dollar',
    },
    GTQ: {
        type: 'FiatCurrency',
        id: 'GTQ',
        symbol: 'GTQ',
        code: 'GTQ',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Guatemalan Quetzal',
    },
    GNF: {
        type: 'FiatCurrency',
        id: 'GNF',
        symbol: 'FG',
        code: 'GNF',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Guinean Franc',
    },
    GHS: {
        type: 'FiatCurrency',
        id: 'GHS',
        symbol: 'GH₵',
        code: 'GHS',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Ghanaian Cedi',
    },
    GEL: {
        type: 'FiatCurrency',
        id: 'GEL',
        symbol: '₾',
        code: 'GEL',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Georgian Lari',
    },
    ETB: {
        type: 'FiatCurrency',
        id: 'ETB',
        symbol: 'Br',
        code: 'ETB',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Ethiopian Birr',
    },
    ERN: {
        type: 'FiatCurrency',
        id: 'ERN',
        symbol: 'Nfk',
        code: 'ERN',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Eritrean Nakfa',
    },
    EGP: {
        type: 'FiatCurrency',
        id: 'EGP',
        symbol: 'EGP',
        code: 'EGP',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Egyptian Pound',
    },
    EEK: {
        type: 'FiatCurrency',
        id: 'EEK',
        symbol: 'Ekr',
        code: 'EEK',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Estonian Kroon',
    },
    DZD: {
        type: 'FiatCurrency',
        id: 'DZD',
        symbol: 'DA',
        code: 'DZD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Algerian Dinar',
    },
    DOP: {
        type: 'FiatCurrency',
        id: 'DOP',
        symbol: 'RD$',
        code: 'DOP',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Dominican Peso',
    },
    DKK: {
        type: 'FiatCurrency',
        id: 'DKK',
        symbol: 'kr',
        code: 'DKK',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Danish Krone',
    },
    DJF: {
        type: 'FiatCurrency',
        id: 'DJF',
        symbol: 'Fdj',
        code: 'DJF',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Djiboutian Franc',
    },
    CZK: {
        type: 'FiatCurrency',
        id: 'CZK',
        symbol: 'Kč',
        code: 'CZK',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Czech Republic Koruna',
    },
    CVE: {
        type: 'FiatCurrency',
        id: 'CVE',
        symbol: 'CV$',
        code: 'CVE',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Cape Verdean Escudo',
    },
    CRC: {
        type: 'FiatCurrency',
        id: 'CRC',
        symbol: '₡',
        code: 'CRC',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Costa Rican Colón',
    },
    COP: {
        type: 'FiatCurrency',
        id: 'COP',
        symbol: 'CO$',
        code: 'COP',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Colombian Peso',
    },
    CNY: {
        type: 'FiatCurrency',
        id: 'CNY',
        symbol: '¥',
        code: 'CNY',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Chinese Yuan',
    },
    CLP: {
        type: 'FiatCurrency',
        id: 'CLP',
        symbol: 'CLP$',
        code: 'CLP',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Chilean Peso',
    },
    CHF: {
        type: 'FiatCurrency',
        id: 'CHF',
        symbol: 'Fr.',
        code: 'CHF',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Swiss Franc',
    },
    CDF: {
        type: 'FiatCurrency',
        id: 'CDF',
        symbol: 'CDF',
        code: 'CDF',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Congolese Franc',
    },
    BZD: {
        type: 'FiatCurrency',
        id: 'BZD',
        symbol: 'BZ$',
        code: 'BZD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Belize Dollar',
    },
    BYN: {
        type: 'FiatCurrency',
        id: 'BYN',
        symbol: 'Br',
        code: 'BYN',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Belarusian Ruble',
    },
    BWP: {
        type: 'FiatCurrency',
        id: 'BWP',
        symbol: 'BWP',
        code: 'BWP',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Botswanan Pula',
    },
    BRL: {
        type: 'FiatCurrency',
        id: 'BRL',
        symbol: 'R$',
        code: 'BRL',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Brazilian Real',
    },
    BOB: {
        type: 'FiatCurrency',
        id: 'BOB',
        symbol: 'Bs',
        code: 'BOB',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Bolivian Boliviano',
    },
    BND: {
        type: 'FiatCurrency',
        id: 'BND',
        symbol: 'BN$',
        code: 'BND',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Brunei Dollar',
    },
    BIF: {
        type: 'FiatCurrency',
        id: 'BIF',
        symbol: 'FBu',
        code: 'BIF',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Burundian Franc',
    },
    BHD: {
        type: 'FiatCurrency',
        id: 'BHD',
        symbol: 'BD',
        code: 'BHD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Bahraini Dinar',
    },
    BGN: {
        type: 'FiatCurrency',
        id: 'BGN',
        symbol: 'BGN',
        code: 'BGN',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Bulgarian Lev',
    },
    BDT: {
        type: 'FiatCurrency',
        id: 'BDT',
        symbol: '৳',
        code: 'BDT',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Bangladeshi Taka',
    },
    BAM: {
        type: 'FiatCurrency',
        id: 'BAM',
        symbol: 'KM',
        code: 'BAM',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Bosnia-Herzegovina Convertible Mark',
    },
    AZN: {
        type: 'FiatCurrency',
        id: 'AZN',
        symbol: 'man.',
        code: 'AZN',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Azerbaijani Manat',
    },
    AUD: {
        type: 'FiatCurrency',
        id: 'AUD',
        symbol: 'A$',
        code: 'AUD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Australian Dollar',
    },
    ARS: {
        type: 'FiatCurrency',
        id: 'ARS',
        symbol: '$',
        code: 'ARS',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Argentine Peso',
    },
    AMD: {
        type: 'FiatCurrency',
        id: 'AMD',
        symbol: 'AMD',
        code: 'AMD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Armenian Dram',
    },
    ALL: {
        type: 'FiatCurrency',
        id: 'ALL',
        symbol: 'ALL',
        code: 'ALL',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Albanian Lek',
    },
    AFN: {
        type: 'FiatCurrency',
        id: 'AFN',
        symbol: 'Af',
        code: 'AFN',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Afghan Afghani',
    },
    AED: {
        type: 'FiatCurrency',
        id: 'AED',
        symbol: 'DH',
        code: 'AED',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'United Arab Emirates Dirham',
    },
    CAD: {
        type: 'FiatCurrency',
        id: 'CAD',
        symbol: 'CA$',
        code: 'CAD',
        fraction: 18,
        rateFraction: 18,
        icon: 'TODO',
        name: 'Canadian Dollar',
    },
}

export const COIN_GECKO_SUPPORTED_FIAT_CURRENCIES: Set<FiatCurrencyCode> =
    new Set([
        'AED',
        'ARS',
        'AUD',
        'BDT',
        'BHD',
        'BMD',
        'BRL',
        'CAD',
        'CHF',
        'CLP',
        'CNY',
        'CZK',
        'DKK',
        'EUR',
        'GBP',
        'GEL',
        'HKD',
        'HUF',
        'IDR',
        'ILS',
        'INR',
        'JPY',
        'KRW',
        'KWD',
        'LKR',
        'MMK',
        'MXN',
        'MYR',
        'NGN',
        'NOK',
        'NZD',
        'PHP',
        'PKR',
        'PLN',
        'RUB',
        'SAR',
        'SEK',
        'SGD',
        'THB',
        'TRY',
        'TWD',
        'UAH',
        'USD',
        'VEF',
        'VND',
        'ZAR',
    ])

export const INITIAL_DEFAULT_CURRENCY = FIAT_CURRENCIES.USD

// Natives

export const POLYGON_POL: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Polygon|0x0000000000000000000000000000000000001010',
    symbol: 'POL',
    code: 'POL',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Polygon|0x0000000000000000000000000000000000001010',
    name: 'POL',
    address: '0x0000000000000000000000000000000000001010',
    marketCapRank: 42,
    networkHexChainId: POLYGON.hexChainId,
}

export const ETHEREUM_ETH: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Ethereum|0x0000000000000000000000000000000000000000',
    symbol: 'ETH',
    code: 'ETH',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Ethereum|0x0000000000000000000000000000000000000000',
    name: 'ETH',
    address: '0x0000000000000000000000000000000000000000',
    marketCapRank: 2,
    networkHexChainId: ETHEREUM.hexChainId,
}

export const GNOSIS_XDAI: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0x44fa8e6f47987339850636f88629646662444217',
    networkHexChainId: GNOSIS.hexChainId,
    address: '0x44fa8e6f47987339850636f88629646662444217',
    symbol: 'xDAI',
    code: 'XDAI',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0x44fa8e6f47987339850636f88629646662444217',
    name: 'XDAI',
    marketCapRank: null,
}

export const ARBITRUM_ETH: CryptoCurrency = {
    symbol: 'ETH',
    fraction: 18,
    name: 'ETH',
    marketCapRank: 2,
    type: 'CryptoCurrency',
    rateFraction: 18,
    id: 'Arbitrum|0x0000000000000000000000000000000000000000',
    networkHexChainId: ARBITRUM.hexChainId,
    address: '0x0000000000000000000000000000000000000000',
    code: 'ETH',
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Arbitrum|0x0000000000000000000000000000000000000000',
}

export const OPTIMISM_ETH: CryptoCurrency = {
    symbol: 'ETH',
    fraction: 18,
    name: 'ETH',
    marketCapRank: 2,
    type: 'CryptoCurrency',
    rateFraction: 18,
    id: 'Optimism|0x0000000000000000000000000000000000000000',
    networkHexChainId: OPTIMISM.hexChainId,
    address: '0x0000000000000000000000000000000000000000',
    code: 'ETH',
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Optimism|0x0000000000000000000000000000000000000000',
}

export const BSC_BNB: CryptoCurrency = {
    symbol: 'BNB',
    fraction: 18,
    name: 'BNB',
    marketCapRank: 4,
    type: 'CryptoCurrency',
    rateFraction: 18,
    id: 'BSC|0x0000000000000000000000000000000000000000',
    networkHexChainId: BSC.hexChainId,
    address: '0x0000000000000000000000000000000000000000',
    code: 'BNB',
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/BSC|0x0000000000000000000000000000000000000000',
}

// Wrapped natives

export const ETHEREUM_WETH: CryptoCurrency = {
    symbol: 'WETH',
    fraction: 18,
    name: 'Wrapped Ether',
    marketCapRank: 17,
    type: 'CryptoCurrency',
    rateFraction: 18,
    id: 'Ethereum|0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    networkHexChainId: ETHEREUM.hexChainId,
    address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    code: 'WETH',
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Ethereum|0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
}

export const BSC_WBNB: CryptoCurrency = {
    symbol: 'WBNB',
    fraction: 18,
    name: 'Wrapped BNB',
    marketCapRank: null,
    type: 'CryptoCurrency',
    rateFraction: 18,
    id: 'BSC|0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    networkHexChainId: BSC.hexChainId,
    address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    code: 'WBNB',
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/BSC|0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
}

export const POLYGON_WMATIC: CryptoCurrency = {
    symbol: 'WMATIC',
    fraction: 18,
    name: 'Wrapped Matic',
    marketCapRank: null,
    type: 'CryptoCurrency',
    rateFraction: 18,
    id: 'Polygon|0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    networkHexChainId: POLYGON.hexChainId,
    address: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    code: 'WMATIC',
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Polygon|0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
}

export const GNOSIS_WXDAI: CryptoCurrency = {
    symbol: 'WXDAI',
    fraction: 18,
    name: 'Wrapped XDAI',
    marketCapRank: null,
    type: 'CryptoCurrency',
    rateFraction: 18,
    id: 'Gnosis|0xe91d153e0b41518a2ce8dd3d7944fa863463a97d',
    networkHexChainId: GNOSIS.hexChainId,
    address: '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d',
    code: 'WXDAI',
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0xe91d153e0b41518a2ce8dd3d7944fa863463a97d',
}

export const ARBITRUM_WETH: CryptoCurrency = {
    symbol: 'WETH',
    fraction: 18,
    name: 'Wrapped Ether',
    marketCapRank: 155,
    type: 'CryptoCurrency',
    rateFraction: 18,
    id: 'Arbitrum|0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    networkHexChainId: ARBITRUM.hexChainId,
    address: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    code: 'WETH',
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Arbitrum|0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
}

export const OPTIMISM_WETH: CryptoCurrency = {
    symbol: 'WETH',
    fraction: 18,
    name: 'Wrapped Ether',
    marketCapRank: 405,
    type: 'CryptoCurrency',
    rateFraction: 18,
    id: 'Optimism|0x4200000000000000000000000000000000000006',
    networkHexChainId: OPTIMISM.hexChainId,
    address: '0x4200000000000000000000000000000000000006',
    code: 'WETH',
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Optimism|0x4200000000000000000000000000000000000006',
}

// Stables

export const GNOSIS_EURE: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0xcb444e90d8198415266c6a2724b7900fb12fc56e',
    symbol: 'EURe',
    code: 'EURe',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0xcb444e90d8198415266c6a2724b7900fb12fc56e',
    name: 'Monerium EUR emoney',
    address: '0xcb444e90d8198415266c6a2724b7900fb12fc56e',
    marketCapRank: null,
    networkHexChainId: GNOSIS.hexChainId,
}

export const GNOSIS_EURE_V2: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0x420ca0f9b9b604ce0fd9c18ef134c705e5fa3430',
    symbol: 'EURe',
    code: 'EURe',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0xcb444e90d8198415266c6a2724b7900fb12fc56e',
    name: 'Monerium EUR emoney',
    address: '0x420ca0f9b9b604ce0fd9c18ef134c705e5fa3430',
    marketCapRank: null,
    networkHexChainId: GNOSIS.hexChainId,
}

export const GNOSIS_GBPE: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0x5cb9073902f2035222b9749f8fb0c9bfe5527108',
    symbol: 'GBPe',
    code: 'GBPe',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0x5cb9073902f2035222b9749f8fb0c9bfe5527108',
    name: 'Monerium GBP emoney',
    address: '0x5cb9073902f2035222b9749f8fb0c9bfe5527108',
    marketCapRank: null,
    networkHexChainId: GNOSIS.hexChainId,
}

export const GNOSIS_GBPE_V2: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0x8e34bfec4f6eb781f9743d9b4af99cd23f9b7053',
    symbol: 'GBPe',
    code: 'GBPe',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0x5cb9073902f2035222b9749f8fb0c9bfe5527108',
    name: 'Monerium GBP emoney',
    address: '0x8e34bfec4f6eb781f9743d9b4af99cd23f9b7053',
    marketCapRank: null,
    networkHexChainId: GNOSIS.hexChainId,
}

export const GNOSIS_USDC: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0xddafbb505ad214d7b80b1f830fccc89b60fb7a83',
    symbol: 'USDC.e',
    code: 'USDC.e',
    fraction: 6,
    rateFraction: 6,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0xddafbb505ad214d7b80b1f830fccc89b60fb7a83',
    name: 'USD//C on xDai',
    address: '0xddafbb505ad214d7b80b1f830fccc89b60fb7a83',
    marketCapRank: null,
    networkHexChainId: GNOSIS.hexChainId,
}

export const OPTIMISM_USDC: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Optimism|0x0b2c639c533813f4aa9d7837caf62653d097ff85',
    symbol: 'USDC',
    code: 'USDC',
    fraction: 6,
    rateFraction: 6,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Optimism|0x0b2c639c533813f4aa9d7837caf62653d097ff85',
    name: 'USD Coin',
    address: '0x0b2c639c533813f4aa9d7837caf62653d097ff85',
    marketCapRank: 6,
    networkHexChainId: OPTIMISM.hexChainId,
}

export const GNOSIS_EURA: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0x4b1e2c2762667331bc91648052f646d1b0d35984',
    symbol: 'agEUR',
    code: 'agEUR',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0x4b1e2c2762667331bc91648052f646d1b0d35984',
    name: 'agEUR',
    address: '0x4b1e2c2762667331bc91648052f646d1b0d35984',
    marketCapRank: 1030,
    networkHexChainId: GNOSIS.hexChainId,
}

// Savings

export const GNOSIS_AAVE_EURE: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0xedbc7449a9b594ca4e053d9737ec5dc4cbccbfb2',
    symbol: 'aGnoEURe',
    code: 'aGnoEURe',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0xedbc7449a9b594ca4e053d9737ec5dc4cbccbfb2',
    name: 'Aave Gnosis EURe',
    address: '0xedbc7449a9b594ca4e053d9737ec5dc4cbccbfb2',
    marketCapRank: null,
    networkHexChainId: GNOSIS.hexChainId,
}

export const GNOSIS_SDAI: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0xaf204776c7245bf4147c2612bf6e5972ee483701',
    symbol: 'sDAI',
    code: 'sDAI',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0xaf204776c7245bf4147c2612bf6e5972ee483701',
    name: 'Savings xDAI',
    address: '0xaf204776c7245bf4147c2612bf6e5972ee483701',
    marketCapRank: null,
    networkHexChainId: GNOSIS.hexChainId,
}

export const GNOSIS_STEUR: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0x004626a008b1acdc4c74ab51644093b155e59a23',
    symbol: 'stEUR',
    code: 'stEUR',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0x004626a008b1acdc4c74ab51644093b155e59a23',
    name: 'Staked agEUR',
    address: '0x004626a008b1acdc4c74ab51644093b155e59a23',
    marketCapRank: null,
    networkHexChainId: GNOSIS.hexChainId,
}

export const GNOSIS_WSTETH: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0x6c76971f98945ae98dd7d4dfca8711ebea946ea6',
    symbol: 'wstETH',
    code: 'wstETH',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0x6c76971f98945ae98dd7d4dfca8711ebea946ea6',
    name: 'Wrapped liquid staked Ether 2.0 from Mainnet',
    address: '0x6c76971f98945ae98dd7d4dfca8711ebea946ea6',
    marketCapRank: null,
    networkHexChainId: GNOSIS.hexChainId,
}

export const GNOSIS_WETH: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1',
    symbol: 'WETH',
    code: 'WETH',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Ethereum|0x0000000000000000000000000000000000000000',
    name: 'Wrapped Ether',
    address: '0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1',
    marketCapRank: 155,
    networkHexChainId: GNOSIS.hexChainId,
}

// Other

export const GNOSIS_GNO: CryptoCurrency = {
    type: 'CryptoCurrency',
    id: 'Gnosis|0x9c58bacc331c9aa871afd802db6379a98e80cedb',
    symbol: 'GNO',
    code: 'GNO',
    fraction: 18,
    rateFraction: 18,
    icon: 'https://rdwdvjp8j5.execute-api.eu-west-1.amazonaws.com/wallet/image/currency/Gnosis|0x9c58bacc331c9aa871afd802db6379a98e80cedb',
    name: 'Gnosis Token on xDai',
    address: '0x9c58bacc331c9aa871afd802db6379a98e80cedb',
    marketCapRank: 150,
    networkHexChainId: GNOSIS.hexChainId,
}

export const ERC20_ABI = [
    {
        inputs: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'transfer',
        outputs: [{ internalType: 'bool', name: 'ok', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            { name: '_spender', type: 'address' },
            { name: '_value', type: 'uint256' },
        ],
        name: 'approve',
        outputs: [{ name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable' as const,
        type: 'function' as const,
    },
    {
        constant: true,
        inputs: [
            { name: '_owner', type: 'address' },
            { name: '_spender', type: 'address' },
        ],
        name: 'allowance',
        outputs: [{ name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'symbol',
        outputs: [
            {
                name: '',
                type: 'string',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'name',
        outputs: [
            {
                name: '',
                type: 'string',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'decimals',
        outputs: [
            {
                name: '',
                type: 'uint8',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
] as const

export const ERC20_NATIVE_WRAPPER_ABI = [
    {
        constant: false,
        inputs: [{ name: 'wad', type: 'uint256' }],
        name: 'withdraw',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: false,
        inputs: [],
        name: 'deposit',
        outputs: [],
        payable: true,
        stateMutability: 'payable',
        type: 'function',
    },
]

// TODO @resetko-zeal convert to Record<PredefinedNetwork['name'], [from,to]>, it will be more typesafe, and then derive this array from it
export const WRAPPED_NATIVE_TOKENS: [CryptoCurrency, CryptoCurrency][] = [
    [POLYGON_POL, POLYGON_WMATIC],
    [ETHEREUM_ETH, ETHEREUM_WETH],
    [GNOSIS_XDAI, GNOSIS_WXDAI],
    [ARBITRUM_ETH, ARBITRUM_WETH],
    [OPTIMISM_ETH, OPTIMISM_WETH],
    [BSC_BNB, BSC_WBNB],
]

export const WRAPPED_NATIVE_TOKENS_MAP: Record<
    CryptoCurrency['id'],
    CryptoCurrency
> = WRAPPED_NATIVE_TOKENS.reduce(
    (map, [native, wrapped]) => {
        map[native.id] = wrapped
        return map
    },
    {} as Record<CryptoCurrency['id'], CryptoCurrency>
)

// FIXME :: @max delete after QA
export const _MONERIUM_V1_TOKENS = new Set([
    currencyId({
        network: GNOSIS.hexChainId,
        address: staticFromString('0x5cb9073902f2035222b9749f8fb0c9bfe5527108'), // GBP
    }),
    currencyId({
        network: GNOSIS.hexChainId,
        address: staticFromString('0xcb444e90d8198415266c6a2724b7900fb12fc56e'), // EURO
    }),
    currencyId({
        network: POLYGON.hexChainId,
        address: staticFromString('0x75792CBDb361d80ba89271a079EfeE62c29FA324'), // GBP
    }),
    currencyId({
        network: POLYGON.hexChainId,
        address: staticFromString('0x18ec0A6E18E5bc3784fDd3a3634b31245ab704F6'), // GBP
    }),
])

export const MONERIUM_V2_TOKENS = new Set([
    currencyId({
        network: GNOSIS.hexChainId,
        address: staticFromString('0x420ca0f9b9b604ce0fd9c18ef134c705e5fa3430'), // EURO
    }),
    currencyId({
        network: POLYGON.hexChainId,
        address: staticFromString('0xE0aEa583266584DafBB3f9C3211d5588c73fEa8d'), // EURO
    }),
    currencyId({
        network: GNOSIS.hexChainId,
        address: staticFromString('0x8e34bfec4f6eb781f9743d9b4af99cd23f9b7053'), // GBP
    }),
    currencyId({
        network: POLYGON.hexChainId,
        address: staticFromString('0x646BEea7a02FdAdA34c8e118949fE32350aB2206'), // GBP
    }),
])

export const DEFAULT_CURRENCIES_LIST: FiatCurrency[] = Array.from(
    COIN_GECKO_SUPPORTED_FIAT_CURRENCIES
).reduce((acc, item) => {
    acc.push(FIAT_CURRENCIES[item])
    return acc
}, [] as FiatCurrency[])
