import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgOm = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7246)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M0 256C0 353.035 53.99 437.455 133.565 480.873V31.127C53.99 74.545 0 158.965 0 256Z"
                fill="#D80027"
            />
            <Path
                d="M496.078 345.043C506.368 317.31 512 287.314 512 256C512 224.686 506.368 194.689 496.078 166.957H15.924C5.633 194.689 0 224.686 0 256C0 287.314 5.633 317.31 15.924 345.043L256 367.304L496.078 345.043Z"
                fill="#D80027"
            />
            <Path
                d="M256 512C366.072 512 459.906 442.528 496.078 345.043H15.9241C52.0941 442.528 145.93 512 256 512Z"
                fill="#6DA544"
            />
            <Path
                d="M0 256C0 374.279 80.221 473.805 189.217 503.181V8.81909C80.221 38.1951 0 137.721 0 256Z"
                fill="#D80027"
            />
            <Path
                d="M156.617 112.654L133.006 89.043L117.266 104.785L101.526 89.043L77.9151 112.654L93.6561 128.395L77.9131 144.137L101.524 167.748L117.265 152.006L133.006 167.748L156.617 144.137L140.876 128.395L156.617 112.654Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7246">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgOm
