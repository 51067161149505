import { FormattedMessage, useIntl } from 'react-intl'

import { Badge as UIBadge } from '@zeal/uikit/Avatar'
import { BoldStarFavourite } from '@zeal/uikit/Icon/BoldStarFavourite'
import { Spam } from '@zeal/uikit/Icon/Spam'
import { ListItem as UIListItem } from '@zeal/uikit/ListItem'
import { Row } from '@zeal/uikit/Row'
import { Text } from '@zeal/uikit/Text'

import { notReachable } from '@zeal/toolkit'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

import { CurrencyHiddenMap, CurrencyPinMap } from '@zeal/domains/Currency'
import { FormattedCryptoToFiatRate } from '@zeal/domains/FXRate/components/FormattedRate'
import { FormattedMoneyPrecise } from '@zeal/domains/Money/components/FormattedMoneyPrecise'
import { NetworkMap } from '@zeal/domains/Network'
import { Badge } from '@zeal/domains/Network/components/Badge'
import { findNetworkByHexChainId } from '@zeal/domains/Network/constants'
import { Token2 } from '@zeal/domains/Token'
import { Avatar2 } from '@zeal/domains/Token/components/Avatar'

type Props = {
    token: Token2
    'aria-current': boolean
    networkMap: NetworkMap
    currencyHiddenMap: CurrencyHiddenMap
    currencyPinMap: CurrencyPinMap
    variant: 'portfolio' | 'search'
    onClick?: () => void
}

const TOKEN_PRICE_CHANGE_PERCENTAGE_PRECISION = 1

export const ListItem2 = ({
    token,
    'aria-current': ariaCurrent,
    networkMap,
    currencyPinMap,
    currencyHiddenMap,
    variant,
    onClick,
}: Props) => {
    const currency = token.balance.currency

    const network = findNetworkByHexChainId(
        currency.networkHexChainId,
        networkMap
    )

    const ticker = currency?.symbol

    return (
        <UIListItem
            size="large"
            onClick={onClick}
            aria-current={ariaCurrent}
            avatar={({ size }) => (
                <Avatar2
                    leftBadge={({ size }) => {
                        if (
                            currencyHiddenMap[currency.id] ||
                            (currencyHiddenMap[currency.id] === undefined &&
                                token.scam)
                        ) {
                            return (
                                <UIBadge outlineColor="transparent" size={size}>
                                    <Spam
                                        size={size}
                                        color="iconStatusCritical"
                                    />
                                </UIBadge>
                            )
                        }

                        if (currencyPinMap[currency.id]) {
                            return (
                                <UIBadge outlineColor="transparent" size={size}>
                                    <BoldStarFavourite size={size} />
                                </UIBadge>
                            )
                        }

                        return null
                    }}
                    key={currency.id}
                    token={token}
                    size={size}
                    rightBadge={({ size }) => (
                        <Badge size={size} network={network} />
                    )}
                />
            )}
            primaryText={(() => {
                switch (variant) {
                    case 'search':
                        return currency?.name
                    case 'portfolio':
                        return currency?.symbol
                    default:
                        return notReachable(variant)
                }
            })()}
            shortText={(() => {
                switch (variant) {
                    case 'portfolio':
                        return (
                            (token.rate || token.marketData) && (
                                <Row spacing={4}>
                                    {token.rate ? (
                                        <Text ellipsis>
                                            <FormattedCryptoToFiatRate
                                                rate={token.rate}
                                                variant="just_quote"
                                            />
                                        </Text>
                                    ) : null}

                                    {token.marketData ? (
                                        <PriceChange24H
                                            marketData={token.marketData}
                                        />
                                    ) : null}
                                </Row>
                            )
                        )

                    case 'search':
                        return (
                            (network.name || ticker) && (
                                <FormattedMessage
                                    id="token.list_item.network_ticker"
                                    defaultMessage="{ticker} · {network}"
                                    values={{
                                        network: network.name,
                                        ticker: ticker,
                                    }}
                                />
                            )
                        )

                    default:
                        return notReachable(variant)
                }
            })()}
            side={{
                title: (
                    <FormattedMoneyPrecise
                        withSymbol={false}
                        sign={null}
                        money={token.balance}
                    />
                ),
                subtitle: token.priceInDefaultCurrency ? (
                    <FormattedMoneyPrecise
                        withSymbol
                        sign={null}
                        money={token.priceInDefaultCurrency}
                    />
                ) : null,
            }}
        />
    )
}

const PriceChange24H = ({
    marketData,
}: {
    marketData: NonNullable<Token2['marketData']>
}) => {
    const { formatNumber } = useIntl()
    const sufix = (() => {
        switch (ZealPlatform.OS) {
            case 'ios':
            case 'web':
                return ''
            case 'android':
                return '%' // andoid ignore style: 'percent'
            /* istanbul ignore next */
            default:
                return notReachable(ZealPlatform.OS)
        }
    })()

    switch (marketData.priceChange24h.direction) {
        case 'Unchanged':
            return null

        case 'Up':
            return (
                <Text ellipsis color="textStatusSuccess">
                    +
                    {formatNumber(marketData.priceChange24h.percentage, {
                        style: 'percent',
                        signDisplay: 'never',
                        minimumFractionDigits: 0,
                        maximumFractionDigits:
                            TOKEN_PRICE_CHANGE_PERCENTAGE_PRECISION,
                    })}
                    {sufix}
                </Text>
            )

        case 'Down':
            const prefix = (() => {
                switch (ZealPlatform.OS) {
                    case 'ios':
                        return '' // ios ignore signDisplay: 'never', for negative values
                    case 'android':
                    case 'web':
                        return '-'
                    /* istanbul ignore next */
                    default:
                        return notReachable(ZealPlatform.OS)
                }
            })()

            return (
                <Text ellipsis color="textStatusWarning">
                    {prefix}
                    {formatNumber(marketData.priceChange24h.percentage, {
                        style: 'percent',
                        signDisplay: 'never',
                        minimumFractionDigits: 0,
                        maximumFractionDigits:
                            TOKEN_PRICE_CHANGE_PERCENTAGE_PRECISION,
                    })}
                    {sufix}
                </Text>
            )

        /* istanbul ignore next */
        default:
            return notReachable(marketData.priceChange24h)
    }
}
