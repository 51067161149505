import { notReachable } from '@zeal/toolkit'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

export const getAppVersion = () => {
    switch (ZealPlatform.OS) {
        case 'ios':
        case 'android':
            return require('expo-application').nativeApplicationVersion || '' // eslint-disable-line @typescript-eslint/no-var-requires
        case 'web':
            return process.env.ZEAL_APP_VERSION
        /* istanbul ignore next */
        default:
            return notReachable(ZealPlatform.OS)
    }
}
