import { Modal as UIModal } from '@zeal/uikit/Modal'
import { ViewPortModal } from '@zeal/uikit/ViewPortModal'

import { notReachable } from '@zeal/toolkit'
import { MsgOf } from '@zeal/toolkit/MsgOf'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

import { CryptoCurrency } from '@zeal/domains/Currency'
import { PredefinedNetwork } from '@zeal/domains/Network'
import { ServerPortfolio } from '@zeal/domains/Portfolio'

import { CryptoCurrencySelector } from './CryptoCurrencySelector'

import { Connected } from '../../../../types'
import { Form } from '../validation'

type Props = {
    state: State
    form: Form
    portfolio: ServerPortfolio
    supportedNetworks: PredefinedNetwork[]
    topUpCurrencies: CryptoCurrency[]
    connectionState: Connected
    onMsg: (msg: Msg) => void
}

type Msg = MsgOf<typeof CryptoCurrencySelector>

export type State = { type: 'closed' } | { type: 'crypto_currency_selector' }

export const Modal = ({
    form,
    state,
    supportedNetworks,
    topUpCurrencies,
    onMsg,
    connectionState,
    portfolio,
}: Props) => {
    switch (state.type) {
        case 'closed':
            return null
        case 'crypto_currency_selector':
            switch (ZealPlatform.OS) {
                case 'ios':
                case 'android':
                    return (
                        <UIModal>
                            <CryptoCurrencySelector
                                connectionState={connectionState}
                                selectedCurrency={form.currency}
                                portfolio={portfolio}
                                topUpCurrencies={topUpCurrencies}
                                supportedNetworks={supportedNetworks}
                                onMsg={onMsg}
                            />
                        </UIModal>
                    )
                case 'web':
                    return (
                        <ViewPortModal>
                            <CryptoCurrencySelector
                                connectionState={connectionState}
                                selectedCurrency={form.currency}
                                portfolio={portfolio}
                                topUpCurrencies={topUpCurrencies}
                                supportedNetworks={supportedNetworks}
                                onMsg={onMsg}
                            />
                        </ViewPortModal>
                    )
                /* istanbul ignore next */
                default:
                    return notReachable(ZealPlatform.OS)
            }
        /* istanbul ignore next */
        default:
            return notReachable(state)
    }
}
