import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgAo = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7080)">
            <Path
                d="M0 256C0 114.616 114.616 0 256 0C397.384 0 512 114.616 512 256C500.87 256 256 289.391 256 289.391L0 256Z"
                fill="#D80027"
            />
            <Path
                d="M512 256C512 397.384 397.384 512 256 512C114.616 512 0 397.384 0 256"
                fill="black"
            />
            <Path
                d="M220.898 203.612L242.6 219.362L234.33 244.87L256.016 229.094L277.72 244.844L269.418 219.344L291.102 203.57L264.287 203.586L255.984 178.087L247.713 203.595L220.898 203.612Z"
                fill="#FFDA44"
            />
            <Path
                d="M320 145.149C299.827 133.502 277.77 128.005 256 128.043V161.427C272.091 161.398 288.393 165.458 303.304 174.068C348.482 200.152 364.017 258.128 337.932 303.306C311.849 348.484 253.874 364.019 208.695 337.935C195.533 330.336 184.908 320.018 177.076 308.111L149.2 326.515C159.797 342.624 174.193 356.571 192 366.851C253.124 402.141 331.561 381.124 366.85 320C402.14 258.876 381.124 180.439 320 145.149Z"
                fill="#FFDA44"
            />
            <Path
                d="M182.19 233.739C173.34 249.918 179.282 270.209 195.46 279.059L303.909 338.319C296.534 351.8 300.451 368.209 313.932 375.584L343.227 391.609C356.708 398.985 373.619 394.033 380.994 380.551L397.019 351.257L182.19 233.739Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7080">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgAo
