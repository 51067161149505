import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgSx = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7283)">
            <Path
                d="M233.739 278.261C233.739 278.261 75.13 74.945 74.98 74.981C121.306 28.654 185.307 0 256 0C397.384 0 512 114.616 512 256L233.739 278.261Z"
                fill="#D80027"
            />
            <Path
                d="M233.739 256C233.739 256 75.13 437.055 74.98 437.019C121.306 483.346 185.307 512 256 512C397.384 512 512 397.384 512 256H233.739Z"
                fill="#0052B4"
            />
            <Path
                d="M74.98 74.98C-24.994 174.954 -24.994 337.045 74.98 437.02C116.293 395.707 156.026 355.974 256 256L74.98 74.98Z"
                fill="#F0F0F0"
            />
            <Path
                d="M28.0612 256.001C27.9212 257.839 27.8262 259.691 27.8262 261.565C27.8262 301.521 60.2182 333.913 100.174 333.913C140.13 333.913 172.522 301.521 172.522 261.565C172.522 259.691 172.427 257.838 172.287 256.001H28.0612Z"
                fill="#FFDA44"
            />
            <Path
                d="M100.174 222.609C112.468 222.609 122.435 212.642 122.435 200.348C122.435 188.054 112.468 178.087 100.174 178.087C87.8797 178.087 77.9131 188.054 77.9131 200.348C77.9131 212.642 87.8797 222.609 100.174 222.609Z"
                fill="#FFDA44"
            />
            <Path
                d="M50.0869 194.783V267.131C50.0869 305.471 100.174 317.218 100.174 317.218C100.174 317.218 150.261 305.471 150.261 267.131V194.783H50.0869Z"
                fill="#D80027"
            />
            <Path
                d="M100.198 293.92C90.9062 290.576 72.3472 282.03 72.3472 267.13V217.043H128V267.13C128 282.079 109.443 290.604 100.198 293.92Z"
                fill="#338AF3"
            />
            <Path
                d="M111.304 244.87V233.739L100.174 228.174L89.043 233.739V244.87L83.478 250.435V272.696H116.87V250.435L111.304 244.87Z"
                fill="#F3F3F3"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7283">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgSx
