import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgSb = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7286)">
            <Path
                d="M437.027 437.027C533.038 341.016 536.829 187.731 448.44 87.172L256 255.999L87.1719 448.44C187.731 536.829 341.015 533.038 437.027 437.027Z"
                fill="#496E2D"
            />
            <Path
                d="M74.974 74.974C170.985 -21.037 324.27 -24.828 424.829 63.561L256 256.003L63.562 424.829C-24.828 324.27 -21.036 170.985 74.974 74.974Z"
                fill="#0052B4"
            />
            <Path
                d="M424.827 63.56L63.5601 424.828C67.2121 428.983 71.0041 433.059 74.9731 437.026C78.9411 440.994 83.0181 444.786 87.1741 448.44L448.439 87.172C444.787 83.016 440.994 78.94 437.025 74.971C433.059 71.005 428.982 67.212 424.827 63.56Z"
                fill="#FFDA44"
            />
            <Path
                d="M107.861 89.036L113.388 106.043H131.269L116.803 116.552L122.328 133.559L107.861 123.048L93.3941 133.559L98.9211 116.552L84.4541 106.043H102.336L107.861 89.036Z"
                fill="#F0F0F0"
            />
            <Path
                d="M199.2 89.036L204.727 106.043H222.607L208.141 116.552L213.667 133.559L199.2 123.048L184.733 133.559L190.26 116.552L175.793 106.043H193.674L199.2 89.036Z"
                fill="#F0F0F0"
            />
            <Path
                d="M107.861 178.083L113.388 195.09H131.269L116.803 205.6L122.328 222.607L107.861 212.096L93.3941 222.607L98.9211 205.6L84.4541 195.09H102.336L107.861 178.083Z"
                fill="#F0F0F0"
            />
            <Path
                d="M199.2 178.083L204.727 195.09H222.607L208.141 205.6L213.667 222.607L199.2 212.096L184.733 222.607L190.26 205.6L175.793 195.09H193.674L199.2 178.083Z"
                fill="#F0F0F0"
            />
            <Path
                d="M153.531 133.559L159.058 150.566H176.938L162.472 161.076L167.998 178.083L153.531 167.572L139.064 178.083L144.59 161.076L130.123 150.566H148.005L153.531 133.559Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7286">
                <Rect width={512.001} height={512.001} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgSb
