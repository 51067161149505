import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgCk = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7129)">
            <Path
                d="M512 256C512 397.384 397.384 512 256 512C114.616 512 0 397.384 0 256C0 256.061 256 0.028 256 0C397.384 0 512 114.616 512 256Z"
                fill="#0052B4"
            />
            <Path
                d="M255.315 256H256C256 255.77 256 255.546 256 255.315C255.772 255.544 255.544 255.772 255.315 256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 133.565C256 88.52 256 59.005 256 0H255.957C114.591 0.024 0 114.629 0 256H133.565V180.789L208.776 256H255.316C255.544 255.772 255.772 255.544 256.001 255.315C256.001 238.068 256.001 222.679 256.001 208.778L180.788 133.565H256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M129.515 33.391C89.4756 56.19 56.1886 89.476 33.3906 129.515V256H100.174V100.176V100.174H256C256 79.111 256 59.045 256 33.391H129.515Z"
                fill="#D80027"
            />
            <Path
                d="M255.999 224.519L165.044 133.566H133.564V133.568L255.997 256H255.999C255.999 256 255.999 234.293 255.999 224.519Z"
                fill="#D80027"
            />
            <Path
                d="M345.043 256L349.781 270.576H365.106L352.708 279.584L357.444 294.161L345.043 285.152L332.644 294.161L337.38 279.584L324.982 270.576H340.307L345.043 256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M282.081 282.081L295.736 289.038L306.574 278.201L304.176 293.339L317.832 300.297L302.694 302.693L300.297 317.833L293.338 304.176L278.201 306.574L289.04 295.737L282.081 282.081Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 345.043L270.576 340.307L270.578 324.983L279.584 337.38L294.161 332.643L285.152 345.043L294.161 357.444L279.584 352.708L270.578 365.106V349.781L256 345.043Z"
                fill="#F0F0F0"
            />
            <Path
                d="M282.081 408.006L289.038 394.35L278.201 383.514L293.339 385.911L300.297 372.255L302.694 387.393L317.832 389.789L304.176 396.748L306.574 411.887L295.736 401.047L282.081 408.006Z"
                fill="#F0F0F0"
            />
            <Path
                d="M345.043 434.087L340.307 419.51H324.981L337.38 410.502L332.644 395.926L345.043 404.934L357.444 395.926L352.708 410.502L365.106 419.51H349.781L345.043 434.087Z"
                fill="#F0F0F0"
            />
            <Path
                d="M408.006 408.006L394.35 401.05L383.514 411.887L385.911 396.748L372.255 389.791L387.393 387.393L389.791 372.255L396.748 385.911L411.886 383.514L401.047 394.351L408.006 408.006Z"
                fill="#F0F0F0"
            />
            <Path
                d="M434.087 345.043L419.51 349.781V365.106L410.502 352.707L395.926 357.444L404.935 345.043L395.926 332.643L410.502 337.38L419.51 324.983V340.307L434.087 345.043Z"
                fill="#F0F0F0"
            />
            <Path
                d="M408.006 282.082L401.05 295.737L411.886 306.574L396.748 304.176L389.791 317.833L387.393 302.693L372.255 300.296L385.911 293.339L383.514 278.202L394.351 289.038L408.006 282.082Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7129">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgCk
