import { oneOf, Result } from '@zeal/toolkit/Result'

import { PasskeyError } from '@zeal/domains/Error/domains/Passkey'
import { parsePasskeyAndroidCannotValidateIncomingRequest } from '@zeal/domains/Error/domains/Passkey/parsers/parsePasskeyAndroidCannotValidateIncomingRequest'
import { parsePasskeyAndroidCreationInterrupted } from '@zeal/domains/Error/domains/Passkey/parsers/parsePasskeyAndroidCreationInterrupted'
import { parsePasskeyAndroidFailedToLaunchSelector } from '@zeal/domains/Error/domains/Passkey/parsers/parsePasskeyAndroidFailedToLaunchSelector'
import { parsePasskeyAndroidFidoApiNotAvailable } from '@zeal/domains/Error/domains/Passkey/parsers/parsePasskeyAndroidFidoApiNotAvailable'
import { parsePasskeyAndroidFidoApiNotSupported } from '@zeal/domains/Error/domains/Passkey/parsers/parsePasskeyAndroidFidoApiNotSupported'
import { parsePasskeyAndroidNoCreateOptionsAvailable } from '@zeal/domains/Error/domains/Passkey/parsers/parsePasskeyAndroidNoCreateOptionsAvailable'
import { parsePasskeyAndroidNoCredentialAvailable } from '@zeal/domains/Error/domains/Passkey/parsers/parsePasskeyAndroidNoCredentialAvailable'
import { parsePasskeyAndroidProviderConfigurationError } from '@zeal/domains/Error/domains/Passkey/parsers/parsePasskeyAndroidProviderConfigurationError'
import { parsePasskeyAndroidResidentKeyCreationNotSupported } from '@zeal/domains/Error/domains/Passkey/parsers/parsePasskeyAndroidResidentKeyCreationNotSupported'
import { parsePasskeyAndroidTimeoutError } from '@zeal/domains/Error/domains/Passkey/parsers/parsePasskeyAndroidTimeoutError'
import { parsePasskeyAndroidUnableToGetSyncAccount } from '@zeal/domains/Error/domains/Passkey/parsers/parsePasskeyAndroidUnableToGetSyncAccount'
import { parsePasskeyAndroidUnknownError } from '@zeal/domains/Error/domains/Passkey/parsers/parsePasskeyAndroidUnknownError'
import { parsePasskeyGoogleAccountMissing } from '@zeal/domains/Error/domains/Passkey/parsers/parsePasskeyGoogleAccountMissing'
import { parsePasskeyScreenLockMissing } from '@zeal/domains/Error/domains/Passkey/parsers/parsePasskeyScreenLockMissing'

import { parsePasskeyAppNotAssociatedWithDomain } from './parsePasskeyAppNotAssociatedWithDomain'
import { parsePasskeyOperationCancelled } from './parsePasskeyOperationCancelled'
import { parsePasskeySignerNotFoundError } from './parsePasskeySignerNotFoundError'

export const parsePasskeyError = (
    input: unknown
): Result<unknown, PasskeyError> =>
    oneOf(input, [
        parsePasskeyOperationCancelled(input),
        parsePasskeySignerNotFoundError(input),
        parsePasskeyAppNotAssociatedWithDomain(input),
        parsePasskeyAndroidNoCredentialAvailable(input),
        parsePasskeyAndroidCannotValidateIncomingRequest(input),
        parsePasskeyAndroidNoCreateOptionsAvailable(input),
        parsePasskeyAndroidResidentKeyCreationNotSupported(input),
        parsePasskeyScreenLockMissing(input),
        parsePasskeyGoogleAccountMissing(input),
        oneOf(input, [
            parsePasskeyAndroidFailedToLaunchSelector(input),
            parsePasskeyAndroidProviderConfigurationError(input),
            parsePasskeyAndroidFidoApiNotAvailable(input),
            parsePasskeyAndroidFidoApiNotSupported(input),
            parsePasskeyAndroidUnableToGetSyncAccount(input),
            parsePasskeyAndroidCreationInterrupted(input),
            parsePasskeyAndroidTimeoutError(input),
            parsePasskeyAndroidUnknownError(input), // should be last in list since it's generic
        ]),
    ])
