import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgUy = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 513" fill="none">
        <G clipPath="url(#clip0_4_7324)">
            <Path
                d="M256 512.581C397.385 512.581 512 397.966 512 256.581C512 115.196 397.385 0.581055 256 0.581055C114.615 0.581055 0 115.196 0 256.581C0 397.966 114.615 512.581 256 512.581Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 189.798H503.181C496.762 165.984 487.006 143.543 474.426 123.015H256V189.798Z"
                fill="#338AF3"
            />
            <Path
                d="M96.6432 456.929H415.356C438.719 438.321 458.755 415.719 474.425 390.146H37.5742C53.2452 415.718 73.2812 438.321 96.6432 456.929Z"
                fill="#338AF3"
            />
            <Path
                d="M256 0.581055V56.2331H415.357C371.627 21.4051 316.249 0.581055 256 0.581055Z"
                fill="#338AF3"
            />
            <Path
                d="M256 189.798H503.181C496.762 165.984 487.006 143.543 474.426 123.015H256V189.798Z"
                fill="#338AF3"
            />
            <Path
                d="M0 256.581C0 279.688 3.08 302.07 8.819 323.364H503.182C508.92 302.07 512 279.688 512 256.581H0Z"
                fill="#338AF3"
            />
            <Path
                d="M222.609 150.402L191.343 165.109L207.992 195.389L174.042 188.895L169.74 223.19L146.094 197.966L122.446 223.19L118.145 188.895L84.1951 195.387L100.843 165.108L69.5791 150.402L100.844 135.697L84.1951 105.417L118.144 111.911L122.447 77.6162L146.094 102.84L169.741 77.6162L174.042 111.911L207.993 105.417L191.344 135.698L222.609 150.402Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7324">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0 0.581055)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgUy
