import { joinURL } from '@zeal/toolkit/URL/joinURL'
import { Address } from '@zeal/toolkit/Web3/address'

import { NetworkHexId } from '@zeal/domains/Network'

import { processFetchFailure, processFetchResponse } from './interceptors'

export const BASE_URL =
    'https://nmbgrcr7f6.execute-api.eu-west-1.amazonaws.com/prod'

export type Paths = {
    post: Record<`/proxy/bundler/${string}`, { query: undefined; body: any }> & // TODO @resetko-zeal proper type for API body, string to 0x{string}
        Record<
            `/proxy/pimlico/${string}/rpc`,
            { query: undefined; body: any }
        > &
        Record<
            '/api/monerium/auth',
            {
                query: never
                body: {
                    message: string
                    signature: string
                }
            }
        >
    get: Record<`/proxy/cgv3/exchange_rates`, { query: undefined }> &
        Record<
            `/proxy/cgv3/simple/token_price/${string}`,
            {
                query: {
                    contract_addresses: string
                    vs_currencies: string
                }
            }
        > &
        Record<
            `/indexer/transactions/${NetworkHexId}/${Address}`,
            { query: undefined }
        > &
        Record<'/indexer/card-safe', { query: { owners: Address[] } }>
}

export const post = <T extends keyof Paths['post']>(
    path: T,
    params: {
        query?: Paths['post'][T]['query']
        body: Paths['post'][T]['body']
    },
    signal?: AbortSignal
): Promise<unknown> => {
    const url = joinURL(BASE_URL, path)
    const query = params.query
        ? `?${new URLSearchParams(params.query as Record<string, string>)}`
        : ''
    const urlWithQuery = `${url}${query}`

    return fetch(urlWithQuery, {
        method: 'POST',
        body: JSON.stringify(params.body),
        headers: {
            'Content-Type': 'application/json',
        },
        signal,
    })
        .catch((error) =>
            processFetchFailure({ error, method: 'POST', params, url })
        )
        .then((response) =>
            processFetchResponse({ params, method: 'POST', response, url })
        )
        .then((text) => JSON.parse(text))
}

export const get = <T extends keyof Paths['get']>(
    path: T,
    params: { query?: Paths['get'][T]['query'] },
    signal?: AbortSignal
): Promise<unknown> => {
    const url = joinURL(BASE_URL, path)
    const query = params.query
        ? `?${new URLSearchParams(params.query as Record<string, string>)}`
        : ''
    const urlWithQuery = `${url}${query}`

    return fetch(urlWithQuery, {
        method: 'GET',
        headers: {},
        signal,
    })
        .catch((error) =>
            processFetchFailure({ error, method: 'GET', params, url })
        )
        .then((response) =>
            processFetchResponse({ params, method: 'GET', response, url })
        )
        .then((text) => JSON.parse(text))
}
