import { notReachable } from '@zeal/toolkit'
import {
    failure,
    match,
    matchRegExp,
    object,
    oneOf,
    Result,
    shape,
    string,
    success,
} from '@zeal/toolkit/Result'

import { parseUnexpectedFailureError } from '@zeal/domains/Error/parsers/parseUnexpectedFailureError'

import {
    AlreadyKnow,
    BlockGasLimitExceededError,
    BlockStateUnavailableError,
    CannotExecuteRequest,
    CannotQueryUnfinalizedData,
    Erc20InsufficientAllowance,
    Erc20InsufficientBalance,
    Erc20TransactionError,
    ExecutionReverted,
    ExecutionTimeout,
    GasPriceIsLessThanMinimum,
    GasRequiredExceedsAllowance,
    InsufficientBalanceForTransfer,
    InsufficientFundsForGasAndValue,
    InvalidArgument,
    InvalidRsvValues,
    InvalidSender,
    MaxFeePerGasLessThanBlockBaseFee,
    NounceIsTooLow,
    PriorityFeeTooLow,
    ReplacementTransactionUnderpriced,
    RPCError,
    RPCResponseError,
    SwapFailed,
    TooManyRequests,
    TransactionUnderpriced,
    TxPoolDisabled,
    UnknownRPCError,
} from '../RPCError'

const startsWith = (
    input: string,
    shouldStartWith: string
): Result<
    {
        type: 'does_not_start_with_correct_string'
        required: string
        actual: string
    },
    string
> =>
    input.toLowerCase().startsWith(shouldStartWith.toLowerCase())
        ? success(input)
        : failure({
              type: 'does_not_start_with_correct_string',
              actual: input,
              required: shouldStartWith,
          })

const stringStartsWith = (
    input: unknown,
    shouldStartWith: string
): Result<unknown, string> =>
    string(input).andThen((str) => startsWith(str, shouldStartWith))

const parseReplacementTransactionUnderpriced = (
    input: unknown
): Result<unknown, ReplacementTransactionUnderpriced> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32000),
                message: stringStartsWith(
                    obj.message,
                    'replacement transaction underpriced'
                ),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_replacement_transaction_underpriced',
            payload,
        }))

const parseNounceIsTooLow = (input: unknown): Result<unknown, NounceIsTooLow> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: oneOf(obj.code, [
                    match(obj.code, -32000),
                    match(obj.code, -32003),
                    match(obj.code, -32010),
                ]),
                message: string(obj.message).andThen((str) =>
                    matchRegExp(str, /nonce too low|oldNonce/gi)
                ),
            })
        )
        .map((payload) => ({ type: 'rpc_error_nounce_is_too_low', payload }))

const parseCannotQueryUnfinalizedData = (
    input: unknown
): Result<unknown, CannotQueryUnfinalizedData> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32000),
                message: stringStartsWith(
                    obj.message,
                    'cannot query unfinalized data'
                ),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_cannot_query_unfinalized_data',
            payload,
        }))

const parseExecutionReverted = (
    input: unknown
): Result<unknown, ExecutionReverted> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: oneOf(obj.code, [
                    match(obj.code, -32000),
                    match(obj.code, 3),
                ]),
                message: oneOf(obj.message, [
                    stringStartsWith(obj.message, 'execution reverted'),
                    stringStartsWith(obj.message, 'revert'),
                ]),
            })
        )
        .map((payload) => ({ type: 'rpc_error_execution_reverted', payload }))

const parseGasPriceIsLessThanMinimum = (
    input: unknown
): Result<unknown, GasPriceIsLessThanMinimum> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32000),
                message: stringStartsWith(
                    obj.message,
                    'gasprice is less than gas price minimum floor'
                ),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_gas_price_is_less_than_minimum',
            payload,
        }))

const parseGasRequiredExceedsAllowance = (
    input: unknown
): Result<unknown, GasRequiredExceedsAllowance> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32000),
                message: stringStartsWith(
                    obj.message,
                    'gas required exceeds allowance'
                ),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_gas_required_exceeds_allowance',
            payload,
        }))

const parseInsufficientBalanceForTransfer = (
    input: unknown
): Result<unknown, InsufficientBalanceForTransfer> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32000),
                message: oneOf(obj.message, [
                    stringStartsWith(
                        obj.message,
                        'insufficient balance for transfer'
                    ),
                    stringStartsWith(
                        obj.message,
                        'insufficient funds for transfer'
                    ),
                ]),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_insufficient_balance_for_transfer',
            payload,
        }))

const parseInsufficientFundsForGasAndValue = (
    input: unknown
): Result<unknown, InsufficientFundsForGasAndValue> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32000),
                message: oneOf(obj.message, [
                    string(obj.message).andThen((message) =>
                        /insufficient funds for gas \* price \+ value/.test(
                            message
                        )
                            ? success(message)
                            : failure({
                                  type: 'not_matching_regexp',
                                  message: obj.message,
                              })
                    ),
                    stringStartsWith(
                        obj.message,
                        'insufficient funds for gas + value'
                    ),
                ]),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_insufficient_funds_for_gas_and_value',
            payload,
        }))

const parseMaxFeePerGasLessThanBlockBaseFee = (
    input: unknown
): Result<unknown, MaxFeePerGasLessThanBlockBaseFee> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32000),
                message: stringStartsWith(
                    obj.message,
                    'max fee per gas less than block base fee'
                ),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_max_fee_per_gas_less_than_block_base_fee',
            payload,
        }))

const parsePriorityFeeTooLow = (
    input: unknown
): Result<unknown, PriorityFeeTooLow> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32010),
                message: stringStartsWith(
                    obj.message,
                    'FeeTooLow, EffectivePriorityFeePerGas too low'
                ),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_priority_fee_too_low',
            payload,
        }))

const parseTransactionUnderpriced = (
    input: unknown
): Result<unknown, TransactionUnderpriced> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32000),
                message: stringStartsWith(
                    obj.message,
                    'transaction underpriced'
                ),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_transaction_underpriced',
            payload,
        }))

const parseTxPoolDisabled = (input: unknown): Result<unknown, TxPoolDisabled> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32000),
                message: stringStartsWith(obj.message, 'TxPool Disabled'),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_tx_pool_disabled',
            payload,
        }))

const parseInvalidArgument = (
    input: unknown
): Result<unknown, InvalidArgument> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32602),
                message: stringStartsWith(obj.message, 'invalid argument'),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_invalid_argument',
            payload,
        }))

const parseInvalidSender = (input: unknown): Result<unknown, InvalidSender> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32000),
                message: stringStartsWith(obj.message, 'invalid sender'),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_invalid_sender',
            payload,
        }))

const parseExecutionTimeout = (
    input: unknown
): Result<unknown, ExecutionTimeout> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32000),
                message: oneOf(obj.message, [
                    stringStartsWith(obj.message, 'execution timeout'),
                    stringStartsWith(obj.message, 'context deadline exceeded'),
                ]),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_execution_timeout',
            payload,
        }))

const parseCannotExecuteRequest = (
    input: unknown
): Result<unknown, CannotExecuteRequest> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, 0),
                message: stringStartsWith(
                    obj.message,
                    `we can't execute this request`
                ),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_cannot_execute_request',
            payload,
        }))

const parseTooManyRequests = (
    input: unknown
): Result<unknown, TooManyRequests> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, 0),
                message: string(obj.message).andThen((str) =>
                    matchRegExp(str, /Too Many Requests/gi)
                ),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_too_many_requests',
            payload,
        }))

const parseUnknown = (input: unknown): Result<unknown, UnknownRPCError> =>
    success({
        type: 'rpc_error_unknown' as const,
        payload: input,
    })

const parseAlreadyKnown = (input: unknown): Result<unknown, AlreadyKnow> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: oneOf(obj.code, [
                    match(obj.code, -32000),
                    match(obj.code, -32010),
                ]),
                message: stringStartsWith(obj.message, 'AlreadyKnown'),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_already_known',
            payload,
        }))

const parseErc20InsufficientBalance = (
    input: unknown
): Result<unknown, Erc20InsufficientBalance> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32015),
                message: string(obj.message).andThen((str) =>
                    matchRegExp(str, /0xe450d38c/gi)
                ),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_transaction_erc20_insufficient_balance',
            payload,
        }))

const parseErc20InsufficientAllowance = (
    input: unknown
): Result<unknown, Erc20InsufficientAllowance> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32015),
                message: string(obj.message).andThen((str) =>
                    matchRegExp(str, /0xfb8f41b2/gi)
                ),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_transaction_erc20_insufficient_allowance',
            payload,
        }))

const parseErc20TransferError = (
    input: unknown
): Result<unknown, Erc20TransactionError> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32000),
                message: string(obj.message).andThen((str) =>
                    matchRegExp(
                        str,
                        /TRANSFER_FROM_FAILED|arithmetic underflow or overflow/gi
                    )
                ),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_transaction_erc20_transfer_error',
            payload,
        }))

const parseSwapFailed = (input: unknown): Result<unknown, SwapFailed> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32000),
                message: string(obj.message).andThen((str) =>
                    matchRegExp(str, /0x81ceff30/gi)
                ),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_swap_failed',
            payload,
        }))

const parseInvalidRsvValues = (
    input: unknown
): Result<unknown, InvalidRsvValues> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32000),
                message: string(obj.message).andThen((str) =>
                    matchRegExp(str, /v, r, s/gi)
                ),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_invalid_rsv_values',
            payload,
        }))

const parseBlockGasLimitExceeded = (
    input: unknown
): Result<unknown, BlockGasLimitExceededError> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32000),
                message: stringStartsWith(
                    obj.message,
                    'exceeds block gas limit'
                ),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_block_gas_limit_exceeded',
            payload,
        }))

const parseBlockStateUnavailable = (
    input: unknown
): Result<unknown, BlockStateUnavailableError> =>
    object(input)
        .andThen((obj) =>
            shape({
                code: match(obj.code, -32002),
                message: stringStartsWith(
                    obj.message,
                    'No state available for block'
                ),
            })
        )
        .map((payload) => ({
            type: 'rpc_error_block_state_unavailable',
            payload,
        }))

const parserForError: RPCError['type'] =
    'rpc_error_replacement_transaction_underpriced' as any as RPCError['type']

switch (parserForError) {
    case 'rpc_error_cannot_execute_request':
    case 'rpc_error_cannot_query_unfinalized_data':
    case 'rpc_error_execution_reverted':
    case 'rpc_error_execution_timeout':
    case 'rpc_error_gas_price_is_less_than_minimum':
    case 'rpc_error_gas_required_exceeds_allowance':
    case 'rpc_error_insufficient_balance_for_transfer':
    case 'rpc_error_insufficient_funds_for_gas_and_value':
    case 'rpc_error_invalid_argument':
    case 'rpc_error_invalid_sender':
    case 'rpc_error_max_fee_per_gas_less_than_block_base_fee':
    case 'rpc_error_nounce_is_too_low':
    case 'rpc_error_priority_fee_too_low':
    case 'rpc_error_replacement_transaction_underpriced':
    case 'rpc_error_transaction_underpriced':
    case 'rpc_error_tx_pool_disabled':
    case 'rpc_error_unknown':
    case 'rpc_error_too_many_requests':
    case 'rpc_error_already_known':
    case 'rpc_error_transaction_erc20_insufficient_balance':
    case 'rpc_error_transaction_erc20_insufficient_allowance':
    case 'rpc_error_transaction_erc20_transfer_error':
    case 'rpc_error_block_gas_limit_exceeded':
    case 'rpc_error_block_state_unavailable':
    case 'rpc_error_swap_failed':
    case 'rpc_error_invalid_rsv_values':
        // update parseRPCErrorPayload below 👇
        break
    /* istanbul ignore next */
    default:
        notReachable(parserForError)
}

export const parseRPCErrorPayload = (
    input: unknown
): Result<unknown, RPCError> =>
    oneOf(input, [
        oneOf(input, [
            parseCannotExecuteRequest(input),
            parseCannotQueryUnfinalizedData(input),
            parseExecutionReverted(input),
            parseExecutionTimeout(input),
            parseGasPriceIsLessThanMinimum(input),
            parseInsufficientFundsForGasAndValue(input),
            parseInvalidArgument(input),
            parseInvalidSender(input),
            parseMaxFeePerGasLessThanBlockBaseFee(input),
            parseNounceIsTooLow(input),
        ]),
        oneOf(input, [
            parseReplacementTransactionUnderpriced(input),
            parseTransactionUnderpriced(input),
            parseTxPoolDisabled(input),
            parseGasRequiredExceedsAllowance(input),
            parseInsufficientBalanceForTransfer(input),
            parseTooManyRequests(input),
            parsePriorityFeeTooLow(input),
            parseAlreadyKnown(input),
        ]),
        oneOf(input, [
            parseErc20InsufficientBalance(input),
            parseErc20InsufficientAllowance(input),
            parseErc20TransferError(input),
            parseBlockGasLimitExceeded(input),
            parseBlockStateUnavailable(input),
            parseSwapFailed(input),
            parseInvalidRsvValues(input),
        ]),
        parseUnknown(input),
    ])

export const parseRPCError = (input: unknown) =>
    parseUnexpectedFailureError(input)
        .map((error) => error.error.reason)
        .andThen((reason) =>
            reason instanceof RPCResponseError && reason.isRPCResponseError
                ? success(reason)
                : failure({
                      type: 'not_rpc_response_error',
                      payload: reason,
                  })
        )
