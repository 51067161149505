import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgMo = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7208)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#496E2D"
            />
            <Path
                d="M300.522 245.325C294.238 245.325 288.272 246.578 282.732 248.572C285.767 241.808 287.802 234.282 287.802 226.245C287.802 194.444 256.001 170.593 256.001 170.593C256.001 170.593 224.2 194.444 224.2 226.245C224.2 234.281 226.235 241.808 229.27 248.572C223.729 246.58 217.763 245.325 211.48 245.325C179.679 245.325 155.828 277.126 155.828 277.126C155.828 277.126 179.679 308.928 211.48 308.928C230.125 308.928 246.027 298 256.002 288.954C265.977 297.999 281.879 308.928 300.524 308.928C332.325 308.928 356.176 277.126 356.176 277.126C356.176 277.126 332.323 245.325 300.522 245.325Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 100.174L262.569 120.39H283.826L266.631 132.886L273.195 153.102L256 140.606L238.805 153.102L245.369 132.886L228.174 120.39H249.431L256 100.174Z"
                fill="#FFDA44"
            />
            <Path
                d="M175.167 135.533L187.805 146.911L202.532 138.409L195.616 153.944L208.253 165.324L191.341 163.546L184.425 179.081L180.887 162.448L163.976 160.67L178.702 152.166L175.167 135.533Z"
                fill="#FFDA44"
            />
            <Path
                d="M115.994 194.707L132.628 198.242L141.131 183.515L142.909 200.426L159.541 203.964L144.007 210.881L145.785 227.791L134.404 215.156L118.87 222.072L127.373 207.344L115.994 194.707Z"
                fill="#FFDA44"
            />
            <Path
                d="M336.833 135.533L324.196 146.911L309.469 138.409L316.384 153.944L303.748 165.324L320.66 163.546L327.576 179.081L331.114 162.448L348.025 160.67L333.299 152.166L336.833 135.533Z"
                fill="#FFDA44"
            />
            <Path
                d="M396.007 194.707L379.373 198.242L370.871 183.515L369.092 200.426L352.46 203.964L367.994 210.881L366.216 227.791L377.597 215.156L393.131 222.072L384.628 207.344L396.007 194.707Z"
                fill="#FFDA44"
            />
            <Path
                d="M256 397.972C281.261 397.972 304.545 389.544 323.223 375.363H188.778C207.456 389.543 230.739 397.972 256 397.972Z"
                fill="#F0F0F0"
            />
            <Path
                d="M153.967 331.189C157.5 339.275 161.98 346.848 167.242 353.798H344.76C350.022 346.849 354.502 339.275 358.035 331.189H153.967Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7208">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgMo
