import * as React from 'react'
import { ClipPath, Defs, G, Path } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgEu = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip_eu)">
            <Path
                fill="#0052B4"
                d="M256 512c141.385 0 256-114.615 256-256S397.385 0 256 0 0 114.615 0 256s114.615 256 256 256Z"
            />
            <Path
                fill="#FFDA44"
                d="m256.001 100.174 8.289 25.509h26.82l-21.699 15.765 8.289 25.509-21.699-15.766-21.7 15.766 8.289-25.509-21.699-15.765h26.821l8.289-25.509ZM145.813 145.814l23.9 12.176 18.965-18.964-4.197 26.49 23.899 12.177-26.491 4.196-4.196 26.492-12.177-23.899-26.49 4.197 18.965-18.965-12.178-23.9ZM100.175 256l25.509-8.289V220.89l15.764 21.7 25.51-8.289L151.191 256l15.767 21.699-25.51-8.288-15.764 21.699v-26.821L100.175 256ZM145.813 366.186l12.177-23.9-18.964-18.965 26.491 4.198 12.175-23.899 4.197 26.491 26.49 4.196-23.896 12.177 4.195 26.49-18.965-18.965-23.9 12.177ZM256.001 411.826l-8.29-25.509h-26.82l21.7-15.765-8.29-25.507 21.7 15.764 21.699-15.764-8.289 25.507 21.699 15.765h-26.821l-8.288 25.509ZM366.187 366.186l-23.899-12.176-18.966 18.965 4.197-26.492-23.897-12.176 26.49-4.196 4.196-26.491 12.176 23.899 26.49-4.198-18.965 18.967 12.178 23.898ZM411.826 256l-25.509 8.289v26.821l-15.765-21.7-25.507 8.289L360.81 256l-15.765-21.699 25.508 8.289 15.764-21.7v26.822L411.826 256ZM366.187 145.814l-12.177 23.9 18.965 18.965-26.492-4.198-12.175 23.899-4.196-26.491-26.49-4.197 23.897-12.176-4.197-26.489 18.967 18.964 23.898-12.177Z"
            />
        </G>
        <Defs>
            <ClipPath id="clip_eu">
                <Path fill="#fff" d="M0 0h512v512H0z" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgEu
