import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgKr = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7290)">
            <Path
                d="M256 511.999C397.385 511.999 512 397.384 512 255.999C512 114.614 397.385 -0.000976562 256 -0.000976562C114.615 -0.000976562 0 114.614 0 255.999C0 397.384 114.615 511.999 256 511.999Z"
                fill="#F0F0F0"
            />
            <Path
                d="M345.043 255.999C345.043 278.26 305.177 333.912 256 333.912C206.823 333.912 166.957 278.26 166.957 255.999C166.957 206.821 206.823 166.956 256 166.956C305.177 166.956 345.043 206.821 345.043 255.999Z"
                fill="#D80027"
            />
            <Path
                d="M345.043 255.999C345.043 305.177 305.177 345.042 256 345.042C206.823 345.042 166.957 305.177 166.957 255.999"
                fill="#0052B4"
            />
            <Path
                d="M350.375 334.707L373.982 311.099L389.721 326.838L366.113 350.445L350.375 334.707Z"
                fill="black"
            />
            <Path
                d="M311.017 374.054L334.625 350.447L350.363 366.185L326.756 389.793L311.017 374.054Z"
                fill="black"
            />
            <Path
                d="M397.593 381.92L421.201 358.312L436.939 374.051L413.332 397.658L397.593 381.92Z"
                fill="black"
            />
            <Path
                d="M358.237 421.273L381.844 397.666L397.583 413.404L373.976 437.012L358.237 421.273Z"
                fill="black"
            />
            <Path
                d="M373.983 358.315L397.59 334.708L413.329 350.447L389.721 374.054L373.983 358.315Z"
                fill="black"
            />
            <Path
                d="M334.636 397.662L358.244 374.055L373.982 389.793L350.375 413.401L334.636 397.662Z"
                fill="black"
            />
            <Path
                d="M397.698 177.334L334.744 114.381L350.483 98.6423L413.436 161.596L397.698 177.334Z"
                fill="black"
            />
            <Path
                d="M334.732 161.602L311.125 137.995L326.863 122.257L350.47 145.864L334.732 161.602Z"
                fill="black"
            />
            <Path
                d="M374.078 200.958L350.471 177.35L366.209 161.613L389.816 185.22L374.078 200.958Z"
                fill="black"
            />
            <Path
                d="M381.943 114.379L358.335 90.7714L374.074 75.0328L397.681 98.6403L381.943 114.379Z"
                fill="black"
            />
            <Path
                d="M421.31 153.739L397.703 130.132L413.441 114.393L437.049 138.001L421.31 153.739Z"
                fill="black"
            />
            <Path
                d="M90.7545 358.271L153.708 421.224L137.969 436.963L75.0159 374.009L90.7545 358.271Z"
                fill="black"
            />
            <Path
                d="M153.705 373.997L177.312 397.604L161.574 413.342L137.967 389.735L153.705 373.997Z"
                fill="black"
            />
            <Path
                d="M114.355 334.658L137.963 358.266L122.224 374.004L98.6166 350.397L114.355 334.658Z"
                fill="black"
            />
            <Path
                d="M137.965 311.044L200.919 373.998L185.18 389.736L122.227 326.783L137.965 311.044Z"
                fill="black"
            />
            <Path
                d="M153.701 90.7226L90.7478 153.676L75.0093 137.937L137.963 74.9841L153.701 90.7226Z"
                fill="black"
            />
            <Path
                d="M177.309 114.328L114.355 177.281L98.6169 161.542L161.57 98.589L177.309 114.328Z"
                fill="black"
            />
            <Path
                d="M200.939 137.949L137.985 200.903L122.247 185.165L185.201 122.212L200.939 137.949Z"
                fill="black"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7290">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0 -0.000976562)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgKr
