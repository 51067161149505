import {
    failure,
    object,
    Result,
    shape,
    string,
    success,
} from '@zeal/toolkit/Result'

import { PasskeyAndroidTimeoutError } from '@zeal/domains/Error/domains/Passkey'

export const parsePasskeyAndroidTimeoutError = (
    input: unknown
): Result<unknown, PasskeyAndroidTimeoutError> =>
    object(input)
        .andThen((obj) =>
            shape({
                message: string(obj.message).andThen((msg) =>
                    msg.match(`Request doesn't finish on time`)
                        ? success(msg)
                        : failure({
                              type: 'message_does_not_match_regexp',
                              msg,
                          })
                ),
            })
        )
        .map(() => ({ type: 'passkey_android_timeout_error' }))
