import { NetworkHexId } from '@zeal/domains/Network'

type BundlerErrorType =
    | 'bundler_error_aa10_sender_already_constructed'
    | 'bundler_error_aa13_init_code_failed_or_out_of_gas'
    | 'bundler_error_aa21_didnt_pay_prefund'
    | 'bundler_error_aa22_expired_or_not_due'
    | 'bundler_error_aa23_reverted_or_oog'
    | 'bundler_error_aa24_signature_error'
    | 'bundler_error_aa25_invalid_account_nonce'
    | 'bundler_error_aa31_paymaster_deposit_too_low'
    | 'bundler_error_aa33_reverted_or_out_of_gas'
    | 'bundler_error_aa34_signature_error'
    | 'bundler_error_aa40_over_verification_gas_limit'
    | 'bundler_error_aa41_too_little_verification_gas'
    | 'bundler_error_aa51_prefund_below_gas_cost'
    | 'bundler_error_aa93_invalid_paymaster_and_data'
    | 'bundler_error_aa95_out_of_gas'
    | 'bundler_error_cannot_execute_request'
    | 'bundler_error_unknown'
    | 'bundler_error_user_operation_reverted_during_execution_phase'

export class BundlerResponseError extends Error {
    name = 'BundlerResponseError' as const
    networkHexId: NetworkHexId
    type: BundlerErrorType
    payload: unknown
    request: unknown

    constructor({
        networkHexId,
        payload,
        request,
        type,
    }: {
        networkHexId: NetworkHexId
        payload: unknown
        request: unknown
        type: BundlerErrorType
    }) {
        super()
        this.networkHexId = networkHexId
        this.payload = payload
        this.request = request
        this.type = type
    }
}

// TODO :: @Nicvaniek kill once we settle on a 4337 provider
export class PimlicoBundlerResponseError extends Error {
    name = 'PimlicoBundlerResponseError' as const
    type: 'pimlico_bundler_response_error' =
        'pimlico_bundler_response_error' as const
    networkHexId: NetworkHexId
    payload: unknown

    constructor({
        networkHexId,
        payload,
    }: {
        networkHexId: NetworkHexId
        payload: unknown
    }) {
        super()
        this.networkHexId = networkHexId
        this.payload = payload
    }
}
