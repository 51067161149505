import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgBt = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7101)">
            <Path
                d="M437.019 437.02C337.045 536.994 174.955 536.994 74.9795 437.02C-24.9945 337.046 -24.9945 174.955 74.9795 74.9798C75.0155 74.9798 305.038 7.01283 405.013 106.987C504.988 206.961 437.088 436.95 437.019 437.02Z"
                fill="#FFDA44"
            />
            <Path
                d="M437.019 437.02C536.993 337.046 536.993 174.955 437.019 74.9799C337.045 -24.9951 174.955 -24.9941 74.9805 74.9799L437.019 437.02Z"
                fill="#D80027"
            />
            <Path
                d="M322.916 322.941C312.4 327.071 300.181 326.633 289.58 320.684C270.85 310.172 264.164 286.378 274.679 267.645L271.487 265.855C303.558 258.122 327.285 229.012 326.869 194.66C326.611 173.303 317.068 154.196 302.141 141.122L278.768 165.068C287.604 172.11 293.333 182.912 293.48 195.065C293.74 216.545 276.478 234.232 254.999 234.491L255.044 238.148C232.311 214.242 195.239 208.249 165.698 225.785C147.332 236.689 135.558 254.505 131.697 273.97L164.121 282.24C165.802 271.066 172.291 260.704 182.742 254.499C201.213 243.534 225.161 249.642 236.126 268.112L239.274 266.245C229.936 297.883 243.281 332.986 273.24 349.802C291.866 360.256 313.182 361.545 331.97 355.156L322.916 322.941ZM255.268 256.747L255.266 256.748L255.268 256.747Z"
                fill="#FF9811"
            />
            <Path
                d="M350.786 173.381L332.692 237.952L278.402 227.927L243.456 155.892L149.049 189.393L141.604 168.413L116.904 165.37L135.515 217.819L200.484 201.203L218.947 253.232L174.035 319.513L250.252 384.519L235.805 401.456L245.52 424.371L281.638 382.027L234.764 334.07L270.591 292.067L350.449 297.821L368.638 199.311L390.529 203.354L405.518 183.484L350.786 173.381ZM255.981 257.742V257.744V257.742Z"
                fill="#FFEACF"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7101">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgBt
