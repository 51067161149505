import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgNp = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 513 512" fill="none">
        <G clipPath="url(#clip0_4_7234)">
            <Path
                d="M256.988 512C398.373 512 512.988 397.385 512.988 256C512.988 114.615 398.373 0 256.988 0C115.603 0 0.988281 114.615 0.988281 256C0.988281 397.385 115.603 512 256.988 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M511.486 283.826L511.48 283.818L511.479 283.826H511.486Z"
                fill="#0052B4"
            />
            <Path
                d="M511.478 283.826C511.478 283.824 511.479 283.822 511.479 283.818L230.734 1.33105C218.069 2.62205 205.676 4.83606 193.625 7.90506C82.8863 36.1001 0.988281 256 0.988281 256C0.988281 256 359.386 495.835 400.273 468.164C408.454 462.628 416.298 456.631 423.766 450.211L257.383 283.826H511.478Z"
                fill="#0052B4"
            />
            <Path
                d="M446.205 256L197.233 7.02905C84.6763 33.9461 0.988281 135.192 0.988281 256C0.988281 397.384 115.604 512 256.988 512C310.617 512 360.385 495.498 401.517 467.311L190.205 256H446.205Z"
                fill="#D80027"
            />
            <Path
                d="M244.46 377.993L213.195 363.287L229.844 333.008L195.894 339.503L191.592 305.205L167.945 330.43L144.298 305.205L139.995 339.503L106.046 333.007L122.695 363.287L91.4292 377.993L122.695 392.698L106.046 422.978L139.997 416.484L144.297 450.78L167.945 425.555L191.592 450.78L195.894 416.484L229.843 422.979L213.194 392.7L244.46 377.993Z"
                fill="#F0F0F0"
            />
            <Path
                d="M223.597 141.983L200.857 131.288L212.966 109.265L188.273 113.989L185.144 89.043L167.945 107.39L150.746 89.043L147.616 113.989L122.923 109.265L135.033 131.288L112.292 141.983L167.945 153.115L223.597 141.983Z"
                fill="#F0F0F0"
            />
            <Path
                d="M234.727 141.983C234.727 178.866 204.827 208.766 167.944 208.766C131.061 208.766 101.161 178.866 101.161 141.983"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7234">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0.988281)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgNp
