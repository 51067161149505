import {
    failure,
    match,
    object,
    Result,
    shape,
    string,
    success,
} from '@zeal/toolkit/Result'

import { SecureStoreKeychainDecryptionError } from '@zeal/domains/Error'

export const parseSecureStoreKeychainDecryptionError = (
    input: unknown
): Result<unknown, SecureStoreKeychainDecryptionError> =>
    object(input).andThen((obj) =>
        shape({
            code: match(obj.code, 'ERR_DECRYPT'),
            message: string(obj.message).andThen((msg) =>
                msg.match('Could not decrypt the value with provided keychain')
                    ? success(msg)
                    : failure({ type: 'message_does_not_match_regexp', msg })
            ),
        }).map(
            () => ({ type: 'secure_store_keychain_decryption_error' }) as const
        )
    )
