import {
    failure,
    match,
    object,
    Result,
    shape,
    string,
    success,
} from '@zeal/toolkit/Result'

import { BiometricPromptAuthFailed } from '@zeal/domains/Error'

export const parseBiometricPromptAuthFailedError = (
    input: unknown
): Result<unknown, BiometricPromptAuthFailed> => {
    return object(input).andThen((obj) =>
        shape({
            code: match(obj.code, 'ERR_AUTHENTICATION'),
            message: string(obj.message).andThen((msg) =>
                msg.match('ExpoSecureStore.getValueWithKeyAsync') &&
                msg.match(
                    'Could not Authenticate the user: Could not authenticate the user'
                )
                    ? success(msg)
                    : failure({ type: 'message_does_not_match_regexp', msg })
            ),
        }).map(() => ({ type: 'biometric_prompt_auth_failed' }) as const)
    )
}
