import cloneDeepWith from 'lodash.clonedeepwith'

import { notReachable } from '@zeal/toolkit'
import { getEnvironment } from '@zeal/toolkit/Environment/getEnvironment'
import * as reporting from '@zeal/toolkit/Error/reporting'
import { replaceUUID } from '@zeal/toolkit/replaceUUID'
import { string } from '@zeal/toolkit/Result'

import { AppError } from '../AppError'

type Params = {
    source:
        | 'manually_captured'
        | 'app_error_popup'
        | 'error_boundary'
        | 'app_error_list_item'
        | 'app_error_banner'
    extra?: Record<string, unknown>
}

const KEYS_TO_SCRUB = new Set<string>([
    'account',
    'country',
    'signature',
    'keystore',
    'merchant',
    'sessionpassword',
    'token',
])

/**
 * We need to strip UUIDs and non-UUID specific params from URL to improve tagging in sentry and reduce duplication
 */
const cleanupUrl = (url: string) => {
    const noQueryString = url.split('?')[0]
    const noUUID = replaceUUID(noQueryString, 'uuid')
    const noSpecificParams = noUUID
        .replace(
            /\/wallet\/rate\/default\/[a-zA-Z]+\/0x[0-9a-fA-F]+\//gim,
            '/wallet/rate/default/:network/:address/'
        )
        .replace(
            /\/wallet\/transaction\/history\/0x[0-9a-fA-F]+\//gim,
            '/wallet/transaction/history/:address/'
        )
        .replace(
            /\/wallet\/transaction\/0x[0-9a-fA-F]+\/result/gim,
            '/wallet/transaction/:trx_hash/result'
        )

    return noSpecificParams
}

const scrubSensitiveFields = <T>(data: T): T =>
    cloneDeepWith(data, (_, key) => {
        if (
            key &&
            typeof key === 'string' &&
            KEYS_TO_SCRUB.has(key.toLowerCase())
        ) {
            return '***scrubbed***'
        }
        return undefined
    })

const stringifyUnknownData = (data: unknown): string =>
    JSON.stringify(
        scrubSensitiveFields(data),
        (_, value) => {
            switch (true) {
                case typeof value === 'bigint':
                    return value.toString()
                case value instanceof Map:
                    return Object.fromEntries(value)
                default:
                    return value
            }
        },
        2
    )

/**
 * @deprecated This helper is for domain internal use. Do not export and use it outside of Error domain
 */
export const captureAppError = (error: AppError, params: Params) => {
    const { extra, source } = params

    const tags = {
        errorType: error.type,
        source,
    }

    switch (error.type) {
        case 'unknown_unblock_error':
            report({
                error,
                tags: {
                    ...tags,
                    url: cleanupUrl(error.url),
                    method: error.method,
                    status: error.status,
                },
                extra: {
                    message: error.message,
                    errorId: error.errorId,
                    url: error.url,
                    method: error.method,
                    ...extra,
                },
            })
            break

        case 'unblock_account_number_and_sort_code_mismatch':
        case 'unblock_hard_kyc_failure':
        case 'unblock_invalid_faster_payment_configuration':
        case 'unblock_invalid_iban':
        case 'unblock_invalid_otp':
        case 'unblock_login_user_did_not_exists':
        case 'unblock_maximum_otp_attempts_exceeded':
        case 'unblock_nonce_already_in_use':
        case 'unblock_otp_expired':
        case 'unblock_session_expired':
        case 'unblock_unsupported_country':
        case 'unblock_user_associated_with_other_merchant':
        case 'unblock_user_with_address_already_exists':
        case 'unblock_user_with_such_email_already_exists':
            report({
                error,
                tags,
                extra,
            })
            break

        case 'unexpected_failure':
            report({
                error: error.error,
                tags,
                extra: {
                    reason: stringifyUnknownData(error.error.reason),
                    ...extra,
                },
            })
            break

        case 'unknown_error':
            report({
                error: error.error,
                tags,
                extra: {
                    extra: {
                        ...extra,
                        stringifyError: stringifyUnknownData(error.error),
                    },
                },
            })
            break

        case 'rpc_request_parse_error':
            report({
                error,
                tags: {
                    ...tags,
                    rpcMethod:
                        string(error.rpcMethod).getSuccessResult() || null,
                },
                extra: {
                    reason: stringifyUnknownData(error.reason),
                    ...extra,
                },
            })
            break

        case 'connectivity_error':
            report({
                error,
                tags: {
                    ...tags,
                    url: cleanupUrl(error.url),
                    method: error.method,
                },
                extra,
            })
            break

        case 'http_error':
            report({
                error,
                tags: {
                    ...tags,
                    url: cleanupUrl(error.url),
                    method: error.method,
                    status: error.status,
                },
                extra: {
                    ...extra,
                    trace: error.trace,
                    data: stringifyUnknownData(error.data),
                },
            })
            break

        case 'imperative_error':
            report({
                error,
                tags,
                extra: {
                    ...error.extra,
                    ...extra,
                },
            })
            break

        case 'passkey_signer_not_found_error':
            report({
                error,
                tags,
                extra: {
                    recoveryId: error.recoveryId,
                    ...extra,
                },
            })
            break
        case 'rpc_error_already_known':
        case 'rpc_error_block_gas_limit_exceeded':
        case 'rpc_error_block_state_unavailable':
        case 'rpc_error_cannot_execute_request':
        case 'rpc_error_cannot_query_unfinalized_data':
        case 'rpc_error_transaction_erc20_insufficient_allowance':
        case 'rpc_error_transaction_erc20_insufficient_balance':
        case 'rpc_error_transaction_erc20_transfer_error':
        case 'rpc_error_execution_reverted':
        case 'rpc_error_execution_timeout':
        case 'rpc_error_gas_price_is_less_than_minimum':
        case 'rpc_error_gas_required_exceeds_allowance':
        case 'rpc_error_insufficient_balance_for_transfer':
        case 'rpc_error_insufficient_funds_for_gas_and_value':
        case 'rpc_error_invalid_argument':
        case 'rpc_error_invalid_rsv_values':
        case 'rpc_error_invalid_sender':
        case 'rpc_error_max_fee_per_gas_less_than_block_base_fee':
        case 'rpc_error_nounce_is_too_low':
        case 'rpc_error_priority_fee_too_low':
        case 'rpc_error_replacement_transaction_underpriced':
        case 'rpc_error_swap_failed':
        case 'rpc_error_too_many_requests':
        case 'rpc_error_transaction_underpriced':
        case 'rpc_error_tx_pool_disabled':
            report({
                error,
                tags: {
                    ...tags,
                    type: error.type, // TODO @resetko-zeal : remove this + adjust grouping rules to use `errorType`
                    networkHexId: error.networkHexId,
                },
                extra: {
                    ...extra,
                    error: error.payload,
                },
            })
            break

        case 'rpc_error_unknown':
            report({
                error,
                tags: {
                    ...tags,
                    type: error.type, // TODO @resetko-zeal: remove this + adjust grouping rules to use `errorType`
                    networkHexId: error.networkHexId,
                },
                extra: {
                    ...extra,
                    error: error.payload,
                },
            })
            break

        case 'biometric_prompt_auth_failed':
        case 'biometric_prompt_cancelled':
        case 'decrypt_incorrect_password':
        case 'encrypted_object_invalid_format':
        case 'failed_to_fetch_google_auth_token':
        case 'gnosis_pay_is_not_available_in_this_country':
        case 'gnosis_pay_no_active_cards_found':
        case 'gnosis_pay_readonly_signer_is_already_in_use':
        case 'gnosis_pay_user_already_has_monerium_account':
        case 'google_api_error':
        case 'hardware_wallet_failed_to_open_device':
        case 'invalid_encrypted_file_format':
        case 'ios_could_not_communicate_with_helper_application':
        case 'ledger_blind_sign_not_enabled_or_running_non_eth_app':
        case 'ledger_is_locked':
        case 'ledger_not_running_any_app':
        case 'ledger_running_non_eth_app':
        case 'passkey_android_cannot_validate_incoming_request':
        case 'passkey_android_failed_to_launch_selector':
        case 'passkey_android_fido_api_not_available':
        case 'passkey_android_fido_api_not_supported':
        case 'passkey_android_no_create_options_available':
        case 'passkey_android_no_credential_available':
        case 'passkey_android_provider_configuration_error':
        case 'passkey_android_resident_key_creation_not_supported':
        case 'passkey_android_unable_to_get_sync_account':
        case 'passkey_app_not_associated_with_domain':
        case 'passkey_google_account_missing':
        case 'passkey_operation_cancelled':
        case 'passkey_screen_lock_missing':
        case 'passkey_android_creation_interrupted':
        case 'passkey_android_timeout_error':
        case 'secure_store_keychain_decryption_error':
        case 'trezor_action_cancelled':
        case 'trezor_connection_already_initialized':
        case 'trezor_device_used_elsewhere':
        case 'trezor_method_cancelled':
        case 'trezor_permissions_not_granted':
        case 'trezor_pin_cancelled':
        case 'trezor_popup_closed':
        case 'user_trx_denied_by_user':
        case 'wallet_connect_proposal_expired':
        case 'wallet_connect_proposal_no_more_available':
        case 'wallet_connect_add_ethereum_chain_missing_or_invalid':
        case 'wagmi_switch_chain_chain_id_not_supported':
        case 'wagmi_add_ethereum_chain_not_supported':
            report({ error, tags, extra })
            break

        case 'passkey_android_unknown_error':
            report({
                error,
                tags,
                extra: {
                    ...extra,
                    error: error.originalError,
                    stringifyError: stringifyUnknownData(error.originalError),
                },
            })
            break

        case 'bundler_error_aa10_sender_already_constructed':
        case 'bundler_error_aa13_init_code_failed_or_out_of_gas':
        case 'bundler_error_aa21_didnt_pay_prefund':
        case 'bundler_error_aa22_expired_or_not_due':
        case 'bundler_error_aa23_reverted_or_oog':
        case 'bundler_error_aa24_signature_error':
        case 'bundler_error_aa25_invalid_account_nonce':
        case 'bundler_error_aa31_paymaster_deposit_too_low':
        case 'bundler_error_aa33_reverted_or_out_of_gas':
        case 'bundler_error_aa34_signature_error':
        case 'bundler_error_aa40_over_verification_gas_limit':
        case 'bundler_error_aa41_too_little_verification_gas':
        case 'bundler_error_aa51_prefund_below_gas_cost':
        case 'bundler_error_aa93_invalid_paymaster_and_data':
        case 'bundler_error_aa95_out_of_gas':
        case 'bundler_error_cannot_execute_request':
        case 'bundler_error_user_operation_reverted_during_execution_phase':
        case 'bundler_error_unknown':
            report({
                error,
                tags: {
                    ...tags,
                    networkHexId: error.networkHexId,
                },
                extra: {
                    ...extra,
                    error: stringifyUnknownData(error.payload),
                    request: stringifyUnknownData(error.request),
                },
            })
            break
        case 'pimlico_bundler_response_error':
            report({
                error,
                tags: {
                    ...tags,
                    networkHexId: error.networkHexId,
                },
                extra: {
                    ...extra,
                    error: stringifyUnknownData(error.payload),
                },
            })
            break
        case 'unknown_merchant_code':
            report({
                error,
                tags: {
                    ...tags,
                    code: error.code,
                },
                extra: {
                    ...extra,
                    code: error.code,
                },
            })
            break

        default:
            notReachable(error)
    }
}

const report = ({
    error,
    tags,
    extra: rawExtra,
}: {
    error: unknown
    tags: Record<string, string | number | null>
    extra?: Record<string, unknown>
}): void => {
    const env = getEnvironment()

    const alertError = (input: unknown) => {
        const string = [
            typeof input === 'object' && input instanceof Error
                ? input.stack
                : null,
            stringifyUnknownData(input),
        ]
            .filter(Boolean)
            .join('\n\n')

        if (global.alert) {
            global.alert(string)
        }
    }

    const extra = scrubSensitiveFields(rawExtra)

    switch (env) {
        case 'local':
            console.error('💥💥💥 LOCAL mode error', error, { tags, extra }) // eslint-disable-line no-console
            alertError(error)
            break

        case 'development':
            console.error('💥💥💥 DEV mode error', error, { tags, extra }) // eslint-disable-line no-console
            alertError(error)
            reporting.captureException(error, { tags, extra })
            break

        case 'production':
            reporting.captureException(error, { tags, extra })
            break

        default:
            notReachable(env)
    }
}
