import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgEh = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7269)">
            <Path
                d="M25.402 144.696C9.128 178.352 0 216.109 0 256C0 295.891 9.128 333.648 25.402 367.304L256 389.565L486.598 367.304C502.872 333.648 512 295.891 512 256C512 216.109 502.872 178.352 486.598 144.696L256 122.435L25.402 144.696Z"
                fill="#F0F0F0"
            />
            <Path
                d="M25.411 367.304C66.817 452.935 154.507 512 256 512C357.493 512 445.183 452.935 486.589 367.304H25.411Z"
                fill="#496E2D"
            />
            <Path
                d="M25.411 144.696H486.589C445.183 59.065 357.494 0 256 0C154.506 0 66.817 59.065 25.411 144.696Z"
                fill="black"
            />
            <Path
                d="M74.98 74.98C-24.994 174.954 -24.994 337.045 74.98 437.02C116.293 395.707 156.026 355.974 256 256L74.98 74.98Z"
                fill="#D80027"
            />
            <Path
                d="M322.783 256C322.783 218.702 348.996 187.543 384 179.899C378.62 178.724 373.038 178.087 367.304 178.087C324.273 178.087 289.391 212.969 289.391 256C289.391 299.031 324.273 333.913 367.304 333.913C373.037 333.913 378.619 333.276 384 332.101C348.996 324.457 322.783 293.298 322.783 256Z"
                fill="#D80027"
            />
            <Path
                d="M387.273 205.913L398.326 239.927H434.087L405.155 260.945L416.207 294.957L387.273 273.937L358.342 294.957L369.395 260.945L340.461 239.927H376.222L387.273 205.913Z"
                fill="#D80027"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7269">
                <Rect width="512" height="512" fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgEh
