import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgGi = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7164)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F9F9F9"
            />
            <Path
                d="M256 512C366.07 512 459.906 442.528 496.076 345.043H15.9219C52.0939 442.528 145.928 512 256 512Z"
                fill="#D80027"
            />
            <Path
                d="M356.173 211.478V178.087H367.303V155.826H345.042V166.957H322.782V155.826H300.521V178.087H311.651V211.478H289.39V133.567H300.521V111.307H278.26V122.435H267.129V111.307H244.869V122.435H233.738V111.307H211.477V133.567H222.608V211.478H200.347V178.087H211.477V155.826H189.216V166.957H166.956V155.826H144.695V178.087H155.825V211.478H133.564V300.522H378.434V211.478H356.173Z"
                fill="#D80027"
            />
            <Path
                d="M256 289.391C237.559 289.391 222.609 304.341 222.609 322.782C222.609 337.318 231.903 349.682 244.87 354.266V400.696H211.479V445.218H267.131V354.267C280.098 349.683 289.392 337.32 289.392 322.783C289.391 304.342 274.441 289.391 256 289.391ZM256 333.913C249.853 333.913 244.87 328.929 244.87 322.783C244.87 316.636 249.853 311.653 256 311.653C262.147 311.653 267.13 316.636 267.13 322.783C267.13 328.929 262.147 333.913 256 333.913Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7164">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgGi
