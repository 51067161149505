import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgBn = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7110)">
            <Path
                d="M255.997 511.953C397.358 511.953 511.954 397.357 511.954 255.996C511.954 114.635 397.358 0.0390625 255.997 0.0390625C114.636 0.0390625 0.0400391 114.635 0.0400391 255.996C0.0400391 397.357 114.636 511.953 255.997 511.953Z"
                fill="#F0F0F0"
            />
            <Path
                d="M34.3301 128.016C22.7771 148.024 14.2551 168.942 8.58008 190.249L436.638 437.39C452.253 421.821 466.109 403.98 477.659 383.973L34.3301 128.016Z"
                fill="black"
            />
            <Path
                d="M383.975 34.329C281.559 -24.8 155.279 -5.09305 75.3516 74.599L503.41 321.738C532.463 212.676 486.389 93.459 383.975 34.329Z"
                fill="#FFDA44"
            />
            <Path
                d="M128.017 477.66C230.431 536.789 356.714 517.082 436.639 437.391L8.58039 190.249C-20.4706 299.314 25.6014 418.531 128.017 477.66Z"
                fill="#FFDA44"
            />
            <Path
                d="M328.331 228.173C328.331 268.122 295.945 300.509 255.995 300.509C216.045 300.509 183.659 268.123 183.659 228.173C183.659 215.717 186.808 203.996 192.354 193.763C176.657 209.816 166.966 231.768 166.966 255.994C166.966 305.163 206.825 345.023 255.995 345.023C305.165 345.023 345.024 305.164 345.024 255.994C345.024 231.768 335.334 209.816 319.636 193.763C325.182 203.998 328.331 215.717 328.331 228.173Z"
                fill="#D80027"
            />
            <Path
                d="M239.304 144.71H272.69V311.639H239.304V144.71Z"
                fill="#D80027"
            />
            <Path
                d="M311.638 178.095H200.353C200.353 185.778 207.046 192.006 214.728 192.006H214.264C214.264 199.689 220.493 205.917 228.175 205.917C228.175 213.6 234.404 219.828 242.086 219.828H269.907C277.59 219.828 283.818 213.6 283.818 205.917C291.501 205.917 297.729 199.689 297.729 192.006H297.266C304.946 192.005 311.638 185.779 311.638 178.095Z"
                fill="#D80027"
            />
            <Path
                d="M178.097 322.767H333.897V356.153H178.097V322.767Z"
                fill="#D80027"
            />
            <Path
                d="M289.382 333.896H356.153V367.282H289.382V333.896Z"
                fill="#D80027"
            />
            <Path
                d="M155.84 333.896H222.611V367.282H155.84V333.896Z"
                fill="#D80027"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7110">
                <Rect width={511.989} height={511.989} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgBn
