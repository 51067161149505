import { failure, Result, success } from '../Result'

type JSONParseError = { type: 'unable_to_parse_json'; value: unknown }

export const parse = (value: string): Result<JSONParseError, unknown> => {
    try {
        return success(JSON.parse(value))
    } catch (e) {
        return failure({ type: 'unable_to_parse_json', value })
    }
}

export const stringify = (value: any, space?: number): string => {
    return JSON.stringify(
        value,
        (_, value) => {
            switch (true) {
                case typeof value === 'bigint':
                    return value.toString()
                case value instanceof Map:
                    return Object.fromEntries(value)
                default:
                    return value
            }
        },
        space
    )
}
