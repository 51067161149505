import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgHr = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7132)">
            <Path
                d="M512 256.001C512 224.687 506.367 194.69 496.077 166.958L256 155.827L15.923 166.957C5.633 194.69 0 224.687 0 256.001C0 287.315 5.633 317.312 15.923 345.044L256 356.175L496.077 345.045C506.367 317.312 512 287.315 512 256.001Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 512.001C366.071 512.001 459.906 442.529 496.077 345.044H15.9229C52.0939 442.529 145.929 512.001 256 512.001Z"
                fill="#0052B4"
            />
            <Path
                d="M15.9229 166.958H496.078C459.906 69.473 366.071 0.000976562 256 0.000976562C145.929 0.000976562 52.0939 69.473 15.9229 166.958Z"
                fill="#D80027"
            />
            <Path
                d="M322.783 178.089H278.261L285.682 122.436L315.362 100.175L345.043 122.436V166.958L322.783 178.089Z"
                fill="#338AF3"
            />
            <Path
                d="M189.217 178.089H233.739L226.318 122.436L196.637 100.175L166.957 122.436V166.958L189.217 178.089Z"
                fill="#338AF3"
            />
            <Path
                d="M285.682 178.089H226.318V122.436L256 100.175L285.682 122.436V178.089Z"
                fill="#0052B4"
            />
            <Path
                d="M166.957 166.959V289.393C166.957 318.546 181.039 344.472 202.759 360.725L218.342 356.826L238.006 376.608C243.821 377.806 249.838 378.437 256.001 378.437C262.131 378.437 268.118 377.814 273.902 376.628L296.349 357.938L309.241 360.689C330.952 344.437 345.044 318.538 345.044 289.393V166.959H166.957Z"
                fill="#F0F0F0"
            />
            <Path
                d="M166.957 166.958H202.574V202.575H166.957V166.958Z"
                fill="#D80027"
            />
            <Path
                d="M238.191 166.958H273.808V202.575H238.191V166.958Z"
                fill="#D80027"
            />
            <Path
                d="M309.426 166.958H345.043V202.575H309.426V166.958Z"
                fill="#D80027"
            />
            <Path
                d="M202.574 202.575H238.191V238.192H202.574V202.575Z"
                fill="#D80027"
            />
            <Path
                d="M273.809 202.575H309.426V238.192H273.809V202.575Z"
                fill="#D80027"
            />
            <Path
                d="M166.957 238.181H202.574V273.798H166.957V238.181Z"
                fill="#D80027"
            />
            <Path
                d="M202.574 273.799H238.191V309.416H202.574V273.799Z"
                fill="#D80027"
            />
            <Path
                d="M238.191 238.181H273.808V273.798H238.191V238.181Z"
                fill="#D80027"
            />
            <Path
                d="M309.426 238.181H345.043V273.798H309.426V238.181Z"
                fill="#D80027"
            />
            <Path
                d="M273.809 273.799H309.426V309.416H273.809V273.799Z"
                fill="#D80027"
            />
            <Path
                d="M238.191 309.416H273.808V345.033H238.191V309.416Z"
                fill="#D80027"
            />
            <Path
                d="M202.574 309.419H169.255C172.311 322.659 178.319 334.774 186.557 345.036H202.574V309.419Z"
                fill="#D80027"
            />
            <Path
                d="M309.426 345.037H325.442C333.682 334.775 339.688 322.659 342.744 309.42H309.426V345.037Z"
                fill="#D80027"
            />
            <Path
                d="M202.574 345.037V360.578C212.933 368.373 225.039 373.962 238.191 376.644V345.037H202.574Z"
                fill="#D80027"
            />
            <Path
                d="M273.809 345.037V376.644C286.962 373.964 299.067 368.373 309.426 360.578V345.037H273.809Z"
                fill="#D80027"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7132">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0 0.000976562)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgHr
