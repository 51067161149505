import { useLayoutEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { SafeAreaProvider } from 'react-native-safe-area-context'

import './polyfill'

import { ApplicationContainer } from '@zeal/uikit/ApplicationContainer'

import { getAppVersion } from '@zeal/toolkit/Environment'
import { isLocal } from '@zeal/toolkit/Environment/isLocal'
import * as reporting from '@zeal/toolkit/Error/reporting'

import { ErrorBoundary } from '@zeal/domains/Error/components/ErrorBoundary'

import { EntryPoint } from './EntryPoint'

if (!isLocal()) {
    reporting.initClientReporting({
        dsn: 'https://5a06fa32714599f22e845633fd074c2a@o1301891.ingest.sentry.io/4506653980753920',
        release: `${getAppVersion()}`,
    })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

export const RootComponent = () => {
    useLayoutEffect(() => {
        const version = getAppVersion()
        const div = document.querySelector('.app-version')
        div && (div.innerHTML = `Version ${version}`)
    }, [])

    return (
        <IntlProvider locale="en">
            <SafeAreaProvider>
                <ErrorBoundary
                    onRetry={() => {
                        window.location.reload()
                    }}
                >
                    <ApplicationContainer variant="dApp">
                        <EntryPoint />
                    </ApplicationContainer>
                </ErrorBoundary>
            </SafeAreaProvider>
        </IntlProvider>
    )
}

root.render(<RootComponent />)
