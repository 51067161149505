import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgNa = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7231)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M74.981 437.02C85.72 447.759 97.178 457.336 109.197 465.768L465.766 109.198C457.335 97.1789 447.758 85.7209 437.018 74.9819C426.279 64.2429 414.821 54.6669 402.802 46.2349L46.234 402.805C54.665 414.822 64.242 426.281 74.981 437.02Z"
                fill="#A2001D"
            />
            <Path
                d="M74.981 74.9801C-5.78297 155.744 -21.287 277.036 28.436 373.378L373.378 28.4371C277.036 -21.2859 155.743 -5.77988 74.981 74.9801Z"
                fill="#0052B4"
            />
            <Path
                d="M437.019 437.02C517.781 356.258 533.285 234.965 483.564 138.622L138.622 483.564C234.963 533.287 356.256 517.783 437.019 437.02Z"
                fill="#496E2D"
            />
            <Path
                d="M211.478 144.696L182.792 158.189L198.068 185.971L166.919 180.013L162.971 211.478L141.275 188.335L119.578 211.478L115.632 180.013L84.482 185.97L99.757 158.188L71.072 144.696L99.758 131.202L84.482 103.422L115.631 109.38L119.579 77.9131L141.275 101.057L162.972 77.9131L166.919 109.38L198.068 103.422L182.794 131.203L211.478 144.696Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7231">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgNa
