import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgLy = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7204)">
            <Path
                d="M25.402 144.696C9.128 178.352 0 216.109 0 256C0 295.891 9.128 333.648 25.402 367.304L256 389.565L486.598 367.304C502.872 333.648 512 295.891 512 256C512 216.109 502.872 178.352 486.598 144.696L256 122.435L25.402 144.696Z"
                fill="black"
            />
            <Path
                d="M25.4111 367.304C66.8171 452.935 154.506 512 256 512C357.493 512 445.183 452.935 486.589 367.304H25.4111Z"
                fill="#496E2D"
            />
            <Path
                d="M25.4111 144.696H486.589C445.183 59.065 357.493 0 256 0C154.506 0 66.8171 59.065 25.4111 144.696Z"
                fill="#D80027"
            />
            <Path
                d="M315.586 209.186L336.59 238.131L370.607 227.101L349.569 256.021L370.571 284.965L336.567 273.893L315.529 302.813L315.551 267.052L281.545 255.98L315.563 244.95L315.586 209.186Z"
                fill="#F0F0F0"
            />
            <Path
                d="M258.261 328.348C218.305 328.348 185.913 295.956 185.913 256C185.913 216.044 218.305 183.652 258.261 183.652C270.719 183.652 282.442 186.802 292.676 192.348C276.62 176.647 254.664 166.956 230.435 166.956C181.257 166.956 141.392 206.822 141.392 255.999C141.392 305.176 181.258 345.042 230.435 345.042C254.665 345.042 276.621 335.351 292.676 319.65C282.441 325.198 270.72 328.348 258.261 328.348Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7204">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgLy
