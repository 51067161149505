import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgTm = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7315)">
            <Path
                d="M255.999 0C236.885 0 218.266 2.113 200.348 6.085L189.218 255.999L200.348 505.914C218.267 509.886 236.886 512 255.999 512C397.384 512 511.999 397.384 511.999 255.999C511.998 114.615 397.384 0 255.999 0V0Z"
                fill="#496E2D"
            />
            <Path
                d="M66.783 83.571C25.299 129.067 0.000976562 189.577 0.000976562 255.999C0.000976562 322.421 25.3 382.931 66.784 428.429L77.914 255.999L66.783 83.571Z"
                fill="#496E2D"
            />
            <Path
                d="M374.421 193.099C387.016 157.993 372.866 119.812 342.499 100.796C347.961 101.507 353.429 102.793 358.825 104.728C399.328 119.26 420.382 163.872 405.85 204.374C391.318 244.879 346.706 265.931 306.203 251.399C300.806 249.463 295.767 246.978 291.1 244.055C326.63 248.682 361.826 228.205 374.421 193.099Z"
                fill="#F0F0F0"
            />
            <Path
                d="M304.564 91.1731L293.619 105.315L276.79 99.2761L286.856 114.054L275.913 128.194L293.078 123.188L303.146 137.964L303.686 120.092L320.852 115.085L304.023 109.048L304.564 91.1731Z"
                fill="#F0F0F0"
            />
            <Path
                d="M334.116 137.252L323.171 151.393L306.341 145.354L316.408 160.132L305.464 174.272L322.629 169.264L332.697 184.043L333.239 166.17L350.404 161.162L333.574 155.124L334.116 137.252Z"
                fill="#F0F0F0"
            />
            <Path
                d="M252.46 107.955L241.515 122.095L224.686 116.058L234.753 130.835L223.809 144.976L240.973 139.969L251.041 154.746L251.583 136.874L268.748 131.867L251.919 125.828L252.46 107.955Z"
                fill="#F0F0F0"
            />
            <Path
                d="M251.26 160.737L240.316 174.878L223.486 168.84L233.554 183.618L222.608 197.758L239.775 192.752L249.842 207.528L250.383 189.656L267.549 184.649L250.718 178.61L251.26 160.737Z"
                fill="#F0F0F0"
            />
            <Path
                d="M301.486 178.758L290.541 192.898L273.712 186.86L283.78 201.638L272.834 215.777L290.001 210.771L300.068 225.549L300.609 207.675L317.775 202.669L300.944 196.631L301.486 178.758Z"
                fill="#F0F0F0"
            />
            <Path
                d="M66.7832 83.571V428.428C101.663 466.682 147.993 494.307 200.348 505.913V6.08496C147.992 17.691 101.664 45.316 66.7832 83.571Z"
                fill="#D80027"
            />
            <Path
                d="M117.753 134.785L95.3921 118.127V94.5701L117.753 77.9131H149.378L171.739 94.5701V118.127L149.378 134.785H117.753Z"
                fill="#F0F0F0"
            />
            <Path
                d="M133.566 77.9131H117.753L95.3921 94.5701V106.087H133.566V77.9131Z"
                fill="#FF9811"
            />
            <Path
                d="M133.566 134.785H149.378L171.739 118.127V106.11H133.566V134.785Z"
                fill="#FF9811"
            />
            <Path
                d="M117.753 434.085L95.3921 417.427V393.871L117.753 377.213H149.378L171.739 393.871V417.427L149.378 434.085H117.753Z"
                fill="#F0F0F0"
            />
            <Path
                d="M133.566 377.213H117.753L95.3921 393.871V405.388H133.566V377.213Z"
                fill="#FF9811"
            />
            <Path
                d="M133.566 434.085H149.378L171.739 417.427V405.411H133.566V434.085Z"
                fill="#FF9811"
            />
            <Path
                d="M117.753 284.435L95.3921 267.777V244.221L117.753 227.563H149.378L171.739 244.221V267.777L149.378 284.435H117.753Z"
                fill="#496E2D"
            />
            <Path
                d="M172.522 321.085H162.782V311.346H145.667L133.565 299.244L121.464 311.346H104.349V321.085H94.6099V340.563H104.349V350.302H121.463L133.566 362.405L145.667 350.302H162.782V340.563H172.522V321.085Z"
                fill="#496E2D"
            />
            <Path
                d="M172.522 171.434H162.782V161.694H145.667L133.565 149.592L121.464 161.694H104.349V171.434H94.6099V190.912H104.349V200.651H121.463L133.566 212.755L145.667 200.651H162.782V190.912H172.522V171.434Z"
                fill="#496E2D"
            />
            <Path
                d="M122.434 244.868H144.695V267.129H122.434V244.868Z"
                fill="#D80027"
            />
            <Path
                d="M133.564 192.299C139.711 192.299 144.694 187.316 144.694 181.169C144.694 175.022 139.711 170.039 133.564 170.039C127.417 170.039 122.434 175.022 122.434 181.169C122.434 187.316 127.417 192.299 133.564 192.299Z"
                fill="#D80027"
            />
            <Path
                d="M133.564 341.958C139.711 341.958 144.694 336.975 144.694 330.828C144.694 324.681 139.711 319.698 133.564 319.698C127.417 319.698 122.434 324.681 122.434 330.828C122.434 336.975 127.417 341.958 133.564 341.958Z"
                fill="#D80027"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7315">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgTm
