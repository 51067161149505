import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgMh = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7216)">
            <Path
                d="M256 512C397.384 512 512 397.384 512 256C512 213.037 501.4 172.554 482.698 137L255.699 256.3L375 29.302C339.446 10.6 298.963 0 256 0C114.616 0 0 114.616 0 256C0 326.693 28.654 390.693 74.98 437.02C121.307 483.347 185.307 512 256 512Z"
                fill="#0052B4"
            />
            <Path
                d="M482.428 137.279C470.71 114.975 455.475 94.0342 436.721 75.2802C417.966 56.5262 397.028 41.2912 374.722 29.5742L74.6809 437.319L482.428 137.279Z"
                fill="#F0F0F0"
            />
            <Path
                d="M74.6809 437.319L482.428 137.279C470.71 114.975 455.475 94.0338 436.721 75.2798L74.6809 437.319Z"
                fill="#FF9811"
            />
            <Path
                d="M222.308 150.122L191.044 164.828L207.693 195.108L173.743 188.613L169.441 222.909L145.793 197.684L122.145 222.909L117.845 188.613L83.8951 195.106L100.542 164.827L69.2781 150.122L100.543 135.415L83.8951 105.137L117.843 111.63L122.146 77.334L145.793 102.559L169.441 77.334L173.743 111.63L207.694 105.137L191.045 135.416L222.308 150.122Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7216">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgMh
