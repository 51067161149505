import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgFo = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7152)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M500.87 256L509.829 222.609C508.341 211.228 506.109 200.08 503.182 189.218H233.739V0.973999C222.379 1.952 211.234 3.681 200.348 6.095L155.826 33.391L133.565 31.144C121.85 37.536 110.689 44.816 100.174 52.895V189.217H8.8189C5.8919 200.079 3.6599 211.227 2.1719 222.608L11.1299 256L2.1709 289.391C3.6589 300.772 5.8909 311.92 8.8179 322.782H100.173V459.104C110.688 467.184 121.85 474.463 133.564 480.855L166.955 478.608L200.346 505.904C211.233 508.317 222.378 510.046 233.737 511.025V322.783H503.179C506.106 311.921 508.338 300.773 509.826 289.392L500.87 256Z"
                fill="#0052B4"
            />
            <Path
                d="M509.833 222.609H200.349H200.348V6.08502C176.69 11.331 154.261 19.834 133.565 31.127V222.608H2.167C0.744 233.539 0 244.683 0 256C0 267.317 0.744 278.461 2.167 289.391H133.564H133.565V480.872C154.261 492.164 176.69 500.669 200.348 505.914V289.394V289.392H509.833C511.256 278.461 512 267.317 512 256C512 244.683 511.256 233.539 509.833 222.609Z"
                fill="#D80027"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7152">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgFo
