import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgJo = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7192)">
            <Path
                d="M256 512.001C397.385 512.001 512 397.386 512 256.001C512 114.616 397.385 0.000976562 256 0.000976562C114.615 0.000976562 0 114.616 0 256.001C0 397.386 114.615 512.001 256 512.001Z"
                fill="#F0F0F0"
            />
            <Path
                d="M155.826 166.958H496.076C459.906 69.473 366.07 0.000976562 256 0.000976562C185.306 0.000976562 121.313 28.66 74.9888 74.99L155.826 166.958Z"
                fill="black"
            />
            <Path
                d="M155.826 345.044H496.076C459.906 442.529 366.07 512.001 256 512.001C185.306 512.001 121.313 483.342 74.9888 437.012L155.826 345.044Z"
                fill="#6DA544"
            />
            <Path
                d="M74.98 74.981C-24.994 174.955 -24.994 337.046 74.98 437.021C116.293 395.708 156.026 355.975 256 256.001L74.98 74.981Z"
                fill="#D80027"
            />
            <Path
                d="M101.605 200.349L115.654 229.728L147.383 222.395L133.173 251.696L158.688 271.93L126.921 279.089L127.009 311.653L101.605 291.28L76.2 311.653L76.289 279.089L44.522 271.93L70.036 251.696L55.828 222.395L87.555 229.728L101.605 200.349Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7192">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0 0.000976562)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgJo
