import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgMn = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7225)">
            <Path
                d="M256 512C287.314 512 317.311 506.368 345.043 496.077L356.174 256L345.044 15.923C317.311 5.633 287.314 0 256 0C224.686 0 194.689 5.633 166.957 15.923L155.826 256L166.956 496.077C194.689 506.368 224.686 512 256 512Z"
                fill="#0052B4"
            />
            <Path
                d="M0 256C0 366.071 69.472 459.906 166.957 496.077V15.9229C69.472 52.0939 0 145.929 0 256Z"
                fill="#A2001D"
            />
            <Path
                d="M345.043 15.9229V496.078C442.528 459.906 512 366.071 512 256C512 145.929 442.528 52.0939 345.043 15.9229Z"
                fill="#A2001D"
            />
            <Path
                d="M122.435 256H144.696V345.043H122.435V256Z"
                fill="#FFDA44"
            />
            <Path
                d="M33.3911 256H55.6521V345.043H33.3911V256Z"
                fill="#FFDA44"
            />
            <Path
                d="M89.0432 311.652C101.338 311.652 111.304 301.685 111.304 289.391C111.304 277.096 101.338 267.13 89.0432 267.13C76.7488 267.13 66.7822 277.096 66.7822 289.391C66.7822 301.685 76.7488 311.652 89.0432 311.652Z"
                fill="#FFDA44"
            />
            <Path
                d="M89.0431 222.608C95.19 222.608 100.173 217.625 100.173 211.478C100.173 205.331 95.19 200.348 89.0431 200.348C82.8962 200.348 77.9131 205.331 77.9131 211.478C77.9131 217.625 82.8962 222.608 89.0431 222.608Z"
                fill="#FFDA44"
            />
            <Path
                d="M66.7832 322.783H111.305V345.044H66.7832V322.783Z"
                fill="#FFDA44"
            />
            <Path
                d="M66.7832 233.739H111.305V256H66.7832V233.739Z"
                fill="#FFDA44"
            />
            <Path
                d="M89.0433 133.565L96.9273 157.825H122.435L101.8 172.819L109.678 197.079L89.0433 182.085L68.4093 197.079L76.2873 172.819L55.6523 157.825H81.1603L89.0433 133.565Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7225">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgMn
