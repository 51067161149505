import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgPg = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7253)">
            <Path
                d="M437.02 437.019C337.046 536.993 174.956 536.993 74.9799 437.019C-24.9941 337.045 -24.9941 174.954 74.9809 74.979C174.955 -24.995 321.304 -9.25404 421.278 90.721C521.251 190.695 536.993 337.045 437.02 437.019Z"
                fill="black"
            />
            <Path
                d="M74.981 74.98C174.955 -24.994 337.044 -24.992 437.02 74.981C536.994 174.955 536.994 337.045 437.02 437.02"
                fill="#A2001D"
            />
            <Path
                d="M195.642 345.044L199.786 357.799H213.197L202.347 365.681L206.492 378.436L195.642 370.553L184.792 378.436L188.936 365.681L178.087 357.799H191.497L195.642 345.044Z"
                fill="#F0F0F0"
            />
            <Path
                d="M161.391 222.609L168.3 243.867H190.65L172.567 257.004L179.474 278.262L161.391 265.123L143.309 278.262L150.217 257.004L132.133 243.867H154.485L161.391 222.609Z"
                fill="#F0F0F0"
            />
            <Path
                d="M161.391 378.436L168.298 399.694H190.65L172.565 412.83L179.474 434.088L161.391 420.949L143.309 434.088L150.215 412.83L132.133 399.694H154.482L161.391 378.436Z"
                fill="#F0F0F0"
            />
            <Path
                d="M226.741 278.262L233.65 299.52H256L237.917 312.656L244.824 333.914L226.741 320.775L208.659 333.914L215.567 312.656L197.484 299.52H219.835L226.741 278.262Z"
                fill="#F0F0F0"
            />
            <Path
                d="M96.0402 278.262L102.949 299.52H125.298L107.216 312.656L114.122 333.914L96.0402 320.775L77.9572 333.914L84.8662 312.656L66.7822 299.52H89.1332L96.0402 278.262Z"
                fill="#F0F0F0"
            />
            <Path
                d="M428.549 189.561C417.108 178.12 402.242 171.361 386.267 170.2L434.087 133.007C415.042 113.962 389.516 105.361 364.608 107.18C361.626 96.464 355.941 86.342 347.514 77.915L328.625 115.693C327.386 113.435 325.817 111.31 323.903 109.397C313.035 98.53 295.417 98.53 284.551 109.397C273.685 120.264 273.683 137.882 284.551 148.749C286.464 150.662 288.59 152.231 290.848 153.471L253.07 172.36C261.499 180.79 271.628 186.479 282.35 189.459C280.525 214.367 289.117 239.889 308.163 258.935L349.943 205.217C353.086 202.122 357.237 200.413 361.653 200.413C366.113 200.413 370.305 202.149 373.458 205.304C376.612 208.457 378.349 212.65 378.349 217.109C378.349 221.569 376.612 225.761 373.458 228.915L389.2 244.657C396.557 237.299 400.61 227.516 400.61 217.11C400.61 208.596 397.894 200.502 392.88 193.81C400.186 195.761 407.091 199.583 412.811 205.304C430.171 222.663 430.171 250.909 412.811 268.267L428.553 284.009C441.166 271.395 448.113 254.625 448.113 236.786C448.11 218.945 441.162 202.175 428.549 189.561Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7253">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgPg
