import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgMe = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7226)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#FFDA44"
            />
            <Path
                d="M512 256C512 168.139 467.731 90.625 400.287 44.522H111.713C44.27 90.625 0 168.139 0 256C0 343.863 44.27 421.375 111.713 467.478H400.287C467.731 421.375 512 343.863 512 256Z"
                fill="#A2001D"
            />
            <Path
                d="M200.348 189.217H311.652V144.696L289.391 155.826L256 122.435L222.609 155.826L200.348 144.696V189.217Z"
                fill="#FFDA44"
            />
            <Path
                d="M356.174 256H300.543C302.725 252.226 303.993 247.859 303.993 243.187C303.993 229 292.492 217.501 278.307 217.501C268.506 217.501 259.994 222.993 255.663 231.063C251.332 222.992 242.82 217.501 233.019 217.501C218.834 217.501 207.333 229 207.333 243.187C207.333 247.86 208.603 252.227 210.783 256H155.824C155.824 273.023 170.651 286.822 187.673 286.822H186.647C186.647 300.785 195.934 312.575 208.667 316.362L183.65 341.381L207.261 364.992L240.754 331.499C242.066 331.98 243.423 332.364 244.826 332.628L224.588 378.323C234.286 382.267 244.885 384.456 256 384.456C267.114 384.456 277.713 382.267 287.412 378.323L267.174 332.628C268.576 332.365 269.933 331.98 271.244 331.499L304.737 364.992L328.347 341.381L303.329 316.363C316.061 312.574 325.349 300.786 325.349 286.823H324.322C341.346 286.822 356.174 273.023 356.174 256Z"
                fill="#FFDA44"
            />
            <Path
                d="M256 272.696L219.826 289.391V322.783L256 345.043L292.174 322.783V289.391L256 272.696Z"
                fill="#6DA544"
            />
            <Path
                d="M219.826 256H292.174V300.522H219.826V256Z"
                fill="#0052B4"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7226">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgMe
