import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgFj = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7154)">
            <Path
                d="M512 256C512 397.384 397.384 512 256 512C114.616 512 0 397.384 0 256C0 256.061 256 0.028 256 0C397.384 0 512 114.616 512 256Z"
                fill="#338AF3"
            />
            <Path
                d="M256 122.435V208.778L166.957 122.435H256Z"
                fill="#0052B4"
            />
            <Path
                d="M133.565 256H208.777L133.565 155.826L122.435 233.739L133.565 256Z"
                fill="#0052B4"
            />
            <Path
                d="M255.315 256H256C256 255.77 256 255.546 256 255.315C255.772 255.544 255.544 255.772 255.315 256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 133.565C256 88.52 256 59.005 256 0H255.957C114.592 0.024 0 114.629 0 256H133.565V180.789L208.777 256H255.317C255.545 255.772 255.773 255.544 256.002 255.315C256.002 238.068 256.002 222.679 256.002 208.778L180.788 133.565H256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M129.515 33.391C89.4756 56.19 56.1896 89.476 33.3906 129.515V256H100.174V100.176V100.174H256C256 79.111 256 59.045 256 33.391H129.515Z"
                fill="#D80027"
            />
            <Path
                d="M255.999 224.519L165.046 133.566H133.565V133.568L255.997 256H255.999C255.999 256 255.999 234.293 255.999 224.519Z"
                fill="#D80027"
            />
            <Path
                d="M289.391 166.957V255.999C289.391 315.639 367.304 333.913 367.304 333.913C367.304 333.913 445.217 315.639 445.217 255.999V166.957L367.304 144.696L289.391 166.957Z"
                fill="#F3F3F3"
            />
            <Path
                d="M445.217 166.957V133.566H289.391V166.957H356.172V233.741H289.391V256V256.002L356.172 256.003V330.5C362.89 332.876 367.304 333.914 367.304 333.914C367.304 333.914 371.716 332.878 378.433 330.5V256.002H445.217V256V233.74H378.433V166.956H445.217V166.957Z"
                fill="#D80027"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7154">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgFj
