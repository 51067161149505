import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgGq = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7147)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M155.826 166.957H496.076C459.906 69.472 366.07 0 256 0C185.306 0 121.313 28.659 74.9893 74.989L155.826 166.957Z"
                fill="#6DA544"
            />
            <Path
                d="M155.826 345.043H496.076C459.906 442.528 366.07 512 256 512C185.306 512 121.313 483.341 74.9893 437.011L155.826 345.043Z"
                fill="#D80027"
            />
            <Path
                d="M74.9795 74.98C-24.9945 174.954 -24.9945 337.045 74.9795 437.02C116.293 395.707 156.026 355.974 256 256L74.9795 74.98Z"
                fill="#0052B4"
            />
            <Path
                d="M300.522 211.478V267.13C300.522 301.21 345.044 311.653 345.044 311.653C345.044 311.653 389.566 301.21 389.566 267.13V211.478H300.522Z"
                fill="#DEDDE0"
            />
            <Path
                d="M333.913 257.113H356.174V289.391H333.913V257.113Z"
                fill="#786145"
            />
            <Path
                d="M367.304 244.87C367.304 232.575 357.338 222.609 345.043 222.609C332.748 222.609 322.782 232.575 322.782 244.87C316.635 244.87 311.652 249.853 311.652 256C311.652 262.147 316.635 267.13 322.782 267.13H367.304C373.451 267.13 378.434 262.147 378.434 256C378.434 249.853 373.452 244.87 367.304 244.87Z"
                fill="#6DA544"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7147">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgGq
