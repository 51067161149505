import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgEg = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7144)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 0C145.929 0 52.0939 69.472 15.9229 166.957H496.078C459.906 69.472 366.071 0 256 0Z"
                fill="#D80027"
            />
            <Path
                d="M256 512C366.071 512 459.906 442.528 496.077 345.043H15.9229C52.0939 442.528 145.929 512 256 512Z"
                fill="black"
            />
            <Path
                d="M345.043 228.174H278.26C278.26 215.88 268.293 205.913 255.999 205.913C243.705 205.913 233.738 215.88 233.738 228.174H166.955C166.955 240.469 177.664 250.435 189.958 250.435H189.216C189.216 262.73 199.182 272.696 211.477 272.696C211.477 284.991 221.443 294.957 233.738 294.957H278.26C290.555 294.957 300.521 284.991 300.521 272.696C312.816 272.696 322.782 262.73 322.782 250.435H322.041C334.335 250.435 345.043 240.469 345.043 228.174Z"
                fill="#FF9811"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7144">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgEg
