import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgMk = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7263)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#FFDA44"
            />
            <Path
                d="M294.932 509.057C336.568 502.705 374.894 486.309 407.388 462.439L256 256L294.932 509.057Z"
                fill="#D80027"
            />
            <Path
                d="M217.067 2.94299C175.432 9.29499 137.106 25.691 104.61 49.56L256 256L217.067 2.94299Z"
                fill="#D80027"
            />
            <Path
                d="M104.61 462.439C137.104 486.308 175.43 502.704 217.065 509.058L256 256L104.61 462.439Z"
                fill="#D80027"
            />
            <Path
                d="M49.5599 104.61C25.6899 137.106 9.29587 175.431 2.94287 217.067L256 256L49.5599 104.61Z"
                fill="#D80027"
            />
            <Path
                d="M2.94287 294.932C9.29487 336.568 25.6909 374.894 49.5609 407.388L256 256L2.94287 294.932Z"
                fill="#D80027"
            />
            <Path
                d="M509.057 217.067C502.704 175.432 486.309 137.106 462.439 104.611L256 256L509.057 217.067Z"
                fill="#D80027"
            />
            <Path
                d="M407.388 49.559C374.893 25.69 336.568 9.29499 294.932 2.94299L256 256L407.388 49.559Z"
                fill="#D80027"
            />
            <Path
                d="M462.441 407.389C486.31 374.894 502.705 336.567 509.059 294.933L256 256L462.441 407.389Z"
                fill="#D80027"
            />
            <Path
                d="M256 345.043C305.177 345.043 345.043 305.177 345.043 256C345.043 206.823 305.177 166.957 256 166.957C206.823 166.957 166.957 206.823 166.957 256C166.957 305.177 206.823 345.043 256 345.043Z"
                fill="#D80027"
            />
            <Path
                d="M256 322.783C292.883 322.783 322.783 292.883 322.783 256C322.783 219.117 292.883 189.217 256 189.217C219.117 189.217 189.217 219.117 189.217 256C189.217 292.883 219.117 322.783 256 322.783Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7263">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgMk
