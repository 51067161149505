import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgAl = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7076)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#D80027"
            />
            <Path
                d="M400.696 190.009H307.97C313.694 184.015 317.217 175.9 317.217 166.957C317.217 148.516 302.267 133.566 283.826 133.566C272.208 133.566 261.981 139.502 256 148.503C250.019 139.502 239.791 133.566 228.174 133.566C209.733 133.566 194.783 148.516 194.783 166.957C194.783 175.9 198.306 184.016 204.03 190.009H111.305C111.305 214.598 132.722 234.53 157.309 234.53H155.825C155.825 259.12 175.758 279.053 200.348 279.053C200.348 287.017 202.447 294.483 206.111 300.947L169.181 337.878L197.515 366.21L237.707 326.019C239.281 326.596 240.91 327.059 242.592 327.374L218.305 382.209L256 422.957L293.695 382.207L269.408 327.372C271.091 327.057 272.719 326.595 274.293 326.017L314.485 366.208L342.819 337.875L305.888 300.944C309.553 294.481 311.652 287.014 311.652 279.05C336.241 279.05 356.174 259.118 356.174 234.527H354.691C379.278 234.529 400.696 214.598 400.696 190.009Z"
                fill="black"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7076">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgAl
