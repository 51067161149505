import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgMq = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7217)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M509.806 222.608C502.744 168.622 478.494 116.456 437.019 74.9809C395.544 33.5059 343.377 9.25487 289.391 2.19287L289.392 222.608H509.806Z"
                fill="#0052B4"
            />
            <Path
                d="M222.608 2.19287C168.622 9.25487 116.455 33.5049 74.9801 74.9799C33.5061 116.456 9.25512 168.623 2.19312 222.61L222.609 222.609L222.608 2.19287Z"
                fill="#0052B4"
            />
            <Path
                d="M2.19312 289.392C9.25512 343.378 33.5061 395.546 74.9801 437.02C116.455 478.494 168.621 502.744 222.609 509.809L222.608 289.394L2.19312 289.392Z"
                fill="#0052B4"
            />
            <Path
                d="M289.392 509.806C343.378 502.744 395.544 478.494 437.019 437.019C478.494 395.544 502.744 343.378 509.806 289.392H289.392V509.806Z"
                fill="#0052B4"
            />
            <Path
                d="M343.389 162.438C343.389 159.034 346.156 156.267 349.561 156.267H362.785C383.689 156.267 400.696 139.261 400.696 118.358C400.696 97.4552 383.69 80.4482 362.785 80.4482H350.227C346.128 77.3692 340.629 75.4702 334.563 75.4702C321.91 75.4702 311.653 83.6752 311.653 93.7972C311.653 103.821 321.714 111.953 334.198 112.111L334.192 112.184H362.785C366.19 112.184 368.957 114.952 368.957 118.356C368.957 121.76 366.19 124.527 362.785 124.527H349.561C328.657 124.527 311.652 141.533 311.652 162.436C311.652 183.339 328.656 200.346 349.561 200.346H400.695V168.608H349.561C346.156 168.609 343.389 165.842 343.389 162.438Z"
                fill="#F0F0F0"
            />
            <Path
                d="M143.042 162.438C143.042 159.034 145.809 156.267 149.214 156.267H162.438C183.342 156.267 200.349 139.261 200.349 118.358C200.349 97.4552 183.343 80.4482 162.438 80.4482H149.88C145.781 77.3692 140.282 75.4702 134.216 75.4702C121.563 75.4702 111.306 83.6752 111.306 93.7972C111.306 103.821 121.367 111.953 133.851 112.111L133.845 112.184H162.439C165.844 112.184 168.611 114.952 168.611 118.356C168.611 121.76 165.844 124.527 162.439 124.527H149.215C128.311 124.527 111.306 141.533 111.306 162.436C111.306 183.339 128.31 200.346 149.215 200.346H200.348V168.608H149.214C145.809 168.609 143.042 165.842 143.042 162.438Z"
                fill="#F0F0F0"
            />
            <Path
                d="M343.389 398.62C343.389 395.216 346.156 392.449 349.561 392.449H362.785C383.689 392.449 400.696 375.443 400.696 354.54C400.696 333.637 383.69 316.63 362.785 316.63H350.227C346.128 313.551 340.629 311.652 334.563 311.652C321.909 311.652 311.652 319.858 311.652 329.979C311.652 340.003 321.713 348.135 334.197 348.293L334.191 348.366H362.784C366.189 348.366 368.956 351.134 368.956 354.538C368.956 357.942 366.189 360.709 362.784 360.709H349.56C328.656 360.709 311.651 377.715 311.651 398.618C311.651 419.521 328.655 436.528 349.56 436.528H400.695V404.791H349.561C346.156 404.792 343.389 402.023 343.389 398.62Z"
                fill="#F0F0F0"
            />
            <Path
                d="M143.042 398.62C143.042 395.216 145.809 392.449 149.214 392.449H162.438C183.342 392.449 200.349 375.443 200.349 354.54C200.349 333.637 183.343 316.63 162.438 316.63H149.88C145.781 313.551 140.282 311.652 134.216 311.652C121.562 311.652 111.305 319.858 111.305 329.979C111.305 340.003 121.366 348.135 133.85 348.293L133.844 348.366H162.438C165.843 348.366 168.61 351.134 168.61 354.538C168.61 357.942 165.843 360.709 162.438 360.709H149.214C128.31 360.709 111.305 377.715 111.305 398.618C111.305 419.521 128.309 436.528 149.214 436.528H200.348V404.791H149.214C145.809 404.792 143.042 402.023 143.042 398.62Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7217">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgMq
