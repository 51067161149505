import { useEffect } from 'react'

import { notReachable } from '@zeal/toolkit'
import { ImperativeError } from '@zeal/toolkit/Error'

import {
    CryptoCurrency,
    Currency,
    CurrencyId,
    FiatCurrency,
    KnownCurrencies,
} from '@zeal/domains/Currency'
import { captureError } from '@zeal/domains/Error/helpers/captureError'

/**
 * @deprecated use Money2
 */
export const useCurrencyById = (
    currencyId: CurrencyId,
    knownCurrencies: KnownCurrencies
): Currency | null => {
    const currency = knownCurrencies[currencyId] || null

    useEffect(() => {
        if (!currency) {
            captureError(
                new ImperativeError('Unknown currency picked from record', {
                    currency: currencyId,
                })
            )
        }
    }, [currency, currencyId])

    return currency
}

/**
 * @deprecated use Money2
 */
export const useCryptoCurrencyById = (
    currencyId: CurrencyId,
    knownCurrencies: KnownCurrencies
): CryptoCurrency | null => {
    const currency = useCurrencyById(currencyId, knownCurrencies)

    useEffect(() => {
        switch (currency?.type) {
            case undefined:
            case 'CryptoCurrency':
                break
            case 'FiatCurrency':
                captureError(
                    new ImperativeError(
                        'FiatCurrency currency picked as CryptoCurrency',
                        {
                            currency: currencyId,
                        }
                    )
                )
                break

            /* istanbul ignore next */
            default:
                return notReachable(currency)
        }
    }, [currency, currencyId])

    switch (currency?.type) {
        case undefined:
        case 'FiatCurrency':
            return null
        case 'CryptoCurrency':
            return currency

        /* istanbul ignore next */
        default:
            return notReachable(currency)
    }
}

/**
 * @deprecated use Money2
 */
export const useFiatCurrencyById = (
    currencyId: CurrencyId,
    knownCurrencies: KnownCurrencies
): FiatCurrency | null => {
    const currency = useCurrencyById(currencyId, knownCurrencies)

    useEffect(() => {
        switch (currency?.type) {
            case undefined:
            case 'FiatCurrency':
                break
            case 'CryptoCurrency':
                captureError(
                    new ImperativeError(
                        'CryptoCurrency picked as FiatCurrency',
                        {
                            currency: currencyId,
                        }
                    )
                )
                break

            /* istanbul ignore next */
            default:
                return notReachable(currency)
        }
    }, [currency, currencyId])

    switch (currency?.type) {
        case undefined:
        case 'CryptoCurrency':
            return null
        case 'FiatCurrency':
            return currency

        /* istanbul ignore next */
        default:
            return notReachable(currency)
    }
}
