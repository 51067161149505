import { joinURL } from '@zeal/toolkit/URL/joinURL'

import { Auth, getAuthHeaders } from './Auth'
import { processFetchFailure, processFetchResponse } from './interceptors'

export const GNOSIS_PAY_BASE_URL = 'https://api.gnosispay.com/api/v1'

export type Paths = {
    get: {
        '/me': { query: undefined }
        '/auth/nonce': { query: undefined }
        '/transactions': { query: undefined }
        '/account-balances': { query: undefined }
        '/external/ibans/available': { query: undefined }
        '/eoa-accounts': { query: undefined }
    } & Record<
        `/cards/${string}/details`,
        { query: { encryptedKey: string } }
    > &
        Record<`/cards/${string}/pin`, { query: { encryptedKey: string } }> &
        Record<`/cards/${string}/status`, { query: undefined }>

    post: {
        '/eoa-accounts': {
            query: undefined
            body: {
                address: string
            }
        }
        '/auth/verify': {
            query: undefined
            body: { message: string; signature: string }
        }
        '/delay-relay': {
            query: undefined
            body: {
                chainId: number
                target: string
                signedData: string
                safeAddress: string
                operationType: 'CALL'
                transactionData: {
                    data: string
                    value: string
                    to: string
                }
            }
        }
        '/user/terms': {
            query: undefined
            body: { terms: string; version: string }
        }
        '/external/ibans': {
            query: undefined
            body: { address: string; signature: string }
        }
        '/external/ibans/transfer': {
            query: undefined
            body: { address: string; signature: string }
        }
    } & Record<
        `/cards/${string}/freeze`,
        { query: undefined; body: undefined }
    > &
        Record<
            `/cards/${string}/unfreeze`,
            { query: undefined; body: undefined }
        >

    delete: Record<
        `/eoa-accounts/${string}`,
        {
            query: undefined
        }
    >
}

export const get = <T extends keyof Paths['get']>(
    path: T,
    params: { query?: Paths['get'][T]['query']; auth?: Auth }, // TODO @resetko-zeal fix query is not mandatory even if its there in Paths
    signal?: AbortSignal
): Promise<unknown> => {
    const url = joinURL(GNOSIS_PAY_BASE_URL, path)
    const query = params.query
        ? `?${new URLSearchParams(params.query as Record<string, string>)}`
        : ''
    const urlWithQuery = `${url}${query}`

    return fetch(urlWithQuery, {
        method: 'GET',
        headers: {
            ...(params.auth ? getAuthHeaders(params.auth) : {}),
        },
        signal,
    })
        .catch((error) =>
            processFetchFailure({ error, method: 'GET', params, url })
        )
        .then((response) =>
            processFetchResponse({ params, method: 'GET', response, url })
        )
}

export const post = <T extends keyof Paths['post']>(
    path: T,
    params: {
        query?: Paths['post'][T]['query']
        auth?: Auth
        body: Paths['post'][T]['body']
    },
    signal?: AbortSignal
): Promise<unknown> => {
    const url = joinURL(GNOSIS_PAY_BASE_URL, path)
    const query = params.query
        ? `?${new URLSearchParams(params.query as Record<string, string>)}`
        : ''
    const urlWithQuery = `${url}${query}`

    return fetch(urlWithQuery, {
        method: 'POST',
        body: JSON.stringify(params.body),
        headers: {
            'Content-Type': 'application/json',
        },
        signal,
    })
        .catch((error) =>
            processFetchFailure({ error, method: 'POST', params, url })
        )
        .then((response) =>
            processFetchResponse({ params, method: 'POST', response, url })
        )
}

export const del = <T extends keyof Paths['delete']>(
    path: T,
    params: {
        query?: Paths['delete'][T]['query']
        auth?: Auth
    },
    signal?: AbortSignal
): Promise<unknown> => {
    const url = joinURL(GNOSIS_PAY_BASE_URL, path)
    const query = params.query
        ? `?${new URLSearchParams(params.query as Record<string, string>)}`
        : ''
    const urlWithQuery = `${url}${query}`

    return fetch(urlWithQuery, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        signal,
    })
        .catch((error) =>
            processFetchFailure({ error, method: 'DELETE', params, url })
        )
        .then((response) =>
            processFetchResponse({ params, method: 'DELETE', response, url })
        )
}
