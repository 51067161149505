import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgGe = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7161)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M509.833 222.609H289.393H289.392V2.167C278.461 0.744 267.317 0 256 0C244.683 0 233.539 0.744 222.609 2.167V222.607V222.608H2.167C0.744 233.539 0 244.683 0 256C0 267.319 0.744 278.461 2.167 289.391H222.607H222.608V509.833C233.539 511.256 244.683 512 256 512C267.317 512 278.461 511.257 289.391 509.833V289.393V289.392H509.833C511.256 278.461 512 267.319 512 256C512 244.683 511.256 233.539 509.833 222.609Z"
                fill="#D80027"
            />
            <Path
                d="M155.826 122.435V89.043H122.435V122.435H89.043V155.826H122.435V189.217H155.826V155.826H189.217V122.435H155.826Z"
                fill="#D80027"
            />
            <Path
                d="M389.565 122.435V89.043H356.174V122.435H322.783V155.826H356.174V189.217H389.565V155.826H422.957V122.435H389.565Z"
                fill="#D80027"
            />
            <Path
                d="M155.826 356.174V322.783H122.435V356.174H89.043V389.565H122.435V422.957H155.826V389.565H189.217V356.174H155.826Z"
                fill="#D80027"
            />
            <Path
                d="M389.565 356.174V322.783H356.174V356.174H322.783V389.565H356.174V422.957H389.565V389.565H422.957V356.174H389.565Z"
                fill="#D80027"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7161">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgGe
