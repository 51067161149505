import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgFk = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7153)">
            <Path
                d="M512 256.001C512 397.385 397.384 512.001 256 512.001C114.616 512.001 0 397.385 0 256.001C0 256.062 256 0.0289766 256 0.000976562C397.384 0.000976562 512 114.617 512 256.001Z"
                fill="#0052B4"
            />
            <Path
                d="M255.315 256.001H256C256 255.771 256 255.547 256 255.316C255.772 255.545 255.544 255.773 255.315 256.001Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 133.566C256 88.521 256 59.006 256 0.000976562H255.957C114.592 0.0249766 0 114.63 0 256.001H133.565V180.79L208.777 256.001H255.316L256.001 255.316C256.001 238.069 256.001 222.68 256.001 208.779L180.788 133.566H256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M129.515 33.392C89.4761 56.191 56.1901 89.477 33.3911 129.516V256.001H100.174V100.177V100.175H256C256 79.112 256 59.046 256 33.392H129.515Z"
                fill="#D80027"
            />
            <Path
                d="M256 224.52L165.047 133.567H133.566V133.569L255.998 256.001H256C256 256.001 256 234.294 256 224.52Z"
                fill="#D80027"
            />
            <Path
                d="M411.826 300.523V311.653H322.783V300.523H289.391V345.044H311.652V356.175H422.957V345.044H445.217V300.523H411.826Z"
                fill="#FFDA44"
            />
            <Path
                d="M289.391 133.566V256.001C289.391 315.64 367.304 333.914 367.304 333.914C367.304 333.914 445.217 315.64 445.217 256.001V133.566H289.391Z"
                fill="#338AF3"
            />
            <Path
                d="M367.3 224.89C347.823 224.89 347.823 242.699 328.346 242.699C308.869 242.699 308.869 224.89 289.392 224.89V256.055C308.869 256.055 308.869 273.864 328.346 273.864C347.823 273.864 347.823 256.055 367.3 256.055C386.779 256.055 386.779 273.864 406.259 273.864C425.739 273.864 425.738 256.055 445.218 256.055V224.89C425.739 224.89 425.739 242.699 406.259 242.699C386.779 242.699 386.779 224.89 367.3 224.89Z"
                fill="#F3F3F3"
            />
            <Path
                d="M367.3 162.505C347.823 162.505 347.823 180.314 328.346 180.314C308.869 180.314 308.869 162.505 289.392 162.505V193.67C308.869 193.67 308.869 211.479 328.346 211.479C347.823 211.479 347.823 193.67 367.3 193.67C386.779 193.67 386.779 211.479 406.259 211.479C425.739 211.479 425.738 193.67 445.218 193.67V162.505C425.739 162.505 425.739 180.314 406.259 180.314C386.779 180.314 386.779 162.505 367.3 162.505Z"
                fill="#F3F3F3"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7153">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0 0.000976562)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgFk
