import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createWeb3Modal, Web3Modal } from '@web3modal/wagmi-react-native'
import { WagmiProvider } from 'wagmi'

import { notReachable } from '@zeal/toolkit'
import { MsgOf } from '@zeal/toolkit/MsgOf'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

import { DefaultCurrency } from '@zeal/domains/Currency'
import { INITIAL_DEFAULT_CURRENCY } from '@zeal/domains/Currency/constants'
import { ZEAL_DAPP_WC_PROJECT_ID } from '@zeal/domains/DApp/domains/WalletConnect/constants'
import { ZealDAppEntryPoint } from '@zeal/domains/Main'
import { getPredefinedNetworkMap } from '@zeal/domains/Network/helpers/getPredefinedNetworkMap'

import { createWagmiConfig } from './createWagmiConfig'
import { EntryPoint } from './EntryPoint'

type Props = {
    entryPoint: ZealDAppEntryPoint
    onMsg: (msg: Msg) => void
}

type Msg = MsgOf<typeof EntryPoint>

const queryClient = new QueryClient()

const { config: wagmiConfig, supportedNetworks } = createWagmiConfig()

createWeb3Modal({
    projectId: ZEAL_DAPP_WC_PROJECT_ID,
    wagmiConfig,
    featuredWalletIds: [
        'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // MetaMask
        '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', // Trust
        '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369', // Rainbow
        'ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18', // Zerion
        '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709', // OKX
        'c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a', // Uniswap
    ],
    excludeWalletIds: [
        '43832260665ea0d076f9af1ee157d580bb0eb44ca0415117fef65666460a2652', // Theta wallet (using same scheme as rainbow)
        '6289878a2ef8c49944cf428184bba626b161b0974f0b4f8b31a15e0317d8861c', // ID Pocket (using same scheme as rainbow)
    ],
})

export const ZealDApp = ({ entryPoint, onMsg }: Props) => {
    const predefinedNetworkMap = getPredefinedNetworkMap()
    return (
        <WagmiProvider config={wagmiConfig}>
            <QueryClientProvider client={queryClient}>
                <EntryPoint
                    defaultCurrencyConfig={{
                        defaultCurrency:
                            INITIAL_DEFAULT_CURRENCY as DefaultCurrency,
                    }}
                    entryPoint={entryPoint}
                    supportedNetworks={supportedNetworks}
                    networkMap={predefinedNetworkMap}
                    networkRPCMap={{}}
                    onMsg={onMsg}
                />
                {(() => {
                    switch (ZealPlatform.OS) {
                        case 'ios':
                        case 'android':
                            return <Web3Modal />
                        case 'web':
                            return null
                        /* istanbul ignore next */
                        default:
                            return notReachable(ZealPlatform.OS)
                    }
                })()}
            </QueryClientProvider>
        </WagmiProvider>
    )
}
