import React from 'react'
import { ImageStyle, Pressable, StyleSheet, View } from 'react-native'

import { colors } from '@zeal/uikit/colors'
import {
    Color as TextColor,
    Variant as TextVariant,
    Weight as TextWeight,
} from '@zeal/uikit/Text'

import { notReachable } from '@zeal/toolkit'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

import { InternalItem, Props as InternalItemProps } from './InternalItem'

import { ImageBackground } from '../ImageBackground'

type BackgroundVariant = 'earn_usd' | 'earn_eur' | 'earn_eth' | 'earn' | 'card'

type Props = {
    background: BackgroundVariant
    title: (p: {
        color: TextColor
        textVariant: TextVariant
        textWeight: TextWeight
    }) => React.ReactNode
    onClick: () => void
} & Omit<InternalItemProps, 'size' | 'background'>

const styles = StyleSheet.create({
    list_item_container: {
        padding: 16,
        zIndex: 1,
    },
    artwork_content_container: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: 16,
    },
})

export const ListItemButtonLarge = ({
    background,
    onClick,
    primaryTextIcon,
    shortText,
    primaryText,
    wrapPrimaryText,
    side,
    title,
    avatar,
}: Props) => (
    <Pressable onPress={onClick} role="button">
        {({ pressed, hovered }) => (
            <Wrapper
                background={background}
                hovered={!!hovered}
                pressed={pressed}
            >
                <Artwork background={background}>
                    <View style={styles.artwork_content_container}>
                        {title({
                            color: 'gray100',
                            textVariant: 'title2',
                            textWeight: 'regular',
                        })}
                    </View>
                </Artwork>
                <View style={[styles.list_item_container]}>
                    <InternalItem
                        size="large"
                        background={(() => {
                            switch (background) {
                                case 'earn_usd':
                                case 'earn_eur':
                                case 'earn_eth':
                                case 'earn':
                                case 'card':
                                    return 'dark' as const

                                /* istanbul ignore next */
                                default:
                                    return notReachable(background)
                            }
                        })()}
                        wrapPrimaryText={wrapPrimaryText}
                        primaryText={primaryText}
                        primaryTextIcon={primaryTextIcon}
                        side={side}
                        avatar={avatar}
                        shortText={shortText}
                    />
                </View>
            </Wrapper>
        )}
    </Pressable>
)

const backgroundColor = ({ background }: { background: BackgroundVariant }) => {
    switch (background) {
        case 'earn_usd':
            return colors.orange30
        case 'earn_eur':
            return colors.blue30
        case 'earn_eth':
            return colors.gray40
        case 'earn':
            return colors.purple40
        case 'card':
            return colors.gray10

        /* istanbul ignore next */
        default:
            return notReachable(background)
    }
}

const Wrapper = ({
    background,
    hovered,
    pressed,
    children,
}: {
    background: BackgroundVariant
    hovered: boolean
    pressed: boolean
    children: React.ReactNode
}) => (
    <View
        style={[
            {
                backgroundColor: backgroundColor({ background }),
                borderRadius: 16,
                overflow: 'hidden',
                ...(() => {
                    switch (ZealPlatform.OS) {
                        case 'ios':
                        case 'android':
                            return {}
                        case 'web':
                            return { cursor: 'pointer' }
                        /* istanbul ignore next */
                        default:
                            return notReachable(ZealPlatform.OS)
                    }
                })(),
            },
            hovered && { opacity: 0.8 },
            pressed && { opacity: 0.5 },
        ]}
    >
        {children}
    </View>
)

const artworkStyles: ImageStyle = {
    height: 72,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
}

export const Artwork = ({
    background,
    children,
}: {
    background: BackgroundVariant
    children: React.ReactNode
}) => {
    switch (background) {
        case 'earn_usd':
            return (
                <ImageBackground
                    style={artworkStyles}
                    position="right"
                    source={require('@zeal/assets/list-item-button-earn-usd-bg.png')}
                >
                    {children}
                </ImageBackground>
            )
        case 'earn_eur':
            return (
                <ImageBackground
                    style={artworkStyles}
                    position="right"
                    source={require('@zeal/assets/list-item-button-earn-eur-bg.png')}
                >
                    {children}
                </ImageBackground>
            )
        case 'earn_eth':
            return (
                <ImageBackground
                    style={artworkStyles}
                    position="right"
                    source={require('@zeal/assets/list-item-button-earn-eth-bg.png')}
                >
                    {children}
                </ImageBackground>
            )
        case 'earn':
            return (
                <ImageBackground
                    style={artworkStyles}
                    position="right"
                    source={require('@zeal/assets/list-item-button-earn-bg.png')}
                >
                    {children}
                </ImageBackground>
            )
        case 'card':
            return (
                <ImageBackground
                    style={artworkStyles}
                    position="right"
                    source={require('@zeal/assets/list-item-button-card-bg.png')}
                >
                    {children}
                </ImageBackground>
            )

        default:
            return notReachable(background)
    }
}
