import {
    CryptoCurrency,
    FiatCurrency,
    KnownCurrencies,
} from '@zeal/domains/Currency'
import { getCryptoCurrencyOrThrow } from '@zeal/domains/Currency/helpers/getCryptoCurrency'
import { getFiatCurrencyOrThrow } from '@zeal/domains/Currency/helpers/getFiatCurrencyOrThrow'
import { FXRate2 } from '@zeal/domains/FXRate'
import { FiatMoney } from '@zeal/domains/Money'

import { Token, Token2 } from '..'

/**
 * @deprecated Remove once Token type is dead
 */
export const tokenToToken2 = ({
    token,
    knownCurrencies,
}: {
    token: Token
    knownCurrencies: KnownCurrencies
}): Token2 => {
    const cryptoCurrency = getCryptoCurrencyOrThrow({
        cryptoCurrencyId: token.balance.currencyId,
        knownCurrencies,
    })

    const rate = ((): FXRate2<CryptoCurrency, FiatCurrency> | null => {
        if (token.rate === null) {
            return null
        }

        const rateQuoteCurrency = getFiatCurrencyOrThrow({
            fiatCurrencyId: token.rate.quote,
            knownCurrencies,
        })

        return {
            rate: token.rate.rate,
            base: cryptoCurrency,
            quote: rateQuoteCurrency,
        }
    })()

    const priceInDefaultCurrency: FiatMoney | null =
        token.priceInDefaultCurrency
            ? {
                  amount: token.priceInDefaultCurrency.amount,
                  currency: getFiatCurrencyOrThrow({
                      fiatCurrencyId: token.priceInDefaultCurrency.currencyId,
                      knownCurrencies,
                  }),
              }
            : null

    return {
        balance: {
            amount: token.balance.amount,
            currency: cryptoCurrency,
        },
        rate,
        scam: token.scam,
        marketData: token.marketData,
        priceInDefaultCurrency,
    }
}
