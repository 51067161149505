import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgDo = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7141)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M8.81934 322.783C32.4573 410.486 101.514 479.544 189.218 503.182V322.783H8.81934Z"
                fill="#D80027"
            />
            <Path
                d="M189.217 8.81897C101.514 32.456 32.4563 101.514 8.81934 189.217H189.218V8.81897H189.217Z"
                fill="#0052B4"
            />
            <Path
                d="M503.181 189.217C479.544 101.514 410.486 32.456 322.783 8.81897V189.218H503.181V189.217Z"
                fill="#D80027"
            />
            <Path
                d="M322.783 503.181C410.486 479.543 479.544 410.486 503.182 322.782H322.783V503.181Z"
                fill="#0052B4"
            />
            <Path
                d="M322.783 256C322.783 292.884 292.884 322.783 256 322.783C219.117 322.783 189.217 292.884 189.217 256C189.217 219.116 256 189.217 256 189.217C256 189.217 322.783 219.116 322.783 256Z"
                fill="#496E2D"
            />
            <Path
                d="M189.217 256C189.217 219.116 219.117 189.217 256 189.217C292.884 189.217 322.783 219.116 322.783 256"
                fill="#0052B4"
            />
            <Path
                d="M218.435 222.609V264.348C218.435 285.095 235.253 301.913 256 301.913C276.747 301.913 293.565 285.095 293.565 264.348V222.609H218.435Z"
                fill="#D80027"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7141">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgDo
