import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgTr = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 513" fill="none">
        <G clipPath="url(#clip0_4_7314)">
            <Path
                d="M256 512.581C397.385 512.581 512 397.966 512 256.581C512 115.196 397.385 0.581055 256 0.581055C114.615 0.581055 0 115.196 0 256.581C0 397.966 114.615 512.581 256 512.581Z"
                fill="#D80027"
            />
            <Path
                d="M245.518 209.767L266.523 238.712L300.54 227.682L279.502 256.602L300.504 285.546L266.499 274.474L245.462 303.394L245.484 267.633L211.478 256.561L245.496 245.531L245.518 209.767Z"
                fill="#F0F0F0"
            />
            <Path
                d="M188.194 328.929C148.238 328.929 115.846 296.537 115.846 256.581C115.846 216.625 148.238 184.233 188.194 184.233C200.652 184.233 212.374 187.384 222.608 192.929C206.553 177.227 184.596 167.537 160.368 167.537C111.19 167.537 71.3252 207.403 71.3252 256.58C71.3252 305.757 111.191 345.623 160.368 345.623C184.598 345.623 206.554 335.932 222.608 320.231C212.374 325.778 200.652 328.929 188.194 328.929Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7314">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0 0.581055)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgTr
