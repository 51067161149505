import { ImperativeError } from '@zeal/toolkit/Error'
import * as Web3 from '@zeal/toolkit/Web3'

import { Address } from '@zeal/domains/Address'
import { Network, NetworkHexId } from '@zeal/domains/Network'
import {
    KNOWN_NETWORKS_MAP,
    PREDEFINED_NETWORKS,
} from '@zeal/domains/Network/constants'

export type Currency = FiatCurrency | CryptoCurrency

export type FiatCurrency = {
    type: 'FiatCurrency'
    id: string
    symbol: string
    code: FiatCurrencyCode
    fraction: number
    rateFraction: number
    icon: string
    name: string
}

export type ShortCryptoCurrency = {
    symbol: string
    fraction: number
    name: string
    // https://docs.coingecko.com/reference/coins-id - coin rank by market cap
    marketCapRank: number | null
}

export type CryptoCurrency = ShortCryptoCurrency & {
    type: 'CryptoCurrency'
    id: string
    address: Address
    networkHexChainId: NetworkHexId
    code: string
    rateFraction: number
    icon: string
}

export type CurrencyId = string // fixme @max-tern opaque

export const currencyId = ({
    network,
    address,
}: {
    network: NetworkHexId
    address: Address
}): CurrencyId => {
    const predefinedNetwork = PREDEFINED_NETWORKS.find(
        (item) => item.hexChainId === network
    )
    if (!predefinedNetwork) {
        if (!KNOWN_NETWORKS_MAP[network]) {
            throw new ImperativeError(`currencyId not parsed`, { network })
        }
        return `${KNOWN_NETWORKS_MAP[network].chainName}|${address}`
    }
    return `${predefinedNetwork.name}|${address}`
}

export const getCurrencyInfo = (
    id: CurrencyId
): { network: Network; address: Address } => {
    const split = id.split('|')
    const network = PREDEFINED_NETWORKS.find(
        (predefinedNetwork) => predefinedNetwork.name === split[0]
    )
    const address = Web3.address
        .parse(split[1])
        .mapErrorEntityInfo({ currencyId: id })
        .getSuccessResultOrThrow(`[getCurrencyInfo] parsing failed`)

    if (network && address) {
        return { network, address }
    }
    throw new ImperativeError(`[getCurrencyInfo] parsing failed`, {
        currencyId: id,
    })
}

/**
 * @deprecated this type should not be needed, please use Money2
 */
export type KnownCurrencies = Record<CurrencyId, Currency>
export type KnownCryptoCurrencies = Record<CurrencyId, CryptoCurrency>
export type ShortKnownCryptoCurrencies = Record<CurrencyId, ShortCryptoCurrency>

export type CurrencyPinMap = Record<CurrencyId, boolean>
export type CurrencyHiddenMap = Record<CurrencyId, boolean>
export type GasCurrencyPresetMap = Record<NetworkHexId, CurrencyId>

export type FiatCurrencyCode =
    | 'GBP'
    | 'EUR'
    | 'NGN'
    | 'PLN'
    | 'USD'
    | 'CAD'
    | 'AED'
    | 'AFN'
    | 'ALL'
    | 'AMD'
    | 'ARS'
    | 'AUD'
    | 'AZN'
    | 'BAM'
    | 'BDT'
    | 'BGN'
    | 'BHD'
    | 'BIF'
    | 'BND'
    | 'BOB'
    | 'BRL'
    | 'BWP'
    | 'BYN'
    | 'BZD'
    | 'CDF'
    | 'CHF'
    | 'CLP'
    | 'CNY'
    | 'COP'
    | 'CRC'
    | 'CVE'
    | 'CZK'
    | 'DJF'
    | 'DKK'
    | 'DOP'
    | 'DZD'
    | 'EEK'
    | 'EGP'
    | 'ERN'
    | 'ETB'
    | 'GEL'
    | 'GHS'
    | 'GNF'
    | 'GTQ'
    | 'HKD'
    | 'HNL'
    | 'HRK'
    | 'HUF'
    | 'IDR'
    | 'ILS'
    | 'INR'
    | 'IQD'
    | 'IRR'
    | 'ISK'
    | 'JMD'
    | 'JOD'
    | 'JPY'
    | 'KES'
    | 'KHR'
    | 'KMF'
    | 'KRW'
    | 'KWD'
    | 'KZT'
    | 'LBP'
    | 'LKR'
    | 'LTL'
    | 'LVL'
    | 'LYD'
    | 'MAD'
    | 'MDL'
    | 'MGA'
    | 'MKD'
    | 'MMK'
    | 'MOP'
    | 'MUR'
    | 'MXN'
    | 'MYR'
    | 'MZN'
    | 'NAD'
    | 'NIO'
    | 'NOK'
    | 'NPR'
    | 'NZD'
    | 'OMR'
    | 'PAB'
    | 'PEN'
    | 'PHP'
    | 'PKR'
    | 'PYG'
    | 'QAR'
    | 'RON'
    | 'RSD'
    | 'RUB'
    | 'RWF'
    | 'SAR'
    | 'SDG'
    | 'SEK'
    | 'SGD'
    | 'SOS'
    | 'SYP'
    | 'THB'
    | 'TND'
    | 'TOP'
    | 'TRY'
    | 'TTD'
    | 'TWD'
    | 'TZS'
    | 'UAH'
    | 'UGX'
    | 'UYU'
    | 'UZS'
    | 'VEF'
    | 'VND'
    | 'XAF'
    | 'XOF'
    | 'YER'
    | 'ZAR'
    | 'ZMK'
    | 'ZWL'
    | 'BMD'

declare const DefaultCurrencySymbol: unique symbol

export type DefaultCurrency = FiatCurrency & {
    _opaque: typeof DefaultCurrencySymbol
}
