import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgCv = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7118)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 512C333.591 512 403.114 477.476 450.061 422.957H61.9395C108.886 477.476 178.409 512 256 512Z"
                fill="#0052B4"
            />
            <Path
                d="M512 256C512 114.616 397.384 0 256 0C114.616 0 0 114.616 0 256C0 279.107 3.08 301.489 8.819 322.783H503.182C508.92 301.489 512 279.107 512 256Z"
                fill="#0052B4"
            />
            <Path
                d="M20.3477 356.174C25.2827 367.77 31.0517 378.922 37.5747 389.565H474.427C480.948 378.921 486.717 367.769 491.654 356.174H20.3477Z"
                fill="#D80027"
            />
            <Path
                d="M182.99 272.696L187.134 285.45H200.545L189.696 293.333L193.84 306.087L182.99 298.204L172.14 306.087L176.285 293.333L165.436 285.45H178.847L182.99 272.696Z"
                fill="#FFDA44"
            />
            <Path
                d="M182.99 439.652L187.134 452.407H200.545L189.696 460.289L193.84 473.043L182.99 465.161L172.14 473.043L176.285 460.289L165.436 452.407H178.847L182.99 439.652Z"
                fill="#FFDA44"
            />
            <Path
                d="M105.274 383.189L109.418 395.942H122.829L111.98 403.824L116.124 416.58L105.274 408.697L94.4237 416.58L98.5697 403.824L87.7197 395.942H101.13L105.274 383.189Z"
                fill="#FFDA44"
            />
            <Path
                d="M134.958 289.391L139.102 302.146H152.512L141.664 310.028L145.808 322.783L134.958 314.9L124.108 322.783L128.254 310.028L117.403 302.146H130.814L134.958 289.391Z"
                fill="#FFDA44"
            />
            <Path
                d="M87.7197 341.915L101.128 341.916L105.273 329.159L109.418 341.915L122.829 341.914L111.979 349.797L116.124 362.552L105.273 354.669L94.4237 362.549L98.5677 349.796L87.7197 341.915Z"
                fill="#FFDA44"
            />
            <Path
                d="M124.109 453.911L128.253 441.159L117.402 433.277H130.813L134.958 420.522L139.103 433.276H152.513L141.663 441.158L145.807 453.914L134.957 446.03L124.109 453.911Z"
                fill="#FFDA44"
            />
            <Path
                d="M260.677 383.189L256.534 395.942H243.123L253.971 403.824L249.827 416.58L260.677 408.697L271.527 416.58L267.382 403.824L278.232 395.942H264.821L260.677 383.189Z"
                fill="#FFDA44"
            />
            <Path
                d="M230.992 289.391L226.848 302.146H213.438L224.285 310.028L220.141 322.783L230.992 314.9L241.841 322.783L237.697 310.028L248.546 302.146H235.135L230.992 289.391Z"
                fill="#FFDA44"
            />
            <Path
                d="M278.232 341.915L264.823 341.916L260.678 329.159L256.534 341.915L243.123 341.914L253.972 349.797L249.827 362.552L260.678 354.669L271.527 362.549L267.383 349.796L278.232 341.915Z"
                fill="#FFDA44"
            />
            <Path
                d="M241.841 453.911L237.698 441.159L248.548 433.277H235.137L230.992 420.522L226.848 433.276H213.437L224.287 441.158L220.143 453.914L230.993 446.03L241.841 453.911Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7118">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgCv
