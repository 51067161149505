import { ComponentPropsWithoutRef } from 'react'

import { Chain } from '@zeal/uikit/Chain'
import { ListItem } from '@zeal/uikit/ListItem'
import { IconRenderProps } from '@zeal/uikit/ListItem/InternalItem'

import { getCurrencyInfo } from '@zeal/domains/Currency'
import { Avatar as CurrencyAvatar } from '@zeal/domains/Currency/components/Avatar'
import { CryptoMoney, FiatMoney } from '@zeal/domains/Money'
import { FormattedMoneyPrecise } from '@zeal/domains/Money/components/FormattedMoneyPrecise'
import { Badge } from '@zeal/domains/Network/components/Badge'

type Props = {
    balance: CryptoMoney
    priceInDefaultCurrency: FiatMoney | null
    sign: '+' | '-' | null
    size: 'regular' | 'large'
    onClick?: () => void
    avatarLeftBadge?: ComponentPropsWithoutRef<
        typeof CurrencyAvatar
    >['leftBadge']
    sideRightIcon?: (iconRenderProps: IconRenderProps) => React.ReactNode
}

export const CryptoMoneyListItem = ({
    balance,
    priceInDefaultCurrency,
    sign,
    size,
    onClick,
    avatarLeftBadge,
    sideRightIcon,
}: Props) => {
    const { network } = getCurrencyInfo(balance.currency.id)

    return (
        <ListItem
            onClick={onClick}
            size={size}
            aria-current={false}
            avatar={({ size }) => (
                <CurrencyAvatar
                    size={size}
                    currency={balance.currency}
                    rightBadge={({ size }) => (
                        <Badge size={size} network={network} />
                    )}
                    leftBadge={avatarLeftBadge}
                />
            )}
            primaryText={balance.currency.name}
            shortText={
                <Chain>
                    {balance.currency.code}
                    {network.name}
                </Chain>
            }
            side={{
                title: (
                    <FormattedMoneyPrecise
                        withSymbol={false}
                        sign={sign}
                        money={balance}
                    />
                ),
                subtitle: priceInDefaultCurrency && (
                    <FormattedMoneyPrecise
                        withSymbol
                        sign={sign}
                        money={priceInDefaultCurrency}
                    />
                ),
                rightIcon: sideRightIcon,
            }}
        />
    )
}
