import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgAu = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7086)">
            <Path
                d="M512 256C512 397.384 397.384 512 256 512C114.616 512 0 397.384 0 256C0 256.06 256 0.029 256 0C397.384 0 512 114.616 512 256Z"
                fill="#0052B4"
            />
            <Path
                d="M256 0C255.986 0 255.971 0.001 255.957 0.001L256 0Z"
                fill="#F0F0F0"
            />
            <Path
                d="M255.315 256H256C256 255.768 256 255.546 256 255.315C255.772 255.544 255.544 255.772 255.315 256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 133.566C256 88.521 256 59.004 256 0.000976562H255.957C114.592 0.0239766 0 114.629 0 256H133.565V180.788L208.777 256H255.316C255.545 255.772 255.773 255.544 256.001 255.315C256.001 238.068 256.001 222.679 256.001 208.779L180.788 133.566H256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M129.515 33.3911C89.4756 56.1901 56.1896 89.4761 33.3906 129.515V256H100.174V100.175V100.174H256C256 79.1111 256 59.0451 256 33.3911H129.515Z"
                fill="#D80027"
            />
            <Path
                d="M255.999 224.519L165.046 133.567H133.565C133.565 133.566 133.565 133.567 133.565 133.567L255.998 256H255.999C255.999 256 255.999 234.295 255.999 224.519Z"
                fill="#D80027"
            />
            <Path
                d="M154.395 300.522L168.445 329.9L200.172 322.567L185.964 351.869L211.478 372.102L179.711 379.262L179.8 411.826L154.395 391.453L128.991 411.826L129.08 379.262L97.3115 372.102L122.827 351.869L108.617 322.567L140.346 329.9L154.395 300.522Z"
                fill="#F0F0F0"
            />
            <Path
                d="M383.283 356.174L390.308 370.863L406.172 367.196L399.067 381.847L411.825 391.964L395.941 395.544L395.985 411.826L383.283 401.639L370.581 411.826L370.625 395.544L354.742 391.964L367.499 381.847L360.395 367.196L376.258 370.863L383.283 356.174Z"
                fill="#F0F0F0"
            />
            <Path
                d="M317.933 200.348L324.957 215.038L340.821 211.37L333.717 226.021L346.474 236.138L330.591 239.718L330.634 256L317.933 245.813L305.231 256L305.274 239.718L289.391 236.138L302.148 226.021L295.044 211.37L310.908 215.038L317.933 200.348Z"
                fill="#F0F0F0"
            />
            <Path
                d="M383.283 111.304L390.308 125.994L406.172 122.327L399.068 136.978L411.824 147.094L395.941 150.675L395.985 166.957L383.283 156.77L370.581 166.957L370.625 150.675L354.742 147.094L367.498 136.978L360.395 122.327L376.258 125.994L383.283 111.304Z"
                fill="#F0F0F0"
            />
            <Path
                d="M440.367 178.087L447.391 192.777L463.255 189.109L456.151 203.76L468.908 213.877L453.024 217.458L453.068 233.739L440.367 223.553L427.665 233.739L427.708 217.458L411.825 213.877L424.582 203.76L417.478 189.109L433.341 192.777L440.367 178.087Z"
                fill="#F0F0F0"
            />
            <Path
                d="M399.55 256L405.075 273.006H422.957L408.49 283.517L414.017 300.522L399.55 290.012L385.084 300.522L390.609 283.517L376.143 273.006H394.024L399.55 256Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7086">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgAu
