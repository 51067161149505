import React from 'react'

import { getEnvironment, isProduction } from '../Environment'
import { notReachable } from '../notReachable'
import { ZealPlatform } from '../OS/ZealPlatform'

type Params = {
    dsn: string
    release?: string // TODO @resetko-zeal this got automagically populated in RN, do we want to pass it explicitly?
}

type WrapFunction = <P extends Record<string, unknown>>(
    RootComponent: React.ComponentType<P>
) => React.ComponentType<P>

export const initClientReporting = (
    params: Params
): { wrapperFunction: WrapFunction } => {
    switch (ZealPlatform.OS) {
        case 'ios':
        case 'android':
            const SentryRN = require('@sentry/react-native') // eslint-disable-line @typescript-eslint/no-var-requires
            SentryRN.init({
                dsn: params.dsn,
                debug: !isProduction(),
                environment: getEnvironment(),
            })
            return { wrapperFunction: SentryRN.wrap }

        case 'web':
            const SentryWeb = require('@sentry/react') // eslint-disable-line @typescript-eslint/no-var-requires
            SentryWeb.addTracingExtensions()
            SentryWeb.init({
                dsn: params.dsn,
                debug: !isProduction(),
                environment: getEnvironment(),
                release: params.release,
                integrations: [],
                tracesSampleRate: 0.05,
            })
            return { wrapperFunction: (comp) => comp }

        default:
            return notReachable(ZealPlatform.OS)
    }
}

export const initServerReporting = (
    params: Params
): { wrapperFunction: (app: unknown) => void } => {
    const isNode = process?.versions?.node // TODO @resetko-zeal should we have a helper for this?
    if (!isNode) {
        return { wrapperFunction: () => {} }
    }

    const SentryNode = require('@sentry/node') // eslint-disable-line @typescript-eslint/no-var-requires
    SentryNode.init({
        dsn: params.dsn,
        environment: getEnvironment(),
        release: params.release,
    })

    return {
        wrapperFunction: (app) => {
            SentryNode.setupKoaErrorHandler(app)
        },
    }
}

export const setUserId = (id: string) => {
    switch (ZealPlatform.OS) {
        case 'ios':
        case 'android':
            const SentryRN = require('@sentry/react-native') // eslint-disable-line @typescript-eslint/no-var-requires
            SentryRN.setUser({ id })
            break

        case 'web':
            const SentryWeb = require('@sentry/react') // eslint-disable-line @typescript-eslint/no-var-requires
            SentryWeb.setUser({ id })
            break

        default:
            notReachable(ZealPlatform.OS)
    }
}

export const captureException = (
    error: unknown,
    {
        tags,
        extra,
    }: {
        tags: Record<string, string | number | null>
        extra?: Record<string, unknown>
    }
) => {
    switch (ZealPlatform.OS) {
        case 'web': {
            const isNode = process?.versions?.node // TODO @resetko-zeal should we have a helper for this?

            if (isNode) {
                const SentryNode = require('@sentry/node') // eslint-disable-line @typescript-eslint/no-var-requires
                SentryNode.captureException(error, { tags, extra })
            } else {
                const SentryWeb = require('@sentry/react') // eslint-disable-line @typescript-eslint/no-var-requires
                SentryWeb.captureException(error, { tags, extra })
            }
            break
        }
        case 'ios':
        case 'android':
            const SentryRN = require('@sentry/react-native') // eslint-disable-line @typescript-eslint/no-var-requires
            SentryRN.captureException(error, { tags, extra })
            break
        default:
            notReachable(ZealPlatform.OS)
    }
}
