import { GNOSIS_PAY_BASE_URL } from '@zeal/api/gnosisApi'

import {
    failure,
    match,
    object,
    Result,
    shape,
    string,
    success,
} from '@zeal/toolkit/Result'

import { parseHttpError } from './parseHttpError'

import {
    GnosisPayIsNotAvailableInThisCountry,
    GnosisPayNoActiveCardsFound,
    GnosisPayReadonlySignerIsAlredyInUse,
    GnosisPayUserAlreadyHasMoneriumAccount,
} from '../AppError'

export const parseGnosisPayIsNotAvailableInThisCountry = (
    input: unknown
): Result<unknown, GnosisPayIsNotAvailableInThisCountry> => {
    return parseHttpError(input).andThen((httpError) =>
        shape({
            urlPrefix: httpError.url.startsWith(GNOSIS_PAY_BASE_URL)
                ? success(true)
                : failure({ type: 'not_gnosis_pay_base_url' }),
            status: match(httpError.status, 403),
            payload: object(httpError.data).andThen((data) =>
                shape({
                    code: match(data.code, 'WAFForbidden'),
                    message: match(data.message, 'forbidden'),
                })
            ),
        }).map(
            () =>
                ({
                    type: 'gnosis_pay_is_not_available_in_this_country',
                }) as const
        )
    )
}

export const parseGnosisPayNoActiveCardsFound = (
    input: unknown
): Result<unknown, GnosisPayNoActiveCardsFound> =>
    parseHttpError(input).andThen((httpError) =>
        shape({
            urlPrefix: httpError.url.startsWith(GNOSIS_PAY_BASE_URL)
                ? success(true)
                : failure({ type: 'not_gnosis_pay_base_url' }),
            status: match(httpError.status, 404),
            payload: object(httpError.data).andThen((data) =>
                shape({
                    error: match(data.error, 'No activated cards found'),
                })
            ),
        }).map(
            () =>
                ({
                    type: 'gnosis_pay_no_active_cards_found',
                }) as const
        )
    )

export const parseGnosisPayReadonlySignerIsAlreadyInUse = (
    input: unknown
): Result<unknown, GnosisPayReadonlySignerIsAlredyInUse> =>
    parseHttpError(input).andThen((httpError) =>
        shape({
            urlPrefix: httpError.url.startsWith(GNOSIS_PAY_BASE_URL)
                ? success(true)
                : failure({ type: 'not_gnosis_pay_base_url' }),
            status: match(httpError.status, 422),
            payload: object(httpError.data).andThen((data) =>
                shape({
                    message: match(
                        data.message,
                        'EOA account with this address already exists'
                    ),
                })
            ),
        }).map(
            () =>
                ({
                    type: 'gnosis_pay_readonly_signer_is_already_in_use',
                }) as const
        )
    )

export const parseGnosisPayUserAlreadyHasMoneriumAccount = (
    input: unknown
): Result<unknown, GnosisPayUserAlreadyHasMoneriumAccount> =>
    parseHttpError(input).andThen((httpError) =>
        shape({
            urlPrefix: httpError.url.startsWith(GNOSIS_PAY_BASE_URL)
                ? success(true)
                : failure({ type: 'not_gnosis_pay_base_url' }),
            status: match(httpError.status, 409),
            payload: object(httpError.data).andThen((dataObj) =>
                object(dataObj.data).andThen((data) => string(data.redirectUrl))
            ),
        }).map(
            () =>
                ({
                    type: 'gnosis_pay_user_already_has_monerium_account',
                }) as const
        )
    )
