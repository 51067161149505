import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgKz = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7193)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#338AF3"
            />
            <Path
                d="M400.696 258.783H111.304C111.304 278.762 128.705 294.957 148.682 294.957H147.478C147.478 314.936 163.673 331.131 183.652 331.131C183.652 351.11 199.847 367.305 219.826 367.305H292.174C312.153 367.305 328.348 351.11 328.348 331.131C348.327 331.131 364.522 314.936 364.522 294.957H363.318C383.293 294.957 400.696 278.762 400.696 258.783Z"
                fill="#FFDA44"
            />
            <Path
                d="M356.174 211.478C356.174 266.802 311.324 311.652 256 311.652C200.675 311.652 155.826 266.802 155.826 211.478"
                fill="#338AF3"
            />
            <Path
                d="M332.515 211.478L301.25 226.185L317.899 256.465L283.949 249.97L279.647 284.266L256 259.041L232.352 284.266L228.051 249.97L194.101 256.463L210.749 226.184L179.485 211.478L210.75 196.772L194.101 166.493L228.05 172.987L232.353 138.691L256 163.916L279.648 138.691L283.949 172.987L317.9 166.493L301.251 196.773L332.515 211.478Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7193">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgKz
