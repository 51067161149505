import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgAz = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7088)">
            <Path
                d="M512 256C512 224.686 506.368 194.689 496.077 166.957L256 155.826L15.923 166.956C5.633 194.689 0 224.686 0 256C0 287.314 5.633 317.311 15.923 345.043L256 356.174L496.077 345.044C506.368 317.311 512 287.314 512 256Z"
                fill="#D80027"
            />
            <Path
                d="M256 512C366.071 512 459.906 442.528 496.077 345.043H15.9229C52.0939 442.528 145.929 512 256 512Z"
                fill="#6DA544"
            />
            <Path
                d="M15.9229 166.957H496.078C459.906 69.472 366.071 0 256 0C145.929 0 52.0939 69.472 15.9229 166.957Z"
                fill="#338AF3"
            />
            <Path
                d="M261.565 328.348C221.609 328.348 189.217 295.956 189.217 256C189.217 216.044 221.609 183.652 261.565 183.652C274.023 183.652 285.746 186.802 295.98 192.348C279.924 176.647 257.968 166.956 233.739 166.956C184.561 166.956 144.696 206.822 144.696 255.999C144.696 305.176 184.562 345.042 233.739 345.042C257.969 345.042 279.925 335.351 295.98 319.65C285.746 325.198 274.023 328.348 261.565 328.348Z"
                fill="#F0F0F0"
            />
            <Path
                d="M317.217 205.913L326.8 232.864L352.634 220.582L340.353 246.416L367.304 256L340.353 265.584L352.634 291.418L326.8 279.136L317.217 306.087L307.635 279.136L281.8 291.418L294.082 265.584L267.13 256L294.082 246.416L281.8 220.582L307.635 232.864L317.217 205.913Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7088">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgAz
