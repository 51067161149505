import {
    bigint,
    failure,
    oneOf,
    Result,
    string,
    success,
} from '@zeal/toolkit/Result'

import {
    Network,
    NetworkHexId,
    NetworkMap,
    PredefinedNetwork,
    TestNetwork,
} from '@zeal/domains/Network'
import { PREDEFINED_AND_TEST_NETWORKS } from '@zeal/domains/Network/constants'

const MAPPER: Record<(PredefinedNetwork | TestNetwork)['name'], true> = {
    Ethereum: true,
    Arbitrum: true,
    zkSync: true,
    BSC: true,
    Polygon: true,
    PolygonZkevm: true,
    Linea: true,
    Fantom: true,
    Optimism: true,
    Base: true,
    Blast: true,
    OPBNB: true,
    Gnosis: true,
    Celo: true,
    Avalanche: true,
    Cronos: true,
    Mantle: true,
    Manta: true,
    Aurora: true,
    EthereumSepolia: true,
    AvalancheFuji: true,
    BscTestnet: true,
    FantomTestnet: true,
    AuroraTestnet: true,
}

const parseNetworkHexIdFromNetworkName = (
    input: unknown
): Result<unknown, NetworkHexId> => {
    const networkName = MAPPER[input as keyof typeof MAPPER]
        ? success(input as keyof typeof MAPPER)
        : failure(`cannot map network: ${input}`)

    return networkName.andThen((name) => {
        const network =
            PREDEFINED_AND_TEST_NETWORKS.find(
                (network) => network.name === name
            ) || null

        return network
            ? success(network.hexChainId)
            : failure(`cannot find network: ${name}`)
    })
}

export const parseNetworkFromMapByHexId = ({
    networkMap,
    networkHexId,
}: {
    networkMap: NetworkMap
    networkHexId: NetworkHexId
}): Result<unknown, Network> =>
    networkMap[networkHexId]
        ? success(networkMap[networkHexId])
        : failure({ type: 'network_not_found_in_map', networkHexId })

export const parseNetworkHexId = (
    input: unknown
): Result<unknown, NetworkHexId> =>
    string(input)
        .andThen((str) => bigint(str))
        .andThen((int) => success(`0x${int.toString(16)}` as NetworkHexId))

export const parse = (input: unknown): Result<unknown, NetworkHexId> =>
    oneOf(input, [
        parseNetworkHexIdFromNetworkName(input),
        parseNetworkHexId(input),
    ])
