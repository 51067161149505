import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgKe = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7194)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 0C154.506 0 66.8103 59.065 25.4023 144.696H486.597C445.19 59.065 357.493 0 256 0Z"
                fill="black"
            />
            <Path
                d="M256 512C154.506 512 66.8103 452.935 25.4023 367.304H486.597C445.19 452.935 357.493 512 256 512Z"
                fill="#496E2D"
            />
            <Path
                d="M499.911 178.087H12.089C4.247 202.656 0 228.831 0 256C0 283.169 4.247 309.344 12.089 333.913H499.91C507.753 309.344 512 283.169 512 256C512 228.831 507.753 202.656 499.911 178.087Z"
                fill="#A2001D"
            />
            <Path
                d="M335.715 118.301L305.398 104.308L256 216.159L206.602 104.308L176.285 118.301L237.613 256L176.285 393.699L206.602 407.692L256 295.841L305.398 407.692L335.715 393.699L274.387 256L335.715 118.301Z"
                fill="#F0F0F0"
            />
            <Path
                d="M276.591 131.489C264.855 118.672 256 111.304 256 111.304C256 111.304 247.145 118.671 235.409 131.489V380.509C247.145 393.328 256 400.696 256 400.696C256 400.696 264.855 393.329 276.591 380.51V131.489Z"
                fill="#F0F0F0"
            />
            <Path
                d="M200.348 179.243V332.755C212.75 354.73 227.929 372.742 239.305 384.684V127.315C227.929 139.256 212.749 157.269 200.348 179.243Z"
                fill="#A2001D"
            />
            <Path
                d="M311.651 179.243C299.248 157.268 284.07 139.256 272.694 127.315V384.684C284.069 372.742 299.248 354.73 311.651 332.755V179.243Z"
                fill="#A2001D"
            />
            <Path
                d="M311.652 179.243V332.755C324.207 310.506 333.913 284.197 333.913 256C333.913 227.803 324.207 201.493 311.652 179.243Z"
                fill="black"
            />
            <Path
                d="M200.348 179.243V332.755C187.793 310.506 178.087 284.197 178.087 256C178.087 227.803 187.793 201.493 200.348 179.243Z"
                fill="black"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7194">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgKe
