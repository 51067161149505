import { get } from '@zeal/api/requestBackend'

import { fromFixedWithFraction } from '@zeal/toolkit/BigInt'
import { ImperativeError } from '@zeal/toolkit/Error'
import {
    number,
    object,
    recordStrict,
    Result,
    string,
} from '@zeal/toolkit/Result'

import { DefaultCurrency, FiatCurrency } from '@zeal/domains/Currency'
import { FIAT_CURRENCIES } from '@zeal/domains/Currency/constants'
import { captureError } from '@zeal/domains/Error/helpers/captureError'

import { FXRate2 } from '..'

const parseBTCRates = (
    input: unknown
): Result<unknown, Record<string, number>> =>
    object(input)
        .andThen((obj) => object(obj.rates))
        .andThen((obj) =>
            recordStrict(obj, {
                keyParser: string,
                valueParser: (item) =>
                    object(item).andThen((item) => number(item.value)),
            })
        )

const fetchCoinGeckoBtcRates = async () =>
    get('/proxy/cgv3/exchange_rates', {}).then((data) =>
        parseBTCRates(data).getSuccessResultOrThrow('failed to parse BTC rates')
    )

export const fetchDefaultCurrencyRateToUSD = async ({
    defaultCurrency,
}: {
    defaultCurrency: DefaultCurrency
}): Promise<FXRate2<FiatCurrency, DefaultCurrency> | null> => {
    try {
        const btcRates = await fetchCoinGeckoBtcRates()

        const btcDefaultCurrency =
            btcRates[defaultCurrency.code.toLowerCase()] || null

        const btcUsd = btcRates['usd'] || null

        if (!btcUsd || !btcDefaultCurrency) {
            throw new ImperativeError(
                'Failed to fetch BTC rates for default currency or USD',
                { defaultCurrency }
            )
        }

        return {
            base: FIAT_CURRENCIES.USD,
            quote: defaultCurrency,
            rate: fromFixedWithFraction(
                (btcDefaultCurrency / btcUsd).toFixed(6), // Double precision of coingecko
                defaultCurrency.rateFraction
            ),
        }
    } catch (error) {
        captureError(error)
        return null
    }
}

export const fetchDefaultCurrencyRateToFiatCurrency = async ({
    defaultCurrency,
    fiatCurrency,
}: {
    defaultCurrency: DefaultCurrency
    fiatCurrency: FiatCurrency
}): Promise<FXRate2<FiatCurrency, DefaultCurrency> | null> => {
    try {
        const btcRates = await fetchCoinGeckoBtcRates()

        const btcDefaultCurrency =
            btcRates[defaultCurrency.code.toLowerCase()] || null

        const btcFiatCurrency =
            btcRates[fiatCurrency.code.toLowerCase()] || null

        if (!btcFiatCurrency || !btcDefaultCurrency) {
            throw new ImperativeError(
                'Failed to fetch BTC rates for default currency or fiat currency',
                { defaultCurrency, fiatCurrency }
            )
        }

        return {
            base: fiatCurrency,
            quote: defaultCurrency,
            rate: fromFixedWithFraction(
                (btcDefaultCurrency / btcFiatCurrency).toFixed(6), // Double precision of coingecko
                defaultCurrency.rateFraction
            ),
        }
    } catch (error) {
        captureError(error)
        return null
    }
}
