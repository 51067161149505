import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgVg = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7109)">
            <Path
                d="M512 256C512 397.384 397.384 512 256 512C114.616 512 0 397.384 0 256C0 256.061 256 0.028 256 0C397.384 0 512 114.616 512 256Z"
                fill="#0052B4"
            />
            <Path
                d="M255.315 256H256C256 255.77 256 255.546 256 255.315C255.772 255.544 255.544 255.772 255.315 256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 133.565C256 88.52 256 59.005 256 0H255.957C114.591 0.024 0 114.629 0 256H133.565V180.789L208.776 256H255.316C255.544 255.772 255.772 255.544 256.001 255.315C256.001 238.068 256.001 222.679 256.001 208.778L180.788 133.565H256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M129.515 33.3911C89.4756 56.1901 56.1886 89.4761 33.3906 129.515V256H100.174V100.176V100.174H256C256 79.1111 256 59.0451 256 33.3911H129.515Z"
                fill="#D80027"
            />
            <Path
                d="M367.304 256L298.111 325.193C311.079 350.204 337.187 367.304 367.304 367.304C397.421 367.304 423.529 350.204 436.497 325.193L367.304 256Z"
                fill="#FFDA44"
            />
            <Path
                d="M256 224.519L165.045 133.566H133.565V133.568L255.998 256H256C256 256 256 234.293 256 224.519Z"
                fill="#D80027"
            />
            <Path
                d="M289.391 133.565V255.999V256C289.392 315.639 367.304 333.913 367.304 333.913C367.304 333.913 445.216 315.639 445.217 256V255.999V133.565H289.391Z"
                fill="#6DA544"
            />
            <Path
                d="M350.609 189.217H384V278.26H350.609V189.217Z"
                fill="#F0F0F0"
            />
            <Path
                d="M367.304 205.913C376.525 205.913 384 198.438 384 189.217C384 179.996 376.525 172.521 367.304 172.521C358.083 172.521 350.608 179.996 350.608 189.217C350.608 198.438 358.083 205.913 367.304 205.913Z"
                fill="#A2001D"
            />
            <Path
                d="M311.652 155.826H333.913V178.087H311.652V155.826Z"
                fill="#FFDA44"
            />
            <Path
                d="M311.652 205.913H333.913V228.174H311.652V205.913Z"
                fill="#FFDA44"
            />
            <Path
                d="M311.652 256H333.913V278.261H311.652V256Z"
                fill="#FFDA44"
            />
            <Path
                d="M400.696 155.826H422.957V178.087H400.696V155.826Z"
                fill="#FFDA44"
            />
            <Path
                d="M400.696 205.913H422.957V228.174H400.696V205.913Z"
                fill="#FFDA44"
            />
            <Path
                d="M400.696 256H422.957V278.261H400.696V256Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7109">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgVg
