import Big from 'big.js'

import { Currency } from '@zeal/domains/Currency'
import { FXRate2 } from '@zeal/domains/FXRate'
import { MoneyByCurrency } from '@zeal/domains/Money'

export const mergeRates = <
    B extends Currency,
    Q extends Currency,
    R extends Currency,
>({
    rateA,
    rateB,
}: {
    rateA: FXRate2<B, Q>
    rateB: FXRate2<Q, R>
}): FXRate2<B, R> => {
    const rateABig = Big(rateA.rate.toString()).div(
        Big(10).pow(rateA.quote.fraction)
    )

    const rateBBig = Big(rateB.rate.toString()).div(
        Big(10).pow(rateB.base.fraction)
    )

    return {
        base: rateA.base,
        quote: rateB.quote,
        rate: BigInt(
            rateABig
                .mul(rateBBig)
                .mul(10 ** rateB.quote.fraction)
                .toFixed(0)
        ),
    }
}

export const applyRate2 = <B extends Currency, Q extends Currency>({
    baseAmount,
    rate,
    extraRatePrecision,
}: {
    rate: FXRate2<B, Q>
    baseAmount: MoneyByCurrency<B>
    extraRatePrecision?: number // TODO @resetko-zeal consider decoupling rateFraction from currency and putting it in FXRate type, so we don't need this
}): MoneyByCurrency<Q> => {
    const { base, quote } = rate

    const amount = BigInt(
        Big(baseAmount.amount.toString())
            .div(Big(10).pow(base.fraction))
            .mul(rate.rate.toString())
            .div(Big(10).pow(extraRatePrecision || 0))
            .toFixed(0)
    )

    return {
        amount,
        currency: quote,
    } as MoneyByCurrency<Q>
}

export const applyNullableRate = <B extends Currency, Q extends Currency>({
    baseAmount,
    rate,
    extraRatePrecision,
}: {
    rate: FXRate2<B, Q> | null
    baseAmount: MoneyByCurrency<B> | null
    extraRatePrecision?: number
}): MoneyByCurrency<Q> | null =>
    rate && baseAmount
        ? applyRate2({ baseAmount, rate, extraRatePrecision })
        : null
