import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { ActionBar as UIActionBar } from '@zeal/uikit/ActionBar'
import { Actions } from '@zeal/uikit/Actions'
import { Button } from '@zeal/uikit/Button'
import { Column } from '@zeal/uikit/Column'
import { Content } from '@zeal/uikit/Content'
import { Row } from '@zeal/uikit/Row'
import { Screen } from '@zeal/uikit/Screen'
import { Text } from '@zeal/uikit/Text'

import { notReachable } from '@zeal/toolkit'
import { useLoadableData } from '@zeal/toolkit/LoadableData/LoadableData'
import { useLiveRef } from '@zeal/toolkit/React'

import { format } from '@zeal/domains/Address/helpers/format'
import { FormattedMoneyPrecise } from '@zeal/domains/Money/components/FormattedMoneyPrecise'
import { NetworkRPCMap } from '@zeal/domains/Network'
import { Avatar } from '@zeal/domains/Network/components/Avatar'
import { createERC20EthSendTransaction } from '@zeal/domains/RPCRequest/helpers/createERC20EthSendTransaction'
import { SubmitedTransactionQueued } from '@zeal/domains/TransactionRequest/domains/SubmitedTransaction'
import { postUserEvent } from '@zeal/domains/UserEvents/api/postUserEvent'

import { ExternalWalletAvatar } from '../../../components/ExternalWalletAvatar'
import { TransactionSentToWalletPopup } from '../../../components/TransactionSentToWalletPopup'
import { SendTransactionToExternalWallet } from '../../../types'
import { Header } from '../../components/Header'
import { TopUpRequest } from '../TopUpRequest'

type Props = {
    sendTransaction: SendTransactionToExternalWallet
    topUpRequest: TopUpRequest
    networkRPCMap: NetworkRPCMap
    installationId: string
    onMsg: (msg: Msg) => void
}

type Msg =
    | { type: 'close' }
    | { type: 'on_transaction_rejected' }
    | {
          type: 'on_transaction_submitted'
          submittedTransaction: SubmitedTransactionQueued
      }

export const SubmitTransaction = ({
    topUpRequest,
    sendTransaction,
    onMsg,
    networkRPCMap,
    installationId,
}: Props) => {
    const [loadable] = useLoadableData(sendTransaction, {
        type: 'loading',
        params: {
            network: topUpRequest.network,
            request: createERC20EthSendTransaction({
                fromAccount: topUpRequest.fromAccount,
                toAddress: topUpRequest.account.address,
                network: topUpRequest.network,
                networkRPCMap,
                amount: {
                    amount: topUpRequest.amount.amount,
                    currencyId: topUpRequest.amount.currency.id,
                },
                knownCurrencies: {
                    [topUpRequest.amount.currency.id]:
                        topUpRequest.amount.currency,
                },
            }),
        },
    })

    const onMsgLive = useLiveRef(onMsg)

    useEffect(() => {
        switch (loadable.type) {
            case 'loading':
                break
            case 'loaded':
                postUserEvent({
                    type: 'TopUpTransactionSubmittedEvent',
                    network: topUpRequest.network.hexChainId,
                    installationId,
                })

                onMsgLive.current({
                    type: 'on_transaction_submitted',
                    submittedTransaction: {
                        state: 'queued',
                        queuedAt: Date.now(),
                        hash: loadable.data.transactionHash,
                        submittedNonce: loadable.data.submittedNonce,
                    },
                })
                break
            case 'error':
                // There is currently no clean way to distinguish between a transaction being rejected in the wallet vs some other error occurring since different wallets respond differently.
                onMsgLive.current({
                    type: 'on_transaction_rejected',
                })
                break
            /* istanbul ignore next */
            default:
                return notReachable(loadable)
        }
    }, [loadable, onMsgLive, topUpRequest.network.hexChainId, installationId])

    return (
        <>
            <Screen padding="form" background="light" onNavigateBack={null}>
                <Column spacing={0}>
                    <UIActionBar
                        left={
                            <Row spacing={8}>
                                <ExternalWalletAvatar
                                    fromAccount={topUpRequest.fromAccount}
                                    size={24}
                                />

                                <Text
                                    variant="paragraph"
                                    weight="medium"
                                    color="textSecondary"
                                >
                                    {format(topUpRequest.fromAccount.address)}
                                </Text>
                            </Row>
                        }
                        size="small"
                        right={null}
                    />
                    <UIActionBar
                        left={<Header onMsg={onMsg} />}
                        right={
                            <Avatar
                                currentNetwork={{
                                    type: 'specific_network',
                                    network: topUpRequest.network,
                                }}
                                size={24}
                            />
                        }
                    />
                </Column>
                <Column spacing={12} fill>
                    <Content
                        header={
                            <Content.Header
                                title={
                                    <FormattedMessage
                                        id="topup.send.title"
                                        defaultMessage="Send"
                                    />
                                }
                            />
                        }
                    >
                        <Content.Splash
                            onAnimationComplete={null}
                            variant="spinner"
                            title={
                                <FormattedMessage
                                    id="topup.continue-in-wallet"
                                    defaultMessage="Continue in your wallet"
                                />
                            }
                        />
                    </Content>

                    <Actions>
                        <Button
                            variant="secondary"
                            size="regular"
                            onClick={() => {
                                onMsg({ type: 'close' })
                            }}
                        >
                            <FormattedMessage
                                id="topup.submit-transaction.close"
                                defaultMessage="Close"
                            />
                        </Button>
                    </Actions>
                </Column>
            </Screen>
            <TransactionSentToWalletPopup
                onMsg={onMsg}
                fromAccount={topUpRequest.fromAccount}
                title={
                    <FormattedMessage
                        id="topup.submit-transaction.sent-to-wallet"
                        defaultMessage="Send {amount}"
                        values={{
                            amount: (
                                <FormattedMoneyPrecise
                                    withSymbol
                                    sign={null}
                                    money={topUpRequest.amount}
                                />
                            ),
                        }}
                    />
                }
            />
        </>
    )
}
