import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgIo = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7108)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M469.33 286.637C447.997 286.637 447.997 267.131 426.663 267.131C405.33 267.131 405.33 286.637 383.998 286.637C362.666 286.637 362.665 267.131 341.333 267.131C320 267.131 320 286.637 298.666 286.637C277.332 286.637 277.331 267.131 255.996 267.131C234.663 267.131 234.663 286.637 213.331 286.637C192.001 286.637 192.001 267.131 170.668 267.131C149.333 267.131 149.333 286.637 127.998 286.637C106.663 286.637 106.663 267.131 85.328 267.131C63.998 267.131 63.998 286.637 42.665 286.637C21.447 286.637 21.324 267.346 0.333008 267.14C0.924008 278.849 2.32201 290.346 4.43601 301.589C21.44 304.086 22.929 320.769 42.667 320.769C64 320.769 64 301.265 85.33 301.265C106.665 301.265 106.665 320.769 128 320.769C149.335 320.769 149.335 301.265 170.67 301.265C192.003 301.265 192.003 320.769 213.333 320.769C234.666 320.769 234.666 301.265 255.998 301.265C277.333 301.265 277.333 320.769 298.668 320.769C320.003 320.769 320.003 301.265 341.335 301.265C362.668 301.265 362.668 320.769 384 320.769C405.333 320.769 405.333 301.265 426.665 301.265C448 301.265 448 320.769 469.332 320.769C489.071 320.769 490.56 304.087 507.565 301.589C509.68 290.345 511.076 278.85 511.67 267.14C490.674 267.347 490.549 286.637 469.33 286.637Z"
                fill="#0052B4"
            />
            <Path
                d="M426.663 333.913C405.33 333.913 405.33 353.419 383.998 353.419C362.666 353.419 362.665 333.913 341.333 333.913C320 333.913 320 353.419 298.666 353.419C277.331 353.419 277.331 333.913 255.996 333.913C234.663 333.913 234.663 353.419 213.331 353.419C192.001 353.419 192.001 333.913 170.668 333.913C149.333 333.913 149.333 353.419 127.998 353.419C106.663 353.419 106.663 333.913 85.3277 333.913C63.9977 333.913 63.9977 353.419 42.6647 353.419C26.9497 353.419 22.8007 342.841 13.1777 337.269C19.0057 354.688 26.6607 371.267 35.9057 386.791C37.9077 387.274 40.1337 387.552 42.6637 387.552C63.9967 387.552 63.9967 368.048 85.3267 368.048C106.662 368.048 106.662 387.552 127.997 387.552C149.332 387.552 149.332 368.048 170.667 368.048C192 368.048 192 387.552 213.33 387.552C234.663 387.552 234.663 368.048 255.995 368.048C277.33 368.048 277.33 387.552 298.665 387.552C320 387.552 320 368.048 341.332 368.048C362.665 368.048 362.665 387.552 383.997 387.552C405.329 387.552 405.33 368.048 426.662 368.048C447.997 368.048 447.997 387.552 469.329 387.552C471.861 387.552 474.089 387.274 476.092 386.79C485.338 371.267 492.992 354.688 498.819 337.27C489.196 342.842 485.046 353.42 469.329 353.42C447.998 353.419 447.998 333.913 426.663 333.913Z"
                fill="#0052B4"
            />
            <Path
                d="M506.044 200.941C490.45 204.178 488.462 219.854 469.33 219.854C447.997 219.854 447.997 200.348 426.663 200.348C405.33 200.348 405.33 219.854 383.998 219.854C362.666 219.854 362.665 200.348 341.333 200.348C320 200.348 320 219.854 298.666 219.854C277.332 219.854 277.331 200.348 255.996 200.348C244.469 200.348 239.169 206.042 233.368 211.278V245.411C239.169 240.176 244.468 234.483 255.996 234.483C277.331 234.483 277.331 253.987 298.666 253.987C320.001 253.987 320.001 234.483 341.333 234.483C362.666 234.483 362.666 253.987 383.998 253.987C405.33 253.987 405.331 234.483 426.663 234.483C447.998 234.483 447.998 253.987 469.33 253.987C489.069 253.987 490.558 237.305 507.563 234.807C508.653 234.807 509.871 234.796 511.118 234.781C510.173 223.268 508.463 211.972 506.044 200.941Z"
                fill="#0052B4"
            />
            <Path
                d="M487.344 146.274C482.923 149.987 477.813 153.071 469.331 153.071C447.998 153.071 447.998 133.565 426.664 133.565C405.331 133.565 405.331 153.071 383.999 153.071C362.667 153.071 362.666 133.565 341.334 133.565C320.001 133.565 320.001 153.071 298.667 153.071C277.333 153.071 277.332 133.565 255.997 133.565C244.47 133.565 239.17 139.259 233.369 144.495V178.628C239.17 173.393 244.469 167.7 255.997 167.7C277.332 167.7 277.332 187.204 298.667 187.204C320 187.204 320 167.7 341.333 167.7C362.666 167.7 362.666 187.204 383.998 187.204C405.33 187.204 405.33 167.7 426.663 167.7C447.998 167.7 447.998 187.204 469.33 187.204C484.467 187.204 488.876 177.399 497.776 171.697C494.743 163 491.26 154.515 487.344 146.274Z"
                fill="#0052B4"
            />
            <Path
                d="M428.485 66.835C427.893 66.803 427.29 66.783 426.663 66.783C405.33 66.783 405.33 86.289 383.998 86.289C362.665 86.289 362.665 66.783 341.333 66.783C320 66.783 320 86.289 298.665 86.289C277.33 86.289 277.33 66.783 255.995 66.783C244.468 66.783 239.168 72.477 233.367 77.713V111.846C239.168 106.611 244.467 100.918 255.995 100.918C277.33 100.918 277.33 120.422 298.665 120.422C320 120.422 320 100.918 341.332 100.918C362.665 100.918 362.665 120.422 383.997 120.422C405.329 120.422 405.33 100.918 426.662 100.918C447.997 100.918 447.997 120.422 469.329 120.422C470.643 120.422 471.87 120.344 473.032 120.205C460.641 100.441 445.637 82.484 428.485 66.835Z"
                fill="#0052B4"
            />
            <Path
                d="M321.432 8.45395C315.576 13.722 310.284 19.508 298.665 19.508C277.33 19.508 277.33 0.00395313 255.996 0.00195312H255.992C244.96 0.00295313 239.634 5.21995 234.11 10.259V44.393C239.633 39.354 244.961 34.136 255.996 34.136C277.331 34.136 277.331 53.64 298.666 53.64C320 53.64 320 34.136 341.333 34.136C362.666 34.136 362.666 53.64 383.998 53.64C392.949 53.64 398.141 50.204 402.733 46.218C378.397 29.163 350.959 16.237 321.432 8.45395Z"
                fill="#0052B4"
            />
            <Path
                d="M298.665 486.984C277.33 486.984 277.33 467.478 255.995 467.478C234.662 467.478 234.662 486.984 213.33 486.984C192 486.984 192 467.478 170.667 467.478C154.326 467.478 150.493 478.912 140.013 484.27C168.913 498.984 200.98 508.363 234.92 511.13C240.169 506.334 245.501 501.612 255.995 501.612C266.492 501.612 271.824 506.334 277.074 511.131C311.015 508.365 343.084 498.985 371.985 484.271C361.503 478.914 357.671 467.477 341.332 467.477C320 467.478 320 486.984 298.665 486.984Z"
                fill="#0052B4"
            />
            <Path
                d="M383.998 420.202C362.665 420.202 362.665 400.696 341.333 400.696C320 400.696 320 420.202 298.666 420.202C277.331 420.202 277.331 400.696 255.996 400.696C234.663 400.696 234.663 420.202 213.331 420.202C192.001 420.202 192.001 400.696 170.668 400.696C149.333 400.696 149.333 420.202 127.998 420.202C106.663 420.202 106.663 400.696 85.3282 400.696C69.9872 400.696 65.6792 410.786 56.5342 416.451C62.2662 423.569 68.3602 430.383 74.8092 436.843C77.6982 435.609 81.0882 434.83 85.3272 434.83C106.662 434.83 106.662 454.334 127.997 454.334C149.332 454.334 149.332 434.83 170.667 434.83C192 434.83 192 454.334 213.33 454.334C234.663 454.334 234.663 434.83 255.995 434.83C277.33 434.83 277.33 454.334 298.665 454.334C320 454.334 320 434.83 341.332 434.83C362.664 434.83 362.665 454.334 383.997 454.334C405.329 454.334 405.33 434.83 426.662 434.83C430.906 434.83 434.296 435.609 437.187 436.845C443.637 430.384 449.731 423.57 455.463 416.452C446.317 410.788 442.009 400.697 426.663 400.697C405.33 400.696 405.33 420.202 383.998 420.202Z"
                fill="#0052B4"
            />
            <Path
                d="M255.315 256H256C256 255.77 256 255.546 256 255.315C255.772 255.544 255.544 255.772 255.315 256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 133.565V208.778L166.957 122.435L256 133.565Z"
                fill="#0052B4"
            />
            <Path
                d="M133.566 256H208.776L133.565 155.826L122.435 233.739L133.566 256Z"
                fill="#0052B4"
            />
            <Path
                d="M256 133.565C256 88.52 256 59.005 256 0H255.957C114.592 0.024 0 114.629 0 256H133.566V180.789L208.776 256H255.316C255.544 255.772 255.772 255.544 256.001 255.315C256.001 238.068 256.001 222.679 256.001 208.778L180.788 133.565H256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M129.515 33.3911C89.4756 56.1901 56.1896 89.4761 33.3906 129.515V256H100.174V100.176V100.174H256C256 79.1111 256 59.0451 256 33.3911H129.515Z"
                fill="#D80027"
            />
            <Path
                d="M255.999 224.519L165.044 133.566H133.564V133.568L255.997 256H255.999C255.999 256 255.999 234.293 255.999 224.519Z"
                fill="#D80027"
            />
            <Path
                d="M333.913 133.565H434.087L384 183.652L333.913 133.565Z"
                fill="#6DA544"
            />
            <Path
                d="M372.87 172.522H395.131V233.739H372.87V172.522Z"
                fill="#A2001D"
            />
            <Path
                d="M372.87 333.913H395.131V395.13H372.87V333.913Z"
                fill="#A2001D"
            />
            <Path
                d="M450.783 300.522C450.783 282.081 435.833 267.131 417.392 267.131C408.838 267.131 401.04 270.351 395.131 275.64V256H406.261V233.739H395.131V222.609H372.87V233.739H361.74V256H372.87V275.64C366.961 270.351 359.163 267.131 350.609 267.131C332.168 267.131 317.218 282.081 317.218 300.522C317.218 310.409 321.519 319.289 328.348 325.404V345.044H439.652V325.404C446.482 319.289 450.783 310.409 450.783 300.522Z"
                fill="#FFDA44"
            />
            <Path
                d="M417.391 289.391C413.88 289.391 411.422 290.933 409.977 292.225L395.13 305.516H372.87L358.023 292.225C356.578 290.933 354.12 289.391 350.609 289.391C344.472 289.391 339.479 294.384 339.479 300.521C339.479 304.844 341.81 307.575 343.199 308.82L350.609 315.456V322.782H417.393V315.456L424.803 308.82C426.192 307.576 428.523 304.844 428.523 300.521C428.521 294.384 423.529 289.391 417.391 289.391Z"
                fill="#D80027"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7108">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgIo
