import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgPh = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7256)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 0V256L74.98 437.02C121.307 483.346 185.307 512 256 512C397.384 512 512 397.384 512 256C512 114.616 256 0 256 0Z"
                fill="#D80027"
            />
            <Path
                d="M256 0C185.307 0 121.307 28.654 74.98 74.98C137.79 137.79 160.087 160.088 256 256H512C512 114.616 397.384 0 256 0Z"
                fill="#0052B4"
            />
            <Path
                d="M175.291 256L144.027 241.294L160.676 211.015L126.726 217.51L122.424 183.213L98.776 208.437L75.129 183.213L70.826 217.51L36.877 211.014L53.526 241.294L22.261 256L53.526 270.706L36.877 300.985L70.829 294.491L75.128 328.787L98.776 303.563L122.424 328.787L126.726 294.491L160.674 300.986L144.026 270.707L175.291 256Z"
                fill="#FFDA44"
            />
            <Path
                d="M68.273 100.174L78.677 114.717L95.722 109.318L85.104 123.706L95.508 138.249L78.542 132.598L67.925 146.985L68.058 129.105L51.093 123.453L68.141 118.054L68.273 100.174Z"
                fill="#FFDA44"
            />
            <Path
                d="M68.273 365.015L78.677 379.558L95.722 374.158L85.104 388.547L95.508 403.09L78.542 397.439L67.925 411.826L68.058 393.946L51.093 388.294L68.141 382.895L68.273 365.015Z"
                fill="#FFDA44"
            />
            <Path
                d="M216.666 232.595L206.263 247.138L189.217 241.737L199.835 256.126L189.431 270.67L206.397 265.018L217.015 279.405L216.881 261.526L233.846 255.874L216.799 250.475L216.666 232.595Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7256">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgPh
