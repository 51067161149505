import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgGg = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 513" fill="none">
        <G clipPath="url(#clip0_4_7170)">
            <Path
                d="M256 512.989C397.385 512.989 512 398.374 512 256.989C512 115.604 397.385 0.989258 256 0.989258C114.615 0.989258 0 115.604 0 256.989C0 398.374 114.615 512.989 256 512.989Z"
                fill="#F0F0F0"
            />
            <Path
                d="M509.833 223.598H289.393H289.392V3.15626C278.461 1.73326 267.317 0.989258 256 0.989258C244.683 0.989258 233.539 1.73326 222.609 3.15626V223.598H2.167C0.744 234.528 0 245.672 0 256.989C0 268.306 0.744 279.45 2.167 290.38H222.607H222.608V510.822C233.539 512.245 244.683 512.989 256 512.989C267.317 512.989 278.461 512.246 289.391 510.822V290.383V290.381H509.833C511.256 279.45 512 268.306 512 256.989C512 245.672 511.256 234.528 509.833 223.598Z"
                fill="#D80027"
            />
            <Path
                d="M328.348 268.119L345.043 279.25V234.728L328.348 245.859H267.13V184.641L278.261 167.946H233.739L244.87 184.641V245.859H183.652L166.957 234.728V279.25L183.652 268.119H244.87V329.337L233.739 346.032H278.261L267.13 329.337V268.119H328.348Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7170">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0 0.989258)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgGg
