import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgDm = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 513" fill="none">
        <G clipPath="url(#clip0_4_7140)">
            <Path
                d="M256 512.989C397.385 512.989 512 398.374 512 256.989C512 115.604 397.385 0.989258 256 0.989258C114.615 0.989258 0 115.604 0 256.989C0 398.374 114.615 512.989 256 512.989Z"
                fill="#F0F0F0"
            />
            <Path
                d="M306.087 508.076C407.29 488 487.011 408.278 507.087 307.076H306.087V508.076Z"
                fill="#496E2D"
            />
            <Path
                d="M4.91309 307.076C24.9881 408.278 104.71 488 205.913 508.076V307.076H4.91309Z"
                fill="#496E2D"
            />
            <Path
                d="M507.087 206.902C487.011 105.7 407.29 25.9783 306.087 5.90228V206.902H507.087Z"
                fill="#496E2D"
            />
            <Path
                d="M205.913 5.90228C104.71 25.9783 24.9881 105.7 4.91309 206.902H205.913V5.90228Z"
                fill="#496E2D"
            />
            <Path
                d="M239.304 307.076H205.913V508.076C216.81 510.237 227.957 511.701 239.304 512.432V307.076Z"
                fill="#FFDA44"
            />
            <Path
                d="M239.304 206.902V1.54626C227.957 2.27626 216.809 3.74126 205.913 5.90226V206.902H239.304Z"
                fill="#FFDA44"
            />
            <Path
                d="M272.696 1.54626C267.174 1.18926 261.611 0.989258 256 0.989258C250.389 0.989258 244.826 1.18926 239.304 1.54626V206.903H272.695V1.54626H272.696Z"
                fill="black"
            />
            <Path
                d="M239.304 512.432C244.826 512.789 250.389 512.989 256 512.989C261.611 512.989 267.174 512.789 272.696 512.432V307.076H239.305V512.432H239.304Z"
                fill="black"
            />
            <Path
                d="M205.913 240.293V206.902H4.91264C2.75064 217.8 1.28664 228.946 0.556641 240.293H205.913Z"
                fill="#FFDA44"
            />
            <Path
                d="M306.087 240.293H511.444C510.714 228.946 509.249 217.8 507.088 206.902H306.088V240.293H306.087Z"
                fill="#FFDA44"
            />
            <Path
                d="M306.087 273.685H511.444C511.8 268.164 512 262.6 512 256.989C512 251.378 511.8 245.814 511.443 240.293H306.087V273.685Z"
                fill="black"
            />
            <Path
                d="M0.557 240.293C0.201 245.814 0 251.378 0 256.989C0 262.6 0.201 268.164 0.557 273.685H205.914V240.294H0.557V240.293Z"
                fill="black"
            />
            <Path
                d="M256 379.424C323.619 379.424 378.435 324.608 378.435 256.989C378.435 189.37 323.619 134.554 256 134.554C188.381 134.554 133.565 189.37 133.565 256.989C133.565 324.608 188.381 379.424 256 379.424Z"
                fill="#D80027"
            />
            <Path
                d="M283.894 270.649C274.787 252.436 262.95 233.976 262.95 233.976C262.95 233.976 263.244 225.571 263.244 220.563C263.244 213.019 257.128 206.903 249.584 206.903C242.437 206.903 236.581 212.394 235.984 219.385C230.364 219.752 225.917 224.414 225.917 230.126C225.917 234.279 228.27 237.878 231.714 239.677C233.426 235.673 236.778 232.547 240.924 231.122C241.6 231.675 242.326 232.169 243.101 232.588L244.851 233.426C244.851 233.426 238.362 251.717 238.362 261.543C238.362 289.167 256.576 297.686 256.576 297.686V297.971L247.469 307.077H265.682V288.863L274.789 297.97C274.869 297.799 292.039 286.94 283.894 270.649Z"
                fill="#496E2D"
            />
            <Path
                d="M255.999 156.815L260.143 169.569H273.553L262.704 177.452L266.849 190.206L255.999 182.324L245.149 190.206L249.294 177.452L238.445 169.569H251.855L255.999 156.815Z"
                fill="#496E2D"
            />
            <Path
                d="M197.119 175.947L207.967 183.83L218.817 175.946L214.673 188.702L225.523 196.583L212.112 196.584L207.968 209.338L203.823 196.584L190.413 196.583L201.262 188.7L197.119 175.947Z"
                fill="#496E2D"
            />
            <Path
                d="M160.729 226.034H174.138L178.283 213.28L182.427 226.034L195.838 226.033L184.989 233.917L189.134 246.671L178.283 238.788L167.434 246.67L171.577 233.916L160.729 226.034Z"
                fill="#496E2D"
            />
            <Path
                d="M160.729 287.944L171.577 280.063L167.433 267.308L178.283 275.191L189.133 267.308L184.989 280.062L195.839 287.945L182.427 287.946L178.283 300.699L174.138 287.944H160.729Z"
                fill="#496E2D"
            />
            <Path
                d="M197.119 338.031L201.262 325.279L190.412 317.396H203.823L207.968 304.641L212.112 317.395H225.523L214.673 325.279L218.817 338.032L207.967 330.149L197.119 338.031Z"
                fill="#496E2D"
            />
            <Path
                d="M255.999 357.163L251.855 344.411H238.445L249.294 336.527L245.149 323.773L255.999 331.654L266.848 323.772L262.704 336.527L273.553 344.41L260.143 344.411L255.999 357.163Z"
                fill="#496E2D"
            />
            <Path
                d="M314.88 338.031L304.032 330.149L293.183 338.033L297.326 325.279L286.476 317.396L299.887 317.395L304.03 304.641L308.176 317.396H321.586L310.737 325.279L314.88 338.031Z"
                fill="#496E2D"
            />
            <Path
                d="M351.27 287.944H337.861L333.716 300.699L329.572 287.946H316.16L327.01 280.062L322.865 267.309L333.716 275.191L344.564 267.309L340.422 280.063L351.27 287.944Z"
                fill="#496E2D"
            />
            <Path
                d="M351.27 226.034L340.422 233.916L344.565 246.67L333.716 238.788L322.866 246.672L327.01 233.917L316.16 226.035L329.572 226.033L333.715 213.281L337.861 226.034H351.27Z"
                fill="#496E2D"
            />
            <Path
                d="M314.88 175.947L310.737 188.7L321.586 196.583H308.176L304.032 209.338L299.887 196.584L286.476 196.585L297.326 188.7L293.182 175.948L304.032 183.829L314.88 175.947Z"
                fill="#496E2D"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7140">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0 0.989258)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgDm
