import React from 'react'

import { Column } from '@zeal/uikit/Column'
import { Screen } from '@zeal/uikit/Screen'
import { Spacer } from '@zeal/uikit/Spacer'
import { Spinner } from '@zeal/uikit/Spinner'

import { Text } from './Text'

type Props = {
    actionBar: React.ReactNode
    title: React.ReactNode | null
    onClose: (() => void) | null
}

export const LoadingLayout = ({ actionBar, title, onClose }: Props) => (
    <Screen background="light" padding="form" onNavigateBack={onClose}>
        {actionBar}

        <Spacer />

        <Column alignX="center" spacing={16}>
            <Spinner size={72} color="iconStatusNeutral" />
            {title && (
                <Text variant="title2" weight="bold" color="textPrimary">
                    {title}
                </Text>
            )}
        </Column>

        <Spacer />
    </Screen>
)
