import { notReachable } from '@zeal/toolkit'
import { ImperativeError } from '@zeal/toolkit/Error'

import {
    CurrencyId,
    FiatCurrency,
    KnownCurrencies,
} from '@zeal/domains/Currency'

export const getFiatCurrencyOrThrow = ({
    fiatCurrencyId,
    knownCurrencies,
}: {
    fiatCurrencyId: CurrencyId
    knownCurrencies: KnownCurrencies
}): FiatCurrency => {
    const currency = knownCurrencies[fiatCurrencyId]
    if (!currency) {
        throw new ImperativeError('currency is missing in `knownCurrencies`', {
            fiatCurrencyId,
        })
    }

    switch (currency.type) {
        case 'FiatCurrency':
            return currency

        case 'CryptoCurrency':
            throw new ImperativeError('CryptoCurrency can not be here')
        /* istanbul ignore next */
        default:
            return notReachable(currency)
    }
}
