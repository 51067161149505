import { FormatNumberOptions, useIntl } from 'react-intl'

import { Big } from 'big.js'

import { notReachable } from '@zeal/toolkit'
import { toNumberWithFraction } from '@zeal/toolkit/BigInt'

import { CryptoCurrency, FiatCurrency } from '@zeal/domains/Currency'
import { FXRate2 } from '@zeal/domains/FXRate'

const NON_BREAKING_SPACE = '\u00A0'

export const FormattedCryptoToFiatRate = <
    B extends CryptoCurrency,
    Q extends FiatCurrency,
>({
    rate,
    variant,
}: {
    variant: 'just_quote' | 'base_and_quote'
    rate: FXRate2<B, Q>
}) => {
    const { formatNumber } = useIntl()
    const { quote, base } = rate

    const amount = new Big(rate.rate.toString()).div(
        Math.pow(10, quote.rateFraction)
    )

    const options: Parameters<typeof formatNumber>[1] = {}

    if (amount.lt(0.001)) {
        options.maximumFractionDigits = 8
        options.maximumSignificantDigits = 1
    } else if (amount.lt(0.01)) {
        options.minimumSignificantDigits = 1
        options.maximumSignificantDigits = 2
    } else if (amount.lt(1)) {
        options.maximumSignificantDigits = 2
    } else if (amount.lt(10)) {
        options.minimumFractionDigits = 2
        options.maximumFractionDigits = 2
    } else if (amount.lt(100)) {
        options.minimumFractionDigits = 1
        options.maximumFractionDigits = 1
    } else {
        options.minimumFractionDigits = 0
        options.maximumFractionDigits = 0
    }

    const formattedQuote = formatNumber(amount.toNumber(), options)

    switch (variant) {
        case 'just_quote':
            return <>{`${quote.symbol}${formattedQuote}`}</>
        case 'base_and_quote': {
            return (
                <>{`1${NON_BREAKING_SPACE}${base.symbol}${NON_BREAKING_SPACE}=${NON_BREAKING_SPACE}${quote.symbol}${formattedQuote}`}</>
            )
        }
        default:
            return notReachable(variant)
    }
}

export const FormattedFiatToCryptoRate = <
    B extends FiatCurrency,
    Q extends CryptoCurrency,
>({
    rate,
    variant,
}: {
    variant: 'just_quote' | 'base_and_quote'
    rate: FXRate2<B, Q>
}) => {
    const { formatNumber } = useIntl()
    const { quote, base } = rate

    const amount = new Big(rate.rate.toString()).div(
        Math.pow(10, quote.rateFraction)
    )

    const options: Parameters<typeof formatNumber>[1] = {}

    if (amount.lt(0.001)) {
        options.maximumFractionDigits = 8
        options.maximumSignificantDigits = 1
    } else if (amount.lt(1)) {
        options.minimumSignificantDigits = 1
        options.maximumSignificantDigits = 2
    } else if (amount.lt(10)) {
        options.minimumFractionDigits = 2
        options.maximumFractionDigits = 2
    } else if (amount.lt(100)) {
        options.minimumFractionDigits = 1
        options.maximumFractionDigits = 1
    } else {
        options.minimumFractionDigits = 0
        options.maximumFractionDigits = 0
    }

    const formattedQuote = formatNumber(amount.toNumber(), options)

    switch (variant) {
        case 'just_quote':
            return <>{formattedQuote}</>
        case 'base_and_quote': {
            return (
                <>
                    {['1', base.symbol, '=', formattedQuote, quote.symbol].join(
                        NON_BREAKING_SPACE
                    )}
                </>
            )
        }
        default:
            return notReachable(variant)
    }
}

export const FormattedQuoteRate = ({
    rate,
}: {
    rate: FXRate2<CryptoCurrency, CryptoCurrency>
}) => {
    const { formatNumber } = useIntl()
    const { quote } = rate

    const quoteAmount = toNumberWithFraction(rate.rate, quote.rateFraction)

    const options: FormatNumberOptions = {}

    if (quoteAmount < 0.001) {
        options.maximumFractionDigits = 8
        options.maximumSignificantDigits = 1
    } else if (quoteAmount < 1) {
        options.minimumSignificantDigits = 1
        options.maximumSignificantDigits = 2
    } else if (quoteAmount < 10) {
        options.minimumFractionDigits = 2
        options.maximumFractionDigits = 2
    } else if (quoteAmount < 100) {
        options.minimumFractionDigits = 1
        options.maximumFractionDigits = 1
    } else {
        options.minimumFractionDigits = 0
        options.maximumFractionDigits = 0
    }

    return `${formatNumber(quoteAmount, options)}${NON_BREAKING_SPACE}${
        rate.quote.symbol
    }`
}
