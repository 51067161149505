import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgWs = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7271)">
            <Path
                d="M512 256C512 397.384 397.384 512 256 512C114.616 512 0 397.384 0 256C87.93 168.07 162.504 93.496 256 0C397.384 0 512 114.616 512 256Z"
                fill="#D80027"
            />
            <Path
                d="M256 256C256 114.616 256 97.948 256 0C114.616 0 0 114.616 0 256H256Z"
                fill="#0052B4"
            />
            <Path
                d="M205.054 166.957L209.199 179.712H222.609L211.759 187.593L215.904 200.348L205.054 192.465L194.204 200.348L198.349 187.593L187.499 179.712H200.909L205.054 166.957Z"
                fill="#F0F0F0"
            />
            <Path
                d="M137.698 66.783L144.605 88.042H166.957L148.873 101.177L155.782 122.435L137.698 109.296L119.615 122.435L126.523 101.177L108.44 88.042H130.79L137.698 66.783Z"
                fill="#F0F0F0"
            />
            <Path
                d="M204.481 89.043L211.388 110.303H233.739L215.656 123.438L222.563 144.696L204.481 131.557L186.398 144.696L193.306 123.438L175.223 110.303H197.573L204.481 89.043Z"
                fill="#F0F0F0"
            />
            <Path
                d="M151.693 178.087L158.601 199.346H180.951L162.868 212.481L169.776 233.739L151.693 220.601L133.61 233.739L140.518 212.481L122.435 199.346H144.786L151.693 178.087Z"
                fill="#F0F0F0"
            />
            <Path
                d="M93.176 122.435L100.084 143.694H122.435L104.351 156.829L111.259 178.087L93.176 164.949L75.094 178.087L82.001 156.829L63.919 143.694H86.269L93.176 122.435Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7271">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgWs
