import { get as getProxy } from '@zeal/api/requestBackend'

import { notReachable } from '@zeal/toolkit'
import { toBigIntWithFraction } from '@zeal/toolkit/BigInt'
import { ImperativeError } from '@zeal/toolkit/Error'
import { keys, values } from '@zeal/toolkit/Object'
import { number, object, recordStrict, Result } from '@zeal/toolkit/Result'
import * as Web3 from '@zeal/toolkit/Web3'

import {
    CryptoCurrency,
    DefaultCurrency,
    KnownCryptoCurrencies,
} from '@zeal/domains/Currency'
import {
    COIN_GECKO_SUPPORTED_FIAT_CURRENCIES,
    GNOSIS_EURE,
    GNOSIS_EURE_V2,
    GNOSIS_GBPE,
    GNOSIS_GBPE_V2,
    WRAPPED_NATIVE_TOKENS,
} from '@zeal/domains/Currency/constants'
import { captureError } from '@zeal/domains/Error/helpers/captureError'
import { FXRate2 } from '@zeal/domains/FXRate'
import {
    NetworkHexId,
    NetworkMap,
    PredefinedNetwork,
} from '@zeal/domains/Network'
import { findNetworkByHexChainId } from '@zeal/domains/Network/constants'
import { DefaultCurrencyConfig } from '@zeal/domains/Storage'

// https://docs.coingecko.com/reference/asset-platforms-list
const COIN_GECKO_NETWORK_MAP: Record<PredefinedNetwork['name'], string> = {
    Ethereum: 'ethereum',
    Polygon: 'polygon-pos',
    PolygonZkevm: 'polygon-zkevm',
    Linea: 'linea',
    Arbitrum: 'arbitrum-one',
    zkSync: 'zksync',
    Aurora: 'aurora',
    Avalanche: 'avalanche',
    BSC: 'binance-smart-chain',
    Celo: 'celo',
    Fantom: 'fantom',
    Gnosis: 'xdai',
    Optimism: 'optimistic-ethereum',
    Base: 'base',
    Blast: 'blast',
    OPBNB: 'opbnb',
    Cronos: 'cronos',
    Mantle: 'mantle',
    Manta: 'manta-pacific',
}

type RatesMap = Record<
    CryptoCurrency['id'],
    FXRate2<CryptoCurrency, DefaultCurrency> | null
>

const parseCoinGeckoRate = ({
    input,
    defaultCurrency,
    knownCryptoCurrencies,
}: {
    input: unknown
    defaultCurrency: DefaultCurrency
    knownCryptoCurrencies: KnownCryptoCurrencies
}): Result<unknown, RatesMap> =>
    object(input)
        .andThen((prices) =>
            recordStrict(prices, {
                keyParser: Web3.address.parse,
                valueParser: (value) =>
                    object(value).andThen((valObj) =>
                        number(valObj[defaultCurrency.code.toLowerCase()])
                    ),
            })
        )
        .map((prices) =>
            keys(prices).reduce((hash, address) => {
                const currency = values(knownCryptoCurrencies).find(
                    (item) => item.address === address
                )

                if (currency) {
                    hash[currency.id] = {
                        base: currency,
                        quote: defaultCurrency,
                        rate: toBigIntWithFraction(
                            prices[address],
                            defaultCurrency.rateFraction
                        ),
                    }
                }

                // TODO @resetko-zeal how to report if there is no currency found somehow?
                return hash
            }, {} as RatesMap)
        )

// Coingecko does not support some of the currencies, so we map them to other ones
// Also it does not work well with natives, so we map them with wrapped ones
const COIN_GECKO_QUIRKS: { from: CryptoCurrency; to: CryptoCurrency }[] = [
    { from: GNOSIS_EURE_V2, to: GNOSIS_EURE },
    { from: GNOSIS_GBPE_V2, to: GNOSIS_GBPE },
    ...WRAPPED_NATIVE_TOKENS.map(([from, to]) => ({ from, to })),
]

const DIRECT_QUIRKS_MAP: Record<CryptoCurrency['id'], CryptoCurrency> =
    COIN_GECKO_QUIRKS.reduce(
        (hash, item) => {
            hash[item.from.id] = item.to
            return hash
        },
        {} as Record<CryptoCurrency['id'], CryptoCurrency>
    )

const REVERSE_QUIRKS_MAP: Record<CryptoCurrency['id'], CryptoCurrency> =
    COIN_GECKO_QUIRKS.reduce(
        (hash, item) => {
            hash[item.to.id] = item.from
            return hash
        },
        {} as Record<CryptoCurrency['id'], CryptoCurrency>
    )

export const fetchRates = async ({
    cryptoCurrencies,
    defaultCurrencyConfig,
    networkMap,
    signal,
}: {
    cryptoCurrencies: CryptoCurrency[]
    defaultCurrencyConfig: DefaultCurrencyConfig
    networkMap: NetworkMap
    signal?: AbortSignal
}): Promise<RatesMap> => {
    if (
        !COIN_GECKO_SUPPORTED_FIAT_CURRENCIES.has(
            defaultCurrencyConfig.defaultCurrency.code
        )
    ) {
        captureError(
            new ImperativeError('Unsupported default currency', {
                defaultCurrencyConfig,
            })
        )
        return {}
    }

    const currencies = cryptoCurrencies.map(
        (currency) => DIRECT_QUIRKS_MAP[currency.id] || currency
    )

    const perNetworkMap: Record<NetworkHexId, CryptoCurrency[]> =
        currencies.reduce(
            (acc, item) => {
                if (!acc[item.networkHexChainId]) {
                    acc[item.networkHexChainId] = []
                }
                acc[item.networkHexChainId].push(item)
                return acc
            },
            {} as Record<NetworkHexId, CryptoCurrency[]>
        )

    const networkHexIds = keys(perNetworkMap)

    const responses = await Promise.all(
        networkHexIds.map((networkHexId) => {
            const currencies = perNetworkMap[networkHexId]
            const knownCryptoCurrencies: KnownCryptoCurrencies =
                currencies.reduce((hash, item) => {
                    hash[item.id] = item
                    return hash
                }, {} as KnownCryptoCurrencies)

            const network = findNetworkByHexChainId(networkHexId, networkMap)

            switch (network.type) {
                case 'predefined': {
                    const coingGeckoNetwork =
                        COIN_GECKO_NETWORK_MAP[network.name] || null
                    const addresses = currencies
                        .map((currency) => currency.address)
                        .join(',')

                    return getProxy(
                        `/proxy/cgv3/simple/token_price/${coingGeckoNetwork}`,
                        {
                            query: {
                                contract_addresses: addresses,
                                vs_currencies:
                                    defaultCurrencyConfig.defaultCurrency.code,
                            },
                        },
                        signal
                    )
                        .then((res) => {
                            const parsedMap = parseCoinGeckoRate({
                                input: res,
                                defaultCurrency:
                                    defaultCurrencyConfig.defaultCurrency,
                                knownCryptoCurrencies,
                            }).getSuccessResultOrThrow(
                                'cannot parse coingecko rate'
                            )

                            keys(parsedMap).forEach((currencyId) => {
                                const currency = REVERSE_QUIRKS_MAP[currencyId]

                                if (currency && parsedMap[currencyId]) {
                                    // Response will include both original and quirks currencies
                                    parsedMap[currency.id] = {
                                        quote: defaultCurrencyConfig.defaultCurrency,
                                        rate: parsedMap[currencyId].rate,
                                        base: currency,
                                    }
                                }
                            })

                            return parsedMap
                        })
                        .catch((error) => {
                            captureError(error)
                            return null
                        })
                }

                case 'custom':
                case 'testnet':
                    // Rates are not available for custom and testnet networks
                    return null

                default:
                    return notReachable(network)
            }
        })
    )

    return responses
        .filter((map) => map !== null)
        .reduce((fullMap, map) => ({ ...fullMap, ...map }), {} as RatesMap)
}

export const fetchRate = async ({
    cryptoCurrency,
    defaultCurrencyConfig,
    networkMap,
    signal,
}: {
    cryptoCurrency: CryptoCurrency
    defaultCurrencyConfig: DefaultCurrencyConfig
    networkMap: NetworkMap
    signal?: AbortSignal
}): Promise<FXRate2<CryptoCurrency, DefaultCurrency> | null> => {
    const response = await fetchRates({
        defaultCurrencyConfig,
        cryptoCurrencies: [cryptoCurrency],
        networkMap,
        signal,
    })
    return response[cryptoCurrency.id] || null
}
