import { notReachable } from '@zeal/toolkit'

import { fromString } from '@zeal/domains/Address/helpers/fromString'
import { CryptoCurrency } from '@zeal/domains/Currency'
import {
    ETHEREUM_ETH,
    FIAT_CURRENCIES,
    GNOSIS_AAVE_EURE,
    GNOSIS_EURA,
    GNOSIS_EURE,
    GNOSIS_SDAI,
    GNOSIS_STEUR,
    GNOSIS_WETH,
    GNOSIS_WSTETH,
    GNOSIS_XDAI,
} from '@zeal/domains/Currency/constants'
import {
    Taker,
    TakerApyMap,
    TakerType,
    ZealTakerType,
} from '@zeal/domains/Earn'
import { GNOSIS } from '@zeal/domains/Network/constants'

export const EARN_USD_TAKER_USER_CURRENCY = FIAT_CURRENCIES.USD
export const EARN_EUR_TAKER_USER_CURRENCY = FIAT_CURRENCIES.EUR
export const EARN_ETH_TAKER_USER_CURRENCY = ETHEREUM_ETH

export const DEFAULT_EARN_SLIPPAGE_PERCENT = 0.1

export const DUST_AMOUNT: Record<TakerType, bigint> = {
    usd: 1_000_000_000_000_000_000n, // GNOSIS_SDAI
    eur: 1_000_000_000_000_000_000n, // GNOSIS_AAVE_EURE
    angle_eur: 1_000_000_000_000_000_000n, // GNOSIS_ANGLE_EUR
    eth: 1_000_000_000_000_00n, // GNOSIS_WSTETH
}

export const EARN_PRIMARY_INVESTMENT_ASSETS_MAP: Record<
    ZealTakerType,
    CryptoCurrency
> = {
    usd: GNOSIS_SDAI,
    eur: GNOSIS_AAVE_EURE,
    eth: GNOSIS_WSTETH,
}

export const EARN_BASE_ASSET_MAP: Record<TakerType, CryptoCurrency> = {
    usd: GNOSIS_XDAI,
    eur: GNOSIS_EURE,
    eth: GNOSIS_WETH,
    angle_eur: GNOSIS_EURA,
}

export const EARN_ALL_INVESTMENT_ASSETS_MAP: Record<TakerType, CryptoCurrency> =
    {
        ...EARN_PRIMARY_INVESTMENT_ASSETS_MAP,
        angle_eur: GNOSIS_STEUR,
    }

export const DEFAULT_TAKER_APY_MAP: TakerApyMap = {
    usd: 8.9,
    eur: 1.9,
    eth: 3.9,
    angle_eur: 5.3,
} as const

export const DEFAULT_ETH_TAKER_APY = 3.9

export const GNOSIS_S_DAI_VAULT_ADDRESS = fromString(
    '0x670daeaF0F1a5e336090504C68179670B5059088'
).getSuccessResultOrThrow('')

export const EURE_AAVE_POOL_ADDRESS = fromString(
    '0xb50201558B00496A145fE76f7424749556E326D8'
).getSuccessResultOrThrow('')

const _ = (taker: Taker) => {
    switch (taker.type) {
        case 'eth':
        case 'usd':
        case 'eur':
        case 'angle_eur':
            // !!! Every time you add taker make sure you add taker config to AVAILABLE_EARN_CURRENCIES
            break
        /* istanbul ignore next */
        default:
            return notReachable(taker.type)
    }
}

export const EARN_NETWORK = GNOSIS

export const ACCOUNT_FACTORY_ADDRESS = fromString(
    '0x426981eC47Ca15c15C800430754B459b62C14410'
).getSuccessResultOrThrow('')

export const ACCOUNT_FACTORY_ABI = [
    {
        inputs: [{ internalType: 'bytes32', name: '_salt', type: 'bytes32' }],
        name: 'getContractAddress',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes32', name: '_salt', type: 'bytes32' }],
        name: 'isContractDeployed',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
] as const

export const COORDINATOR_FACTORY_ADDRESS = fromString(
    '0xBa24b9d61283df4D61bF1BD9c88f78a57e354cbA'
).getSuccessResultOrThrow('')

export const COORDINATOR_FACTORY_ABI = [
    {
        inputs: [
            {
                internalType: 'address',
                name: '_owner',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_threshold',
                type: 'uint256',
            },
            {
                internalType: 'address',
                name: '_recipient',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '_recipientToken',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '_investmentToken',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '_dustAmount',
                type: 'uint256',
            },
            {
                internalType: 'bool',
                name: '_isRebalanceAccount',
                type: 'bool',
            },
        ],
        name: 'deploy',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        type: 'function',
        name: 'getContractAddress',
        inputs: [
            {
                name: '_salt', // just hash(0x000owner)
                type: 'bytes32',
                internalType: 'bytes32',
            },
        ],
        outputs: [
            {
                name: '',
                type: 'address',
                internalType: 'address',
            },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'isContractDeployed',
        inputs: [
            {
                name: '_salt',
                type: 'bytes32',
                internalType: 'bytes32',
            },
        ],
        outputs: [
            {
                name: '',
                type: 'bool',
                internalType: 'bool',
            },
        ],
        stateMutability: 'view',
    },
] as const

export const HOLDER_ABI = [
    {
        type: 'constructor',
        inputs: [
            {
                name: '_takerFactory',
                type: 'address',
                internalType: 'address',
            },
        ],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'MAX_ACCOUNTS',
        inputs: [],
        outputs: [
            {
                name: '',
                type: 'uint256',
                internalType: 'uint256',
            },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'accountCount',
        inputs: [],
        outputs: [
            {
                name: '',
                type: 'uint256',
                internalType: 'uint256',
            },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'accounts',
        inputs: [
            {
                name: '',
                type: 'uint256',
                internalType: 'uint256',
            },
        ],
        outputs: [
            {
                name: '',
                type: 'address',
                internalType: 'address',
            },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'addAccount',
        inputs: [
            {
                name: '_investmentToken',
                type: 'address',
                internalType: 'address',
            },
            {
                name: '_dustAmount',
                type: 'uint256',
                internalType: 'uint256',
            },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'amountToRebalance',
        inputs: [],
        outputs: [
            {
                name: '',
                type: 'uint256',
                internalType: 'uint256',
            },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getAccountInfo',
        inputs: [],
        outputs: [
            {
                name: '',
                type: 'tuple[]',
                internalType: 'struct Coordinator.AccountInfo[]',
                components: [
                    {
                        name: 'account',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'status',
                        type: 'bool',
                        internalType: 'bool',
                    },
                    {
                        name: 'asset',
                        type: 'address',
                        internalType: 'address',
                    },
                    {
                        name: 'balance',
                        type: 'uint256',
                        internalType: 'uint256',
                    },
                ],
            },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getAccounts',
        inputs: [],
        outputs: [
            {
                name: '',
                type: 'address[8]',
                internalType: 'address[8]',
            },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getAccountAssets',
        inputs: [],
        outputs: [
            {
                name: '',
                type: 'uint256[]',
                internalType: 'uint256[]',
            },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getAccountTokens',
        inputs: [],
        outputs: [
            {
                name: '',
                type: 'address[]',
                internalType: 'address[]',
            },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'isRebalanceAccount',
        inputs: [
            {
                name: '',
                type: 'address',
                internalType: 'address',
            },
        ],
        outputs: [
            {
                name: '',
                type: 'bool',
                internalType: 'bool',
            },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'owner',
        inputs: [],
        outputs: [
            {
                name: '',
                type: 'address',
                internalType: 'address',
            },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'rebalanceTrigger',
        inputs: [],
        outputs: [
            {
                name: '',
                type: 'address',
                internalType: 'address',
            },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'recipient',
        inputs: [],
        outputs: [
            {
                name: '',
                type: 'address',
                internalType: 'address',
            },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'recipientToken',
        inputs: [],
        outputs: [
            {
                name: '',
                type: 'address',
                internalType: 'address',
            },
        ],
        stateMutability: 'view',
    },

    {
        type: 'function',
        name: 'renounceOwnership',
        inputs: [],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'setAccountOrder',
        inputs: [
            {
                name: '_accounts',
                type: 'address[]',
                internalType: 'address[]',
            },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'setRecipient',
        inputs: [
            {
                name: '_recipient',
                type: 'address',
                internalType: 'address',
            },
            {
                name: '_recipientToken',
                type: 'address',
                internalType: 'address',
            },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'setTakerStatus',
        inputs: [
            {
                name: '_account',
                type: 'address',
                internalType: 'address',
            },
            {
                name: '_status',
                type: 'bool',
                internalType: 'bool',
            },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'setThreshold',
        inputs: [
            {
                name: '_threshold',
                type: 'uint256',
                internalType: 'uint256',
            },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'setThresholdWithRecipient',
        inputs: [
            {
                name: '_recipient',
                type: 'address',
                internalType: 'address',
            },
            {
                name: '_recipientToken',
                type: 'address',
                internalType: 'address',
            },
            {
                name: '_rebalancer',
                type: 'address[]',
                internalType: 'address[]',
            },
            {
                name: '_threshold',
                type: 'uint256',
                internalType: 'uint256',
            },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'sweep',
        inputs: [
            {
                name: '_tokens',
                type: 'address[]',
                internalType: 'address[]',
            },
            {
                name: '_amounts',
                type: 'uint256[]',
                internalType: 'uint256[]',
            },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'takerFactory',
        inputs: [],
        outputs: [
            {
                name: '',
                type: 'address',
                internalType: 'address',
            },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'threshold',
        inputs: [],
        outputs: [
            {
                name: '',
                type: 'uint256',
                internalType: 'uint256',
            },
        ],
        stateMutability: 'view',
    },

    {
        type: 'function',
        name: 'withdraw',
        inputs: [
            {
                name: '_takers',
                type: 'address[]',
                internalType: 'address[]',
            },
            {
                name: '_amounts',
                type: 'uint256[]',
                internalType: 'uint256[]',
            },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
] as const

export const TAKER_FACTORY_ABI = [
    {
        inputs: [{ internalType: 'address', name: '_taker', type: 'address' }],
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    { inputs: [], name: 'ERC1167FailedCreateClone', type: 'error' },
    { inputs: [], name: 'OnlyAccountHolderCanDeployContract', type: 'error' },
    { inputs: [], name: 'TakerDeploymentFailed', type: 'error' },
    { inputs: [], name: 'invalidImplementationContract', type: 'error' },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'asyncTaker',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'accountHolder',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'investToken',
                type: 'address',
            },
        ],
        name: 'NewAsyncTaker',
        type: 'event',
    },
    {
        inputs: [{ internalType: 'bytes', name: '_data', type: 'bytes' }],
        name: 'deployContract',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes32', name: '_salt', type: 'bytes32' }],
        name: 'getContractAddress',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'bytes32', name: '_salt', type: 'bytes32' }],
        name: 'isContractDeployed',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
] as const

export const GNOSIS_S_DAI_VAULT_APY_ABI = [
    {
        inputs: [],
        name: 'vaultAPY',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
] as const

export const GNOSIS_SDAI_ABI = [
    {
        inputs: [{ internalType: 'uint256', name: 'shares', type: 'uint256' }],
        name: 'convertToAssets',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
] as const

export const GNOSIS_STEUR_ABI = [
    {
        inputs: [{ internalType: 'uint256', name: 'shares', type: 'uint256' }],
        name: 'previewRedeem',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
] as const

export const ETHEREUM_WSTETH_ADDRESS = fromString(
    '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0'
).getSuccessResultOrThrow('')

export const ETHEREUM_WSTETH_ABI = [
    {
        inputs: [],
        name: 'stEthPerToken',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
] as const

export const AAVE_MARKET_GET_RESERVE_DATA_ABI = [
    {
        inputs: [
            {
                internalType: 'address',
                name: 'asset',
                type: 'address',
            },
        ],
        name: 'getReserveData',
        outputs: [
            {
                components: [
                    {
                        components: [
                            {
                                internalType: 'uint256',
                                name: 'data',
                                type: 'uint256',
                            },
                        ],
                        internalType:
                            'struct DataTypes.ReserveConfigurationMap',
                        name: 'configuration',
                        type: 'tuple',
                    },
                    {
                        internalType: 'uint128',
                        name: 'liquidityIndex',
                        type: 'uint128',
                    },
                    {
                        internalType: 'uint128',
                        name: 'currentLiquidityRate',
                        type: 'uint128',
                    },
                    {
                        internalType: 'uint128',
                        name: 'variableBorrowIndex',
                        type: 'uint128',
                    },
                    {
                        internalType: 'uint128',
                        name: 'currentVariableBorrowRate',
                        type: 'uint128',
                    },
                    {
                        internalType: 'uint128',
                        name: 'currentStableBorrowRate',
                        type: 'uint128',
                    },
                    {
                        internalType: 'uint40',
                        name: 'lastUpdateTimestamp',
                        type: 'uint40',
                    },
                    {
                        internalType: 'uint16',
                        name: 'id',
                        type: 'uint16',
                    },
                    {
                        internalType: 'address',
                        name: 'aTokenAddress',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'stableDebtTokenAddress',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'variableDebtTokenAddress',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'interestRateStrategyAddress',
                        type: 'address',
                    },
                    {
                        internalType: 'uint128',
                        name: 'accruedToTreasury',
                        type: 'uint128',
                    },
                    {
                        internalType: 'uint128',
                        name: 'unbacked',
                        type: 'uint128',
                    },
                    {
                        internalType: 'uint128',
                        name: 'isolationModeTotalDebt',
                        type: 'uint128',
                    },
                ],
                internalType: 'struct DataTypes.ReserveData',
                name: '',
                type: 'tuple',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
] as const
