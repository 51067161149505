import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgVe = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7328)">
            <Path
                d="M486.598 367.304C502.873 333.648 512 295.891 512 256C512 216.109 502.873 178.352 486.598 144.696L256 122.435L25.402 144.696C9.127 178.352 0 216.109 0 256C0 295.891 9.127 333.648 25.402 367.304L256 389.565L486.598 367.304Z"
                fill="#0052B4"
            />
            <Path
                d="M256 512C357.493 512 445.19 452.935 486.598 367.304H25.4021C66.8101 452.935 154.506 512 256 512Z"
                fill="#D80027"
            />
            <Path
                d="M443.367 306.252L429.846 316.818L435.714 332.939L421.487 323.346L407.965 333.91L412.693 317.415L398.468 307.819L415.617 307.221L420.349 290.726L426.219 306.85L443.367 306.252Z"
                fill="#F0F0F0"
            />
            <Path
                d="M408.741 246.282L399.65 260.835L410.681 273.977L394.03 269.831L384.936 284.38L383.737 267.263L367.088 263.11L383 256.681L381.804 239.563L392.833 252.71L408.741 246.282Z"
                fill="#F0F0F0"
            />
            <Path
                d="M355.694 201.769L352.128 218.554L366.988 227.134L349.923 228.933L346.355 245.713L339.375 230.039L322.308 231.83L335.062 220.348L328.083 204.674L342.943 213.252L355.694 201.769Z"
                fill="#F0F0F0"
            />
            <Path
                d="M290.622 178.087L293.013 195.08L309.91 198.058L294.488 205.584L296.877 222.576L284.957 210.232L269.531 217.754L277.589 202.601L265.668 190.259L282.568 193.238L290.622 178.087Z"
                fill="#F0F0F0"
            />
            <Path
                d="M221.377 178.087L229.434 193.238L246.33 190.257L234.413 202.604L242.466 217.756L227.044 210.232L215.122 222.573L217.513 205.584L202.09 198.062L218.989 195.08L221.377 178.087Z"
                fill="#F0F0F0"
            />
            <Path
                d="M156.305 201.769L169.059 213.252L183.916 204.674L176.939 220.354L189.69 231.834L172.625 230.039L165.643 245.713L162.08 228.93L145.014 227.138L159.874 218.556L156.305 201.769Z"
                fill="#F0F0F0"
            />
            <Path
                d="M103.258 246.282L119.171 252.71L130.197 239.566L129.004 256.687L144.912 263.116L128.263 267.265L127.064 284.38L117.974 269.831L101.323 273.98L112.353 260.835L103.258 246.282Z"
                fill="#F0F0F0"
            />
            <Path
                d="M68.634 306.252L85.786 306.85L91.652 290.728L96.387 307.224L113.534 307.823L99.308 317.419L104.036 333.913L90.516 323.349L76.289 332.942L82.158 316.818L68.634 306.252Z"
                fill="#F0F0F0"
            />
            <Path
                d="M486.598 144.696C445.19 59.065 357.493 0 256 0C154.506 0 66.8101 59.065 25.4021 144.696H486.598Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7328">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgVe
