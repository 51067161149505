import { notReachable } from '@zeal/toolkit'

import { App, AppProtocol, AppToken } from '@zeal/domains/App'
import { DefaultCurrency, FiatCurrency } from '@zeal/domains/Currency'
import { FXRate2 } from '@zeal/domains/FXRate'
import {
    applyNullableRate,
    applyRate2,
    mergeRates,
} from '@zeal/domains/FXRate/helpers/applyRate'
import { PortfolioNFT } from '@zeal/domains/NFTCollection'
import { ServerPortfolio } from '@zeal/domains/Portfolio'
import { tokenToToken2 } from '@zeal/domains/Token/helpers/tokenToToken2'

export const updateServerPortfolioUsingDefaultCurrency = (
    portfolio: ServerPortfolio,
    rateToUsd: FXRate2<FiatCurrency, DefaultCurrency> | null
): ServerPortfolio => {
    if (!rateToUsd) {
        const tokens = portfolio.tokens.map((item) => {
            return { ...item, priceInDefaultCurrency: null }
        })
        const apps = portfolio.apps.map((item): App => {
            return {
                ...item,
                protocols: updateAppProtocols(item.protocols, rateToUsd),
                priceInDefaultCurrency: null,
            }
        })
        const nftCollections = portfolio.nftCollections.map((item) => {
            return {
                ...item,
                priceInDefaultCurrency: null,
                nfts: updateNfts(item.nfts, rateToUsd),
            }
        })
        return {
            nftCollections,
            apps,
            tokens,
            currencies: portfolio.currencies,
        }
    }

    const currencies = portfolio.currencies
    currencies[rateToUsd.quote.id] = rateToUsd.quote

    const tokens = portfolio.tokens.map((item) => {
        if (!item.priceInDefaultCurrency) {
            return item
        }
        const priceInDefaultCurrency = applyRate2({
            baseAmount: {
                currency: rateToUsd.base,
                amount: item.priceInDefaultCurrency.amount,
            },
            rate: rateToUsd,
        })
        const token2 = tokenToToken2({
            token: item,
            knownCurrencies: currencies,
        })
        const newRate =
            token2.rate && mergeRates({ rateA: token2.rate, rateB: rateToUsd })
        return {
            ...item,
            rate: newRate && {
                base: newRate.base.id,
                quote: newRate.quote.id,
                rate: newRate.rate,
            },
            priceInDefaultCurrency: {
                currencyId: priceInDefaultCurrency.currency.id,
                amount: priceInDefaultCurrency.amount,
            },
        }
    })
    const apps = portfolio.apps.map((item) => {
        return {
            ...item,
            protocols: updateAppProtocols(item.protocols, rateToUsd),
            priceInDefaultCurrency: applyRate2({
                baseAmount: item.priceInUsd,
                rate: rateToUsd,
            }),
        }
    })
    const nftCollections = portfolio.nftCollections.map((item) => {
        return {
            ...item,
            nfts: updateNfts(item.nfts, rateToUsd),
            priceInDefaultCurrency: applyRate2({
                baseAmount: item.priceInUsd,
                rate: rateToUsd,
            }),
        }
    })

    return {
        nftCollections,
        apps,
        tokens,
        currencies,
    }
}

const updateNfts = (
    nfts: PortfolioNFT[],
    rate: FXRate2<FiatCurrency, DefaultCurrency> | null
): PortfolioNFT[] => {
    return nfts.map((nft) => {
        return {
            ...nft,
            priceInDefaultCurrency: applyNullableRate({
                baseAmount: nft.priceInUsd,
                rate,
            }),
        }
    })
}

const updateAppProtocols = (
    protocols: AppProtocol[],
    rate: FXRate2<FiatCurrency, DefaultCurrency> | null
): AppProtocol[] =>
    protocols.map((protocol) => {
        switch (protocol.type) {
            case 'CommonAppProtocol':
            case 'LendingAppProtocol': {
                return {
                    ...protocol,
                    suppliedTokens: updateTokens(protocol.suppliedTokens, rate),
                    rewardTokens: updateTokens(protocol.rewardTokens, rate),
                    borrowedTokens: updateTokens(protocol.borrowedTokens, rate),
                    priceInDefaultCurrency: applyNullableRate({
                        baseAmount: protocol.priceInDefaultCurrency,
                        rate,
                    }),
                }
            }
            case 'LockedTokenAppProtocol':
                return {
                    ...protocol,
                    rewardTokens: updateTokens(protocol.rewardTokens, rate),
                    lockedTokens: updateTokens(protocol.lockedTokens, rate),
                    priceInDefaultCurrency: applyNullableRate({
                        baseAmount: protocol.priceInDefaultCurrency,
                        rate,
                    }),
                }

            case 'UnknownAppProtocol':
                return {
                    ...protocol,
                    tokens: updateTokens(protocol.tokens, rate),
                    priceInDefaultCurrency: applyNullableRate({
                        baseAmount: protocol.priceInDefaultCurrency,
                        rate,
                    }),
                }

            case 'VestingAppProtocol':
                return {
                    ...protocol,
                    priceInDefaultCurrency: applyNullableRate({
                        baseAmount: protocol.priceInDefaultCurrency,
                        rate,
                    }),
                    vestedToken: {
                        ...protocol.vestedToken,
                        priceInDefaultCurrency: applyNullableRate({
                            baseAmount:
                                protocol.vestedToken.priceInDefaultCurrency,
                            rate,
                        }),
                    },
                    claimableToken: {
                        ...protocol.claimableToken,
                        priceInDefaultCurrency: applyNullableRate({
                            baseAmount:
                                protocol.claimableToken.priceInDefaultCurrency,
                            rate,
                        }),
                    },
                }

            default:
                return notReachable(protocol)
        }
    })

const updateTokens = (
    tokens: AppToken[],
    rate: FXRate2<FiatCurrency, DefaultCurrency> | null
): AppToken[] =>
    tokens.map((item) => {
        return {
            ...item,
            priceInDefaultCurrency: applyNullableRate({
                baseAmount: item.priceInDefaultCurrency,
                rate,
            }),
        }
    })
