import { bigint, object, Result, shape, string } from '@zeal/toolkit/Result'

import { CryptoCurrency, FiatCurrency } from '@zeal/domains/Currency'
import {
    parseCryptoCurrency,
    parseFiatCurrency,
} from '@zeal/domains/Currency/helpers/parse'
import { FXRate, FXRate2 } from '@zeal/domains/FXRate'

/**
 *  @deprecated please use FxRate2 and parse2
 *  TODO @negriienko
 */
export const parse = (input: unknown): Result<unknown, FXRate> =>
    object(input).andThen((obj) =>
        shape({
            base: string(obj.base),
            quote: string(obj.quote),
            rate: bigint(obj.rate),
        })
    )

export const parse2 = (
    input: unknown
): Result<unknown, FXRate2<CryptoCurrency, FiatCurrency>> =>
    object(input)
        .andThen((dto) =>
            shape({
                rate: object(dto.rate).andThen((obj) =>
                    shape({
                        base: string(obj.base),
                        quote: string(obj.quote),
                        rate: bigint(obj.rate),
                    })
                ),
                currencies: object(dto.currencies),
            })
        )
        .andThen((dto) =>
            shape({
                base: object(dto.currencies[dto.rate.base]).andThen(
                    parseCryptoCurrency
                ),
                quote: object(dto.currencies[dto.rate.quote]).andThen(
                    parseFiatCurrency
                ),
                rate: bigint(dto.rate.rate),
            })
        )
