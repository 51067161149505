import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgNz = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 513 512" fill="none">
        <G clipPath="url(#clip0_4_7236)">
            <Path
                d="M512.988 256C512.988 397.384 398.372 512 256.988 512C115.604 512 0.988281 397.384 0.988281 256C0.988281 256.061 256.988 0.028 256.988 0C398.372 0 512.988 114.616 512.988 256Z"
                fill="#0052B4"
            />
            <Path
                d="M256.303 256H256.988C256.988 255.77 256.988 255.546 256.988 255.315C256.76 255.544 256.532 255.772 256.303 256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256.988 133.565C256.988 88.52 256.988 59.005 256.988 0H256.945C115.58 0.024 0.988281 114.629 0.988281 256H134.553V180.789L209.765 256H256.304C256.532 255.772 256.761 255.544 256.989 255.315C256.989 238.068 256.989 222.679 256.989 208.778L181.776 133.565H256.988Z"
                fill="#F0F0F0"
            />
            <Path
                d="M130.503 33.3911C90.4644 56.1901 57.1784 89.4761 34.3794 129.515V256H101.162V100.176V100.174H256.988C256.988 79.1111 256.988 59.0451 256.988 33.3911H130.503Z"
                fill="#D80027"
            />
            <Path
                d="M256.988 224.519L166.035 133.566C166.035 133.566 134.554 133.568 134.554 133.566V133.568L256.987 256H256.988C256.988 256 256.988 234.293 256.988 224.519Z"
                fill="#D80027"
            />
            <Path
                d="M444.318 189.959L449.843 206.965H467.724L453.258 217.475L458.784 234.48L444.318 223.971L429.851 234.48L435.377 217.475L420.91 206.965H438.791L444.318 189.959Z"
                fill="#D80027"
            />
            <Path
                d="M380.399 312.393L388.688 337.903H415.509L393.809 353.667L402.099 379.176L380.399 363.411L358.699 379.176L366.989 353.667L345.289 337.903H372.11L380.399 312.393Z"
                fill="#D80027"
            />
            <Path
                d="M382.813 112.046L389.721 133.305H412.072L393.988 146.44L400.896 167.698L382.813 154.559L364.731 167.698L371.638 146.44L353.556 133.305H375.906L382.813 112.046Z"
                fill="#D80027"
            />
            <Path
                d="M321.31 189.217L329.598 214.727H356.42L334.72 230.491L343.01 256L321.31 240.235L299.61 256L307.9 230.491L286.2 214.727H313.021L321.31 189.217Z"
                fill="#D80027"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7236">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0.988281)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgNz
