import React, { useEffect } from 'react'

import { Actions } from '@zeal/uikit/Actions'
import { Button } from '@zeal/uikit/Button'
import { Header } from '@zeal/uikit/Header'
import { Screen } from '@zeal/uikit/Screen'
import { Spacer } from '@zeal/uikit/Spacer'

import { notReachable } from '@zeal/toolkit'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

import { captureAppError } from '@zeal/domains/Error/helpers/captureAppError'

import { parseAppError } from '../parsers/parseAppError'

export type Props = {
    children: React.ReactNode
    onRetry: (error: unknown) => void
}

type State = { type: 'no_error' } | { type: 'error'; error: unknown }

export class ErrorBoundary extends React.Component<Props, State> {
    state: State = { type: 'no_error' }

    static getDerivedStateFromError(error: unknown): State {
        return { type: 'error', error }
    }

    componentDidCatch(error: unknown, info: { componentStack: string }) {
        captureAppError(parseAppError(error), {
            source: 'error_boundary',
            extra: {
                componentStack: info.componentStack,
            },
        })
    }

    render() {
        switch (this.state.type) {
            case 'no_error':
                return this.props.children

            case 'error':
                return (
                    <Layout
                        error={this.state.error}
                        onRetry={this.props.onRetry}
                    />
                )
            default:
                return notReachable(this.state)
        }
    }
}

const RETRY_DELAY = 2000

type LayoutState = 'delay' | 'ready_to_retry'

export const Layout = ({
    error,
    onRetry,
}: {
    error: unknown
    onRetry: (error: unknown) => void
}) => {
    const [state, setState] = React.useState<LayoutState>(() => {
        switch (ZealPlatform.OS) {
            case 'ios':
            case 'android':
                return 'delay'
            case 'web':
                return 'ready_to_retry'
            default:
                return notReachable(ZealPlatform.OS)
        }
    })

    useEffect(() => {
        switch (state) {
            case 'delay':
                // On mobile we do delay, and pray that sentry will be able to report the error
                const timeout = setTimeout(
                    () => setState('ready_to_retry'),
                    RETRY_DELAY
                )
                return () => clearTimeout(timeout)

            case 'ready_to_retry':
                break
            default:
                return notReachable(state)
        }
    }, [state, error])

    return (
        <Screen background="default" padding="form" onNavigateBack={null}>
            <Header title="Something went wrong" />

            <Spacer />

            <Actions>
                <Button
                    disabled={(() => {
                        switch (state) {
                            case 'delay':
                                return true
                            case 'ready_to_retry':
                                return false
                            default:
                                return notReachable(state)
                        }
                    })()}
                    size="regular"
                    variant="primary"
                    onClick={() => onRetry(error)}
                >
                    Reload Zeal
                </Button>
            </Actions>
        </Screen>
    )
}
