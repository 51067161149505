import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgKy = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7119)">
            <Path
                d="M512 256C512 397.384 397.384 512 256 512C114.616 512 0 397.384 0 256C0 256.061 256 0.028 256 0C397.384 0 512 114.616 512 256Z"
                fill="#0052B4"
            />
            <Path
                d="M255.315 256H256C256 255.77 256 255.546 256 255.315C255.772 255.544 255.544 255.772 255.315 256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 133.565C256 88.52 256 59.005 256 0H255.957C114.591 0.024 0 114.629 0 256H133.565V180.789L208.776 256H255.316C255.544 255.772 255.772 255.544 256.001 255.315C256.001 238.068 256.001 222.679 256.001 208.778L180.788 133.565H256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M367.304 189.218C391.893 189.218 411.826 169.285 411.826 144.696C411.826 120.107 391.893 100.174 367.304 100.174C342.715 100.174 322.782 120.107 322.782 144.696C322.782 169.285 342.715 189.218 367.304 189.218Z"
                fill="#6DA544"
            />
            <Path
                d="M129.515 33.3911C89.4756 56.1901 56.1886 89.4761 33.3906 129.515V256H100.174V100.176V100.174H256C256 79.1111 256 59.0451 256 33.3911H129.515Z"
                fill="#D80027"
            />
            <Path
                d="M255.999 224.519L165.044 133.566H133.564V133.568L255.997 256H255.999C255.999 256 255.999 234.293 255.999 224.519Z"
                fill="#D80027"
            />
            <Path
                d="M289.391 133.565H445.217V200.348H289.391V133.565Z"
                fill="#D80027"
            />
            <Path
                d="M289.391 300.522V345.044H312.815C326.86 358.808 346.085 367.305 367.304 367.305C388.523 367.305 407.748 358.808 421.793 345.044H422.956H445.217V300.522H289.391Z"
                fill="#FFDA44"
            />
            <Path
                d="M289.391 189.217V256C289.391 315.639 367.304 333.913 367.304 333.913C367.304 333.913 445.217 315.639 445.217 256V189.217H289.391Z"
                fill="#338AF3"
            />
            <Path
                d="M367.3 224.889C347.823 224.889 347.823 242.698 328.346 242.698C308.869 242.698 308.869 224.889 289.392 224.889V256.054C308.869 256.054 308.869 273.863 328.346 273.863C347.823 273.863 347.823 256.054 367.3 256.054C386.779 256.054 386.779 273.863 406.259 273.863C425.739 273.863 425.738 256.054 445.218 256.054V224.889C425.739 224.889 425.739 242.698 406.259 242.698C386.779 242.698 386.779 224.889 367.3 224.889Z"
                fill="#F3F3F3"
            />
            <Path
                d="M367.3 162.504C347.823 162.504 347.823 180.313 328.346 180.313C308.869 180.313 308.869 162.504 289.392 162.504V193.669C308.869 193.669 308.869 211.478 328.346 211.478C347.823 211.478 347.823 193.669 367.3 193.669C386.779 193.669 386.779 211.478 406.259 211.478C425.739 211.478 425.738 193.669 445.218 193.669V162.504C425.739 162.504 425.739 180.313 406.259 180.313C386.779 180.313 386.779 162.504 367.3 162.504Z"
                fill="#F3F3F3"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7119">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgKy
