import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgLr = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7203)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#F0F0F0"
            />
            <Path
                d="M58.5161 93.0919H453.484C438.896 75.4269 421.976 59.7619 403.207 46.5469H108.793C90.0241 59.7599 73.1051 75.4259 58.5161 93.0919Z"
                fill="#F0F0F0"
            />
            <Path
                d="M0 256C0 263.847 0.373 271.605 1.064 279.273H510.936C511.627 271.605 512 263.847 512 256C512 248.153 511.627 240.395 510.936 232.728H1.064C0.373 240.395 0 248.153 0 256H0Z"
                fill="#F0F0F0"
            />
            <Path
                d="M27.9161 372.363H484.084C491.622 357.617 497.77 342.046 502.36 325.819H9.64014C14.2291 342.046 20.3791 357.618 27.9161 372.363Z"
                fill="#F0F0F0"
            />
            <Path
                d="M108.793 465.455H403.206C421.975 452.24 438.895 436.575 453.483 418.91H58.5161C73.1051 436.573 90.0241 452.24 108.793 465.455Z"
                fill="#F0F0F0"
            />
            <Path
                d="M9.63916 186.182H502.361C497.771 169.954 491.622 154.382 484.084 139.637H27.9162C20.3792 154.382 14.2292 169.954 9.63916 186.182Z"
                fill="#F0F0F0"
            />
            <Path
                d="M122.435 46.545H403.207C361.567 17.227 310.797 0 256 0C222.609 0 164.075 17.227 122.435 46.545Z"
                fill="#D80027"
            />
            <Path
                d="M244.87 139.637H484.084C475.571 122.984 465.289 107.385 453.484 93.0918H244.87V139.637Z"
                fill="#D80027"
            />
            <Path
                d="M244.87 232.728H510.936C509.496 216.746 506.602 201.182 502.361 186.183H244.87V232.728Z"
                fill="#D80027"
            />
            <Path
                d="M9.63997 325.819H502.36C506.602 310.821 509.496 295.256 510.936 279.274H1.06396C2.50396 295.256 5.39796 310.82 9.63997 325.819Z"
                fill="#D80027"
            />
            <Path
                d="M58.516 418.908H453.484C465.289 404.613 475.571 389.015 484.084 372.363H27.916C36.429 389.016 46.711 404.615 58.516 418.908Z"
                fill="#D80027"
            />
            <Path
                d="M256 512C310.797 512 361.567 494.773 403.207 465.455H108.793C150.433 494.773 201.203 512 256 512Z"
                fill="#D80027"
            />
            <Path
                d="M9.63896 186.182C5.39696 201.182 2.50396 216.745 1.06396 232.727H256V0C138.82 0 40.031 78.732 9.63896 186.182Z"
                fill="#0052B4"
            />
            <Path
                d="M152.388 66.7832L168.963 117.798H222.609L179.211 149.33L195.785 200.348L152.388 168.818L108.989 200.348L125.567 149.33L82.1689 117.798H135.812L152.388 66.7832Z"
                fill="#F0F0F0"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7203">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgLr
