import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgCf = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 513" fill="none">
        <G clipPath="url(#clip0_4_7120)">
            <Path
                d="M256 512.989C397.385 512.989 512 398.374 512 256.989C512 115.604 397.385 0.989258 256 0.989258C114.615 0.989258 0 115.604 0 256.989C0 398.374 114.615 512.989 256 512.989Z"
                fill="#F0F0F0"
            />
            <Path
                d="M480.873 134.554C447.793 73.9252 390.91 28.1562 322.783 9.79419L311.653 134.554H480.873Z"
                fill="#0052B4"
            />
            <Path
                d="M31.127 134.554H200.347L189.217 9.79419C121.09 28.1562 64.208 73.9262 31.127 134.554Z"
                fill="#0052B4"
            />
            <Path
                d="M311.652 256.989V379.424H480.872C500.717 343.053 512 301.339 512 256.989H311.652Z"
                fill="#6DA544"
            />
            <Path
                d="M200.348 256.989H0C0 301.339 11.283 343.053 31.127 379.424H200.347V256.989H200.348Z"
                fill="#6DA544"
            />
            <Path
                d="M31.127 379.424C64.208 440.053 121.09 485.822 189.217 504.184L200.347 395.075L31.127 379.424Z"
                fill="#FFDA44"
            />
            <Path
                d="M322.783 504.184C390.91 485.822 447.793 440.053 480.873 379.424L311.653 395.073L322.783 504.184Z"
                fill="#FFDA44"
            />
            <Path
                d="M311.652 395.073L480.873 379.424H311.652V395.073Z"
                fill="#FFDA44"
            />
            <Path
                d="M200.348 379.424H31.127L200.348 395.073V379.424Z"
                fill="#FFDA44"
            />
            <Path
                d="M256 0.989258C232.893 0.989258 210.511 4.06926 189.217 9.80826V504.171C210.511 509.909 232.893 512.989 256 512.989C279.107 512.989 301.489 509.909 322.783 504.17V9.80826C301.489 4.06926 279.107 0.989258 256 0.989258V0.989258Z"
                fill="#D80027"
            />
            <Path
                d="M137.698 56.6414L144.604 77.8984H166.957L148.875 91.0364L155.782 112.293L137.698 99.1564L119.615 112.293L126.524 91.0364L108.442 77.8984H130.793L137.698 56.6414Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7120">
                <Rect
                    width={512}
                    height={512}
                    fill="white"
                    transform="translate(0 0.989258)"
                />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgCf
