import React, { useEffect } from 'react'

import { ActionBar } from '@zeal/uikit/ActionBar'
import { BackIcon } from '@zeal/uikit/Icon/BackIcon'
import { IconButton } from '@zeal/uikit/IconButton'
import { LoadingLayout as UILoadingLayout } from '@zeal/uikit/LoadingLayout'

import { notReachable } from '@zeal/toolkit'
import { useLoadableData } from '@zeal/toolkit/LoadableData/LoadableData'
import { MsgOf } from '@zeal/toolkit/MsgOf'
import { ZealPlatform } from '@zeal/toolkit/OS/ZealPlatform'

import { AppErrorPopup } from '@zeal/domains/Error/components/AppErrorPopup'
import { parseAppError } from '@zeal/domains/Error/parsers/parseAppError'
import { ZealDAppEntryPoint } from '@zeal/domains/Main'
import {
    NetworkMap,
    NetworkRPCMap,
    PredefinedNetwork,
} from '@zeal/domains/Network'
import { fetchServerPortfolio } from '@zeal/domains/Portfolio/api/fetchPortfolio'
import { DefaultCurrencyConfig } from '@zeal/domains/Storage'

import { AccountTopUp } from '../../AccountTopUp'
import { Connected, SendTransactionToExternalWallet } from '../../types'

type Props = {
    entryPoint: ZealDAppEntryPoint
    sendTransaction: SendTransactionToExternalWallet
    connectionState: Connected
    networkRPCMap: NetworkRPCMap
    supportedNetworks: PredefinedNetwork[]
    defaultCurrencyConfig: DefaultCurrencyConfig
    networkMap: NetworkMap
    onMsg: (msg: Msg) => void
}

type Msg = MsgOf<typeof AccountTopUp>

export const PortfolioLoader = ({
    onMsg,
    entryPoint,
    connectionState,
    supportedNetworks,
    networkRPCMap,
    defaultCurrencyConfig,
    networkMap,
    sendTransaction,
}: Props) => {
    const [loadable, setLoadable] = useLoadableData(fetchServerPortfolio, {
        type: 'loading',
        params: {
            address: connectionState.account.address,
            forceRefresh: false,
            defaultCurrencyConfig,
        },
    })

    useEffect(() => {
        setLoadable({
            type: 'loading',
            params: {
                address: connectionState.account.address,
                defaultCurrencyConfig,
                forceRefresh: false,
            },
        })
    }, [setLoadable, connectionState.account.address, defaultCurrencyConfig])

    switch (loadable.type) {
        case 'loading':
            return <LoadingLayout onMsg={onMsg} />
        case 'loaded':
            switch (entryPoint.type) {
                case 'account_top_up':
                    return (
                        <AccountTopUp
                            networkMap={networkMap}
                            defaultCurrencyConfig={defaultCurrencyConfig}
                            portfolio={loadable.data}
                            networkRPCMap={networkRPCMap}
                            sendTransaction={sendTransaction}
                            account={entryPoint.account}
                            connectionState={connectionState}
                            supportedNetworks={supportedNetworks}
                            keyStoreType={entryPoint.keyStoreType}
                            installationId={entryPoint.installationId}
                            onMsg={onMsg}
                        />
                    )

                /* istanbul ignore next */
                default:
                    return notReachable(entryPoint.type)
            }
        case 'error':
            return (
                <>
                    <LoadingLayout onMsg={onMsg} />
                    <AppErrorPopup
                        error={parseAppError(loadable.error)}
                        installationId={entryPoint.installationId}
                        onMsg={(msg) => {
                            switch (msg.type) {
                                case 'close':
                                    onMsg(msg)
                                    break
                                case 'try_again_clicked':
                                    setLoadable({
                                        type: 'loading',
                                        params: loadable.params,
                                    })
                                    break
                                /* istanbul ignore next */
                                default:
                                    return notReachable(msg)
                            }
                        }}
                    />
                </>
            )
        /* istanbul ignore next */
        default:
            return notReachable(loadable)
    }
}

const LoadingLayout = ({ onMsg }: { onMsg: Props['onMsg'] }) => (
    <UILoadingLayout
        title={null}
        actionBar={
            <ActionBar
                left={(() => {
                    switch (ZealPlatform.OS) {
                        case 'ios':
                        case 'android':
                            return (
                                <IconButton
                                    variant="on_light"
                                    onClick={() => onMsg({ type: 'close' })}
                                >
                                    {({ color }) => (
                                        <BackIcon size={24} color={color} />
                                    )}
                                </IconButton>
                            )
                        case 'web':
                            return null
                        /* istanbul ignore next */
                        default:
                            return notReachable(ZealPlatform.OS)
                    }
                })()}
            />
        }
        onClose={() => onMsg({ type: 'close' })}
    />
)
