import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgBz = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7098)">
            <Path
                d="M512 256C512 168.139 467.731 90.6261 400.287 44.5221L256 33.3911L111.713 44.5211C44.27 90.6261 0 168.14 0 256C0 343.863 44.27 421.374 111.713 467.478L256 478.609L400.287 467.479C467.731 421.374 512 343.863 512 256Z"
                fill="#0052B4"
            />
            <Path
                d="M400.287 44.522C359.202 16.436 309.524 0 256 0C202.477 0 152.798 16.436 111.713 44.522H400.287Z"
                fill="#A2001D"
            />
            <Path
                d="M111.713 467.478C152.797 495.564 202.477 512 256 512C309.524 512 359.202 495.564 400.287 467.478H111.713Z"
                fill="#A2001D"
            />
            <Path
                d="M256 378.435C323.619 378.435 378.435 323.619 378.435 256C378.435 188.381 323.619 133.565 256 133.565C188.381 133.565 133.565 188.381 133.565 256C133.565 323.619 188.381 378.435 256 378.435Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 356.174C311.325 356.174 356.174 311.325 356.174 256C356.174 200.676 311.325 155.826 256 155.826C200.676 155.826 155.826 200.676 155.826 256C155.826 311.325 200.676 356.174 256 356.174Z"
                fill="#6DA544"
            />
            <Path
                d="M256 322.783C292.883 322.783 322.783 292.883 322.783 256C322.783 219.117 292.883 189.217 256 189.217C219.117 189.217 189.217 219.117 189.217 256C189.217 292.883 219.117 322.783 256 322.783Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 239.304L219.826 256V283.826L256 306.087L292.174 283.826V256L256 239.304Z"
                fill="#0052B4"
            />
            <Path
                d="M219.826 222.609H292.174V256H219.826V222.609Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7098">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgBz
