import { AxiosError, CanceledError } from 'axios'

import { ConnectivityError, HttpError } from '@zeal/domains/Error'

export const processFetchFailure = async ({
    error,
    params,
    url,
    method,
}: {
    error: unknown
    params: unknown
    method: 'GET' | 'POST' | 'DELETE'
    url: string
}): Promise<never> => {
    const newError = new ConnectivityError(url, method, params)

    if (error instanceof Error) {
        newError.stack = error.stack
    }

    throw newError
}

export const processFetchResponse = async ({
    params,
    response,
    url,
    method,
}: {
    params: unknown
    url: string
    method: 'GET' | 'POST' | 'DELETE'
    response: Response
}): Promise<string> => {
    if (response.ok) {
        return response.text()
    }

    const status = response.status
    const trace = response.headers.get('trace-id') || null
    const errorData = await response.text().then((data) => {
        try {
            return JSON.parse(data)
        } catch {
            return data
        }
    })

    const requestBody =
        typeof params === 'object' && params !== null && 'body' in params
            ? params.body
            : null

    const queryParams =
        typeof params === 'object' && params !== null && 'query' in params
            ? params.query
            : null

    throw new HttpError(
        url,
        method,
        status,
        trace,
        errorData,
        queryParams,
        requestBody
    )
}

export const convertToHttpErrorToPreserverStack = (error: unknown) => {
    if (error instanceof CanceledError) {
        return Promise.reject(error)
    }

    if (error instanceof AxiosError) {
        const status = error.response?.status || null
        const trace = error.response?.headers?.['trace-id'] || null
        const data = error.response?.data
        const { url, method, params } = error.config

        if (url && method && status) {
            const newError = new HttpError(
                url,
                method,
                status,
                trace,
                data,
                params,
                null // No request body logging for axios, if needed - migrate endpoint to fetch
            )
            newError.stack = error.stack

            // We return customized error if we were able to collect data for it
            return Promise.reject(newError)
        }

        if (url && method && !status) {
            const newError = new ConnectivityError(url, method, params)
            newError.stack = error.stack

            return Promise.reject(newError)
        }

        return Promise.reject(error)
    }

    return Promise.reject(error)
}
