import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgBr = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7106)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#6DA544"
            />
            <Path
                d="M256 100.174L467.478 256L256 411.826L44.5225 256L256 100.174Z"
                fill="#FFDA44"
            />
            <Path
                d="M256 345.043C305.177 345.043 345.043 305.177 345.043 256C345.043 206.823 305.177 166.957 256 166.957C206.823 166.957 166.957 206.823 166.957 256C166.957 305.177 206.823 345.043 256 345.043Z"
                fill="#F0F0F0"
            />
            <Path
                d="M211.478 250.435C195.994 250.435 181.051 252.79 166.985 257.16C167.608 305.8 207.212 345.044 256 345.044C286.168 345.044 312.812 330.027 328.919 307.076C301.362 272.579 258.961 250.435 211.478 250.435Z"
                fill="#0052B4"
            />
            <Path
                d="M343.394 273.06C344.466 267.536 345.045 261.837 345.045 256C345.045 206.822 305.179 166.957 256.002 166.957C219.308 166.957 187.808 189.158 174.176 220.856C186.226 218.359 198.702 217.044 211.481 217.044C263.198 217.043 309.984 238.541 343.394 273.06Z"
                fill="#0052B4"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7106">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgBr
