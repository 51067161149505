import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgCy = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7135)">
            <Path
                d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
                fill="#FCFCFC"
            />
            <Path
                d="M400.696 222.609C392.905 222.609 383.258 222.609 367.305 222.609C367.305 284.081 317.471 333.913 256.001 333.913C194.528 333.913 144.697 284.081 144.697 222.609C133.937 222.609 122.064 222.609 111.306 222.609C111.306 288.824 155.782 344.651 216.492 361.844C209.836 374.488 210.85 390.407 220.358 402.272C232.846 392.263 245.775 381.902 256.743 373.114C267.711 381.903 280.641 392.264 293.128 402.272C302.73 390.289 303.675 374.168 296.798 361.463C356.843 343.848 400.696 288.353 400.696 222.609Z"
                fill="#6DA544"
            />
            <Path
                d="M166.957 211.478C166.957 211.478 166.957 267.13 222.609 267.13L233.739 278.26H256C256 278.26 267.13 244.869 289.391 244.869C289.391 244.869 289.391 222.608 311.652 222.608C333.913 222.608 345.043 222.608 345.043 222.608C345.043 222.608 333.913 178.086 389.565 144.695L367.304 133.565C367.304 133.565 289.391 189.217 233.739 178.087V200.348H211.478L200.348 189.218L166.957 211.478Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7135">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgCy
