import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgPn = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7257)">
            <Path
                d="M512 256C512 397.384 397.384 512 256 512C114.616 512 0 397.384 0 256C0 256.061 256 0.028 256 0C397.384 0 512 114.616 512 256Z"
                fill="#0052B4"
            />
            <Path
                d="M255.315 256H256C256 255.77 256 255.546 256 255.315C255.772 255.544 255.544 255.772 255.315 256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M256 133.565C256 88.52 256 59.005 256 0H255.957C114.591 0.024 0 114.629 0 256H133.565V180.789L208.776 256H255.316C255.544 255.772 255.772 255.544 256.001 255.315C256.001 238.068 256.001 222.679 256.001 208.778L180.788 133.565H256Z"
                fill="#F0F0F0"
            />
            <Path
                d="M129.515 33.391C89.4761 56.19 56.1891 89.476 33.3911 129.515V256H100.174V100.176V100.174H256C256 79.111 256 59.045 256 33.391H129.515Z"
                fill="#D80027"
            />
            <Path
                d="M256 224.519L165.045 133.566H133.565V133.568L255.998 256H256C256 256 256 234.293 256 224.519Z"
                fill="#D80027"
            />
            <Path
                d="M400.696 155.826L367.304 166.957L333.913 155.826L322.783 128L333.913 100.174H400.696L389.565 128L400.696 155.826Z"
                fill="#ACABB1"
            />
            <Path
                d="M289.391 155.826V278.26C289.391 337.9 367.304 356.174 367.304 356.174C367.304 356.174 445.217 337.9 445.217 278.26V155.826H289.391Z"
                fill="#338AF3"
            />
            <Path
                d="M296.415 307.266C315.567 344.035 367.305 356.174 367.305 356.174C367.305 356.174 419.043 344.035 438.195 307.266L367.305 189.218L296.415 307.266Z"
                fill="#6DA544"
            />
            <Path
                d="M445.217 277.023L367.304 155.826L289.391 277.024V278.26C289.391 289.298 292.065 298.916 296.414 307.266L367.304 196.991L438.194 307.265C442.543 298.914 445.217 289.297 445.217 278.259V277.023Z"
                fill="#FFDA44"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7257">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgPn
