import { FiatCurrencyCode } from '@zeal/domains/Currency'

import { CountryISOCode } from '.'

export const EEA_COUNTRIES: CountryISOCode[] = [
    'AT',
    'BE',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'GR',
    'HU',
    'IE',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'NO',
    'PL',
    'PT',
    'SE',
    'SI',
    'SK',
]

export const COUNTRY_TO_CURRENCY_MAP: Partial<
    Partial<Record<CountryISOCode, FiatCurrencyCode>>
> = {
    // EEA
    AT: 'EUR',
    BE: 'EUR',
    CY: 'EUR',
    CZ: 'CZK',
    DE: 'EUR',
    DK: 'DKK',
    EE: 'EUR',
    ES: 'EUR',
    FI: 'EUR',
    FR: 'EUR',
    GR: 'EUR',
    HU: 'HUF',
    IE: 'EUR',
    IT: 'EUR',
    LT: 'EUR',
    LU: 'EUR',
    LV: 'EUR',
    MT: 'EUR',
    NL: 'EUR',
    NO: 'NOK',
    PL: 'PLN',
    PT: 'EUR',
    SE: 'SEK',
    SI: 'EUR',
    SK: 'EUR',

    AE: 'AED',
    AR: 'ARS',
    AU: 'AUD',
    BD: 'BDT',
    BH: 'BHD',
    BM: 'BMD',
    BR: 'BRL',
    CA: 'CAD',
    CH: 'CHF',
    CL: 'CLP',
    CN: 'CNY',
    GB: 'GBP',
    GE: 'GEL',
    HK: 'HKD',
    ID: 'IDR',
    IL: 'ILS',
    IN: 'INR',
    JP: 'JPY',
    KR: 'KRW',
    KW: 'KWD',
    LK: 'LKR',
    MM: 'MMK',
    MX: 'MXN',
    MY: 'MYR',
    NG: 'NGN',
    NZ: 'NZD',
    PH: 'PHP',
    PK: 'PKR',
    RU: 'RUB',
    SA: 'SAR',
    SG: 'SGD',
    TH: 'THB',
    TR: 'TRY',
    TW: 'TWD',
    UA: 'UAH',
    US: 'USD',
    VE: 'VEF',
    VN: 'VND',
    ZA: 'ZAR',
}
