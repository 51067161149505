import * as React from 'react'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { SvgIcon } from '@zeal/uikit/SvgIcon'

const SvgAd = ({ size }: { size: number }) => (
    <SvgIcon width={size} height={size} viewBox="0 0 512 512" fill="none">
        <G clipPath="url(#clip0_4_7079)">
            <Path
                d="M144.696 486.598C178.352 502.872 216.109 512 256 512C295.891 512 333.648 502.872 367.304 486.598L389.565 256L367.304 25.402C333.648 9.128 295.891 0 256 0C216.109 0 178.352 9.128 144.696 25.402L122.435 256L144.696 486.598Z"
                fill="#FFDA44"
            />
            <Path
                d="M367.304 486.589C452.935 445.183 512 357.493 512 256C512 154.507 452.935 66.8171 367.304 25.4111V486.589Z"
                fill="#D80027"
            />
            <Path
                d="M144.696 486.589V25.4111C59.065 66.8171 0 154.506 0 256C0 357.494 59.065 445.183 144.696 486.589Z"
                fill="#0052B4"
            />
            <Path
                d="M256 345.043C256 304.192 256 256 256 256H322.783V289.391C322.783 295.192 311.653 316.448 284.18 333.913C273.775 340.529 263.004 342.657 256 345.043Z"
                fill="#D80027"
            />
            <Path
                d="M189.217 189.217H256V256H189.217V189.217Z"
                fill="#D80027"
            />
            <Path
                d="M289.391 166.957C289.391 154.662 279.424 144.696 267.13 144.696C263.07 144.696 259.276 145.8 256 147.698C252.723 145.8 248.93 144.696 244.87 144.696C232.576 144.696 222.609 154.662 222.609 166.957H166.957V278.26C166.957 319.696 199.863 343.659 225.668 356.081C223.737 359.379 222.609 363.206 222.609 367.305C222.609 379.6 232.576 389.566 244.87 389.566C248.93 389.566 252.724 388.462 256 386.564C259.277 388.462 263.07 389.566 267.13 389.566C279.424 389.566 289.391 379.6 289.391 367.305C289.391 363.206 288.263 359.379 286.331 356.081C312.136 343.659 345.043 319.697 345.043 278.26V166.957H289.391ZM311.652 278.26C311.652 284.061 311.652 301.712 284.18 319.178C273.774 325.794 263.004 330.084 256 332.469C248.996 330.084 238.226 325.794 227.82 319.178C200.348 301.712 200.348 284.061 200.348 278.26V200.348H311.652V278.26Z"
                fill="#FF9811"
            />
        </G>
        <Defs>
            <ClipPath id="clip0_4_7079">
                <Rect width={512} height={512} fill="white" />
            </ClipPath>
        </Defs>
    </SvgIcon>
)
export default SvgAd
