import { useState } from 'react'

import { notReachable } from '@zeal/toolkit'
import { usePollableData } from '@zeal/toolkit/LoadableData/PollableData'
import { MsgOf } from '@zeal/toolkit/MsgOf'

import { Account } from '@zeal/domains/Account'
import { CryptoCurrency, FiatCurrency } from '@zeal/domains/Currency'
import { FXRate2 } from '@zeal/domains/FXRate'
import { fetchRate } from '@zeal/domains/FXRate/api/fetchRate'
import { NetworkMap, PredefinedNetwork } from '@zeal/domains/Network'
import { getNativeTokenAddress } from '@zeal/domains/Network/helpers/getNativeTokenAddress'
import { ServerPortfolio } from '@zeal/domains/Portfolio'
import { DefaultCurrencyConfig } from '@zeal/domains/Storage'

import { Layout } from './Layout'
import { Modal, State as ModalState } from './Modal'
import { Form as IncompleteForm } from './validation'

import { Connected as ConnectedState } from '../../../types'
import { POLYGON_POL } from '../../constants'

type Props = {
    account: Account
    connectionState: ConnectedState
    portfolio: ServerPortfolio
    topUpCurrencies: CryptoCurrency[]
    supportedNetworks: PredefinedNetwork[]
    networkMap: NetworkMap
    defaultCurrencyConfig: DefaultCurrencyConfig
    onMsg: (msg: Msg) => void
}

type Msg =
    | Extract<
          MsgOf<typeof Layout>,
          {
              type:
                  | 'close'
                  | 'on_connect_to_correct_network_clicked'
                  | 'on_disconnect_clicked'
                  | 'on_form_submitted'
          }
      >
    | Extract<MsgOf<typeof Modal>, { type: 'on_crypto_currency_selected' }>

const calculateForm = ({
    connectionState,
    topUpCurrencies,
    defaultCurrencyConfig,
    networkMap,
}: {
    connectionState: ConnectedState
    topUpCurrencies: CryptoCurrency[]

    networkMap: NetworkMap
    defaultCurrencyConfig: DefaultCurrencyConfig
}): IncompleteForm => {
    const connectedNetwork = connectionState.network

    switch (connectedNetwork.type) {
        case 'unsupported_network':
            return {
                currency: POLYGON_POL,
                amount: null,
                networkMap,
                defaultCurrencyConfig,
            }
        case 'supported_network':
            const nativeTokenAddress = getNativeTokenAddress(
                connectedNetwork.network
            )
            const nativeCurrency = topUpCurrencies.find(
                (currency) =>
                    currency.address === nativeTokenAddress &&
                    currency.networkHexChainId ===
                        connectedNetwork.network.hexChainId
            )

            if (!nativeCurrency) {
                return {
                    currency: POLYGON_POL,
                    amount: null,
                    networkMap,
                    defaultCurrencyConfig,
                }
            }

            return {
                currency: nativeCurrency,
                amount: null,
                networkMap,
                defaultCurrencyConfig,
            }
        /* istanbul ignore next */
        default:
            return notReachable(connectedNetwork)
    }
}

const fetchCurrencyRate = async (
    form: IncompleteForm
): Promise<FXRate2<CryptoCurrency, FiatCurrency> | null> => {
    return fetchRate({
        cryptoCurrency: form.currency,
        networkMap: form.networkMap,
        defaultCurrencyConfig: form.defaultCurrencyConfig,
    })
}

const RATE_POLL_INTERVAL_MILLISECONDS = 2000

export const Form = ({
    account,
    topUpCurrencies,
    portfolio,
    onMsg,
    connectionState,
    supportedNetworks,
    networkMap,
    defaultCurrencyConfig,
}: Props) => {
    const [modal, setModal] = useState<ModalState>({ type: 'closed' })
    const [pollable, setPollable] = usePollableData(
        fetchCurrencyRate,
        {
            type: 'loading',
            params: calculateForm({
                connectionState,
                topUpCurrencies,
                defaultCurrencyConfig,
                networkMap,
            }),
        },
        {
            pollIntervalMilliseconds: RATE_POLL_INTERVAL_MILLISECONDS,
            stopIf: () => false,
        }
    )

    return (
        <>
            <Layout
                portfolio={portfolio}
                connectionState={connectionState}
                account={account}
                ratePollable={pollable}
                onMsg={(msg) => {
                    switch (msg.type) {
                        case 'on_crypto_currency_selector_clicked':
                            setModal({ type: 'crypto_currency_selector' })
                            break
                        case 'on_form_change':
                            setPollable({
                                type: 'loading',
                                params: msg.form,
                            })
                            break
                        case 'on_form_submitted':
                        case 'on_connect_to_correct_network_clicked':
                        case 'close':
                        case 'on_disconnect_clicked':
                            onMsg(msg)
                            break
                        /* istanbul ignore next */
                        default:
                            return notReachable(msg)
                    }
                }}
            />
            <Modal
                state={modal}
                form={pollable.params}
                portfolio={portfolio}
                topUpCurrencies={topUpCurrencies}
                connectionState={connectionState}
                supportedNetworks={supportedNetworks}
                onMsg={(msg) => {
                    switch (msg.type) {
                        case 'close':
                            setModal({ type: 'closed' })
                            break
                        case 'on_crypto_currency_selected':
                            setModal({ type: 'closed' })
                            onMsg(msg)
                            setPollable({
                                type: 'loading',
                                params: {
                                    ...pollable.params,
                                    currency: msg.currency,
                                },
                            })
                            break
                        case 'on_disconnect_clicked':
                            onMsg(msg)
                            break
                        /* istanbul ignore next */
                        default:
                            return notReachable(msg)
                    }
                }}
            />
        </>
    )
}
